import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import RadioInput from './inputs/radio.input'
import TextInput from './inputs/text.inputs'
import Text from '@components/ui/text'
import RequestFiles from '@components/ui/requestFiles'
import { Colors } from '@utils/css-variables'
import { joinClasses, makeClasses } from '@utils/styles'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import {
	formatStrapiText,
	getFilesFromStore,
	getSeparetedFilesFromStore
} from '@utils/methods'
import RequestFilesWithMultipleButtons from '@components/ui/requestFilesWithMultipleButtons'
import DateFilterInput from './inputs/datefilter.input'
import Notification from '@components/ui/notification'
import MapForm from '@components/ui/map/MapForm'
import { getLocaleDate } from './__forms.utils'

type Classes = {
	button: string
	outlinedButton: string
	buttonContainer: string
	childHeader: string
	fileHelperSection: string
	fileContainer: string
	inputContainer: string
}

const classes: Classes = makeClasses({
	button: {
		display: 'inline-block',
		padding: '10px 20px',
		fontSize: '13px',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: Colors.secondary,
		border: 'none',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2,
			color: Colors.white
		}
	},
	outlinedButton: {
		background: 'none',
		border: `1px solid ${Colors.secondary} !important`,
		color: Colors.secondary
	},
	buttonContainer: {
		display: 'flex',
		width: 'fit-content',
		columnGap: '10px',
		alignItems: 'center',
		marginBottom: '1rem!important'
	},
	childHeader: {
		fontSize: '18px',
		fontWeight: '500',
		color: Colors.secondary
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	},
	inputContainer: {
		marginBottom: '20px'
	}
})

const CarSharingForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	setDisableNextButton,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onCustomError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const [subscriptionAmountWarning, setSubscriptionAmountWarning] =
		useState<boolean>(false)

	const [grantForWhomInput, setGrantForWhomInput] = useState<RequestInputs>({
		name: 'grantForWhom',
		label: pageAssets?.request_form_sustainableHygiene_forWhom_label,
		labelKey: 'request_form_sustainableHygiene_forWhom_label',
		value: `${answers.grantForWhom}` ?? '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const grantForWhomList: RadioList[] = [
		{
			label: pageAssets?.select_carSharing_forWhom_firstOption,
			value: REQUEST_CONFIGS_KEYS.carSharingForWhom.firstOption,
			key: 'select_carSharing_forWhom_firstOption'
		},
		{
			label: pageAssets?.select_sustainableHygiene_forWhom_secondOption,
			value: REQUEST_CONFIGS_KEYS.carSharingForWhom.secondOption,
			key: 'select_sustainableHygiene_forWhom_secondOption',
			extraText:
				pageAssets?.select_sustainableHygiene_forWhom_secondOption_extra
		}
	]

	const [beneficiaryFirstNameInput, setBeneficiaryFirstNameInput] =
		useState<RequestInputs>({
			name: 'beneficiaryFirstName',
			label: pageAssets?.request_form_sustainableHygiene_beneficiary_firstName,
			labelKey: 'request_form_sustainableHygiene_beneficiary_firstName',
			value: answers.beneficiaryFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [beneficiaryLastNameInput, setBeneficiaryLastNameInput] =
		useState<RequestInputs>({
			name: 'beneficiaryLastName',
			label: pageAssets?.request_form_sustainableHygiene_beneficiary_lastName,
			labelKey: 'request_form_sustainableHygiene_beneficiary_lastName',
			value: answers.beneficiaryLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [beneficiaryAddressInput, setBeneficiaryAddressInput] =
		useState<RequestInputs>({
			name: 'beneficiaryAddress',
			label: pageAssets?.request_form_sustainableHygiene_beneficiary_address,
			labelKey: 'request_form_sustainableHygiene_beneficiary_address',
			value: answers.beneficiaryAddress || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [beneficiaryAppartmentInput, setBeneficiaryAppartmentInput] =
		useState<RequestInputs>({
			name: 'beneficiaryAppartment',
			label: pageAssets?.request_form_sustainableHygiene_beneficiary_address,
			labelKey: 'request_form_sustainableHygiene_beneficiary_address',
			value: answers.beneficiaryAppartment || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [subsidyReceivedInput, setSubsidyReceivedInput] =
		useState<RequestInputs>({
			name: 'subsidyReceived',
			label: pageAssets.request_form_carSharing_myself_subsidyReceived_label,
			labelKey: 'request_form_carSharing_myself_subsidyReceived_label',
			value: answers.subsidyReceived?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

	const [proofOfResidencyInput, setproofOfResidencyInput] =
		useState<RequestInputFiles>({
			name: 'proofOfResidencyInput',
			label: pageAssets?.request_form_sustainableHygiene_proofOfResidency_label,
			labelKey: 'request_form_sustainableHygiene_proofOfResidency_label',
			description:
				pageAssets?.request_form_sustainableHygiene_proofOfResidency_description,
			files: new Set<File>(),
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			numberFilesRequired: 2,
			separateFiles:
				filesInputs?.find((el) => el.name == 'proofOfResidencyInput')
					?.separateFiles || {},
			category: requestCategories.proofOfResidency
		})

	const [subscriptionDateInput, setSubscriptionDateInput] =
		useState<RequestInputs>({
			name: 'subscriptionDate',
			label: pageAssets?.request_form_carSharing_subscriptionDate_label,
			labelKey: 'request_form_carSharing_subscriptionDate_label',
			description:
				pageAssets?.request_form_carSharing_subscriptionDate_description,
			value: answers.subscriptionDate || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [subscriptionAmountInput, setSubscriptionAmountInput] =
		useState<RequestInputs>({
			name: 'subscriptionAmount',
			label: pageAssets?.request_form_carSharing_subscriptionAmount_label,
			labelKey: 'request_form_carSharing_subscriptionAmount_label',
			description:
				pageAssets.request_form_carSharing_subscriptionAmount_description,
			value: answers.subscriptionAmount || '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

	const [usageFeeBill, setUsageFeeBill] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_carSharing_usageFeeBill_label,
		labelKey: 'request_form_carSharing_usageFeeBill_label',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		category: requestCategories.proofOfPurchase
	})

	const [serviceMembership, setServiceMembership] = useState<RequestInputFiles>(
		{
			name: 'secondInput',
			label: pageAssets?.request_form_carSharing_serviceMembership_label,
			labelKey: 'request_form_carSharing_serviceMembership_label',
			description: '',
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'secondInput')?.files || []
			),
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			numberFilesRequired: 1,
			numberMaxFiles: 1,
			category: requestCategories.proofOfPurchase
		}
	)

	const radioListSubsidyReceived: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const isGrantforMyself = (value) => {
		return (
			value?.toString() == REQUEST_CONFIGS_KEYS.carSharingForWhom.firstOption
		)
	}

	const isGrantforBeneficiary = (value) => {
		return (
			value?.toString() == REQUEST_CONFIGS_KEYS.carSharingForWhom.secondOption
		)
	}

	const onSelectGrantForWhomInput = (value: string) => {
		setGrantForWhomInput({ ...grantForWhomInput, value })

		setTimeout(() => {
			setBeneficiaryFirstNameInput({
				...beneficiaryFirstNameInput,
				required: isGrantforBeneficiary(value)
			})
			setBeneficiaryLastNameInput({
				...beneficiaryLastNameInput,
				required: isGrantforBeneficiary(value)
			})
			setBeneficiaryAddressInput({
				...beneficiaryAddressInput,
				required: isGrantforBeneficiary(value)
			})

			// setSubsidyReceivedInput({
			// 	...subsidyReceivedInput,
			// 	label:
			// 		value == REQUEST_CONFIGS_KEYS.carSharingForWhom.firstOption
			// 			? pageAssets?.request_form_carSharing_myself_subsidyReceived_label
			// 			: pageAssets?.request_form_carSharing_beneficiary_subsidyReceived_label
			// })

			setproofOfResidencyInput({
				...proofOfResidencyInput,
				label:
					value == REQUEST_CONFIGS_KEYS.carSharingForWhom.secondOption
						? pageAssets?.request_form_sustainableHygiene_proofOfResidencyBeneficiary_label
						: pageAssets?.request_form_sustainableHygiene_proofOfResidency_label,
				labelKey:
					value == REQUEST_CONFIGS_KEYS.carSharingForWhom.secondOption
						? 'request_form_sustainableHygiene_proofOfResidencyBeneficiary_label'
						: 'request_form_sustainableHygiene_proofOfResidency_label'
			})
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						grantForWhomInput.name,
						beneficiaryFirstNameInput.name,
						beneficiaryLastNameInput.name,
						beneficiaryAddressInput.name,
						proofOfResidencyInput.name,
						subsidyReceivedInput.name
					].includes(error)
			)
		)
	}

	const onSelectBeneficiaryFirstNameInput = (value: string) => {
		setBeneficiaryFirstNameInput({ ...beneficiaryFirstNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, beneficiaryFirstNameInput.name)
		)
	}

	const onSelectBeneficiaryLastNameInput = (value: string) => {
		setBeneficiaryLastNameInput({ ...beneficiaryLastNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, beneficiaryLastNameInput.name)
		)
	}

	const onSelectBeneficiaryAddressInput = (value: string) => {
		setBeneficiaryAddressInput({ ...beneficiaryAddressInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, beneficiaryAddressInput.name)
		)
	}

	const onSelectBeneficiaryAppartmentInput = (value: string) => {
		setBeneficiaryAppartmentInput({ ...beneficiaryAppartmentInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, beneficiaryAppartmentInput.name)
		)
	}

	const onSelectSubsidyReceivedInput = (value: string) => {
		setSubsidyReceivedInput({ ...subsidyReceivedInput, value })

		// updateNextButton(value)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, subsidyReceivedInput.name)
		)
	}

	const onSelectSubscriptionAmountInput = (value: string) => {
		// setDisableNextButton && setDisableNextButton(!!isValidAmount(value))

		setSubscriptionAmountInput({ ...subscriptionAmountInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, subscriptionAmountInput.name)
		)
	}

	const onSelectUsageFeeBill = (files: Set<File>) => {
		setUsageFeeBill({
			...usageFeeBill,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, usageFeeBill.name))
	}

	const onSelectServiceMembership = (files: Set<File>) => {
		setServiceMembership({
			...serviceMembership,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, serviceMembership.name))
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}
			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const generateCleanStringFromAnswer = (value) => {
		let cleanString = ''
		let firstEl = true

		value?.map((el) => {
			if (el?.checked) {
				if (!firstEl) {
					cleanString += ','
				} else {
					firstEl = false
				}

				cleanString += el.value
			}
		})

		if (cleanString) {
			return cleanString
		}

		return ''
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, grantForWhomInput))
	}, [grantForWhomInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryFirstNameInput))
	}, [beneficiaryFirstNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryLastNameInput))
	}, [beneficiaryLastNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryAddressInput))
	}, [beneficiaryAddressInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryAppartmentInput))
	}, [beneficiaryAppartmentInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, subsidyReceivedInput))
	}, [subsidyReceivedInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, proofOfResidencyInput)
		)
	}, [proofOfResidencyInput])

	const onSelectSubscriptionDateInput = (value: string) => {
		setSubscriptionDateInput({ ...subscriptionDateInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, subscriptionDateInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, subscriptionDateInput))
	}, [subscriptionDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, subscriptionAmountInput))
	}, [subscriptionAmountInput])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, usageFeeBill))
	}, [usageFeeBill])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, serviceMembership))
	}, [serviceMembership])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
			]
		})
	}, [])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// proofOfResidencyInput input
		// separateFiles
		if (
			!!answers.proofOfResidency &&
			Object.keys(proofOfResidencyInput.separateFiles!).length == 0
		) {
			getSeparetedFilesFromStore('proofOfResidencyInput', answers).then(
				(result) => {
					setproofOfResidencyInput({
						...proofOfResidencyInput,
						separateFiles: result
					})
				}
			)
		}

		// usageFeeBill Input
		if (!!answers.firstInput && usageFeeBill.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setUsageFeeBill({
					...usageFeeBill,
					files: result
				})
			})
		}

		// serviceMembership Input
		if (!!answers.secondInput && serviceMembership.files.size == 0) {
			getFilesFromStore('secondInput', answers).then((result: Set<File>) => {
				setServiceMembership({
					...serviceMembership,
					files: result
				})
			})
		}
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_sustainableHygiene_beneficiaryInfo}
							</h4>
						</div>

						<div>
							<strong>{pageAssets[grantForWhomInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(grantForWhomList, answers.grantForWhom)}
							</span>
						</div>

						{isGrantforBeneficiary(answers.grantForWhom) && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_sustainableHygiene_beneficiary_label
									}
								</h5>
								<div>
									<strong>
										{pageAssets[beneficiaryFirstNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.beneficiaryFirstName}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[beneficiaryLastNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.beneficiaryLastName}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[beneficiaryAddressInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.beneficiaryAddress}
									</span>
								</div>

								{answers.beneficiaryAppartment && (
									<div>
										<strong>{pageAssets?.apartment_label}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers.beneficiaryAppartment}
										</span>
									</div>
								)}
							</>
						)}

						{/* Pour moi-même ( Juste le titre ) */}
						{isGrantforMyself(answers.grantForWhom) && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.select_form_sustainableHygiene_situation_firstOption
									}
								</h5>
							</>
						)}

						{/* Pour moi-même OU autre Beneficiare */}
						{(isGrantforBeneficiary(answers.grantForWhom) ||
							isGrantforMyself(answers.grantForWhom)) && (
							<>
								<div>
									<strong>
										{isGrantforBeneficiary(answers.grantForWhom)
											? pageAssets?.request_form_carSharing_beneficiary_subsidyReceived_label
											: pageAssets?.request_form_carSharing_myself_subsidyReceived_label}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{getLabelFromList(
											radioListSubsidyReceived,
											answers?.subsidyReceived
										)}
									</span>
								</div>

								<RequestFilesWithMultipleButtons
									input={proofOfResidencyInput}
									hasError={errors.includes(proofOfResidencyInput.name)}
									setInput={setproofOfResidencyInput}
									numberOfFiles={2}
									onSetHasError={onSetErrorFromFileComponent}
									onFixError={onFixError}
									UpdateRequestInputsErrorsArray={
										UpdateRequestInputsErrorsArray
									}
									errors={errors}
									steps={steps!}
									subSteps={subSteps!}
									formPosition={formPosition!}
									buttonLabels={pageAssets?.request_from_residence_proof_number}
									isSummary
								>
									<></>
								</RequestFilesWithMultipleButtons>
							</>
						)}
					</>
				)}
				{subSteps?.details_2 && (
					<div>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_carSharing_subscription_fees_Info}
						</h4>

						<div>
							<strong>{pageAssets[subscriptionAmountInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.subscriptionAmount}
							</span>
						</div>

						<div>
							<strong>{pageAssets[subscriptionDateInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLocaleDate(answers.subscriptionDate ?? '')}
							</span>
						</div>

						<RequestFiles
							subtitle={usageFeeBill.label}
							name={usageFeeBill.name}
							required={usageFeeBill.required}
							files={usageFeeBill.files}
							hasError={errors.includes(usageFeeBill.name)}
							removeSectionMarginTop
							removeSectionPaddingTop
							subSteps={subSteps}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectUsageFeeBill}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={serviceMembership.label}
							name={serviceMembership.name}
							required={serviceMembership.required}
							files={serviceMembership.files}
							hasError={errors.includes(serviceMembership.name)}
							removeSectionMarginTop
							removeSectionPaddingTop
							subSteps={subSteps}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectServiceMembership}
							maxFiles={1}
							isSummary
						>
							<></>
						</RequestFiles>
					</div>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_sustainableHygiene_beneficiaryInfo}
						</h4>

						<div className={pageUtils.classes.radioLabel}>
							<RadioInput
								name={grantForWhomInput.name}
								label={grantForWhomInput.label}
								value={grantForWhomInput.value}
								list={grantForWhomList}
								onChange={onSelectGrantForWhomInput}
								hasError={errors.includes(grantForWhomInput.name)}
								required
								ref={grantForWhomInput.ref as RefObject<HTMLInputElement>}
								hasBackgroundWrapper={true}
							/>
						</div>

						{/* Pour un(e) bénéficiaire */}
						{isGrantforBeneficiary(grantForWhomInput.value) && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_sustainableHygiene_beneficiary_label
									}
								</h5>

								<div className={pageUtils.classes.fieldsContainer}>
									<TextInput
										id={beneficiaryFirstNameInput.name}
										label={beneficiaryFirstNameInput.label}
										value={beneficiaryFirstNameInput.value}
										onChange={onSelectBeneficiaryFirstNameInput}
										hasError={errors.includes(beneficiaryFirstNameInput.name)}
										required={beneficiaryFirstNameInput.required}
										ref={
											beneficiaryFirstNameInput.ref as RefObject<HTMLInputElement>
										}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={100}
									/>
									<TextInput
										id={beneficiaryLastNameInput.name}
										label={beneficiaryLastNameInput.label}
										value={beneficiaryLastNameInput.value}
										onChange={onSelectBeneficiaryLastNameInput}
										hasError={errors.includes(beneficiaryLastNameInput.name)}
										required={beneficiaryLastNameInput.required}
										ref={
											beneficiaryLastNameInput.ref as RefObject<HTMLInputElement>
										}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={100}
									/>
								</div>

								<MapForm
									addressLabel={beneficiaryAddressInput.label}
									addressRequired={beneficiaryAddressInput.required}
									displayAptInput
									onApartmentInputChange={onSelectBeneficiaryAppartmentInput}
									value={answers?.beneficiaryAddress}
									hasError={errors?.includes(beneficiaryAddressInput.name)}
									onAddressInputChange={onSelectBeneficiaryAddressInput}
									addressLabelNoWrapper
								/>
								<br />
							</>
						)}

						{/* Pour moi-même ( Juste le titre ) */}
						{isGrantforMyself(grantForWhomInput.value) && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.select_form_sustainableHygiene_situation_firstOption
									}
								</h5>
							</>
						)}

						{/* Pour moi-même OU autre Beneficiare */}
						{(isGrantforBeneficiary(grantForWhomInput.value) ||
							isGrantforMyself(grantForWhomInput.value)) && (
							<>
								<RadioInput
									name={subsidyReceivedInput.name}
									label={
										isGrantforBeneficiary(grantForWhomInput.value)
											? pageAssets?.request_form_carSharing_beneficiary_subsidyReceived_label
											: pageAssets?.request_form_carSharing_myself_subsidyReceived_label
									}
									value={subsidyReceivedInput.value}
									list={radioListSubsidyReceived}
									onChange={onSelectSubsidyReceivedInput}
									hasError={errors.includes(subsidyReceivedInput.name)}
									required={true}
									ref={subsidyReceivedInput.ref as RefObject<HTMLInputElement>}
									hasBackgroundWrapper
								/>

								<RequestFilesWithMultipleButtons
									input={proofOfResidencyInput}
									hasError={errors.includes(proofOfResidencyInput.name)}
									setInput={setproofOfResidencyInput}
									numberOfFiles={2}
									onSetHasError={onSetErrorFromFileComponent}
									onFixError={onFixError}
									UpdateRequestInputsErrorsArray={
										UpdateRequestInputsErrorsArray
									}
									errors={errors}
									steps={steps!}
									subSteps={subSteps!}
									formPosition={formPosition!}
									buttonLabels={pageAssets?.request_from_residence_proof_number}
									description={proofOfResidencyInput.description}
								></RequestFilesWithMultipleButtons>
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_carSharing_subscription_fees_Info}
						</h4>

						<DateFilterInput
							id={subscriptionDateInput.name}
							label={subscriptionDateInput.label}
							description={subscriptionDateInput.description}
							value={subscriptionDateInput.value}
							onChange={onSelectSubscriptionDateInput}
							hasError={errors.includes(subscriptionDateInput.name)}
							classWrapper={classes.inputContainer}
							required={subscriptionDateInput.required}
							dateFormat="dd/MM/yyyy"
							minDate={new Date('2024-01-01' + 'T12:00:00')}
						/>

						<TextInput
							type="number"
							id={subscriptionAmountInput.name}
							label={subscriptionAmountInput.label}
							value={subscriptionAmountInput.value}
							description={subscriptionAmountInput.description}
							onChange={onSelectSubscriptionAmountInput}
							hasError={errors.includes(subscriptionAmountInput.name)}
							required
							ref={subscriptionAmountInput.ref as RefObject<HTMLInputElement>}
							classWrapper={classes.inputContainer}
							onBlur={() => {
								setSubscriptionAmountWarning(
									subscriptionAmountInput.value !== '' &&
										Number(subscriptionAmountInput.value) < 250
								)
							}}
							// onKeyPress={(event) => {
							// 	const charCode = event.which ? event.which : event.keyCode
							// 	const char = String.fromCharCode(charCode)

							// 	if (!/^\d$/.test(char)) {
							// 		event.preventDefault()
							// 	}
							// }}
							isMoneyInput
						/>

						{subscriptionAmountWarning && (
							<Notification
								text={
									pageAssets?.request_form_carSharing_subscriptionAmount_notification
								}
								type="warning"
								hasHtml
							/>
						)}

						<RequestFiles
							subtitle={usageFeeBill.label}
							name={usageFeeBill.name}
							required
							files={usageFeeBill.files}
							hasError={errors.includes(usageFeeBill.name)}
							removeSectionMarginTop
							subSteps={subSteps}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectUsageFeeBill}
							maxFiles={usageFeeBill.numberMaxFiles}
							description={usageFeeBill.description}
						></RequestFiles>

						<RequestFiles
							subtitle={serviceMembership.label}
							name={serviceMembership.name}
							required
							files={serviceMembership.files}
							hasError={errors.includes(serviceMembership.name)}
							removeSectionMarginTop
							subSteps={subSteps}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectServiceMembership}
							maxFiles={serviceMembership.numberMaxFiles}
							description={serviceMembership.description}
						></RequestFiles>
					</section>
				</Collapse>
			)}
		</>
	)
}

export default CarSharingForm
