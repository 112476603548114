import React, { FC, useContext, useEffect, useRef } from 'react'
import * as pageUtils from './__index.utils'
import { resScrapperData } from '@services/types/scrapper'
import { formatHtmlForStrapiText, formatStrapiText } from '@utils/methods'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import Collapse from '../collapse'
import { joinClasses } from '@utils/styles'
import Text from '../text'
import { FORMS_ID } from '@templates/requests/__create.utils'

type Props = {
	requirements: string | resScrapperData['json']
	pageId?: string
}

export enum ACCORDION_DESCRIPTION {
	value = 'accordeon_description'
}
const EligibilityRequirement: FC<Props> = ({ requirements, pageId }) => {
	const { pageData } = useContext(AppStateContext)
	const isHistoricalArchive = pageId == FORMS_ID.HISTORICAL_ARCHIVE_FORM

	if (typeof requirements == 'string') {
		const eligibilityRequirementText =
			formatHtmlForStrapiText(requirements).split('<h3>')[0]
		const requiredDocumentsHeader = formatHtmlForStrapiText(requirements)
			.match('<h3>(.*)</h3>')
			?.at(1)
			?.replace('<br>', '')
			?.replace('&nbsp;', ' ')
		const requiredDocuments =
			formatHtmlForStrapiText(requirements).split('</h3>')[1]
		return (
			<>
				<p className={pageUtils.classes.text}> </p>

				<div className={pageUtils.classes.collapse}>
					<Collapse
						id={1}
						buttonText={
							isHistoricalArchive
								? pageData?.assets?.page_requestInfo_useful_information
								: pageData?.assets?.page_requestInfo_eligibility_requirements
						}
						key={`${ACCORDION_DESCRIPTION.value}`}
					>
						<div
							className={joinClasses([
								pageUtils.classes.text,
								ACCORDION_DESCRIPTION.value
							])}
							dangerouslySetInnerHTML={{
								__html: eligibilityRequirementText
							}}
						/>
					</Collapse>
					{!!requiredDocumentsHeader && (
						<Collapse
							id={1}
							buttonText={requiredDocumentsHeader ?? ''}
							key={`${ACCORDION_DESCRIPTION.value}`}
						>
							<div
								className={joinClasses([
									pageUtils.classes.text,
									ACCORDION_DESCRIPTION.value
								])}
								dangerouslySetInnerHTML={{
									__html: requiredDocuments
								}}
							/>
						</Collapse>
					)}
				</div>
			</>
		)
	}

	return (
		<>
			{/* <h4 className={pageUtils.classes.h4}>
				{pageData?.assets?.page_requestInfo_eligibility_requirements}
			</h4> */}

			<div className={pageUtils.classes.collapse}>
				{requirements?.map((requirement, index) => (
					<Collapse
						id={index}
						buttonText={
							<Text content={formatStrapiText(requirement.accordion.Title)} />
						}
						key={`${index}-${ACCORDION_DESCRIPTION.value}`}
					>
						<div
							className={joinClasses([
								pageUtils.classes.text,
								ACCORDION_DESCRIPTION.value
							])}
							dangerouslySetInnerHTML={{
								__html: formatHtmlForStrapiText(requirement.accordion.Summary)?.replace('&gt;', '')
							}}
						/>
					</Collapse>
				))}
			</div>
		</>
	)
}

export default EligibilityRequirement
