import React, { FC, useContext } from 'react'
import { navigate } from 'gatsby'
import Modal from '@components/ui/modal'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import Text from '@components/ui/text'
import { TAB } from '@pages/auth/profile/__index.utils'
import config from '@utils/config'
import useLocalStorage from '@services/hooks/useLocalStorage'

type PropsTypes = {
	isOpenModal: boolean
	setIsOpenModal: (value: boolean) => void
}

const ProfilePageRedirectModal: FC<PropsTypes> = ({
	isOpenModal,
	setIsOpenModal
}) => {
	const { pageData, pathname } = useContext<AppContextProps>(AppStateContext)
	
	const updateProfileRedirectTab = (tab: TAB) => {
		localStorage.setItem(config.localStorage.activeTab, tab)
	}

	const { setToLocalStorage } = useLocalStorage()

	const handlePrimaryButtonClick = () => {
		setToLocalStorage(config.localStorage.authRedirect, pathname);
		updateProfileRedirectTab(TAB.INFOS);
		setIsOpenModal(false);
		navigate(`/auth/profile`, {
			state: {
				form: true,
				url: location.pathname,
				step: 0
			}
		});
	};

	return (
		<Modal
			isOpen={isOpenModal}
			onSetIsOpen={setIsOpenModal}
			primaryButtonText={pageData.assets?.button_continue}
			onPrimaryButton={handlePrimaryButtonClick}
			showExitBtn
			alignBtn="left"
		>
			<div style={{ paddingRight: '25px', marginTop: '-35px' }}>
				<Text content={pageData.assets?.redirect_to_personal_info_modal_text} />
			</div>
		</Modal>
	)
}

export default ProfilePageRedirectModal
