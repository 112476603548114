import ScrapperRepository from '@services/repositories/scrapper.repository'
import { resScrapperData } from '@services/types/scrapper'
import assert from 'assert'

export class Scrapper {
	url: string = ''

	selector?: string | null

	private _repository?

	constructor(params) {
		if (params) {
			Object.assign(this, params)
		}

		this._repository = new ScrapperRepository()
	}

	public async launchScrapping() {
		assert(this.url, 'Can not scrap a page without a valid url')

		return (await this._repository.scrap(
			this.url,
			this.selector
		)) as resScrapperData[]
	}
}
