import React, { FC, useEffect, useState } from 'react'
import * as pageUtils from './__index'
import RequiredIcon from '../requiredIcon'
import RequestFiles from '../requestFiles'
import { joinClasses } from '@utils/styles'
import { RequestInputFiles, StepType } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import Error from '@components/requestForms/inputs/error.input'
import { thirdStepSubSteps } from '@templates/requests/create_tn'
import { REQUEST_TN_DETAILS_STEPS_NAME } from '@services/constants'
import { formatStrapiText } from '@utils/methods'
import Text from '../text'

type Props = {
	input: RequestInputFiles
	hasError: boolean
	children?: React.ReactNode
	numberOfFiles: number
	setInput: React.Dispatch<React.SetStateAction<RequestInputFiles>>
	onSetHasError: (hasError: boolean, inputName: string) => void
	UpdateRequestInputsErrorsArray: (
		errors: string[],
		inputName: string
	) => string[]
	onFixError: React.Dispatch<React.SetStateAction<string[]>>
	errors: string[]
	steps: StepType
	subSteps: thirdStepSubSteps | undefined
	formPosition: number
	isSummary?: boolean
	buttonLabels: string
	description?: string
}

const RequestFilesWithMultipleButtons: FC<Props> = ({
	input,
	children,
	hasError,
	numberOfFiles,
	steps,
	subSteps,
	errors,
	formPosition,
	isSummary,
	buttonLabels,
	setInput,
	onSetHasError,
	onFixError,
	UpdateRequestInputsErrorsArray,
	description
}) => {
	const { pageAssets } = requestForm()
	const [duplicateFile, setDuplicateFiles] = useState<boolean>()

	const insertFiles = (files: Set<File>, name: string) => {
		const keys = Object.keys(input.separateFiles!)

		if (keys.length == 0) {
			setInput({
				...input,
				separateFiles: {
					...input.separateFiles,
					[name]: files
				}
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, input.name))
			setDuplicateFiles(false)
		}
		if (keys.length > 0) {
			const fileExist = keys.find(
				(key) =>
					!![...input.separateFiles![key]].find(
						(file) => !![...files].find((elFile) => elFile.name == file.name)
					)
			)

			if (files.size == 0) {
				const tempInput = { ...input.separateFiles }
				delete tempInput[name]
				setInput({
					...input,
					separateFiles: tempInput
				})

				onFixError(UpdateRequestInputsErrorsArray(errors, input.name))
				setDuplicateFiles(false)
			} else if (fileExist && numberOfFiles > 1) {
				onSetHasError(true, input.name)
				setDuplicateFiles(true)
			} else {
				setInput({
					...input,
					separateFiles: {
						...input.separateFiles,
						[name]: files
					}
				})

				onFixError(UpdateRequestInputsErrorsArray(errors, input.name))
				setDuplicateFiles(false)
			}
		}
	}

	const inputButtonHasError = (files: Set<File>, index: number): boolean => {
		if (input.numberFilesRequired && input.numberFilesRequired < index + 1) {
			return false
		}

		return hasError && (files ? files.size == 0 : true)
	}

	useEffect(() => {
		const subStepsDetails: string[] = [
			REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
			REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		] as string[]
		if (
			(formPosition == steps?.form &&
				subStepsDetails.includes(subSteps?.position as string),
			numberOfFiles < Object.keys(input.separateFiles!).length)
		) {
			setInput({
				...input,
				separateFiles: {}
			})
		}
	}, [numberOfFiles])

	useEffect(() => {
		duplicateFile && setDuplicateFiles(false)
	}, [subSteps])

	const displayFileInputs = (numberOfFiles: number) => {
		return [...Array(numberOfFiles > 0 ? numberOfFiles : 1)].map(
			(el, index) => {
				const buttonName = `${buttonLabels}${
					numberOfFiles > 0 ? index + 1 : ''
				}`

				const isFileRequired = index + 1 <= input.numberFilesRequired!

				let separateFiles = { ...input.separateFiles }
				if (isSummary && !separateFiles[buttonName]) {
					return ''
				}

				return (
					<div style={{ marginBottom: '15px' }}>
						<RequestFiles
							name={input.name}
							subtitle={`${buttonName} ${
								isFileRequired ? '' : '(' + pageAssets?.label_facultative + ')'
							}`}
							required={isFileRequired ?? !numberOfFiles}
							files={separateFiles[buttonName] || new Set()}
							hasError={inputButtonHasError(separateFiles[buttonName], index)}
							removeSectionMarginTop
							onSetHasError={(hasErr, name) =>
								onSetHasError(hasErr, input.name)
							}
							onSetFiles={(files) => insertFiles(files, buttonName)}
							isSummary={isSummary}
							maxFiles={1}
							key={buttonName}
							// description={description}
						>
							<></>
						</RequestFiles>
					</div>
				)
			}
		)
	}

	return (
		<>
			<h4
				className={joinClasses([
					pageUtils.classes.inputTitle,
					pageUtils.classes.fileLabel,
					hasError ? pageUtils.classes.error : ''
				])}
			>
				{input.label} <RequiredIcon />
			</h4>
			{!isSummary && description && (
				<span
					className={joinClasses([
						pageUtils.classes.description,
						hasError ? pageUtils.classes.error : ''
					])}
				>
					<Text content={description} />
				</span>
			)}

			<div>{children}</div>

			{duplicateFile && (
				<Error hasError errorText={pageAssets?.file_validation_same_file} />
			)}

			<div
				className={joinClasses([
					pageUtils.classes.sectionNoPadding,
					pageUtils.classes.filesInput,
					pageUtils.classes.fileGrid
				])}
			>
				{displayFileInputs(numberOfFiles)}
			</div>
		</>
	)
}

export default RequestFilesWithMultipleButtons
