import Breakpoints from '@utils/breakpoints'
import { chevronDownBlueIcon } from '@images/icons'
import { ButtonStyle, Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

type classType = {
	h4: string
	sectionNoPadding: string
	h5: string
	fieldsContainer: string
	smallInputs: string
	select: string
	select2: string
	answer: string
	noMargin: string
	buttonOutline: string
	button: string
	btn: string
	customInput: string
	error: string
	inputTitle: string
	filesInput: string
	radioLabel: string
	textFieldContainer: string
	fileGrid: string
	description: string
	fileHelperSection: string
	fileLabel: string
}

export const classes: classType = makeClasses({
	h4: {
		fontSize: '22px',
		margin: '0 0 30px 0',
		color: Colors.darkBlue1
	},
	sectionNoPadding: {
		'& section': {
			paddingRight: '0px !important',
			paddingLeft: '0px !important',
			paddingTop: '0px !important'
		}
	},
	h5: {
		fontSize: '20px',
		fontWeight: '600',
		margin: '20px 0 20px'
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	smallInputs: {
		'& input': {
			maxWidth: '200px'
		}
	},
	select: {
		display: 'block',
		padding: '0 12px',
		width: '100%',
		height: '44px',
		lineHeight: '25px',
		WebkitFontSmoothing: 'antialiased !important',
		fontWeight: '500px',
		fontSize: '15px',
		color: Colors.lightBlack,
		border: `1px solid ${Colors.grey}`,
		MozAppearance: 'none',
		WebkitAppearance: 'none',
		appearance: 'none',
		backgroundImage: `url(${chevronDownBlueIcon})`,
		backgroundColor: Colors.white,
		backgroundRepeat: 'no-repeat,repeat',
		backgroundPosition: 'right 0.7em top 50%,0 0',
		backgroundSize: '24px'
	},
	select2: {
		[Breakpoints.minWidth('sm')]: {
			'& select': {
				width: '30%'
			}
		}
	},
	answer: {
		display: 'block',
		margin: '10px 0px 20px'
	},
	noMargin: {
		margin: '0px !important'
	},
	btn: {
		...ButtonStyle,
		background: Colors.secondary,
		color: Colors.white,
		'&:hover': {
			background: Colors.primary
		}
	},
	buttonOutline: {
		backgroundColor: 'transparent',
		border: `1px solid ${Colors.secondary}`,
		color: `${Colors.secondary}`
	},
	button: {
		cursor: 'pointer',
		padding: '15px 30px',
		fontSize: '16px',
		marginLeft: '40px',
		height: '48px'
	},
	customInput: {
		'& input': {
			width: '40%'
		}
	},
	error: {
		color: Colors.errorRed
	},
	filesInput: {
		marginBottom: '40px',
		'& section': {
			':first-of-type': {
				paddingBottom: '0px'
			},
			paddingTop: '20px',
			paddingBottom: '0px'
		},
		'& h4': {
			fontWeight: '500',
			lineHeight: '1.62em',
			fontSize: '16px'
		}
	},
	inputTitle: {
		margin: '20px 0 5px',
		padding: '0',
		fontWeight: '500',
		fontSize: '20px',
		lineHeight: '22px',
		color: Colors.lightBlack
	},
	radioLabel: {
		[Breakpoints.maxWidth('sm')]: {
			'& label': {
				display: 'block'
			}
		}
	},
	textFieldContainer: {
		marginBottom: '10px !important'
	},
	fileGrid: {
		display: 'grid',
		columnGap: '20px',
		gridTemplateColumns: '1fr 1fr',
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr'
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: '0',
		fontSize: '14px',
		lineHeight: '1.25em',
		color: Colors.lightGrey2
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px',
		width: '100%'
	},
	fileLabel: {
		fontWeight: 700,
		fontSize: '16px'
	}
})
