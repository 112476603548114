import React, {
	FC,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import * as requestPageUtils from '@templates/requests/__create.utils'
import { useAppDispatch, useAppSelector } from '@services/store'
import Text from '@components/ui/text'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import {
	formatStrapiText,
	getFilesFromStore,
	saveFileToStore
} from '@utils/methods'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import Browser from '@components/browser'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import Modal from '@components/ui/modal'
import { navigate } from 'gatsby'
import { Collapse } from 'react-collapse'
import { IRequestConfigsState } from '@services/store/request/type'
import { RequestMetadata } from '@services/types'
import { REQUEST_CONFIG_KEY_RELATION } from '@services/store/request/constant'
import {
	populateRequestConfigsState,
	populateRequestModelState
} from '@services/store/request'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
type PublicAuctionsRequestFormPDFProps = RequestFormPDFProps & {
	renderRequestFailedModal?: () => React.ReactNode
}

const PublicAuctionsForm: FC<PublicAuctionsRequestFormPDFProps> = ({
	request,
	closeLink,
	loading,
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onSetFilesInputs,
	onProcessingData,
	renderRequestFailedModal
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		ownerSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		dateAndTimeSection: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},
		dateAndTimeSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '27%27%',
			columnGap: '1%',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			'& > div': {
				marginBottom: '0px'
			}
		},
		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			margin: '10px 0 15px',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	// const location = useAppSelector((state) => state.request.location)

	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const { browser } = Browser()

	const dispatch = useAppDispatch()

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

	const [showBillingAdresseBlock, setShowBillingAdresseBlock] =
		useState<boolean>(answers.isSameBillingAdresse == true ? false : true)

	//----------------------------------------------------------------
	//--------------- isSameBillingAdresseInput INPUTS ---------------
	//----------------------------------------------------------------

	// Même adresse que celle inscrite dans mes informations personnelles
	const [isSameBillingAdresseInput, setIsSameBillingAdresseInput] =
		useState<RequestInputs>({
			name: 'isSameBillingAdresse',
			label: pageAssets?.request_form_isSameAdresse_label,
			labelKey: 'request_form_isSameAdresse_label',
			value: answers.isSameBillingAdresse?.toString() || 'false',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	////////////////////////////////////////////////////////////////

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// Preuve d’identité avec photo
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_publicAuctions_firstInput_label,
		labelKey: 'request_form_publicAuctions_firstInput_label',
		description: pageAssets?.request_form_publicAuctions_firstInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		numberMaxFiles: 2,
		category: requestCategories.other
	})

	// ------------- DETAILS INPUTS SETTERS ----------------

	const onSelectIsSameBillingAdresseInput = (value) => {
		setIsSameBillingAdresseInput({
			...isSameBillingAdresseInput,
			value: `${value}`
		})

		setTimeout(() => {
			setShowBillingAdresseBlock(!value)
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isSameBillingAdresseInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isSameBillingAdresseInput))
	}, [isSameBillingAdresseInput])

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		setFirstQuestion({
			...firstQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
	}

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		if (!!answers.firstInput && firstQuestion.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setFirstQuestion({
					...firstQuestion,
					files: result
				})
			})
		}
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	const submitRequest = () => {
		if (!processingDataAndValidation()) {
			return
		}

		setTimeout(() => {
			onProcessingData && onProcessingData()
		}, 200)
	}

	const processingDataAndValidation = (): boolean => {
		let details: string = ''
		let configs: IRequestConfigsState = {} as IRequestConfigsState
		const listErrors: string[] = []
		const metadata: RequestMetadata[] = []
		let isFirstErrorAlreadySet: boolean = false

		inputs.forEach((input: RequestInputs) => {
			// the usual validation cases

			if (input.required && input.value === '') {
				listErrors.push(input.name)

				if (input.ref && !isFirstErrorAlreadySet) {
					input.ref.current?.focus()
					isFirstErrorAlreadySet = true
				}
			}

			if (!input.required && input.value !== '') {
				metadata.push({
					label: input.labelKey,
					value: `${input.valueKey ? input.valueKey : input.value}`
				})
			}

			if (input.value !== '') {
				let configsInputValue: number | string | boolean = input.value

				switch (REQUEST_CONFIG_KEY_RELATION[input.name]) {
					case 'number':
						configsInputValue = Number(configsInputValue)
						break
					case 'boolean':
						configsInputValue = configsInputValue.toLowerCase() === 'true'
						break
					default:
						break
				}

				configs[input.name] = configsInputValue

				if (input.labelKey !== '') {
					metadata.push({
						label: input.labelKey,
						value: `${input.valueKey ? input.valueKey : input.value}`,
						required: true
					})
				}
			}

			if (
				!input.required &&
				input.value == '' &&
				answers.hasOwnProperty(input.name)
			) {
				configs[input.name] = undefined
			}
		})

		dispatch(populateRequestConfigsState(configs))

		const cleanedFiles: RequestInputFiles[] = []

		inputFiles.forEach((input: RequestInputFiles) => {
			if (
				input.required &&
				(!!input.separateFiles
					? Object.keys(input.separateFiles).length === 0
					: input.files.size === 0)
			) {
				listErrors.push(input.name)
			}

			// clean deleted files of non required files
			if (!input.required && input.files.size == 0) {
				let config = { ...configs }

				config[input.name] = {}

				dispatch(populateRequestConfigsState(config))
			}

			if (
				input.files.size > 0 ||
				(!!input.separateFiles && Object.keys(input.separateFiles).length > 0)
			) {
				if (
					input.numberFilesRequired &&
					(!!input.separateFiles
						? Object.keys(input.separateFiles).length <
						  input.numberFilesRequired
						: input.files.size < input.numberFilesRequired)
				) {
					listErrors.push(input.name)
				}

				if (!!input.separateFiles) {
					Object.keys(input.separateFiles).forEach((key) => {
						if (input.separateFiles![key].size == 0) listErrors.push(input.name)
					})
				}

				if (input.namingValidation) {
					input.files.forEach((currentFile) => {
						if (!currentFile.name.includes(`${input.namingValidation}`)) {
							listErrors.push(input.name)
						}
					})
				}

				cleanedFiles.push(input)

				// processing/mapping files then dispatch configs to store
				saveFileToStore(input, configs).then(() => {
					dispatch(populateRequestConfigsState(configs))
				})
			}
		})

		onSetFilesInputs && onSetFilesInputs(cleanedFiles)

		dispatch(
			populateRequestModelState({
				description: details,
				metadata
			})
		)

		onFixError(listErrors)

		return listErrors.length === 0
	}

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const confirmRedirectModal = () => {
		return (
			<Modal
				isOpen={isOpenModal}
				onSetIsOpen={setIsOpenModal}
				primaryButtonText={pageAssets?.button_continue}
				onPrimaryButton={() => {
					setIsOpenModal(false)
					navigate(`/auth/profile`, {
						state: {
							form: true,
							url: location.pathname,
							step: 2
						}
					})
				}}
				showExitBtn
				alignBtn="left"
			>
				<div style={{ paddingRight: '25px', marginTop: '-35px' }}>
					<Text content={pageAssets?.redirect_to_personal_info_modal_text} />
				</div>
			</Modal>
		)
	}

	return (
		<div className={joinClasses([requestPageUtils.classes.root, browser])}>
			<div className={requestPageUtils.classes.contentPdf}>
				<PageTitle title={pageData?.title} />

				<div className={requestPageUtils.classes.sectionTitle}>
					<SectionTitle
						title={pageData?.title}
						subTitle={request?.title}
						showBreadcrumbs={false}
						link={closeLink}
						classRoot={joinClasses([
							requestPageUtils.classes.sectionTitleRoot,
							requestPageUtils.classes.sectionTitleRootLowPadding
						])}
					/>
				</div>
			</div>

			<>
				<section
					className={joinClasses([
						requestPageUtils.classes.container,
						requestPageUtils.classes.noPadding,
						requestPageUtils.classes.noBackground
					])}
				>
					<div className={requestPageUtils.classes.sectionBlueTitle}>
						<h3>
							{formatStrapiText(pageAssets?.request_form_personal_informations)}
						</h3>
					</div>

					<div className={requestPageUtils.classes.textSection}>
						<p style={{ marginTop: '0px' }}>
							{pageAssets?.request_form_confirm_personal_information}{' '}
							<span
								className={requestPageUtils.classes.buttonLink}
								onClick={() => setIsOpenModal(true)}
							>
								{formatStrapiText(
									pageAssets?.request_form_change_personal_information
								)}
							</span>
						</p>

						<div className={requestPageUtils.classes.lineDivider}>
							<div className={requestPageUtils.classes.applicantInformation}>
								<div className={requestPageUtils.classes.infoTitle}>
									<strong>{pageAssets?.label_lastName}</strong>
									<br />
									{authUser?.profile?.lastName} {authUser?.profile?.firstName}
								</div>
								<div className={requestPageUtils.classes.infoTitle}>
									<strong>{pageAssets?.label_email}</strong>
									<br />
									{authUser?.profile?.email}
								</div>
								<div className={requestPageUtils.classes.infoTitle}>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>
								<div className={requestPageUtils.classes.infoTitle}>
									<strong>{pageAssets?.label_telephone}</strong>
									<br />
									{authUser?.profile?.phoneNumber ||
										authUser?.profile?.phoneNumberEvening ||
										authUser?.profile?.phoneNumberOther}
								</div>
							</div>
						</div>
						<form>
							<h5 className={pageUtils.classes.h5}>
								{formatStrapiText(pageAssets?.request_form_billing_adresse)}
							</h5>

							<div className={classes.checkbox2Container}>
								<input
									onClick={(e: any) =>
										onSelectIsSameBillingAdresseInput(e.target.checked)
									}
									value={isSameBillingAdresseInput.value}
									className={classes.checkbox2}
									type="checkbox"
									name={isSameBillingAdresseInput.name}
									id={isSameBillingAdresseInput.labelKey}
								/>
								<label
									className={classes.checkbox2Label}
									htmlFor={isSameBillingAdresseInput.labelKey}
								>
									{pageAssets[isSameBillingAdresseInput.labelKey]}
								</label>
							</div>

							<Collapse isOpened={showBillingAdresseBlock == true}>
								<ConcernedInfosSection
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										`${isSameBillingAdresseInput.value}` == 'false'
									}
									requiredFields={[
										'concernedLastName',
										'concernedHasLavalAdresse',
										'concernedAdresse',
										'concernedAppartement',
										'concernedCity',
										'concernedPostalCode'
									]}
								/>
							</Collapse>
						</form>
					</div>
				</section>
				{confirmRedirectModal()}
			</>
			<form
				className={joinClasses([
					requestPageUtils.classes.container,
					requestPageUtils.classes.pdfFormcontainer,
					requestPageUtils.classes.noPadding
				])}
			>
				<div
					className={joinClasses([
						requestPageUtils.classes.sectionBlueTitle,
						requestPageUtils.classes.noMarginBottom
					])}
				>
					<h3>
						{formatStrapiText(pageAssets?.request_form_required_documents)}
					</h3>
				</div>

				<section className={pageUtils.classes.sectionNoPadding}>
					<RequestFiles
						subtitle={firstQuestion.label}
						name={firstQuestion.name}
						required={firstQuestion.required}
						files={firstQuestion.files}
						hasError={errors.includes(firstQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFirstQuestion}
						subSteps={subSteps}
						maxFiles={firstQuestion.numberMaxFiles}
						description={firstQuestion.description}
					></RequestFiles>
				</section>
			</form>
			<section
				className={joinClasses([
					requestPageUtils.classes.container,
					requestPageUtils.classes.noPadding,
					requestPageUtils.classes.noBackground
				])}
			>
				<div className={requestPageUtils.classes.submitContainer}>
					<div className={requestPageUtils.classes.submitRoot}>
						<button
							type="button"
							className={joinClasses([
								requestPageUtils.classes.submitBtn,
								requestPageUtils.classes.btn,
								loading || errors.length > 0
									? // (hasFileError && hasFileRequiredError)
									  requestPageUtils.classes.btnDisabled
									: ''
							])}
							onClick={submitRequest}
						>
							{!loading && (
								<Text content={pageAssets?.page_requestInfo_inputSubmit} />
							)}
							{loading && (
								<div className={`${requestPageUtils.classes.loader} spin`} />
							)}
						</button>
					</div>
					{loading && (
						<span className={requestPageUtils.classes.loadingMessage}>
							{pageAssets?.createRequest_page_wait_while_we_submit_your_request}
						</span>
					)}
				</div>
			</section>
			{renderRequestFailedModal && renderRequestFailedModal()}
		</div>
	)
}

export default PublicAuctionsForm
