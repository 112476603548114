import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormPDFProps,
	RequestInputs,
	SelectList,
	RequestInputFiles,
	CheckboxList,
	RadioList,
	inputTablesFields
} from '@utils/request'
import { joinClasses, makeClasses } from '@utils/styles'
import requestForm from './request.form'
import { useAppSelector } from '@services/store'
import { Collapse } from 'react-collapse'
import {
	requestCategories,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import {
	formatHtmlForStrapiText,
	formatStrapiText,
	getFilesFromStore
} from '@utils/methods'
import SelectInput from './inputs/select.inputs'
import TextInput from './inputs/text.inputs'
import RequestFiles from '@components/ui/requestFiles'
import Text from '@components/ui/text'
import { Colors } from '@utils/css-variables'
import CheckboxInput from './inputs/checkbox.input'
import RadioInput from './inputs/radio.input'
import TableInput from './inputs/table.input'
import { current } from '@reduxjs/toolkit'
import Notification from '@components/ui/notification'

type Classes = {
	fileHelperSection: string
	fileContainer: string
}

const classes: Classes = makeClasses({
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	}
})

const RecruitmentAndCitizenInvolvementForm: FC<RequestFormPDFProps> = ({
	errors,
	toPrint,
	hasFileRequiredError,
	inputs,
	inputFiles,
	subSteps,
	formPosition,
	isSummaryStep,
	steps,
	filesInputs,
	onSetInputFiles,
	onSetSubSteps,
	onSetHasFileRequiredError,
	onSetInputs,
	onFixError,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		radioSelectionYesNo,
		UpdateRequestInputsErrorsArray,
		UpdateRequestInputFilesArray,
		onChangeRadioInput,
		language,
		UpdateRequestInputsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [trainingAndExperienceList, setTrainingAndExperienceList] =
		useState<CheckboxList[]>()
	const [experiencesList, setExperiencesList] = useState<CheckboxList[]>()
	const [interestedPostList, setInterestedPostList] = useState<CheckboxList[]>()
	const [studyLevelList, setStudyLevelList] = useState<SelectList[]>([])
	const [targetedPositionList, setTargetedPositionList] = useState<RadioList[]>(
		[]
	)
	const [genderList, setGenderList] = useState<RadioList[]>([])
	const [disabilityList, setDisabilityList] = useState<CheckboxList[]>()
	const [identificationGroupList, setIdentificationGroupList] = useState<
		RadioList[]
	>([])
	const [shouldDisplayInterestedPost, setShouldDisplayInterestedPost] =
		useState<boolean>(false)
	const [displayOtherEducationLevel, setDisplayOtherEducationLevel] =
		useState<boolean>(false)
	const [trainingAndExperienceInput, setTrainingAndExperienceInput] =
		useState<RequestInputs>({
			name: 'trainingAndExperience',
			value: answers.trainingAndExperience || '',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_first_question,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_first_question',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

	const [experienceInput, setExperienceInput] = useState<RequestInputs>({
		name: 'experience',
		value: answers.experience || '',
		label:
			pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_second_question,
		labelKey: 'newRequestPage_RecruitmentAndCitizenInvolvement_second_question',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})
	const [intrestedPostInput, setIntrestedPostInput] = useState<RequestInputs>({
		name: 'interest',
		value: answers.interest || '',
		label:
			pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_interested_posts,
		labelKey:
			'newRequestPage_RecruitmentAndCitizenInvolvement_interested_posts',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [studyLevelInput, setStudyLevelInput] = useState<RequestInputs>({
		name: 'studyLevel',
		value: answers.studyLevel || '',
		label:
			pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_study_level,
		labelKey: 'newRequestPage_RecruitmentAndCitizenInvolvement_study_level',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [addStudyLevelInput, setAddStudyLevelInput] = useState<RequestInputs>({
		name: 'addStudyLevel',
		value: answers.addStudyLevel || '',
		label: pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_specify,
		labelKey: 'newRequestPage_RecruitmentAndCitizenInvolvement_specify',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [specifyInterestInput, setSpecifyInterestInput] =
		useState<RequestInputs>({
			name: 'pleaseSpecifyInterest',
			value: answers.pleaseSpecifyInterest || '',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_please_specify,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_please_specify',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [linkedinProfileInput, setLinkedinProfileInput] =
		useState<RequestInputs>({
			name: 'linkedinProfile',
			value: answers.linkedinProfile || '',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_linkedin_profile,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_linkedin_profile',
			required: false
		})

	const [targetedPositionInput, setTargetedPositionInput] =
		useState<RequestInputs>({
			name: 'targetedPosition',
			value: answers.targetedPosition || '',
			valueKey: '',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_position_application,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_position_application',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

	const [conflictOfInterestInput, setConflictOfInterestInput] =
		useState<RequestInputs>({
			name: 'conflictOfInterest',
			value: answers.conflictOfInterest || '',
			valueKey: '',
			description:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_conflict_of_interest_extra_text,
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_conflict_of_interest,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_conflict_of_interest',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

	const [isEmployeeOfLavalInput, setIsEmployeeOfLavalInput] =
		useState<RequestInputs>({
			name: 'isEmployeeOfLaval',
			value: answers.isEmployeeOfLaval || '',
			valueKey: '',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_are_you_an_employee_of_la_ville_de_laval,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_are_you_an_employee_of_la_ville_de_laval',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

	const [genderInput, setGenderInput] = useState<RequestInputs>({
		name: 'gender',
		value: answers.gender || '',
		valueKey: '',
		label:
			pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_i_identify_myself_as,
		labelKey:
			'newRequestPage_RecruitmentAndCitizenInvolvement_i_identify_myself_as',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [disabilityInput, setDisabilityInput] = useState<RequestInputs>({
		name: 'disability',
		value: answers.disability || '',
		valueKey: '',
		label:
			pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_i_identify_myself_as,
		labelKey:
			'newRequestPage_RecruitmentAndCitizenInvolvement_i_identify_myself_as',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [identificationGroupInput, setIdentificationGroupInput] =
		useState<RequestInputs>({
			name: 'identificationGroup',
			value: answers.identificationGroup || '',
			valueKey: '',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_identification_group,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_identification_group',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [availabilityMomentInput, setAvailabilityMomentInput] =
		useState<RequestInputs>({
			name: 'availability',
			value: answers.availability || '',
			valueKey: '',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_availability,
			labelKey: 'newRequestPage_RecruitmentAndCitizenInvolvement_availability',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

	const [curriculumVitaeInput, setCurriculumVitaeInput] =
		useState<RequestInputFiles>({
			name: 'curriculumVitae',
			label:
				pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_curriculum_vitae_label,
			labelKey:
				'newRequestPage_RecruitmentAndCitizenInvolvement_curriculum_vitae_label',
			description: '',
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'curriculumVitae')?.files || []
			),
			required: false,
			numberFilesRequired: 1
			// category: requestCategories.
		})

	const [coverLetterInput, setCoverLetterInput] = useState<RequestInputFiles>({
		name: 'coverLetter',
		label:
			pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_cover_letter_label,
		labelKey:
			'newRequestPage_RecruitmentAndCitizenInvolvement_cover_letter_label',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'coverLetter')?.files || []
		),
		required: false,
		numberFilesRequired: 1
		// category: requestCategories.
	})

	const shouldDisplaySpecifyConflictInterest =
		conflictOfInterestInput.value === radioSelectionYesNo[0].value
	const availabilityTable: inputTablesFields = {
		headers: [
			pageAssets?.label_monday,
			pageAssets?.label_tuesday,
			pageAssets?.label_wednesday,
			pageAssets?.label_thursday,
			pageAssets?.label_friday
		],
		rows: [
			[
				{
					label: pageAssets?.label_day,
					selected: false
				},
				{
					label: pageAssets?.label_day,
					selected: false
				},
				{
					label: pageAssets?.label_day,
					selected: false
				},
				{
					label: pageAssets?.label_day,
					selected: false
				},
				{
					label: pageAssets?.label_day,
					selected: false
				}
			],
			[
				{
					label: pageAssets?.label_evening,
					selected: false
				},
				{
					label: pageAssets?.label_evening,
					selected: false
				},
				{
					label: pageAssets?.label_evening,
					selected: false
				},
				{
					label: pageAssets?.label_evening,
					selected: false
				},
				{
					label: pageAssets?.label_evening,
					selected: false
				}
			]
		]
	}
	const [availabilityTableList, setAvailabilityTableList] =
		useState<inputTablesFields>(availabilityTable)

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	useEffect(() => {
		const trainingAndExperienceList = pageUtils.addLabelToList(
			pageUtils.trainingAndExperienceListAndValues,
			pageAssets
		)
		const experienceList = pageUtils.addLabelToList(
			pageUtils.experienceListAndValues,
			pageAssets
		)

		const interestedPostList = pageUtils.addLabelToList(
			pageUtils.interestedPostListAndValues,
			pageAssets
		)
		// since a string is stored as the value of tranining and experience,
		// we need to convert it to a list of objects to match the format the checkbox inputs
		const trainingAndExperienceListAnswer =
			pageUtils.generateCheckboxListFromAnswer(
				pageUtils.trainingAndExperienceListAndValues,
				answers?.trainingAndExperience,
				pageAssets
			)
		setTrainingAndExperienceList(
			trainingAndExperienceListAnswer.length > 0
				? trainingAndExperienceListAnswer
				: trainingAndExperienceList
		)

		const experienceListAnswer = pageUtils.generateCheckboxListFromAnswer(
			pageUtils.experienceListAndValues,
			answers?.experience,
			pageAssets
		)
		setExperiencesList(
			experienceListAnswer.length > 0 ? experienceListAnswer : experienceList
		)

		const interestedPostListAnswer = pageUtils.generateCheckboxListFromAnswer(
			pageUtils.interestedPostListAndValues,
			answers?.interest,
			pageAssets
		)
		setInterestedPostList(
			interestedPostListAnswer.length > 0
				? interestedPostListAnswer
				: interestedPostList
		)

		const availabilityListAnswer = pageUtils.generateTableInputFromAnswer(
			availabilityTable,
			pageAssets,
			answers.availability
		)
		setAvailabilityTableList(availabilityListAnswer ?? availabilityTableList)

		setStudyLevelList(
			pageUtils.addLabelToList(
				pageUtils.studyLevelRawList,
				pageAssets
			) as SelectList[]
		)

		setTargetedPositionList(
			pageUtils.addLabelToList(
				pageUtils.targetedPositionRadioList,
				pageAssets
			) as RadioList[]
		)

		setGenderList(
			pageUtils.addLabelToList(
				pageUtils.setGenderRadioList,
				pageAssets
			) as RadioList[]
		)

		setDisabilityList(
			pageUtils.addLabelToList(pageUtils.specialDisabilityList, pageAssets)
		)

		setIdentificationGroupList(
			pageUtils.addLabelToList(
				pageUtils.identificationGroupRawList,
				pageAssets
			) as RadioList[]
		)
	}, [language])

	const onChangeStudyLevelInput = (value: string, valueKey: string) => {
		setStudyLevelInput({
			...studyLevelInput,
			value,
			valueKey
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, studyLevelInput.name))
	}
	const onChangeAddStudyLevelInput = (value: string) => {
		setAddStudyLevelInput((currentState) => {
			return {
				...currentState,
				value
			}
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, addStudyLevelInput.name))
	}
	const onChangeSpecifyLevelInput = (value: string) => {
		setSpecifyInterestInput((currentState) => {
			return {
				...currentState,
				value
			}
		})
		onFixError(
			UpdateRequestInputsErrorsArray(errors, specifyInterestInput.name)
		)
	}

	const onChangeLinkedinProfileInput = (value: string) => {
		setLinkedinProfileInput({ ...linkedinProfileInput, value })
	}

	const onChangeTrainingAndExperienceInput = (value: string, key: string) => {
		pageUtils.onCheckValue(
			key,
			trainingAndExperienceList,
			setTrainingAndExperienceList,
			setTrainingAndExperienceInput
		)
		onFixError(
			UpdateRequestInputsErrorsArray(errors, trainingAndExperienceInput.name)
		)
	}

	const onChangeExperienceInput = (value: string, key: string) => {
		pageUtils.onCheckValue(
			key,
			experiencesList,
			setExperiencesList,
			setExperienceInput
		)
		onFixError(UpdateRequestInputsErrorsArray(errors, experienceInput.name))
	}

	const onChangeAvailabilityMomentInput = (updatedList: inputTablesFields) => {
		pageUtils.onSelectTableValue(
			updatedList,
			setAvailabilityMomentInput,
			setAvailabilityTableList
		)
		onFixError(
			UpdateRequestInputsErrorsArray(errors, availabilityMomentInput.name)
		)
	}

	const onChangeTargetedPosition = (value: string, key: string) => {
		onChangeRadioInput(value, key, setTargetedPositionInput, () =>
			onFixError(
				UpdateRequestInputsErrorsArray(errors, targetedPositionInput.name)
			)
		)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, targetedPositionInput.name)
		)
	}

	const onChangeInterestedPostInput = (value: string, key: string) => {
		pageUtils.onCheckValue(
			key,
			interestedPostList,
			setInterestedPostList,
			setIntrestedPostInput
		)
		onFixError(UpdateRequestInputsErrorsArray(errors, intrestedPostInput.name))
	}

	const onChangeConflictOfInterestInput = (value: string, key: string) => {
		onChangeRadioInput(value, key, setConflictOfInterestInput, () =>
			onFixError(
				UpdateRequestInputsErrorsArray(errors, conflictOfInterestInput.name)
			)
		)
	}

	const onChangeIsEmployeeOfLavalInput = (value: string, key: string) => {
		onChangeRadioInput(value, key, setIsEmployeeOfLavalInput, () =>
			onFixError(
				UpdateRequestInputsErrorsArray(errors, isEmployeeOfLavalInput.name)
			)
		)
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const onSelectFileCurriculumVitea = (files: Set<File>) => {
		setCurriculumVitaeInput({
			...curriculumVitaeInput,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, curriculumVitaeInput.name)
		)
	}

	const onSelectFileCoverLetterInput = (files: Set<File>) => {
		setCoverLetterInput({
			...coverLetterInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, coverLetterInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, studyLevelInput))
	}, [studyLevelInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, trainingAndExperienceInput))
	}, [trainingAndExperienceInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, experienceInput))
	}, [experienceInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, linkedinProfileInput))
	}, [linkedinProfileInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, conflictOfInterestInput))
	}, [conflictOfInterestInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, targetedPositionInput))
	}, [targetedPositionInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isEmployeeOfLavalInput))
	}, [isEmployeeOfLavalInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, genderInput))
	}, [genderInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, disabilityInput))
	}, [disabilityInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, identificationGroupInput))
	}, [identificationGroupInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, availabilityMomentInput))
	}, [availabilityMomentInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, addStudyLevelInput))
	}, [addStudyLevelInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, intrestedPostInput))
	}, [intrestedPostInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, specifyInterestInput))
	}, [specifyInterestInput])

	useEffect(() => {
		if (displayOtherEducationLevel) {
			setAddStudyLevelInput((currentState) => {
				return {
					...currentState,
					required: true
				}
			})
		} else {
			setAddStudyLevelInput((currentState) => {
				return {
					...currentState,
					required: false
				}
			})
		}
	}, [displayOtherEducationLevel])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, coverLetterInput))
	}, [coverLetterInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, curriculumVitaeInput)
		)
	}, [curriculumVitaeInput])

	useEffect(() => {
		// on page load set disability to Non
		setDisabilityInput((currentState) => {
			return {
				...currentState,
				value: 'request_form_select_no'
			}
		})

		// curriculumVitaeInput
		if (!!answers.curriculumVitae && curriculumVitaeInput.files.size == 0) {
			getFilesFromStore('curriculumVitae', answers).then(
				(result: Set<File>) => {
					setCurriculumVitaeInput({
						...curriculumVitaeInput,
						files: result
					})
				}
			)
		}

		// coverLetterInput
		if (!!answers.coverLetter && coverLetterInput.files.size == 0) {
			getFilesFromStore('coverLetter', answers).then((result: Set<File>) => {
				setCoverLetterInput({
					...coverLetterInput,
					files: result
				})
			})
		}
	}, [])

	useEffect(() => {
		const doDisplayInterest =
			targetedPositionInput.value ===
			pageUtils.targetedPositionRadioList[1].value
		setShouldDisplayInterestedPost(doDisplayInterest)
		if (!doDisplayInterest) {
			setIntrestedPostInput((currentState) => {
				return {
					...currentState,
					value: '',
					required: false
				}
			})
		} else {
			setIntrestedPostInput((currentState) => {
				return {
					...currentState,
					required: true
				}
			})
		}
	}, [targetedPositionInput.value])

	useEffect(() => {
		if (shouldDisplaySpecifyConflictInterest) {
			setSpecifyInterestInput((currentState) => {
				return {
					...currentState,
					required: true
				}
			})
		} else {
			setSpecifyInterestInput((currentState) => {
				return {
					...currentState,
					value: '',
					required: false
				}
			})
			onFixError(
				UpdateRequestInputsErrorsArray(errors, specifyInterestInput.name)
			)
		}
	}, [shouldDisplaySpecifyConflictInterest])

	useEffect(() => {
		const shouldDisplayOtherEducationLevelField =
			studyLevelInput.value ===
			pageUtils.studyLevelRawList[pageUtils.studyLevelRawList.length - 1].value
		if (shouldDisplayOtherEducationLevelField) {
			setDisplayOtherEducationLevel(shouldDisplayOtherEducationLevelField)
		} else {
			setDisplayOtherEducationLevel(false)
		}
	}, [studyLevelInput.value])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const FilesSection: FC<pageUtils.FileSectionProps> = ({ isSummary }) => (
		<>
			{subSteps?.requiredDocument && (
				<>
					<div style={{ marginTop: '40px' }}>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_optional_documents}
						</h4>
					</div>

					{pageUtils.doesFileExist(answers.curriculumVitae) && (
						<RequestFiles
							subtitle={curriculumVitaeInput.label}
							name={curriculumVitaeInput.name}
							required={curriculumVitaeInput.required}
							files={curriculumVitaeInput.files}
							hasError={errors.includes(curriculumVitaeInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileCurriculumVitea}
							maxFiles={curriculumVitaeInput.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>
					)}
					{pageUtils.doesFileExist(answers.coverLetter) && (
						<RequestFiles
							subtitle={coverLetterInput.label}
							name={coverLetterInput.name}
							required={coverLetterInput.required}
							files={coverLetterInput.files}
							hasError={errors.includes(coverLetterInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileCoverLetterInput}
							maxFiles={coverLetterInput.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>
					)}
				</>
			)}
		</>
	)

	if (isSummaryStep) {
		const shouldFilesectionDisplay =
			pageUtils.doesFileExist(answers.curriculumVitae) ||
			pageUtils.doesFileExist(answers.coverLetter)
		const interestedPostList = pageUtils.getListfromString(answers.interest)

		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				<div>
					<a
						style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
						className={joinClasses([
							pageUtils.classes.btn,
							pageUtils.classes.button,
							pageUtils.classes.buttonOutline
						])}
						onClick={() =>
							backToForm(steps?.form!, REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1)
						}
					>
						{pageAssets?.label_modify}
					</a>
					{/* <h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_grant_details}
					</h4> */}
				</div>
				<div>
					<strong>{pageAssets?.label_training}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{pageUtils
							.getListfromString(answers.trainingAndExperience)
							?.map((answer, index) => {
								return (
									<p
										className={pageUtils.classes.answer}
										key={`${answer}-${index}-training`}
									>
										{answer}
									</p>
								)
							})}
					</span>
				</div>
				<div>
					<strong>{pageAssets?.label_experience}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{pageUtils
							.getListfromString(answers.experience)
							?.map((answer, index) => {
								return (
									<p
										className={pageUtils.classes.answer}
										key={`${answer}-${index}-experience`}
									>
										{answer}
									</p>
								)
							})}
					</span>
				</div>

				<div>
					<strong>{pageAssets[studyLevelInput.labelKey]}</strong>
					<br />
					{!answers.addStudyLevel && answers.studyLevel && (
						<span className={pageUtils.classes.answer}>
							{pageAssets[answers.studyLevel]}
						</span>
					)}
					{answers.addStudyLevel && (
						<span className={pageUtils.classes.answer}>
							{answers.addStudyLevel}
						</span>
					)}
				</div>
				{answers.linkedinProfile && (
					<div>
						<strong>{pageAssets[linkedinProfileInput.labelKey]}</strong>
						<br />
						<a
							className={joinClasses([
								pageUtils.classes.answer,
								pageUtils.classes.anchorTag
							])}
							href={answers.linkedinProfile}
						>
							{answers.linkedinProfile}
						</a>
					</div>
				)}
				<div>
					<h5 className={pageUtils.classes.h5}>
						<div>
							{formatStrapiText(
								pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_position_and_availability
							)}
						</div>
					</h5>
					<strong>{pageAssets[targetedPositionInput.labelKey]}</strong>
					<br />
					{answers.targetedPosition && (
						<span className={pageUtils.classes.answer}>
							{!interestedPostList ? pageAssets[answers.targetedPosition] : ''}
						</span>
					)}
					<div className={pageUtils.classes.answer}>
						{interestedPostList?.map((answer, index) => {
							return (
								<p
									className={pageUtils.classes.answer}
									key={`${answer}-${index}-interestedPostList`}
								>
									{answer}
								</p>
							)
						})}
					</div>
				</div>

				<div>
					<strong>{pageAssets?.label_availablity}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						<TableInput
							name={availabilityMomentInput.name}
							label={availabilityMomentInput.label}
							value={`${availabilityMomentInput.value}`}
							fields={availabilityTableList}
							onChange={onChangeAvailabilityMomentInput}
							hasError={errors.includes(availabilityMomentInput.name)}
							ref={availabilityMomentInput.ref as RefObject<HTMLInputElement>}
							isSummary
						/>
					</span>
				</div>

				<div>
					<strong>{pageAssets[conflictOfInterestInput.labelKey]}</strong>
					<p>
						{
							pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_conflict_of_interest_extra_text
						}
					</p>
					<br />
					<span className={pageUtils.classes.answer}>
						{answers.conflictOfInterest} {answers.pleaseSpecifyInterest}
					</span>
				</div>

				<div>
					<strong>{pageAssets[isEmployeeOfLavalInput.labelKey]}</strong>
					<br />
					<span className={pageUtils.classes.answer}>
						{answers.isEmployeeOfLaval}
					</span>
				</div>

				{shouldFilesectionDisplay && <FilesSection isSummary />}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{formatStrapiText(
							pageAssets?.newRequestPage_information_about_the_candidate
						)}
					</h4>

					<h5 className={pageUtils.classes.h5}>
						{formatStrapiText(
							pageAssets?.newRequestPage_training_and_experience
						)}
					</h5>

					<CheckboxInput
						name={trainingAndExperienceInput.name}
						label={trainingAndExperienceInput.label}
						value={`${trainingAndExperienceInput.value}`}
						list={trainingAndExperienceList}
						onChange={onChangeTrainingAndExperienceInput}
						required={trainingAndExperienceInput.required}
						hasError={errors.includes(trainingAndExperienceInput.name)}
						ref={trainingAndExperienceInput.ref as RefObject<HTMLInputElement>}
						setList={setTrainingAndExperienceList}
						setInput={setTrainingAndExperienceInput}
						errors={errors}
						onFixError={onFixError}
						// others
					/>

					<CheckboxInput
						name={experienceInput.name}
						label={experienceInput.label}
						value={`${experienceInput.value}`}
						list={experiencesList}
						onChange={onChangeExperienceInput}
						required={experienceInput.required}
						hasError={errors.includes(experienceInput.name)}
						ref={experienceInput.ref as RefObject<HTMLInputElement>}
						setList={setExperiencesList}
						setInput={setExperienceInput}
						errors={errors}
						onFixError={onFixError}
						// others
					/>

					<SelectInput
						id={studyLevelInput.name}
						label={studyLevelInput.label}
						value={`${studyLevelInput.value}`}
						list={studyLevelList}
						onChange={onChangeStudyLevelInput}
						required={studyLevelInput.required}
						hasError={errors.includes(studyLevelInput.name)}
						ref={studyLevelInput.ref as RefObject<HTMLSelectElement>}
					/>
					{displayOtherEducationLevel && (
						<TextInput
							id={addStudyLevelInput.name}
							value={addStudyLevelInput.value}
							onChange={onChangeAddStudyLevelInput}
							required={addStudyLevelInput.required}
							requiredIcon={false}
							hasError={errors.includes(addStudyLevelInput.name)}
							ref={addStudyLevelInput.ref as RefObject<HTMLInputElement>}
							placeholder={
								pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_specify
							}
						/>
					)}

					<TextInput
						id={linkedinProfileInput.name}
						label={linkedinProfileInput.label}
						value={linkedinProfileInput.value}
						onChange={onChangeLinkedinProfileInput}
						required={linkedinProfileInput.required}
					/>

					<h5 className={pageUtils.classes.h5}>
						{formatStrapiText(
							pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_position_and_availability
						)}
					</h5>

					<RadioInput
						name={targetedPositionInput.name}
						label={targetedPositionInput.label}
						value={targetedPositionInput.value}
						list={targetedPositionList}
						onChange={onChangeTargetedPosition}
						required={targetedPositionInput.required}
						ref={targetedPositionInput.ref as RefObject<HTMLInputElement>}
						hasError={errors.includes(targetedPositionInput.name)}
					/>

					{shouldDisplayInterestedPost && (
						<CheckboxInput
							name={intrestedPostInput.name}
							label={intrestedPostInput.label}
							value={`${intrestedPostInput.value}`}
							list={interestedPostList}
							onChange={onChangeInterestedPostInput}
							required={intrestedPostInput.required}
							hasError={errors.includes(intrestedPostInput.name)}
							ref={intrestedPostInput.ref as RefObject<HTMLInputElement>}
							setList={setInterestedPostList}
							setInput={setIntrestedPostInput}
							errors={errors}
							onFixError={onFixError}
							// others
						/>
					)}
					<TableInput
						name={availabilityMomentInput.name}
						label={availabilityMomentInput.label}
						value={`${availabilityMomentInput.value}`}
						fields={availabilityTableList}
						onChange={onChangeAvailabilityMomentInput}
						required={availabilityMomentInput.required}
						hasError={errors.includes(availabilityMomentInput.name)}
						ref={availabilityMomentInput.ref as RefObject<HTMLInputElement>}
					/>

					<RadioInput
						name={conflictOfInterestInput.name}
						label={conflictOfInterestInput.label}
						value={conflictOfInterestInput.value}
						list={radioSelectionYesNo}
						description={conflictOfInterestInput.description}
						hasError={errors.includes(conflictOfInterestInput.name)}
						onChange={onChangeConflictOfInterestInput}
						required={conflictOfInterestInput.required}
						ref={conflictOfInterestInput.ref as RefObject<HTMLInputElement>}
					/>
					{shouldDisplaySpecifyConflictInterest && (
						<TextInput
							id={specifyInterestInput.name}
							value={specifyInterestInput.value}
							label={specifyInterestInput.label}
							onChange={onChangeSpecifyLevelInput}
							required={specifyInterestInput.required}
							hasError={errors.includes(specifyInterestInput.name)}
							ref={specifyInterestInput.ref as RefObject<HTMLInputElement>}
						/>
					)}

					<RadioInput
						name={isEmployeeOfLavalInput.name}
						label={isEmployeeOfLavalInput.label}
						value={isEmployeeOfLavalInput.value}
						list={radioSelectionYesNo}
						hasError={errors.includes(isEmployeeOfLavalInput.name)}
						onChange={onChangeIsEmployeeOfLavalInput}
						required={isEmployeeOfLavalInput.required}
						ref={isEmployeeOfLavalInput.ref as RefObject<HTMLInputElement>}
					/>

					<h5 className={pageUtils.classes.h5}>
						{formatStrapiText(
							pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_voluntary_self_identification
						)}
					</h5>

					<div>
						<Text
							content={
								pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_self_identification_text
							}
						/>
					</div>
					<br />

					<RadioInput
						name={genderInput.name}
						label={genderInput.label}
						value={genderInput.value}
						list={genderList}
						hasError={errors.includes(genderInput.name)}
						onChange={(value: string, key: string) =>
							onChangeRadioInput(value, key, setGenderInput, () =>
								onFixError(
									UpdateRequestInputsErrorsArray(errors, genderInput.name)
								)
							)
						}
						required={genderInput.required}
						ref={genderInput.ref as RefObject<HTMLInputElement>}
					/>

					<CheckboxInput
						name={disabilityInput.name}
						label={disabilityInput.label}
						value={`${disabilityInput.value}`}
						list={disabilityList}
						onChange={(value, key) =>
							pageUtils.onCheckValue(
								key,
								disabilityList,
								setDisabilityList,
								setDisabilityInput,
								true
							)
						}
						required={disabilityInput.required}
						hasError={errors.includes(disabilityInput.name)}
						ref={disabilityInput.ref as RefObject<HTMLInputElement>}
					/>

					<RadioInput
						name={identificationGroupInput.name}
						label={identificationGroupInput.label}
						value={identificationGroupInput.value}
						list={identificationGroupList}
						hasError={errors.includes(identificationGroupInput.name)}
						onChange={(value: string, key: string) =>
							onChangeRadioInput(value, key, setIdentificationGroupInput, () =>
								onFixError(
									UpdateRequestInputsErrorsArray(
										errors,
										identificationGroupInput.name
									)
								)
							)
						}
						required={identificationGroupInput.required}
						ref={identificationGroupInput.ref as RefObject<HTMLInputElement>}
					/>
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_optional_documents}
					</h4>

					<Notification
						type={'info'}
						text={
							pageAssets?.newRequestPage_RecruitmentAndCitizenInvolvement_indicative_text
						}
					/>
					<RequestFiles
						subtitle={curriculumVitaeInput.label}
						name={curriculumVitaeInput.name}
						required={curriculumVitaeInput.required}
						files={curriculumVitaeInput.files}
						hasError={errors.includes(curriculumVitaeInput.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileCurriculumVitea}
						subSteps={subSteps}
						maxFiles={curriculumVitaeInput.numberFilesRequired}
					>
						<></>
					</RequestFiles>
					<RequestFiles
						subtitle={coverLetterInput.label}
						name={coverLetterInput.name}
						required={coverLetterInput.required}
						files={coverLetterInput.files}
						hasError={errors.includes(coverLetterInput.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileCoverLetterInput}
						subSteps={subSteps}
						maxFiles={coverLetterInput.numberFilesRequired}
					>
						<></>
					</RequestFiles>
				</section>
			</Collapse>
		</>
	)
}

export default RecruitmentAndCitizenInvolvementForm
