import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormPDFProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates, CustomDate } from '@services/models'
import { formatStrapiText } from '@utils/methods'
import RadioInput from '@components/requestForms/inputs/radio.input'

import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import {
	airpulse,
	convecteur,
	eauchaude,
	electrique,
	geothermie,
	plinthes,
	radiant,
	thermopompe
} from '@images/icons'
import SelectInput from './inputs/select.inputs'
import moment from 'moment'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '@components/ui/addressForm/__index.utils'
import DateFilterInput from './inputs/datefilter.input'
import { AddressInfo } from '@components/ui/map/mapForm.interfaces'

const OilHeatingDeclarationForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		ownerSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		dateAndTimeSection: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		detailscontainer: string
		adresseBlock: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},
		dateAndTimeSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '27%27%',
			columnGap: '1%',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			'& > div': {
				marginBottom: '0px'
			}
		},
		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '60%30%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important'
				}
			},
			lineHeight: '1.62em',
			fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			}
		},
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px'
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [showTheConcernedBlock, setShowTheConcernedBlock] = useState<boolean>(
		answers.isConcernedDiffFromApplicant == true ? true : false
	)

	const [showDeclarationAdresseBlock, setShowDeclarationAdresseBlock] =
		useState<boolean>(
			answers.buildingAddressSameHasApplicant == true ? false : true
		)

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	//----------------------------------------------------------------
	//----------------------- DETAILS INPUTS -------------------------
	//----------------------------------------------------------------ddd

	//----------------------------------------------------------------
	//------------------ Declaration Adresse INPUTS ------------------
	//----------------------------------------------------------------

	// Même adresse que celle inscrite dans mes informations personnelles
	const [isSameDeclarationAdresseInput, setIsSameDeclarationAdresseInput] =
		useState<RequestInputs>({
			name: 'buildingAddressSameHasApplicant',
			label: pageAssets?.request_form_isSameAdresse_label,
			labelKey: 'request_form_isSameAdresse_label',
			value: answers.buildingAddressSameHasApplicant?.toString() || 'false',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	// hidden input cadastre and matricule
	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingRegistrationNumberInput, setBuildingRegistrationNumberInput] =
		useState<RequestInputs>({
			name: 'buildingRegistrationNumber',
			label: '',
			labelKey: '',
			value: answers.buildingRegistrationNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	//****** isBuildingOwner inputs ******

	// Êtes-vous propriétaire du bâtiment?
	const [isBuildingOwnerInput, setIsBuildingOwnerInput] =
		useState<RequestInputs>({
			name: 'isBuildingOwner',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_isBuildingOwner_label,
			labelKey: 'request_form_oilHeatingDeclaration_isBuildingOwner_label',
			value: answers.isBuildingOwner?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	// Nom complet de l'un des propriétaires
	const [ownerFullNameInput, setOwnerFullNameInput] = useState<RequestInputs>({
		name: 'ownerFullName',
		label: pageAssets?.request_form_oilHeatingDeclaration_ownerFullName_label,
		labelKey: 'request_form_oilHeatingDeclaration_ownerFullName_label',
		value: answers.ownerFullName || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
	})

	////////////////////////////////////////////////////////////////

	//----------------------------------------------------------------
	//-------------------- DESCRIPTION INPUTS ------------------------
	//----------------------------------------------------------------

	// Est-ce que vous faites cette déclaration à la suite du remplacement de votre appareil de chauffage?
	const [
		isDeclarationForHeatingReplacementInput,
		setIsDeclarationForHeatingReplacementInput
	] = useState<RequestInputs>({
		name: 'isDeclarationForHeatingReplacement',
		label:
			pageAssets?.request_form_oilHeatingDeclaration_isDeclarationForHeatingReplacement_label,
		labelKey:
			'request_form_oilHeatingDeclaration_isDeclarationForHeatingReplacement_label',
		value: answers.isDeclarationForHeatingReplacement?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	// Date de l'installation
	const [installationDateInput, setInstallationDateInput] =
		useState<RequestInputs>({
			name: 'installationDate',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_installationDateInput_label,
			labelKey:
				'request_form_oilHeatingDeclaration_installationDateInput_label',
			value: answers.installationDate || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			extraParams: {
				type: 'date',
				customErrorText:
					pageAssets?.request_form_oilHeatingDeclaration_installationDateInput_customTextError
			}
		})

	// Avez-vous un système de chauffage à l’huile (mazout)?
	const [isOilHeatingSystemInput, setIsOilHeatingSystemInput] =
		useState<RequestInputs>({
			name: 'isOilHeatingSystem',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_isOilHeatingSystem_label,
			labelKey: 'request_form_oilHeatingDeclaration_isOilHeatingSystem_label',
			value: answers.isOilHeatingSystem?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Votre système est-il biénergie?
	const [isDualEnergySystemInput, setIsDualEnergySystemInput] =
		useState<RequestInputs>({
			name: 'isDualEnergySystem',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_isDualEnergySystem_label,
			labelKey: 'request_form_oilHeatingDeclaration_isDualEnergySystem_label',
			value: answers.isDualEnergySystem?.toString() || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			description:
				pageAssets?.request_form_oilHeatingDeclaration_isDualEnergySystem_description,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Par quel genre d’installation avez-vous converti votre système de chauffage?
	const [convertedHeatingSystemTypeInput, setConvertedHeatingSystemTypeInput] =
		useState<RequestInputs>({
			name: 'convertedHeatingSystemType',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_convertedHeatingSystemType_label,
			labelKey:
				'request_form_oilHeatingDeclaration_convertedHeatingSystemType_label',
			value: `${answers.convertedHeatingSystemType}` || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const diplayOptionDetails = (text, image) => {
		return (
			<div className={classes.detailscontainer}>
				{text}
				<img src={image} />
			</div>
		)
	}

	const convertedHeatingSystemTypeList: RadioList[] = [
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_convertedHeatingSystemType_firstOption,
			value: REQUEST_CONFIGS_KEYS.convertedHeatingSystemType.firstOption,
			key: 'select_oilHeatingDeclaration_convertedHeatingSystemType_firstOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_convertedHeatingSystemType_firstOption_details,
				electrique
			)
		},
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_convertedHeatingSystemType_secondOption,
			value: REQUEST_CONFIGS_KEYS.convertedHeatingSystemType.secondOption,
			key: 'select_oilHeatingDeclaration_convertedHeatingSystemType_secondOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_convertedHeatingSystemType_secondOption_details,
				thermopompe
			)
		}
	]

	// Quelle est la source d'énergie du système de chauffage principal?
	const [heatingEnergySourceInput, setHeatingEnergySourceInput] =
		useState<RequestInputs>({
			name: 'heatingEnergySource',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_heatingEnergySource_label,
			labelKey: 'request_form_oilHeatingDeclaration_heatingEnergySource_label',
			value: `${answers.heatingEnergySource}` || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const heatingEnergySourceList: RadioList[] = [
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_heatingEnergySource_firstOption,
			value: REQUEST_CONFIGS_KEYS.heatingEnergySource.firstOption,
			key: 'select_oilHeatingDeclaration_heatingEnergySource_firstOption'
		},
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_heatingEnergySource_secondOption,
			value: REQUEST_CONFIGS_KEYS.heatingEnergySource.secondOption,
			key: 'select_oilHeatingDeclaration_heatingEnergySource_secondOption'
		},
		{
			label:
				pageAssets?.select_oilHeatingDeclaration_heatingEnergySource_thirdOption,
			value: REQUEST_CONFIGS_KEYS.heatingEnergySource.thirdOption,
			key: 'select_oilHeatingDeclaration_heatingEnergySource_thirdOption'
		}
	]

	// Quel est le type du système de chauffage principal?
	const [mainHeatingSystemTypeInput, setMainHeatingSystemTypeInput] =
		useState<RequestInputs>({
			name: 'mainHeatingSystemType',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_mainHeatingSystemType_label,
			labelKey:
				'request_form_oilHeatingDeclaration_mainHeatingSystemType_label',
			value: `${answers.mainHeatingSystemType}` || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const mainHeatingSystemTypeList: RadioList[] = [
		{
			// Air pulsé
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_firstOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.firstOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_firstOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_firstOption_details,
				airpulse
			)
		},
		{
			// Convecteur
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_secondOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.secondOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_secondOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_secondOption_details,
				convecteur
			)
		},
		{
			// Eau Chaude
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_thirdOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.thirdOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_thirdOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_thirdOption_details,
				eauchaude
			)
		},
		{
			// Géothermie
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_fourthOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.fourthOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_fourthOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_fourthOption_details,
				geothermie
			)
		},
		{
			// Plinthes
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_fifthOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.fifthOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_fifthOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_fifthOption_details,
				plinthes
			)
		},
		{
			// Radiant
			label:
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_sixthOption,
			value: REQUEST_CONFIGS_KEYS.mainHeatingSystemType.sixthOption,
			key: 'select_oilHeatingDeclaration_mainHeatingSystemType_sixthOption',
			details: diplayOptionDetails(
				pageAssets?.select_oilHeatingDeclaration_mainHeatingSystemType_sixthOption_details,
				radiant
			)
		}
	]

	// Année approximative de l'installation
	const [approximateInstallYearInput, setApproximateInstallYearInput] =
		useState<RequestInputs>({
			name: 'approximateInstallYear',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_approximateInstallYear_label,
			labelKey:
				'request_form_oilHeatingDeclaration_approximateInstallYear_label',
			value: `${answers.approximateInstallYear}` || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const getApproximateInstallYearList = () => {
		let approximateInstallYearList: SelectList[] = [
			{
				label: '',
				value: '',
				key: ''
			}
		]

		var max = new Date().getFullYear()
		var min = max - 100

		for (var i = max; i >= min; i--) {
			approximateInstallYearList.push({
				label: `${i}`,
				value: `${i}`,
				key: `${i}`
			})
		}

		return approximateInstallYearList
	}

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// No documents required for now

	// ------------------- DETAILS INPUTS SETTERS --------------------

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (!coordinates.validate()) {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	const onSelectIsSameDeclarationAdresseInput = (value) => {
		setIsSameDeclarationAdresseInput({
			...isSameDeclarationAdresseInput,
			value: `${value}`
		})

		if (value == true) {
			getCadastralAndMatriculeNumber(authUser?.profile?.address.coordinates!)
		}

		setTimeout(() => {
			setShowDeclarationAdresseBlock(!value)
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isSameDeclarationAdresseInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isSameDeclarationAdresseInput))
	}, [isSameDeclarationAdresseInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingBatchNumberInput))
	}, [buildingBatchNumberInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingRegistrationNumberInput)
		)
	}, [buildingRegistrationNumberInput])

	// ----------------------------------------------------------------

	const onSelectIsBuildingOwnerInput = (value: string) => {
		setIsBuildingOwnerInput({
			...isBuildingOwnerInput,
			value: `${value}`
		})

		if (value == 'true') {
			setOwnerFullNameInput({ ...ownerFullNameInput, required: false })
		} else {
			setOwnerFullNameInput({ ...ownerFullNameInput, required: true })
		}

		onFixError(
			errors.filter(
				(error) =>
					![isBuildingOwnerInput.name, ownerFullNameInput.name].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isBuildingOwnerInput))
	}, [isBuildingOwnerInput])

	// ----------------------------------------------------------------

	const onSelectOwnerFullNameInput = (value: string) => {
		setOwnerFullNameInput({ ...ownerFullNameInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, ownerFullNameInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, ownerFullNameInput))
	}, [ownerFullNameInput])

	// ----------------------------------------------------------------
	// ----------------------------------------------------------------
	// ----------------------------------------------------------------

	const onSelectIsDeclarationForHeatingReplacementInput = (value: string) => {
		setIsDeclarationForHeatingReplacementInput({
			...isDeclarationForHeatingReplacementInput,
			value: `${value}`
		})

		if (value == 'true') {
			setInstallationDateInput({ ...installationDateInput, required: true })
			setApproximateInstallYearInput({
				...approximateInstallYearInput,
				required: false
			})
		} else {
			setInstallationDateInput({ ...installationDateInput, required: false })
			setApproximateInstallYearInput({
				...approximateInstallYearInput,
				required: true
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isDeclarationForHeatingReplacementInput.name,
						installationDateInput.name,
						approximateInstallYearInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, isDeclarationForHeatingReplacementInput)
		)
	}, [isDeclarationForHeatingReplacementInput])

	// ----------------------------------------------------------------

	const onSelectInstallationDateInput = (value: string) => {
		setInstallationDateInput({
			...installationDateInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, installationDateInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, installationDateInput))
	}, [installationDateInput])

	// ----------------------------------------------------------------

	const onSelectIsOilHeatingSystemInput = (value) => {
		setIsOilHeatingSystemInput({
			...isOilHeatingSystemInput,
			value: `${value}`
		})

		if (value == 'true') {
			setIsDualEnergySystemInput({
				...isDualEnergySystemInput,
				required: true
			})

			setHeatingEnergySourceInput({
				...heatingEnergySourceInput,
				required: false
			})
			setMainHeatingSystemTypeInput({
				...mainHeatingSystemTypeInput,
				required: false
			})
		} else {
			setHeatingEnergySourceInput({
				...heatingEnergySourceInput,
				required: true
			})
			setMainHeatingSystemTypeInput({
				...mainHeatingSystemTypeInput,
				required: true
			})

			setIsDualEnergySystemInput({
				...isDualEnergySystemInput,
				required: false
			})

			setConvertedHeatingSystemTypeInput({
				...convertedHeatingSystemTypeInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isOilHeatingSystemInput.name,
						isDualEnergySystemInput.name,
						convertedHeatingSystemTypeInput.name,
						heatingEnergySourceInput.name,
						mainHeatingSystemTypeInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isOilHeatingSystemInput))
	}, [isOilHeatingSystemInput])

	// ----------------------------------------------------------------

	const onSelectIsDualEnergySystemInput = (value) => {
		setIsDualEnergySystemInput({ ...isDualEnergySystemInput, value: value })

		if (value == 'true') {
			setConvertedHeatingSystemTypeInput({
				...convertedHeatingSystemTypeInput,
				required: true
			})
		} else {
			setConvertedHeatingSystemTypeInput({
				...convertedHeatingSystemTypeInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						convertedHeatingSystemTypeInput.name,
						isDualEnergySystemInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isDualEnergySystemInput))
	}, [isDualEnergySystemInput])

	// ----------------------------------------------------------------

	const onSelectConvertedHeatingSystemTypeInput = (value: string) => {
		setConvertedHeatingSystemTypeInput({
			...convertedHeatingSystemTypeInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				convertedHeatingSystemTypeInput.name
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, convertedHeatingSystemTypeInput)
		)
	}, [convertedHeatingSystemTypeInput])

	// ----------------------------------------------------------------

	const onSelectHeatingEnergySourceInput = (value: string) => {
		setHeatingEnergySourceInput({ ...heatingEnergySourceInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, heatingEnergySourceInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, heatingEnergySourceInput))
	}, [heatingEnergySourceInput])

	// ----------------------------------------------------------------

	const onSelectMainHeatingSystemTypeInput = (value: string) => {
		setMainHeatingSystemTypeInput({ ...mainHeatingSystemTypeInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, mainHeatingSystemTypeInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, mainHeatingSystemTypeInput))
	}, [mainHeatingSystemTypeInput])

	// ----------------------------------------------------------------

	const onSelectApproximateInstallYearInput = (value: string) => {
		setApproximateInstallYearInput({ ...approximateInstallYearInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, approximateInstallYearInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, approximateInstallYearInput))
	}, [approximateInstallYearInput])

	// ----------------------------------------------------------------

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const getCadastralAndMatriculeNumber = (coordinates: AddressCoordinates) => {
		getAddressByCoordinates(coordinates).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})

			setBuildingRegistrationNumberInput({
				...buildingRegistrationNumberInput,
				value: res?.data?.features?.[0].properties.matricule
			})
		})
	}

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
			]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_declaration_adresse}
							</h4>
						</div>

						<div>
							<strong>
								{pageAssets[isSameDeclarationAdresseInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.buildingAddressSameHasApplicant
								)}
							</span>
						</div>

						{answers?.buildingAddressSameHasApplicant?.toString() ==
							'false' && (
							<>
								<>
									<div>
										<strong>{pageAssets?.label_address}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedAdresse}
										</span>
									</div>
								</>
								<>
									<div>
										<strong>{`${formatStrapiText(
											pageAssets?.label_apartment_office
										)}`}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedAppartement}
										</span>
									</div>
								</>
							</>
						)}

						<>
							<div>
								<strong>{pageAssets[isBuildingOwnerInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(radioListYesNo, answers?.isBuildingOwner)}
								</span>
							</div>

							{answers.isBuildingOwner?.toString() == 'false' && (
								<div>
									<strong>{pageAssets[ownerFullNameInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.ownerFullName}
									</span>
								</div>
							)}
						</>
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_oilHeatingDeclaration_secondSubStep_title
								}
							</h4>
						</div>

						<>
							<div>
								<strong>
									{pageAssets[isDeclarationForHeatingReplacementInput.labelKey]}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isDeclarationForHeatingReplacement
									)}
								</span>
							</div>

							{answers?.isDeclarationForHeatingReplacement?.toString() ==
								'true' && (
								<>
									<div>
										<strong>
											{pageAssets[installationDateInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{pageUtils.getLocaleDate(answers?.installationDate!)}
										</span>
									</div>
								</>
							)}

							<div>
								<strong>{pageAssets[isOilHeatingSystemInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isOilHeatingSystem
									)}
								</span>
							</div>

							{answers?.isOilHeatingSystem?.toString() == 'true' && (
								<div>
									<strong>
										{pageAssets[isDualEnergySystemInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{getLabelFromList(
											radioListYesNo,
											answers?.isDualEnergySystem
										)}
									</span>
								</div>
							)}

							{answers?.isOilHeatingSystem?.toString() == 'false' && (
								<div>
									<strong>
										{pageAssets[heatingEnergySourceInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{getLabelFromList(
											heatingEnergySourceList,
											answers?.heatingEnergySource
										)}
									</span>
								</div>
							)}

							{answers?.isOilHeatingSystem?.toString() == 'false' && (
								<>
									<div>
										<strong>
											{pageAssets[mainHeatingSystemTypeInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{getLabelFromList(
												mainHeatingSystemTypeList,
												answers?.mainHeatingSystemType
											)}
										</span>
									</div>
								</>
							)}

							{answers?.isOilHeatingSystem?.toString() == 'true' &&
								answers?.isDualEnergySystem?.toString() == 'true' && (
									<div>
										<strong>
											{pageAssets[convertedHeatingSystemTypeInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{getLabelFromList(
												convertedHeatingSystemTypeList,
												answers?.convertedHeatingSystemType
											)}
										</span>
									</div>
								)}

							{answers?.isDeclarationForHeatingReplacement?.toString() ==
								'false' && (
								<div>
									<strong>
										{pageAssets[approximateInstallYearInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.approximateInstallYear}
									</span>
								</div>
							)}
						</>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_declaration_adresse}
						</h4>

						{/* <form style={{ paddingBottom: '20px' }}> */}
						{!isAddressOutsideLaval && (
							<div className={classes.checkbox2Container}>
								<span className={classes.checkboxWrapper}>
									<input
										onClick={(e: any) =>
											onSelectIsSameDeclarationAdresseInput(e.target.checked)
										}
										value={isSameDeclarationAdresseInput.value}
										className={classes.checkbox2}
										type="checkbox"
										name={isSameDeclarationAdresseInput.name}
										id={isSameDeclarationAdresseInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={isSameDeclarationAdresseInput.labelKey}
									>
										{pageAssets[isSameDeclarationAdresseInput.labelKey]}
									</label>
								</span>
							</div>
						)}

						<div style={{ marginBottom: '20px' }}>
							{showDeclarationAdresseBlock == true ? (
								<ConcernedInfosSection
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										`${isSameDeclarationAdresseInput.value}` == 'false'
									}
									requiredFields={['concernedAdresse', 'concernedAppartement']}
									onGetCadastralAndMatriculeNumber={
										getCadastralAndMatriculeNumber
									}
								/>
							) : (
								<div className={classes.adresseBlock}>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>
							)}
						</div>

						<RadioInput
							name={isBuildingOwnerInput.name}
							label={isBuildingOwnerInput.label}
							value={isBuildingOwnerInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isBuildingOwnerInput.name)}
							onChange={onSelectIsBuildingOwnerInput}
							ref={isBuildingOwnerInput.ref as RefObject<HTMLInputElement>}
							required={isBuildingOwnerInput.required}
							hasBackgroundWrapper={true}
						/>

						{isBuildingOwnerInput.value == 'false' && (
							<TextInput
								id={ownerFullNameInput.name}
								label={ownerFullNameInput.label}
								value={ownerFullNameInput.value}
								onChange={onSelectOwnerFullNameInput}
								hasError={errors.includes(ownerFullNameInput.name)}
								required={ownerFullNameInput.required}
								ref={ownerFullNameInput.ref as RefObject<HTMLInputElement>}
								classWrapper={classes.inputContainer}
							/>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
					checkTimeout={40}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_oilHeatingDeclaration_secondSubStep_title
							}
						</h4>

						<RadioInput
							name={isDeclarationForHeatingReplacementInput.name}
							label={isDeclarationForHeatingReplacementInput.label}
							value={isDeclarationForHeatingReplacementInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isDeclarationForHeatingReplacementInput.name
							)}
							onChange={onSelectIsDeclarationForHeatingReplacementInput}
							ref={
								isDeclarationForHeatingReplacementInput.ref as RefObject<HTMLInputElement>
							}
							required={isDeclarationForHeatingReplacementInput.required}
							hasBackgroundWrapper={true}
						/>

						{isDeclarationForHeatingReplacementInput.value.toString() ==
							'true' && (
							<div className={pageUtils.classes.fieldsContainer}>
								<DateFilterInput
									id={installationDateInput.name}
									label={installationDateInput.label}
									value={installationDateInput.value}
									onChange={onSelectInstallationDateInput}
									hasError={errors.includes(installationDateInput.name)}
									required={installationDateInput.required}
									classWrapper={classes.inputContainer}
									dateFormat="dd/MM/yyyy"
								/>
								{/*
								<TextInput
									type="date"
									id={installationDateInput.name}
									label={installationDateInput.label}
									value={installationDateInput.value}
									onChange={onSelectInstallationDateInput}
									hasError={errors.includes(installationDateInput.name)}
									required={installationDateInput.required}
									ref={installationDateInput.ref as RefObject<HTMLInputElement>}
									classWrapper={classes.inputContainer}
									customErrorText={
										installationDateInput.value !== ''
											? installationDateInput.extraParams?.customErrorText
											: undefined
									}
								/>
							*/}
							</div>
						)}

						<RadioInput
							name={isOilHeatingSystemInput.name}
							label={isOilHeatingSystemInput.label}
							value={isOilHeatingSystemInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isOilHeatingSystemInput.name)}
							onChange={onSelectIsOilHeatingSystemInput}
							ref={isOilHeatingSystemInput.ref as RefObject<HTMLInputElement>}
							required={isOilHeatingSystemInput.required}
							hasBackgroundWrapper={true}
						/>

						{isOilHeatingSystemInput.value.toString() == 'true' && (
							<RadioInput
								name={isDualEnergySystemInput.name}
								label={isDualEnergySystemInput.label}
								value={isDualEnergySystemInput.value}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors!.includes(isDualEnergySystemInput.name)}
								onChange={onSelectIsDualEnergySystemInput}
								ref={isDualEnergySystemInput.ref as RefObject<HTMLInputElement>}
								required={isDualEnergySystemInput.required}
								hasBackgroundWrapper={true}
								description={isDualEnergySystemInput.description}
							/>
						)}

						{isOilHeatingSystemInput.value.toString() == 'false' && (
							<RadioInput
								name={heatingEnergySourceInput.name}
								label={heatingEnergySourceInput.label}
								value={heatingEnergySourceInput.value}
								list={heatingEnergySourceList}
								direction={Direction.vertical}
								hasError={errors!.includes(heatingEnergySourceInput.name)}
								onChange={onSelectHeatingEnergySourceInput}
								ref={
									heatingEnergySourceInput.ref as RefObject<HTMLInputElement>
								}
								required={heatingEnergySourceInput.required}
								hasBackgroundWrapper={true}
							/>
						)}

						{isOilHeatingSystemInput.value.toString() == 'false' && (
							<RadioInput
								name={mainHeatingSystemTypeInput.name}
								label={mainHeatingSystemTypeInput.label}
								value={mainHeatingSystemTypeInput.value}
								list={mainHeatingSystemTypeList}
								direction={Direction.vertical}
								hasError={errors!.includes(mainHeatingSystemTypeInput.name)}
								onChange={onSelectMainHeatingSystemTypeInput}
								ref={
									mainHeatingSystemTypeInput.ref as RefObject<HTMLInputElement>
								}
								required={mainHeatingSystemTypeInput.required}
								hasBackgroundWrapper={true}
							/>
						)}

						{isOilHeatingSystemInput.value.toString() == 'true' &&
							isDualEnergySystemInput.value.toString() == 'true' && (
								<RadioInput
									name={convertedHeatingSystemTypeInput.name}
									label={convertedHeatingSystemTypeInput.label}
									value={convertedHeatingSystemTypeInput.value}
									list={convertedHeatingSystemTypeList}
									direction={Direction.vertical}
									hasError={errors!.includes(
										convertedHeatingSystemTypeInput.name
									)}
									onChange={onSelectConvertedHeatingSystemTypeInput}
									ref={
										convertedHeatingSystemTypeInput.ref as RefObject<HTMLInputElement>
									}
									required={convertedHeatingSystemTypeInput.required}
									hasBackgroundWrapper={true}
								/>
							)}

						{isDeclarationForHeatingReplacementInput.value == 'false' && (
							<div className={classes.inputContainer}>
								<SelectInput
									id={approximateInstallYearInput.name}
									label={approximateInstallYearInput.label}
									value={approximateInstallYearInput.value}
									list={getApproximateInstallYearList()}
									onChange={onSelectApproximateInstallYearInput}
									hasError={errors.includes(approximateInstallYearInput.name)}
									required={approximateInstallYearInput.required}
									ref={
										approximateInstallYearInput.ref as RefObject<HTMLSelectElement>
									}
								/>
							</div>
						)}
					</section>
				</Collapse>
			)}
		</>
	)
}

export default OilHeatingDeclarationForm
