import React, {
	forwardRef,
	ForwardRefRenderFunction,
	useEffect,
	useState
} from 'react'
import { inputTablesFields, TooltipProp } from '@utils/request'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import Tooltip from '@components/ui/tooltip'
import Breakpoints from '@utils/breakpoints'

type Props = TooltipProp & {
	name: string
	label: string
	fields: inputTablesFields
	value: string
	required?: boolean
	hasError?: boolean
	disabled?: boolean
	onChange: (newList: inputTablesFields) => void
	isSummary?: boolean
}

type Classes = {
	root: string
	labelPopup: string
	radio: string
	radioLabel: string
	error: string
	extraText: string
	table: string
	active: string
	disabled: string
	required: string
	contentRoot: string
	noWrap: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '30px'
	},
	labelPopup: {
		display: 'inline-flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('md')]: {
			justifyContent: 'space-between',
			width: '100%'
		}
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		input: {
			'&:before': {
				borderColor: Colors.errorRed
			},
			'&:focus': {
				'&:before': {
					boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
				}
			}
		}
	},
	extraText: {
		'& div.extraText': {
			maxWidth: '100%',
			color: Colors.darkGrey,
			flexBasis: '100%',
			marginLeft: 28,
			p: {
				fontSize: 12,
				lineHeight: '18px'
			}
		}
	},
	radioLabelExtraText: {
		flexWrap: 'wrap'
	},
	table: {
		border: 'none',
		borderCollapse: 'collapse',
		'& thead': {
			fontWeight: 'bold',
			textAlign: 'center',
			'& td': {
				background: Colors.greyIron,
				color: Colors.brown,
				padding: '6px 15px',
				minWidth: '120px',
				fontSize: '14px'
			}
		},
		'& tbody': {
			'& td': {
				background: Colors.white,
				color: Colors.brown,
				cursor: 'pointer',
				padding: '8px 10px',
				border: `5px solid ${Colors.greyIron}`,
				fontSize: '16px',
				textAlign: 'center',
				':hover': {
					background: Colors.grey
				}
			}
		},
		'& td': {
			border: `5px solid ${Colors.greyIron}`
		}
	},
	active: {
		background: `${Colors.secondary} !important`,
		color: `${Colors.white} !important`
	},
	disabled: {
		'& tbody': {
			'& td': {
				':hover': {
					background: Colors.white
				}
			}
		}
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	}
})

const TableInputForwardRef: ForwardRefRenderFunction<HTMLInputElement, Props> =
	(
		{
			name,
			label,
			value,
			fields,
			required,
			hasError,
			disabled,
			showInfo,
			infoLabel,
			onChange,
			isSummary
		},
		ref
	) => {
		const [tableInputFields, setTableInputFields] = useState<inputTablesFields>(
			{} as inputTablesFields
		)

		const activeField = (rowIndex: number, fieldIndex: number) => {
			setTableInputFields((oldInput) => {
				const newValues = JSON.parse(JSON.stringify(oldInput))
				newValues.rows[rowIndex][fieldIndex].selected =
					!newValues.rows[rowIndex][fieldIndex].selected
				onChange(newValues)
				return newValues
			})
		}

		useEffect(() => {
			if (fields) {
				setTableInputFields(fields)
			}
		}, [fields])

		const getLabel = () => {
			const hasQuestionMark = label?.endsWith('?')
			if (hasQuestionMark) {
				return (
					<div className={classes.contentRoot}>
						{label?.replace('?', '')}
						<span className={required ? classes.noWrap : ''}>?</span>
					</div>
				)
			} else {
				return <span className={required ? classes.required : ''}>{label}</span>
			}
		}

		return (
			<div
				className={joinClasses([classes.root, hasError ? classes.error : ''])}
			>
				{!isSummary && (
					<label>
						<div className={classes.labelPopup}>
							{getLabel()}
							{showInfo && infoLabel && (
								<Tooltip showInfo infoLabel={infoLabel} />
							)}
						</div>
						<Error hasError={hasError} />
					</label>
				)}
				<div>
					<table
						className={joinClasses([
							classes.table,
							isSummary ? classes.disabled : ''
						])}
					>
						<thead>
							<tr>
								{tableInputFields?.headers?.map((header, headerIndex) => (
									<td key={`${headerIndex}-${header}`}>{header}</td>
								))}
							</tr>
						</thead>
						<tbody>
							{tableInputFields?.rows?.map((row, rowIndex) => (
								<tr key={`${rowIndex}-row`}>
									{row?.map((field, fieldIndex) => (
										<td
											key={`${fieldIndex}-${field.label}`}
											className={joinClasses([
												field.selected ? classes.active : ''
											])}
											onClick={
												!isSummary
													? activeField.bind(null, rowIndex, fieldIndex)
													: undefined
											}
										>
											{field.label}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

const TableInput = forwardRef(TableInputForwardRef)

export default TableInput
