import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	Direction,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { AddressCoordinates, CustomDate } from '@services/models'
import moment from 'moment'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '@components/ui/addressForm/__index.utils'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import { ConcernFieldNames } from '@components/ui/concernedInfosSection/__index.utils'
import { Icon } from '@components/ui/Icon'
import { closeIcon } from '@images/icons'
import RequestFiles from '@components/ui/requestFiles'
import Text from '@components/ui/text'
import DateFilterInput from './inputs/datefilter.input'
import { usePublicHolidaysData } from '@services/hooks'

type Classes = {
	rowSection: string
	adresseBlock: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	checkboxWrapper: string
	fieldsContainer: string
	rowRemoveContainer: string
	closeIcon: string
	button: string
	description: string
	fileHelperSection: string
	fileContainer: string
}

const classes: Classes = makeClasses({
	rowSection: {
		display: 'grid',
		alignItems: 'end',
		gridTemplateColumns: '96%3%',
		columnGap: '1%',
		[Breakpoints.maxWidth('sm')]: {
			marginBottom: '15px'
		},
		'& > div': {
			marginBottom: '8px'
		}
	},
	adresseBlock: {
		border: `1px solid ${Colors.greyAlto}`,
		padding: '20px 25px',
		marginBottom: '10px'
	},
	checkbox2: {
		width: 'fit-content',
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		}
	},
	checkbox2Container: {
		'& input[value=true] ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '14px 12px'
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	rowRemoveContainer: {
		display: 'flex',
		'& > span': {
			marginBottom: '9px'
		}
	},
	closeIcon: {
		display: 'inline-flex',
		height: '30px',
		marginLeft: '-3px',
		cursor: 'pointer',
		alignSelf: 'flex-start',
		marginBottom: '7px',
		img: {
			height: '100%'
		},

		'& span': {
			display: 'none'
		},

		[Breakpoints.maxWidth('sm')]: {
			marginTop: '5px',
			height: '27px',
			'& span': {
				display: 'inline',
				color: '#0054a6',
				textDecoration: 'underline',
				lineHeight: '27px'
			}
		}
	},
	button: {
		display: 'inline-block',
		padding: '10px 20px',
		fontSize: '13px',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: Colors.secondary,
		border: 'none',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2,
			color: Colors.white
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		marginBottom: '10px!important'
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	}
})

const HydraulicAndPressureTestsForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	isSummaryStep,
	steps,
	toPrint,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	filesInputs,
	onSetInputFiles,
	inputFiles
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		UpdateRequestInputFilesArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)
	const isString = (value: any): boolean => {
		return typeof value === 'string'
	}

	const { isWeekday } = usePublicHolidaysData()

	// Disable 5 working days excluding weekends(Saturday and Sunday)
	const calculateDisabledDays = (): Date => {
		const currentDate: Date = new Date()
		let disabledDays: number = 6

		for (let i = 1; i <= 6; i++) {
			const nextDate: Date = new Date()
			nextDate.setDate(currentDate.getDate() + i)

			if (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
				disabledDays++

				if (nextDate.getDay() === 0) {
					i++
				}
			}
		}
		const endDate: Date = new Date(currentDate.getTime())
		endDate.setDate(currentDate.getDate() + disabledDays)

		return endDate
	}

	const minDate = calculateDisabledDays()

	//----------------------------------------------------------------
	//----------------------- INPUTS ---------------------------------
	//----------------------------------------------------------------

	const [isSameDeclarationAdresseInput, setIsSameDeclarationAdresseInput] =
		useState<RequestInputs>({
			name: 'businessSameAsPersonal',
			label: pageAssets?.request_form_isSameAdresse_label,
			labelKey: 'request_form_isSameAdresse_label',
			value: answers.businessSameAsPersonal?.toString() || 'false',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingRegistrationNumberInput, setBuildingRegistrationNumberInput] =
		useState<RequestInputs>({
			name: 'buildingRegistrationNumber',
			label: '',
			labelKey: '',
			value: answers.buildingRegistrationNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [showDeclarationAdresseBlock, setShowDeclarationAdresseBlock] =
		useState<boolean>(answers.businessSameAsPersonal == true ? false : true)

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
		name: 'concernedLastName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers.concernedLastName || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [isContractWithCityInput, setIsContractWithCityInput] =
		useState<RequestInputs>({
			name: 'isContractWithCity',
			label:
				pageAssets?.request_form_hydraulicAndPressureTest_is_this_a_contract_with_city,
			labelKey:
				'request_form_hydraulicAndPressureTest_is_this_a_contract_with_city',
			value: answers.isContractWithCity?.toString() ?? '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const [contractNumberInput, setContractNumberInput] = useState<RequestInputs>(
		{
			name: 'contractNumber',
			label: pageAssets?.request_form_hydraulicAndPressureTest_contract_number,
			labelKey: 'request_form_hydraulicAndPressureTest_contract_number',
			value: answers.contractNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		}
	)

	const [cityRespondentNameInput, setCityRespondentNameInput] =
		useState<RequestInputs>({
			name: 'cityRespondentName',
			label:
				pageAssets?.request_form_hydraulicAndPressureTest_name_of_respondent_at_city,
			labelKey:
				'request_form_hydraulicAndPressureTest_name_of_respondent_at_city',
			value: answers.cityRespondentName || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const [
		desiredDateForHydraulicTestInput,
		setDesiredDateForHydraulicTestInput
	] = useState<RequestInputs>({
		name: 'desiredDateForHydraulicTest',
		label:
			pageAssets?.request_form_hydraulicAndPressureTest_desiredDateForHydraulicTest_label,
		labelKey:
			'request_form_hydraulicAndPressureTest_desiredDateForHydraulicTest_label',
		value: answers.desiredDateForHydraulicTest || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})
	const [
		isRelatedToInstallingTemporaryNetworkInput,
		setIsRelatedToInstallingTemporaryNetworkInput
	] = useState<RequestInputs>({
		name: 'isRelatedToInstallingTemporaryNetwork',
		label:
			pageAssets?.request_form_hydraulicAndPressureTest_installing_a_temporary_network,
		labelKey:
			'request_form_hydraulicAndPressureTest_installing_a_temporary_network',
		value: answers.isRelatedToInstallingTemporaryNetwork?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [hydraulicTestList, setHydraulicTestList] = useState<string[] | string>(
		answers?.hydraulicTestList ? answers.hydraulicTestList : ['']
	)

	// a fake RequestInputs to put hydraulicTestList data in
	// value will be encoded as JSON

	const [hydraulicTestListInput, setHydraulicTestListInput] =
		useState<RequestInputs>({
			name: 'hydraulicTestList',
			label: pageAssets?.request_form_treePlanting_planting_information_title,
			labelKey: 'request_form_treePlanting_planting_information_title',
			value: isString(hydraulicTestList)
				? (hydraulicTestList as string)
				: JSON.stringify(hydraulicTestList),
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [otherDocumentInput, setOtherDocumentInput] =
		useState<RequestInputFiles>({
			name: 'otherDocumentsInput',
			label: pageAssets?.request_form_temporaryUseOfPesticide_other_documents,
			labelKey: 'request_form_temporaryUseOfPesticide_other_documents',
			description:
				pageAssets?.request_form_hydraulicAndPressureTest_otherDocuments_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'otherDocumentsInput')?.files || []
			),
			required: false,
			numberFilesRequired: 1,
			numberMaxFiles: 10,
			category: requestCategories.other
		})

	//----------------------------------------------------------------
	//------------------- INPUTS SETTERS -----------------------------
	//----------------------------------------------------------------

	const onSelectCompanyNameInput = (value: string) => {
		setCompanyNameInput({ ...companyNameInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, companyNameInput.name))
	}

	const onSelectIsSameDeclarationAdresseInput = (value) => {
		setIsSameDeclarationAdresseInput({
			...isSameDeclarationAdresseInput,
			value: `${value}`
		})

		if (value == true) {
			getCadastralAndMatriculeNumber(authUser?.profile?.address.coordinates!)
		}

		setTimeout(() => {
			setShowDeclarationAdresseBlock(!value)
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isSameDeclarationAdresseInput.name)
		)
	}

	const onSelectIsContractWithCityInput = (value: string) => {
		setIsContractWithCityInput({ ...isContractWithCityInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isContractWithCityInput.name)
		)
	}

	const onSelectContractNumberInput = (value: string) => {
		setContractNumberInput({ ...contractNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, contractNumberInput.name)
		)
	}

	const onSelectCityRespondentNameInput = (value: string) => {
		setCityRespondentNameInput({ ...cityRespondentNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, cityRespondentNameInput.name)
		)
	}

	const onSelectDesiredDateForHydraulicTestInput = (value: string) => {
		setDesiredDateForHydraulicTestInput({
			...desiredDateForHydraulicTestInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				desiredDateForHydraulicTestInput.name
			)
		)
	}

	const onSelectIsRelatedToInstallingTemporaryNetworkInput = (
		value: string
	) => {
		setIsRelatedToInstallingTemporaryNetworkInput({
			...isRelatedToInstallingTemporaryNetworkInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				isRelatedToInstallingTemporaryNetworkInput.name
			)
		)
	}

	const onSelectOtherDocumentInput = (files: Set<File>) => {
		setOtherDocumentInput({
			...otherDocumentInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, otherDocumentInput.name))
	}

	//----------------------------------------------------------------
	//------------------- USE EFFECTS --------------------------------
	//----------------------------------------------------------------

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyNameInput))
	}, [companyNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isSameDeclarationAdresseInput))
	}, [isSameDeclarationAdresseInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isContractWithCityInput))
	}, [isContractWithCityInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, contractNumberInput))
	}, [contractNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, cityRespondentNameInput))
	}, [cityRespondentNameInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, desiredDateForHydraulicTestInput)
		)
	}, [desiredDateForHydraulicTestInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(
				inputs,
				isRelatedToInstallingTemporaryNetworkInput
			)
		)
	}, [isRelatedToInstallingTemporaryNetworkInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, hydraulicTestListInput))
	}, [hydraulicTestListInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, otherDocumentInput)
		)
	}, [otherDocumentInput])

	useEffect(() => {
		if (isContractWithCityInput.value == radioListYesNo[0].value) {
			setContractNumberInput({ ...contractNumberInput, required: true })
			setCityRespondentNameInput({ ...cityRespondentNameInput, required: true })
		} else {
			setContractNumberInput({ ...contractNumberInput, required: false })
			setCityRespondentNameInput({
				...cityRespondentNameInput,
				required: false
			})

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, contractNumberInput.name)
			)
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, cityRespondentNameInput.name)
			)
		}
	}, [isContractWithCityInput])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	useEffect(() => {
		// otherDocuments input
		if (!!answers.otherDocumentsInput && otherDocumentInput.files.size == 0) {
			getFilesFromStore('otherDocumentsInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...otherDocumentInput,
						files: result
					})
				}
			)
		}
	}, [])

	//----------------------------------------------------------------
	//---------------- CONSTANTS AND FUNCTIONS -----------------------
	//----------------------------------------------------------------
	const getHydraulicTests = (
		hydraulicTestList: string | string[]
	): string[] => {
		if (isString(hydraulicTestList))
			return JSON.parse(hydraulicTestList as string)
		return hydraulicTestList as string[]
	}

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getCadastralAndMatriculeNumber = (coordinates: AddressCoordinates) => {
		getAddressByCoordinates(coordinates).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})

			setBuildingRegistrationNumberInput({
				...buildingRegistrationNumberInput,
				value: res?.data?.features?.[0].properties.matricule
			})
		})
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const renderHydraulicTestRow = (hydraulicTest: string, row: number) => {
		return (
			<div className={classes.rowSection} key={row}>
				<TextInput
					id={`hydraulicTest${row}`}
					label={`${pageAssets?.request_form_hydraulicAndPressureTest_label} #${
						row + 1
					}`}
					value={hydraulicTest}
					onChange={(value) => onSetHydraulicTestCurrentInput(value, row)}
					hasError={errors.includes(`hydraulicTest${row}`)}
					// classWrapper={row > 0 ? classes.withoutLabel : ''}
					required
					maxCharacters={100}
				/>
				<div className={classes.rowRemoveContainer}>
					{hydraulicTestList.length > 1 && (
						<span
							className={classes.closeIcon}
							onClick={() => removeHydraulicTestRow(row)}
						>
							<Icon src={closeIcon} />
							<span>
								{
									pageAssets?.request_form_hydraulicAndPressureTest_remove_localisation
								}
							</span>
						</span>
					)}
				</div>
			</div>
		)
	}

	const addHydraulicTestRow = () => {
		let itemList = JSON.parse(JSON.stringify(hydraulicTestList))

		itemList.push('')

		setHydraulicTestList(itemList)

		setHydraulicTestListInput({
			...hydraulicTestListInput,
			value: JSON.stringify(itemList)
		})

		onFixError(updateHydraulicTestErrorsArray(itemList))
	}

	const removeHydraulicTestRow = (row: number) => {
		let itemList = [...hydraulicTestList]

		itemList.splice(row, 1)

		setHydraulicTestList(itemList)

		setHydraulicTestListInput({
			...hydraulicTestListInput,
			value: JSON.stringify(itemList)
		})

		let itemListLength = itemList.length

		onFixError(
			updateHydraulicTestRowErrorsArray([
				`hydraulicTest${row}`,
				// suppr last row errors
				`hydraulicTest${itemListLength}`
			])
		)
	}

	const onSetHydraulicTestCurrentInput = (value: string, row: number) => {
		let itemList = JSON.parse(JSON.stringify(hydraulicTestList))

		itemList[row] = value

		setHydraulicTestList(itemList)

		setHydraulicTestListInput({
			...hydraulicTestListInput,
			value: JSON.stringify(itemList)
		})

		onFixError(updateHydraulicTestErrorsArray(itemList))
	}

	const updateHydraulicTestErrorsArray = (itemList: string[]): string[] => {
		let errorArray: string[] = []

		errors?.forEach((error) => {
			if (error.includes('hydraulicTest')) {
				itemList.forEach((hydraulicTestValue, row) => {
					if (hydraulicTestValue == '' && error == `hydraulicTest${row}`) {
						errorArray.push(error)
					}
				})
			} else {
				errorArray.push(error)
			}
		})

		return errorArray
	}

	const updateHydraulicTestRowErrorsArray = (
		inputNameList: string[]
	): string[] => {
		return errors.filter((err) => !inputNameList.includes(err))
	}
	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_company_information}
							</h4>
						</div>

						<div>
							<strong>{pageAssets[companyNameInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.concernedLastName}
							</span>
						</div>
						<div>
							<strong>
								{pageAssets[isSameDeclarationAdresseInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.businessSameAsPersonal
								)}
							</span>
						</div>

						{/* concerds */}

						{!answers?.businessSameAsPersonal &&
							answers?.concernedHasLavalAdresse && (
								<>
									<>
										<div>
											<strong>{pageAssets?.label_address}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.concernedAdresse}
											</span>
										</div>
									</>
									<>
										<div>
											<strong>{`${formatStrapiText(
												pageAssets?.label_apartment_office
											)}`}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.concernedAppartement}
											</span>
										</div>
									</>
								</>
							)}

						{!answers?.businessSameAsPersonal &&
							!answers?.concernedHasLavalAdresse && (
								<>
									<>
										<div>
											<strong>{pageAssets?.label_address}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.concernedAdresse}
											</span>
										</div>
									</>
									<>
										<div>
											<strong>{`${formatStrapiText(
												pageAssets?.label_apartment_office
											)}`}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.concernedAppartement}
											</span>
										</div>
									</>
									<div>
										<strong>{pageAssets?.label_city}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedCity}
										</span>
									</div>
									<div>
										<strong>{pageAssets?.label_state}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedProvince}
										</span>
									</div>
									<div>
										<strong>{pageAssets?.label_postalCode}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedPostalCode}
										</span>
									</div>
									<div>
										<strong>{pageAssets?.label_country}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedCountry}
										</span>
									</div>
								</>
							)}
						<div>
							<strong>
								{
									pageAssets?.request_form_hydraulicAndPressureTest_is_this_a_contract_with_city
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(radioListYesNo, answers?.isContractWithCity)}
							</span>
						</div>
						{answers?.isContractWithCity && (
							<>
								<div>
									<strong>
										{
											pageAssets?.request_form_hydraulicAndPressureTest_contract_number
										}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.contractNumber}
									</span>
								</div>
								<div>
									<strong>
										{
											pageAssets?.request_form_hydraulicAndPressureTest_name_of_respondent_at_city
										}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.cityRespondentName}
									</span>
								</div>
							</>
						)}

						<h4 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_hydraulicAndPressureTest_hydraulic_test_information
							}
						</h4>

						<div>
							<strong>
								{
									pageAssets?.request_form_hydraulicAndPressureTest_desiredDateForHydraulicTest_label
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{pageUtils.getLocaleDate(answers?.desiredDateForHydraulicTest!)}
							</span>
						</div>

						<div>
							<strong>
								{
									pageAssets?.request_form_hydraulicAndPressureTest_installing_a_temporary_network
								}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.isRelatedToInstallingTemporaryNetwork
								)}
							</span>
						</div>
						{answers?.hydraulicTestList &&
							getHydraulicTests(answers?.hydraulicTestList)?.map(
								(item, index) => (
									<div key={index}>
										<strong>{`${
											pageAssets?.request_form_hydraulicAndPressureTest_label
										} #${index + 1}`}</strong>
										<br />
										<span className={pageUtils.classes.answer}>{item}</span>
									</div>
								)
							)}

						{subSteps?.requiredDocument && otherDocumentInput.files.size > 0 && (
							<>
								<div style={{ marginTop: '40px' }}>
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											float: 'right'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_optional_documents}
									</h4>
								</div>

								<RequestFiles
									subtitle={otherDocumentInput.label}
									name={otherDocumentInput.name}
									required={otherDocumentInput.required}
									files={otherDocumentInput.files}
									hasError={errors.includes(otherDocumentInput.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectOtherDocumentInput}
									maxFiles={otherDocumentInput.numberMaxFiles}
									isSummary
								>
									<></>
								</RequestFiles>
							</>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_company_information}
						</h4>

						<TextInput
							id={companyNameInput.name}
							label={companyNameInput.label}
							value={companyNameInput.value}
							onChange={onSelectCompanyNameInput}
							hasError={errors.includes(companyNameInput.name)}
							required={companyNameInput.required}
							ref={companyNameInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={100}
						/>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_company_address}
						</h5>

						<div className={classes.checkbox2Container}>
							<span className={classes.checkboxWrapper}>
								<input
									onClick={(e: any) =>
										onSelectIsSameDeclarationAdresseInput(e.target.checked)
									}
									value={isSameDeclarationAdresseInput.value}
									className={classes.checkbox2}
									type="checkbox"
									name={isSameDeclarationAdresseInput.name}
									id={isSameDeclarationAdresseInput.labelKey}
								/>
								<label
									className={classes.checkbox2Label}
									htmlFor={isSameDeclarationAdresseInput.labelKey}
								>
									{pageAssets[isSameDeclarationAdresseInput.labelKey]}
								</label>
							</span>
						</div>

						<div style={{ marginBottom: '20px' }}>
							{showDeclarationAdresseBlock == true ? (
								<ConcernedInfosSection
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isSameAddressAsPersonalInfor={
										isSameDeclarationAdresseInput.value == 'true'
									}
									isConcernedInfosRequired={
										`${isSameDeclarationAdresseInput.value}` == 'false'
									}
									requiredFields={[
										ConcernFieldNames.concernedAdresse,
										ConcernFieldNames.concernedAppartement,
										ConcernFieldNames.concernedHasLavalAdresse,
										ConcernFieldNames.concernedCity,
										ConcernFieldNames.concernedProvince,
										ConcernFieldNames.concernedPostalCode,
										ConcernFieldNames.concernedCountry
									]}
									onGetCadastralAndMatriculeNumber={
										getCadastralAndMatriculeNumber
									}
								/>
							) : (
								<div className={classes.adresseBlock}>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>
							)}
						</div>

						<RadioInput
							name={isContractWithCityInput.name}
							label={isContractWithCityInput.label}
							value={isContractWithCityInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isContractWithCityInput.name)}
							onChange={onSelectIsContractWithCityInput}
							ref={isContractWithCityInput.ref as RefObject<HTMLInputElement>}
							required={isContractWithCityInput.required}
							hasBackgroundWrapper={true}
						/>
						{isContractWithCityInput.value === radioListYesNo[0].value && (
							<div className={classes.fieldsContainer}>
								<TextInput
									id={contractNumberInput.name}
									label={contractNumberInput.label}
									value={contractNumberInput.value}
									onChange={onSelectContractNumberInput}
									hasError={errors?.includes(contractNumberInput.name)}
									required={contractNumberInput.required}
									ref={contractNumberInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>
								<TextInput
									id={cityRespondentNameInput.name}
									label={cityRespondentNameInput.label}
									value={cityRespondentNameInput.value}
									onChange={onSelectCityRespondentNameInput}
									hasError={errors?.includes(cityRespondentNameInput.name)}
									required={cityRespondentNameInput.required}
									ref={
										cityRespondentNameInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</div>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
					checkTimeout={40}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_hydraulicAndPressureTest_hydraulic_test_information
							}
						</h4>
						<div className={classes.fieldsContainer}>
							<DateFilterInput
								id={desiredDateForHydraulicTestInput.name}
								label={desiredDateForHydraulicTestInput.label}
								value={desiredDateForHydraulicTestInput.value}
								onChange={onSelectDesiredDateForHydraulicTestInput}
								hasError={errors.includes(
									desiredDateForHydraulicTestInput.name
								)}
								required={desiredDateForHydraulicTestInput.required}
								minDate={new Date()}
								filterDate={isWeekday}
								dateFormat="dd/MM/yyyy"
							/>
						</div>
						<RadioInput
							name={isRelatedToInstallingTemporaryNetworkInput.name}
							label={isRelatedToInstallingTemporaryNetworkInput.label}
							value={isRelatedToInstallingTemporaryNetworkInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isRelatedToInstallingTemporaryNetworkInput.name
							)}
							onChange={onSelectIsRelatedToInstallingTemporaryNetworkInput}
							ref={
								isRelatedToInstallingTemporaryNetworkInput.ref as RefObject<HTMLInputElement>
							}
							required={isRelatedToInstallingTemporaryNetworkInput.required}
							hasBackgroundWrapper={true}
						/>
						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.label_hydraulicTest_localisation}
						</h5>

						<p className={classes.description}>
							{pageAssets?.label_indicate_the_location_or_address_closest}
						</p>

						{/* /////////////////  hydraulic test List  //////////////// */}
						{getHydraulicTests(hydraulicTestList)?.map(
							(hydraulicTest, index) => {
								return renderHydraulicTestRow(hydraulicTest, index)
							}
						)}

						{/* /////////////////  Add Button  //////////////// */}
						{hydraulicTestList.length < 5 && (
							<a
								style={{ marginBottom: '12px' }}
								onClick={() => addHydraulicTestRow()}
								className={classes.button}
							>
								{
									pageAssets?.request_form_hydraulicAndPressureTest_add_localisation
								}
							</a>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
					checkTimeout={40}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_optional_documents}
						</h4>

						<RequestFiles
							subtitle={otherDocumentInput.label}
							name={otherDocumentInput.name}
							required={otherDocumentInput.required}
							files={otherDocumentInput.files}
							hasError={errors.includes(otherDocumentInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOtherDocumentInput}
							subSteps={subSteps}
							maxFiles={otherDocumentInput.numberMaxFiles}
							description={otherDocumentInput.description}
						></RequestFiles>
					</section>
				</Collapse>
			)}
		</>
	)
}

export default HydraulicAndPressureTestsForm
