import { pdfIcon } from '@images/icons'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export type ClassType = {
	text: string
	h4: string
	collapse: string
	paragraph: string
}

export const classes: ClassType = makeClasses({
	text: {
		margin: '0 0 15px',
		padding: '0',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '25px',
		'& a': {
			color: `${Colors.secondary} !important`,
			textDecoration: 'none',
			':hover': {
				textDecoration: 'underline'
			}
		},
		'& .icon-pdf': {
			position: 'relative',
			marginRight: '10px',
			display: 'inline-block',
			backgroundImage: `url("${pdfIcon}")`,
			float: 'left',
			width: '25px',
			height: '25px',
			backgroundSize: '25px',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat'
		},
		'& ul': {
			'& > ul': {
				paddingLeft: '15px',
				marginLeft: '15px',
				margin: '10px 0 20px 15px'
			}
		},
		'& ol': {
			overflow: 'hidden',
			listStyle: 'none',
			margin: '5px 0 30px',
			counterReset: 'parentlist',
			padding: 0,
			'& ul': {
				paddingLeft: '15px',
				marginLeft: '15px',
				margin: '10px 0 20px 15px'
			},
			'& > li': {
				verticalAlign: 'baseline',
				position: 'relative',
				marginBottom: '10px',
				paddingLeft: '25px',
				color: '#0a0a0a',
				WebkitFontSmoothing: 'antialiased !important',
				'&:before': {
					content: 'counter(parentlist) ". "',
					counterIncrement: 'parentlist',
					position: 'absolute',
					top: 'inherit',
					left: '0',
					width: 'inherit',
					height: 'inherit',
					fontWeight: '700',
					color: '#0054a6',
					letterSpacing: '-.08em',
					backgroundColor: 'inherit'
				}
			}
		},
		'& table': {
			borderCollapse: 'collapse',
			border: `1px solid ${Colors.lightGray8}`,
			borderSpacing: '0',
			margin: '0',
			padding: '0',
			outline: '0',
			'& td': {
				border: `1px solid ${Colors.lightGray8}`,
				verticalAlign: 'top',
				padding: '7px 5px 6px',
				fontSize: '15px',
				lineHeight: '21px'
			},
			'& tr:nth-child(even)': {
				backgroundColor: `${Colors.darkWhite}`
			},
			'& thead th': {
				border: `1px solid ${Colors.lightGray8}`,
				padding: '7px 5px 6px',
				'text-align': 'left',
				'vertical-align': 'top'
			}
		},
		p: {
			marginBottom: '15px !important'
		}
	},
	h4: {
		margin: '30px 0 15px',
		padding: '0',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: '700',
		fontSize: '20px',
		lineHeight: '22px'
	},
	collapse: {
		'& > div[id^="collapse-"i]': {
			borderBottom: `1px solid ${Colors.grey}`,
			':first-of-type': {
				borderTop: `1px solid ${Colors.grey}`
			}
		}
	},
	paragraph: {
		margin: '0px 0px 15px',
		padding: '0px',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '25px'
	}
})
