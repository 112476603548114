import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates, CustomDate } from '@services/models'
import { formatStrapiText } from '@utils/methods'
import RadioInput from '@components/requestForms/inputs/radio.input'

import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import {
	airpulse,
	convecteur,
	eauchaude,
	electrique,
	geothermie,
	plinthes,
	radiant,
	thermopompe,
	chaudiereABois,
	cuisiniereAuBois,
	fourCommercial,
	foyerDecoratif,
	foyerDeMasse,
	foyerEncastre,
	foyerMaconnerie,
	poeleABois,
	poeleAGranules,
	poeleAnthracite,
	poeleCombustionLente,
	fournaiseAuBois
} from '@images/icons'
import SelectInput from './inputs/select.inputs'
import moment from 'moment'
import { getDefaultCadastralNumber } from '@components/ui/addressForm/__index.utils'
import DateFilterInput from './inputs/datefilter.input'
import Notification from '@components/ui/notification'
import CheckboxInput from './inputs/checkbox.input'
import Text from '@components/ui/text'
import CustomYearInput from './inputs/customYear.input'

const FireplaceDeclarationForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		language,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		ownerSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		dateAndTimeSection: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		detailscontainer: string
		adresseBlock: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},
		dateAndTimeSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '27%27%',
			columnGap: '1%',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			'& > div': {
				marginBottom: '0px'
			}
		},
		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '60%30%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important'
				}
			},
			lineHeight: '1.62em',
			fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			},
			'& ul': {
				paddingLeft: '18px',
				margin: 0,
				'& li::marker': {
					color: Colors.secondary
				}
			}
		},
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px'
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [showTheConcernedBlock, setShowTheConcernedBlock] = useState<boolean>(
		answers.isConcernedDiffFromApplicant == true ? true : false
	)

	const [showDeclarationAdresseBlock, setShowDeclarationAdresseBlock] =
		useState<boolean>(
			answers.buildingAddressSameHasApplicant == true ? false : true
		)

	const [showBuildingOwnerBlock, setShowBuildingOwnerBlock] = useState<boolean>(
		answers.isBuildingOwner == false ? true : false
	)

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	const [heatingSourceListType, setHeatingSourceListType] =
		useState<CheckboxList[]>()

	//----------------------------------------------------------------
	//----------------------- DETAILS INPUTS -------------------------
	//----------------------------------------------------------------

	//----------------------------------------------------------------
	//------------------ Declaration Adresse INPUTS ------------------
	//----------------------------------------------------------------

	// Même adresse que celle inscrite dans mes informations personnelles
	const [isSameDeclarationAdresseInput, setIsSameDeclarationAdresseInput] =
		useState<RequestInputs>({
			name: 'buildingAddressSameHasApplicant',
			label: pageAssets?.request_form_isSameAdresse_label,
			labelKey: 'request_form_isSameAdresse_label',
			value: answers.buildingAddressSameHasApplicant?.toString() || 'false',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	// hidden input cadastre and matricule
	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingAddressInput, setBuildingAddressInput] =
		useState<RequestInputs>({
			name: 'buildingAddress',
			label: '',
			labelKey: '',
			value: answers.buildingAddress || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingRegistrationNumberInput, setBuildingRegistrationNumberInput] =
		useState<RequestInputs>({
			name: 'buildingRegistrationNumber',
			label: '',
			labelKey: '',
			value: answers.buildingRegistrationNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	//****** isBuildingOwner inputs ******

	// Êtes-vous propriétaire du bâtiment?
	const [isBuildingOwnerInput, setIsBuildingOwnerInput] =
		useState<RequestInputs>({
			name: 'isBuildingOwner',
			label:
				pageAssets?.request_form_oilHeatingDeclaration_isBuildingOwner_label,
			labelKey: 'request_form_oilHeatingDeclaration_isBuildingOwner_label',
			value: answers.isBuildingOwner?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	////////////////////////////////////////////////////////////////

	//----------------------------------------------------------------
	//-------------------- STEP 2 INPUTS ------------------------
	//----------------------------------------------------------------

	const heatingSourceTypeList = pageUtils.addLabelToList(
		pageUtils.heatingSourceTypeListAndValues,
		pageAssets
	)

	useEffect(() => {
		setHeatingSourceListType(heatingSourceTypeList)
	}, [language])

	// Veuillez sélectionner la ou les sources de chauffage de cette propriété
	const [heatingSourceInput, setHeatingSourceInput] = useState<RequestInputs>({
		name: 'heatingSource',
		value: answers.heatingSource || '',
		label: pageAssets?.request_form_fireplaceDeclaration_heatingSource_label,
		labelKey: 'request_form_fireplaceDeclaration_heatingSource_label',
		description:
			pageAssets?.request_form_fireplaceDeclaration_heatingSource_description,
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		ref: useRef<HTMLInputElement>(null)
	})

	const [otherHeatingSourceInput, setOtherHeatingSourceInput] =
		useState<RequestInputs>({
			name: 'otherHeatingSource',
			label:
				pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label,
			labelKey: 'request_form_fireplaceDeclaration_heatingSource_label',
			value: answers.otherHeatingSource ?? '',
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

	const [firstHeatingSource, setFirstHeatingSource] = useState<string>('')
	const [secondHeatingSource, setSecondHeatingSource] = useState<string>('')

	const [
		firstHeatingSourcePercentageInput,
		setFirstHeatingSourcePercentageInput
	] = useState<RequestInputs>({
		name: 'firstHeatingSourcePercentage',
		label:
			pageAssets?.request_form_fireplaceDeclaration_heatingSourcePercentage_label,
		labelKey:
			'request_form_fireplaceDeclaration_firstHeatingSourcePercentage_label',
		value: answers.firstHeatingSourcePercentage ?? '',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		ref: useRef<HTMLInputElement>(null)
	})

	const [
		secondHeatingSourcePercentageInput,
		setSecondHeatingSourcePercentageInput
	] = useState<RequestInputs>({
		name: 'secondHeatingSourcePercentage',
		label:
			pageAssets?.request_form_fireplaceDeclaration_heatingSourcePercentage_label,
		labelKey:
			'request_form_fireplaceDeclaration_secondHeatingSourcePercentage_label',
		value: answers.secondHeatingSourcePercentage ?? '',
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		ref: useRef<HTMLInputElement>(null)
	})

	///////////////////////////////////////////////////////////////////
	/////////////////////// STEP 3 DETAILS  ///////////////////////////
	///////////////////////////////////////////////////////////////////

	// je déclare que..
	const [declarationTypeInput, setDeclarationTypeInput] =
		useState<RequestInputs>({
			name: 'declarationType',
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_label,
			labelKey: 'request_form_fireplaceDeclaration_declarationType_label',
			value: answers.declarationType?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const declarationTypeList: RadioList[] = [
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option1,
			value: REQUEST_CONFIGS_KEYS.declarationType.possess,
			key: 'request_form_fireplaceDeclaration_declarationType_option1'
		},
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option2,
			value: REQUEST_CONFIGS_KEYS.declarationType.replaced,
			key: 'request_form_fireplaceDeclaration_declarationType_option2'
		},
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option3,
			value: REQUEST_CONFIGS_KEYS.declarationType.removed,
			key: 'request_form_fireplaceDeclaration_declarationType_option3'
		},
		{
			label:
				pageAssets?.request_form_fireplaceDeclaration_declarationType_option4,
			value: REQUEST_CONFIGS_KEYS.declarationType.neverHad,
			key: 'request_form_fireplaceDeclaration_declarationType_option4'
		}
	]

	// Question 11 : Quel type d'appareil de chauffage à combustion ou de foyer souhaitez-vous déclarer?
	const [fireplaceTypeToDeclareInput, setFireplaceTypeToDeclareInput] =
		useState<RequestInputs>({
			name: 'fireplaceTypeToDeclare',
			label:
				pageAssets?.request_form_fireplaceDeclaration_fireplaceTypeToDeclare_label,
			labelKey:
				'request_form_fireplaceDeclaration_fireplaceTypeToDeclare_label',
			value: answers.fireplaceTypeToDeclare
				? `${answers.fireplaceTypeToDeclare}`
				: '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Question 12 : Quel type d'appareil de chauffage à combustion ou de foyer souhaitez-vous déclarer?
	// OPTION Other!
	const [
		otherFireplaceTypeToDeclareInput,
		setOtherFireplaceTypeToDeclareInput
	] = useState<RequestInputs>({
		name: 'otherFireplaceTypeToDeclare',
		label:
			pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label,
		labelKey:
			'request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label',
		value: answers.otherFireplaceTypeToDeclare || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const diplayOptionDetails = (text, image) => {
		return (
			<div className={classes.detailscontainer}>
				<Text content={text} />
				<img src={image} />
			</div>
		)
	}

	const displayOtherOptionTextInput = () => {
		return (
			<div>
				<TextInput
					id={otherFireplaceTypeToDeclareInput.name}
					label={otherFireplaceTypeToDeclareInput.label}
					value={otherFireplaceTypeToDeclareInput.value}
					onChange={(value) => {
						onSetOtherFireplaceTypeToDeclareInput(value)
					}}
					hasError={errors?.includes(otherFireplaceTypeToDeclareInput.name)}
					required={otherFireplaceTypeToDeclareInput.required}
					ref={
						otherFireplaceTypeToDeclareInput.ref as RefObject<HTMLInputElement>
					}
					classWrapper={classes.inputContainer}
					maxCharacters={100}
				/>
			</div>
		)
	}

	const fireplaceTypeToDeclareList: RadioList[] = [
		{
			// Chaudière à bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_firstOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.firstOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_firstOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_firstOption_details,
				chaudiereABois
			)
		},
		{
			// Cuisinière au bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_secondOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.secondOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_secondOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_secondOption_details,
				cuisiniereAuBois
			)
		},
		{
			// Foyer au bois muni d'un appareil encastré
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_thirdOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirdOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_thirdOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_thirdOption_details,
				foyerEncastre
			)
		},
		{
			// Foyer de maçonnerie (ouvert en brique ou en pierre, sans appareil à l’intérieur)
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_fourthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fourthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_fourthOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_fourthOption_details,
				foyerMaconnerie
			)
		},
		{
			// Foyer de masse
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_fifthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fifthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_fifthOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_fifthOption_details,
				foyerDeMasse
			)
		},
		{
			// Foyer décoratif (foyer d'ambiance)
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_sixthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.sixthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_sixthOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_sixthOption_details,
				foyerDecoratif
			)
		},
		{
			// Four à bois commercial
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_seventhOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.seventhOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_seventhOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_seventhOption_details,
				fourCommercial
			)
		},
		{
			// Fournaise au bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_eighthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eighthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_eighthOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_eighthOption_details,
				fournaiseAuBois
			)
		},
		{
			// Poêle à bois
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_ninthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.ninthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_ninthOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_ninthOption_details,
				poeleABois
			)
		},
		{
			// Poêle à granules
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_tenthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.tenthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_tenthOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_tenthOption_details,
				poeleAGranules
			)
		},
		{
			// Poêle ou foyer à combustion lente
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_eleventhOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eleventhOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_eleventhOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_eleventhOption_details,
				poeleCombustionLente
			)
		},
		{
			// Poêle ou foyer à l'anthracite
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_twelfthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.twelfthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_twelfthOption',
			details: diplayOptionDetails(
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_twelfthOption_details,
				poeleAnthracite
			)
		},
		{
			// Autre
			label:
				pageAssets?.select_fireplaceDeclaration_fireplaceTypeToDeclare_thirteenthOption,
			value: REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirteenthOption,
			key: 'select_fireplaceDeclaration_fireplaceTypeToDeclare_thirteenthOption',
			details: displayOtherOptionTextInput()
		}
	]

	// Question 13 : Quelle est la fréquence d’utilisation de cet appareil?
	const [frequencyOfUseInput, setFrequencyOfUseInput] = useState<RequestInputs>(
		{
			name: 'frequencyOfUse',
			label: pageAssets?.request_form_fireplaceDeclaration_frequencyOfUse_label,
			labelKey: 'request_form_fireplaceDeclaration_frequencyOfUse_label',
			value: answers.frequencyOfUse || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		}
	)

	const frequencyOfUseList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option1,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.firstOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option1'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option2,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.secondOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option2'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option3,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.thirdOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option3'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option4,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.fourthOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option4'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option5,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.fifthOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option5'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_frequencyOfUse_option6,
			value: REQUEST_CONFIGS_KEYS.frequencyOfUse.sixthOption,
			key: 'select_fireplaceDeclaration_frequencyOfUse_option6'
		}
	]

	// Question 14 : En quelle année l'appareil a-t-il été installé?
	const [installationYearInput, setInstallationYearInput] =
		useState<RequestInputs>({
			name: 'installationYear',
			label:
				pageAssets?.request_form_fireplaceDeclaration_installationYear_label,
			labelKey: 'request_form_fireplaceDeclaration_installationYear_label',
			value: answers.installationYear || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Question 15 : À quel étage est situé l'appareil?
	const [deviceLocationInput, setDeviceLocationInput] = useState<RequestInputs>(
		{
			name: 'deviceLocation',
			label: pageAssets?.request_form_fireplaceDeclaration_deviceLocation_label,
			labelKey: 'request_form_fireplaceDeclaration_deviceLocation_label',
			value: answers.deviceLocation || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		}
	)

	const deviceLocationList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option1,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.firstOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option1'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option2,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.secondOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option2'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option3,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.thirdOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option3'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option4,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.fourthOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option4'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_deviceLocation_option5,
			value: REQUEST_CONFIGS_KEYS.deviceLocation.fifthOption,
			key: 'select_fireplaceDeclaration_deviceLocation_option5'
		}
	]

	// Question 16 : Autre, veuillez spécifier! À quel étage est situé l'appareil?
	const [otherDeviceLocationInput, setOtherDeviceLocationInput] =
		useState<RequestInputs>({
			name: 'otherDeviceLocation',
			label:
				pageAssets?.request_form_fireplaceDeclaration_otherDeviceLocation_label,
			labelKey: 'request_form_fireplaceDeclaration_otherDeviceLocation_label',
			value: answers.otherDeviceLocation || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Question 17 : Quel est le nom du fabricant de l'appareil ?
	const [deviceManifacturerInput, setDeviceManifacturerInput] =
		useState<RequestInputs>({
			name: 'deviceManifacturer',
			label:
				pageAssets?.request_form_fireplaceDeclaration_deviceManifacturer_label,
			labelKey: 'request_form_fireplaceDeclaration_deviceManifacturer_label',
			value: answers.deviceManifacturer || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Question 18 : Quel est le nom et/ou numéro de modèle de l'appareil?
	const [deviceModelInput, setDeviceModelInput] = useState<RequestInputs>({
		name: 'deviceModel',
		label: pageAssets?.request_form_fireplaceDeclaration_deviceModel_label,
		labelKey: 'request_form_fireplaceDeclaration_deviceModel_label',
		value: answers.deviceModel || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	// Question 19 : Est-ce que l'appareil de chauffage ou foyer intérieur est certifié
	// par l’une de ces normes environnementales, soit la norme US EPA et/ou la norme CSA B.415 ?
	const [isDeviceCertifiedInput, setIsDeviceCertifiedInput] =
		useState<RequestInputs>({
			name: 'isDeviceCertified',
			label:
				pageAssets?.request_form_fireplaceDeclaration_isDeviceCertified_label,
			labelKey: 'request_form_fireplaceDeclaration_isDeviceCertified_label',
			value: `${answers?.isDeviceCertified}` || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const isDeviceCertifiedList: RadioList[] = [
		{
			label: pageAssets?.select_fireplaceDeclaration_isDeviceCertified_option1,
			value: REQUEST_CONFIGS_KEYS.isDeviceCertified.firstOption,
			key: 'select_fireplaceDeclaration_isDeviceCertified_option1'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_isDeviceCertified_option2,
			value: REQUEST_CONFIGS_KEYS.isDeviceCertified.secondOption,
			key: 'select_fireplaceDeclaration_isDeviceCertified_option2'
		},
		{
			label: pageAssets?.select_fireplaceDeclaration_isDeviceCertified_option3,
			value: REQUEST_CONFIGS_KEYS.isDeviceCertified.thirdOption,
			key: 'select_fireplaceDeclaration_isDeviceCertified_option3'
		}
	]

	// Question 20 : Quelles sont les certifications ?
	const [deviceCertificationsInput, setDeviceCertificationsInput] =
		useState<RequestInputs>({
			name: 'deviceCertifications',
			label:
				pageAssets?.request_form_fireplaceDeclaration_deviceCertifications_label,
			labelKey: 'request_form_fireplaceDeclaration_deviceCertifications_label',
			value: answers.deviceCertifications || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const deviceCertificationsList: RadioList[] = [
		{
			label:
				pageAssets?.select_fireplaceDeclaration_deviceCertifications_option1,
			value: REQUEST_CONFIGS_KEYS.deviceCertifications.firstOption,
			key: 'select_fireplaceDeclaration_deviceCertifications_option1'
		},
		{
			label:
				pageAssets?.select_fireplaceDeclaration_deviceCertifications_option2,
			value: REQUEST_CONFIGS_KEYS.deviceCertifications.secondOption,
			key: 'select_fireplaceDeclaration_deviceCertifications_option2'
		}
	]

	// Question 22 : Quel est le taux d'émission de particules de l'appareil (gramme par heure)?
	const [deviceParticleEmissionRateInput, setDeviceParticleEmissionRateInput] =
		useState<RequestInputs>({
			name: 'deviceParticleEmissionRate',
			label:
				pageAssets?.request_form_fireplaceDeclaration_deviceParticleEmissionRate_label,
			labelKey:
				'request_form_fireplaceDeclaration_deviceParticleEmissionRate_label',
			description:
				pageAssets?.request_form_fireplaceDeclaration_deviceParticleEmissionRate_description,
			value: answers.deviceParticleEmissionRate ?? '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Question 23 : Nom de l'entrepreneur (Si le foyer a été construit par un entrepreneur expert en maçonnerie)
	const [deviceContractorNameInput, setDeviceContractorNameInput] =
		useState<RequestInputs>({
			name: 'deviceContractorName',
			label:
				pageAssets?.request_form_fireplaceDeclaration_deviceContractorName_label,
			labelKey: 'request_form_fireplaceDeclaration_deviceContractorName_label',
			value: answers.deviceContractorName || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Question 24 : Par quel type d’appareil avez-vous remplacé votre appareil précédent ?
	const [deviceReplacementTypeInput, setDeviceReplacementTypeInput] =
		useState<RequestInputs>({
			name: 'deviceReplacementType',
			label:
				pageAssets?.request_form_fireplaceDeclaration_deviceReplacementType_label,
			labelKey: 'request_form_fireplaceDeclaration_deviceReplacementType_label',
			value: answers.deviceReplacementType || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const deviceReplacementTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_wood_pellets,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.firstOption,
			key: 'request_form_fireplaceDeclaration_wood_pellets'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_electricity,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.secondOption,
			key: 'request_form_fireplaceDeclaration_electricity'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_natural_gas,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.thirdOption,
			key: 'request_form_fireplaceDeclaration_natural_gas'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_propane,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.fourthOption,
			key: 'request_form_fireplaceDeclaration_propane'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_oil_fuel,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.fifthOption,
			key: 'request_form_fireplaceDeclaration_oil_fuel'
		},
		{
			label: pageAssets?.request_form_fireplaceDeclaration_others,
			value: REQUEST_CONFIGS_KEYS.deviceReplacementType.sixthOption,
			key: 'request_form_fireplaceDeclaration_others'
		}
	]

	// Question 25 : Quelle est la date approximative de remplacement?
	const [deviceReplacementDateInput, setDeviceReplacementDateInput] =
		useState<RequestInputs>({
			name: 'mockForLabel',
			label:
				pageAssets?.request_form_fireplaceDeclaration_deviceReplacementDate_label,
			labelKey: 'request_form_fireplaceDeclaration_deviceReplacementDate_label',
			value: '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Question 40 : Quelle est la date approximative de retrait ?
	const [deviceWithdrawalDateInput, setDeviceWithdrawalDateInput] =
		useState<RequestInputs>({
			name: 'deviceWithdrawalDate',
			label:
				pageAssets?.request_form_fireplaceDeclaration_deviceWithdrawalDate_label,
			labelKey: 'request_form_fireplaceDeclaration_deviceWithdrawalDate_label',
			value: answers.deviceWithdrawalDate || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// No documents required for now

	// ------------------- DETAILS INPUTS SETTERS --------------------

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (!coordinates.validate()) {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	const onSelectIsSameDeclarationAdresseInput = (value) => {
		setIsSameDeclarationAdresseInput({
			...isSameDeclarationAdresseInput,
			value: `${value}`
		})

		if (value == true) {
			getCadastralAndMatriculeNumber(authUser?.profile?.address.address)
		}

		setTimeout(() => {
			setShowDeclarationAdresseBlock(!value)
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isSameDeclarationAdresseInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isSameDeclarationAdresseInput))
	}, [isSameDeclarationAdresseInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingBatchNumberInput))
	}, [buildingBatchNumberInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingRegistrationNumberInput)
		)
	}, [buildingRegistrationNumberInput])

	// ----------------------------------------------------------------

	const onSelectIsBuildingOwnerInput = (value: string) => {
		setIsBuildingOwnerInput({
			...isBuildingOwnerInput,
			value: `${value}`
		})

		// if (value == 'true') {
		// 	setOwnerFullNameInput({ ...ownerFullNameInput, required: false })
		// } else {
		// 	setOwnerFullNameInput({ ...ownerFullNameInput, required: true })
		// }

		setTimeout(() => {
			if (value == 'true') {
				setShowBuildingOwnerBlock(false)
			} else {
				setShowBuildingOwnerBlock(true)
			}
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						isBuildingOwnerInput.name
						// ownerFullNameInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isBuildingOwnerInput))
	}, [isBuildingOwnerInput])

	// ----------------------------------------------------------------

	// const onSelectOwnerFullNameInput = (value: string) => {
	// 	setOwnerFullNameInput({ ...ownerFullNameInput, value })

	// 	onFixError(UpdateRequestInputsErrorsArray(errors, ownerFullNameInput.name))
	// }

	// useEffect(() => {
	// 	onSetInputs(UpdateRequestInputsArray(inputs, ownerFullNameInput))
	// }, [ownerFullNameInput])

	// ----------------------------------------------------------------
	// ----------------------------------------------------------------
	// ----------------------------------------------------------------

	////////////////////////////////////////////////////////////////
	// DETAILS 2
	////////////////////////////////////////////////////////////////

	const onChangeHeatingSourceInput = (value: string, key: string) => {
		handleCheckboxWithOthers(
			value,
			heatingSourceInput,
			setHeatingSourceInput,
			otherHeatingSourceInput,
			setOtherHeatingSourceInput,
			2,
			heatingSourceListType!
		)

		onFixError(UpdateRequestInputsErrorsArray(errors, heatingSourceInput.name))

		onFixError(
			errors.filter(
				(error) =>
					![
						heatingSourceInput.name,
						otherHeatingSourceInput.name,
						firstHeatingSourcePercentageInput.name,
						secondHeatingSourcePercentageInput.name
					].includes(error)
			)
		)
	}

	const getKeyFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.key
	}

	const getSplitValuesFromString = (list) => {
		return list?.split(',')?.map((val) => val?.trim())
	}

	useEffect(() => {
		const values = getSplitValuesFromString(heatingSourceInput.value)

		if (values?.length == 2) {
			setFirstHeatingSource(
				getKeyFromList(pageUtils.heatingSourceTypeListAndValues, values[0])
			)
			setSecondHeatingSource(
				getKeyFromList(pageUtils.heatingSourceTypeListAndValues, values[1])
			)
			setFirstHeatingSourcePercentageInput({
				...firstHeatingSourcePercentageInput,
				required: true
				// value: ''
			})
			setSecondHeatingSourcePercentageInput({
				...secondHeatingSourcePercentageInput,
				required: true
				// value: ''
			})
		} else if (values?.length == 1) {
			setFirstHeatingSource(
				getKeyFromList(pageUtils.heatingSourceTypeListAndValues, values[0])
			)
			setFirstHeatingSourcePercentageInput({
				...firstHeatingSourcePercentageInput,
				required: false
				// value: '100'
			})
			setSecondHeatingSource('')
			setSecondHeatingSourcePercentageInput({
				...secondHeatingSourcePercentageInput,
				required: false
				// value: ''
			})
		} else {
			setFirstHeatingSource('')
			setSecondHeatingSource('')
			setFirstHeatingSourcePercentageInput({
				...firstHeatingSourcePercentageInput,
				required: false
				// value: ''
			})
			setSecondHeatingSourcePercentageInput({
				...secondHeatingSourcePercentageInput,
				required: false
				// value: ''
			})
		}

		// heatingSourceListType?.filter(heatingSource => )
	}, [heatingSourceInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, heatingSourceInput))
	}, [heatingSourceInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, otherHeatingSourceInput))
	}, [otherHeatingSourceInput])

	const onSelectFirstHeatingSourcePercentageInput = (value: string) => {
		setFirstHeatingSourcePercentageInput({
			...firstHeatingSourcePercentageInput,
			value
		})

		let pourcentage = parseInt(value)

		if (!isNaN(pourcentage)) {
			setSecondHeatingSourcePercentageInput({
				...secondHeatingSourcePercentageInput,
				value: `${100 - pourcentage}`
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						firstHeatingSourcePercentageInput.name,
						secondHeatingSourcePercentageInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, firstHeatingSourcePercentageInput)
		)
	}, [firstHeatingSourcePercentageInput])

	const onSelectSecondHeatingSourcePercentageInput = (value: string) => {
		setSecondHeatingSourcePercentageInput({
			...secondHeatingSourcePercentageInput,
			value
		})

		let pourcentage = parseInt(value)

		if (!isNaN(pourcentage)) {
			setFirstHeatingSourcePercentageInput({
				...firstHeatingSourcePercentageInput,
				value: `${100 - pourcentage}`
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						firstHeatingSourcePercentageInput.name,
						secondHeatingSourcePercentageInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, secondHeatingSourcePercentageInput)
		)
	}, [secondHeatingSourcePercentageInput])

	const handleCheckboxWithOthers = (
		value: string,
		questionInput: RequestInputs,
		setQuestionInput: (value: React.SetStateAction<RequestInputs>) => void,
		questionOtherInput: RequestInputs,
		setQuestionOtherInput: (value: React.SetStateAction<RequestInputs>) => void,
		maxSelection: number,
		list: CheckboxList[]
	) => {
		const isNumberValue = !isNaN(parseInt(value))

		if (isNumberValue) {
			let newValue = ''
			if (questionInput.value?.includes(value)) {
				newValue = pageUtils.removeStrFromStrArray(value, questionInput.value)
			} else if (
				pageUtils.getNumberOfSelectedFromStrArray(questionInput.value) <
				maxSelection
			) {
				newValue =
					questionInput.value == '' ? value : questionInput.value + `, ${value}`
			} else {
				newValue = questionInput.value == '' ? value : questionInput.value
			}

			setQuestionInput({
				...questionInput,
				value: newValue
			})

			// check if the other option is selected
			// the other input should turn to required or not
			if (newValue.includes(list[list?.length - 1]?.value)) {
				setQuestionOtherInput({
					...questionOtherInput,
					required: true
				})
			} else {
				setQuestionOtherInput({
					...questionOtherInput,
					required: false
				})
			}
		} else {
			let newOtherValue
			if (questionOtherInput.value?.includes(value)) {
				newOtherValue = pageUtils.removeStrFromStrArray(
					value,
					questionOtherInput.value
				)
			} else {
				newOtherValue =
					questionOtherInput.value == ''
						? value
						: questionOtherInput.value + `, ${value}`
			}

			setQuestionOtherInput({
				...questionOtherInput,
				value: newOtherValue
			})
		}
	}

	////////////////////////////////////////////////////////////////
	// DETAILS 3
	////////////////////////////////////////////////////////////////

	const fireplaceTypeListA = [
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.firstOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.secondOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirdOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.sixthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.seventhOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eighthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.ninthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.tenthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.eleventhOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.twelfthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirteenthOption
	]

	const fireplaceTypeListB = [
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fourthOption,
		REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.fifthOption
	]

	const setIsRequiredDeviceInfosInputs = (value: boolean) => {
		setFrequencyOfUseInput({
			...frequencyOfUseInput,
			required: value
		})
		setInstallationYearInput({
			...installationYearInput,
			required: value
		})
		setDeviceLocationInput({
			...deviceLocationInput,
			required: value
		})
	}

	const setIsRequiredEnvironmentalStandardsInputs = (value: boolean) => {
		setIsDeviceCertifiedInput({
			...isDeviceCertifiedInput,
			required: value
		})
	}

	const setIsRequiredDeviceReplacementInputs = (value: boolean) => {
		setDeviceReplacementTypeInput({
			...deviceReplacementTypeInput,
			required: value
		})
		setDeviceWithdrawalDateInput({
			...deviceWithdrawalDateInput,
			required: value
		})
	}

	const onSelectDeclarationTypeInput = (value: string) => {
		setDeclarationTypeInput({
			...declarationTypeInput,
			value: value
		})

		switch (value) {
			case REQUEST_CONFIGS_KEYS.declarationType.possess:
				setFireplaceTypeToDeclareInput({
					...fireplaceTypeToDeclareInput,
					required: true
				})
				if (!!fireplaceTypeToDeclareInput.value) {
					setIsRequiredDeviceInfosInputs(true)

					if (fireplaceTypeListA.includes(fireplaceTypeToDeclareInput.value)) {
						setIsRequiredEnvironmentalStandardsInputs(true)
					} else {
						setIsRequiredEnvironmentalStandardsInputs(false)
					}
				}

				setIsRequiredDeviceReplacementInputs(false)
				break
			case REQUEST_CONFIGS_KEYS.declarationType.replaced:
				setIsRequiredDeviceReplacementInputs(true)
				setFireplaceTypeToDeclareInput({
					...fireplaceTypeToDeclareInput,
					required: false
				})

				setIsRequiredDeviceInfosInputs(false)
				setIsRequiredEnvironmentalStandardsInputs(false)
				break
			case REQUEST_CONFIGS_KEYS.declarationType.removed:
				setFireplaceTypeToDeclareInput({
					...fireplaceTypeToDeclareInput,
					required: true
				})

				setIsRequiredDeviceInfosInputs(false)
				setIsRequiredEnvironmentalStandardsInputs(false)
				setIsRequiredDeviceReplacementInputs(false)
				break
			case REQUEST_CONFIGS_KEYS.declarationType.neverHad:
				setFireplaceTypeToDeclareInput({
					...fireplaceTypeToDeclareInput,
					required: false
				})
				setIsRequiredDeviceInfosInputs(false)
				setIsRequiredEnvironmentalStandardsInputs(false)
				setIsRequiredDeviceReplacementInputs(false)
				break
			default:
		}

		setDeviceWithdrawalDateInput({
			...deviceWithdrawalDateInput,
			value: '',
			required:
				REQUEST_CONFIGS_KEYS.declarationType.possess ||
				REQUEST_CONFIGS_KEYS.declarationType.neverHad
					? false
					: true
		})

		onFixError(
			errors.filter(
				(error) =>
					![
						declarationTypeInput.name,
						fireplaceTypeToDeclareInput.name,
						frequencyOfUseInput.name,
						installationYearInput.name,
						deviceLocationInput.name,
						otherDeviceLocationInput.name,
						isDeviceCertifiedInput.name,
						deviceCertificationsInput.name,
						deviceReplacementTypeInput.name,
						deviceWithdrawalDateInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		switch (declarationTypeInput.value) {
			case REQUEST_CONFIGS_KEYS.declarationType.possess:
				setFireplaceTypeToDeclareInput({
					...fireplaceTypeToDeclareInput,
					label:
						pageAssets?.request_form_fireplaceDeclaration_fireplaceTypeToDeclare_label,
					labelKey:
						'request_form_fireplaceDeclaration_fireplaceTypeToDeclare_label',
					required: true
				})
				break
			case REQUEST_CONFIGS_KEYS.declarationType.replaced:
				setIsRequiredDeviceReplacementInputs(true)
				setFireplaceTypeToDeclareInput({
					...fireplaceTypeToDeclareInput,
					label:
						pageAssets?.request_form_fireplaceDeclaration_fireplaceTypeToReplace_label,
					labelKey:
						'request_form_fireplaceDeclaration_fireplaceTypeToReplace_label'
				})
				break
			case REQUEST_CONFIGS_KEYS.declarationType.removed:
				setFireplaceTypeToDeclareInput({
					...fireplaceTypeToDeclareInput,
					label:
						pageAssets?.request_form_fireplaceDeclaration_fireplaceTypeToRemove_label,
					labelKey:
						'request_form_fireplaceDeclaration_fireplaceTypeToRemove_label',
					required: true
				})
				break
			default:
		}
		if (fireplaceTypeListA.includes(declarationTypeInput.value)!) {
			setDeviceParticleEmissionRateInput({
				...deviceParticleEmissionRateInput,
				value: ''
			})
		}
		onSetInputs(UpdateRequestInputsArray(inputs, declarationTypeInput))
	}, [declarationTypeInput])

	// ----------------------------------------------------------------

	const onChangeFireplaceTypeToDeclareInput = (value) => {
		if (
			[
				REQUEST_CONFIGS_KEYS.declarationType.possess,
				REQUEST_CONFIGS_KEYS.declarationType.replaced
			].includes(declarationTypeInput.value!)
		) {
			setIsRequiredDeviceInfosInputs(true)

			if (fireplaceTypeListA.includes(value)) {
				setIsRequiredEnvironmentalStandardsInputs(true)
			} else {
				setIsRequiredEnvironmentalStandardsInputs(false)
			}
		}
	}

	const onSelectFireplaceTypeToDeclareInput = (value: string) => {
		setFireplaceTypeToDeclareInput({ ...fireplaceTypeToDeclareInput, value })

		onChangeFireplaceTypeToDeclareInput(value)

		onFixError(
			errors.filter(
				(error) =>
					![
						fireplaceTypeToDeclareInput.name,
						otherFireplaceTypeToDeclareInput.name,
						//infos
						frequencyOfUseInput.name,
						installationYearInput.name,
						deviceLocationInput.name,
						otherDeviceLocationInput.name,
						//certifs
						isDeviceCertifiedInput.name,
						deviceCertificationsInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		// the option OTHER
		if (
			!!fireplaceTypeToDeclareInput.required &&
			fireplaceTypeToDeclareInput.value ==
				REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare.thirteenthOption
		) {
			setOtherFireplaceTypeToDeclareInput({
				...otherFireplaceTypeToDeclareInput,
				required: true
			})
		} else {
			setOtherFireplaceTypeToDeclareInput({
				...otherFireplaceTypeToDeclareInput,
				required: false
			})
		}

		if (
			!!fireplaceTypeToDeclareInput.required &&
			!!fireplaceTypeToDeclareInput.value
		) {
			onChangeFireplaceTypeToDeclareInput(fireplaceTypeToDeclareInput.value)
		}

		onSetInputs(UpdateRequestInputsArray(inputs, fireplaceTypeToDeclareInput))
	}, [fireplaceTypeToDeclareInput])

	// ----------------------------------------------------------------

	const onSetOtherFireplaceTypeToDeclareInput = (value: string) => {
		setOtherFireplaceTypeToDeclareInput({
			...otherFireplaceTypeToDeclareInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				otherFireplaceTypeToDeclareInput.name
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, otherFireplaceTypeToDeclareInput)
		)
	}, [otherFireplaceTypeToDeclareInput])

	// ----------------------------------------------------------------

	const onSelectFrequencyOfUseInput = (value: string) => {
		setFrequencyOfUseInput({
			...frequencyOfUseInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, frequencyOfUseInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, frequencyOfUseInput))
	}, [frequencyOfUseInput])

	// ----------------------------------------------------------------

	const onSetInstallationYearInput = (value: string) => {
		setInstallationYearInput({
			...installationYearInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, installationYearInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, installationYearInput))
	}, [installationYearInput])

	// ----------------------------------------------------------------

	const onSelectDeviceLocationInput = (value: string) => {
		setDeviceLocationInput({
			...deviceLocationInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, deviceLocationInput.name))
	}

	useEffect(() => {
		// the option OTHER
		if (
			!!deviceLocationInput.required &&
			deviceLocationInput.value ==
				REQUEST_CONFIGS_KEYS.deviceLocation.fifthOption
		) {
			setOtherDeviceLocationInput({
				...otherDeviceLocationInput,
				required: true
			})
		} else {
			setOtherDeviceLocationInput({
				...otherDeviceLocationInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![deviceLocationInput.name, otherDeviceLocationInput.name].includes(
						error
					)
			)
		)

		onSetInputs(UpdateRequestInputsArray(inputs, deviceLocationInput))
	}, [deviceLocationInput])

	// ----------------------------------------------------------------

	const onSetOtherDeviceLocationInput = (value: string) => {
		setOtherDeviceLocationInput({
			...otherDeviceLocationInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, otherDeviceLocationInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, otherDeviceLocationInput))
	}, [otherDeviceLocationInput])

	// ----------------------------------------------------------------

	const onSetDeviceManifacturerInput = (value: string) => {
		setDeviceManifacturerInput({
			...deviceManifacturerInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, deviceManifacturerInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, deviceManifacturerInput))
	}, [deviceManifacturerInput])

	// ----------------------------------------------------------------

	const onSetDeviceModelInput = (value: string) => {
		setDeviceModelInput({
			...deviceModelInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, deviceModelInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, deviceModelInput))
	}, [deviceModelInput])

	// ----------------------------------------------------------------

	const onSelectIsDeviceCertifiedInput = (value: string) => {
		setIsDeviceCertifiedInput({
			...isDeviceCertifiedInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isDeviceCertifiedInput.name)
		)
	}

	useEffect(() => {
		// the option OTHER
		if (
			!!isDeviceCertifiedInput.required &&
			isDeviceCertifiedInput.value ==
				REQUEST_CONFIGS_KEYS.isDeviceCertified.firstOption
		) {
			setDeviceCertificationsInput({
				...deviceCertificationsInput,
				required: true
			})
		} else {
			setDeviceCertificationsInput({
				...deviceCertificationsInput,
				required: false
			})
		}

		onSetInputs(UpdateRequestInputsArray(inputs, isDeviceCertifiedInput))
	}, [isDeviceCertifiedInput])

	// ----------------------------------------------------------------

	const onSelectDeviceCertificationsInput = (value: string, key: string) => {
		let newValue
		if (deviceCertificationsInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				deviceCertificationsInput.value
			)
		} else {
			newValue =
				deviceCertificationsInput.value == ''
					? value
					: deviceCertificationsInput.value + `, ${value}`
		}

		setDeviceCertificationsInput({
			...deviceCertificationsInput,
			value: newValue
		})
		onFixError(
			UpdateRequestInputsErrorsArray(errors, deviceCertificationsInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, deviceCertificationsInput))
	}, [deviceCertificationsInput])

	// ----------------------------------------------------------------

	const onSetDeviceParticleEmissionRateInput = (value: string) => {
		value = value.replace(/,/g, '.')
		let valueDecimals = value.match('[0-9]{1,13}(\\.[0-9]*)?')
		if (
			value.split('.')[0].length > 2 ||
			(value.includes('.') && valueDecimals![1].length > 3)
		) {
			return
		}
		let newValue = value ? value.match('[0-9]{1,13}(\\.[0-9]*)?')![0] : ''
		setDeviceParticleEmissionRateInput({
			...deviceParticleEmissionRateInput,
			value: newValue
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				deviceParticleEmissionRateInput.name
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, deviceParticleEmissionRateInput)
		)
	}, [deviceParticleEmissionRateInput])

	// ----------------------------------------------------------------

	const onSetDeviceContractorNameInput = (value: string) => {
		setDeviceContractorNameInput({
			...deviceContractorNameInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, deviceContractorNameInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, deviceContractorNameInput))
	}, [deviceContractorNameInput])

	// ----------------------------------------------------------------

	const onSelectDeviceReplacementTypeInput = (value: string) => {
		setDeviceReplacementTypeInput({
			...deviceReplacementTypeInput,
			value
		})

		if (value == REQUEST_CONFIGS_KEYS.deviceReplacementType.firstOption) {
			setFireplaceTypeToDeclareInput({
				...fireplaceTypeToDeclareInput,
				required: true
			})
		} else {
			setFireplaceTypeToDeclareInput({
				...fireplaceTypeToDeclareInput,
				required: false
			})

			setIsRequiredDeviceInfosInputs(false)
			setIsRequiredEnvironmentalStandardsInputs(false)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors, deviceReplacementTypeInput.name)
		)

		onFixError(
			errors.filter(
				(error) =>
					![
						deviceReplacementTypeInput.name,

						fireplaceTypeToDeclareInput.name,
						otherFireplaceTypeToDeclareInput.name,
						//infos
						frequencyOfUseInput.name,
						installationYearInput.name,
						deviceLocationInput.name,
						otherDeviceLocationInput.name,
						//certifs
						isDeviceCertifiedInput.name,
						deviceCertificationsInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		if (
			!!deviceReplacementTypeInput.required &&
			deviceReplacementTypeInput.value ==
				REQUEST_CONFIGS_KEYS.deviceReplacementType.firstOption
		) {
			setFireplaceTypeToDeclareInput({
				...fireplaceTypeToDeclareInput,
				required: true
			})
		}

		onSetInputs(UpdateRequestInputsArray(inputs, deviceReplacementTypeInput))
	}, [deviceReplacementTypeInput])

	// ----------------------------------------------------------------

	const onSelectDeviceWithdrawalDateInput = (value: string) => {
		setDeviceWithdrawalDateInput({
			...deviceWithdrawalDateInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, deviceWithdrawalDateInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, deviceWithdrawalDateInput))
	}, [deviceWithdrawalDateInput])

	// ----------------------------------------------------------------

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const getCadastralAndMatriculeNumber = (address) => {
		getDefaultCadastralNumber(address).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})

			setBuildingRegistrationNumberInput({
				...buildingRegistrationNumberInput,
				value: res?.data?.features?.[0].properties.matricule
			})

			setBuildingAddressInput({
				...buildingAddressInput,
				value: address
			})
		})
	}

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
			]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	const getKeysFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.key)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_declaration_adresse}
							</h4>
						</div>

						<div>
							<strong>
								{pageAssets[isSameDeclarationAdresseInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.buildingAddressSameHasApplicant
								)}
							</span>
						</div>

						{answers?.buildingAddressSameHasApplicant?.toString() ==
							'false' && (
							<>
								<>
									<div>
										<strong>{pageAssets?.label_address}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedAdresse}
										</span>
									</div>
								</>
								<>
									<div>
										<strong>{`${formatStrapiText(
											pageAssets?.label_apartment_office
										)}`}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedAppartement}
										</span>
									</div>
								</>
							</>
						)}

						<>
							<div>
								<strong>{pageAssets[isBuildingOwnerInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(radioListYesNo, answers?.isBuildingOwner)}
								</span>
							</div>

							{answers.isBuildingOwner?.toString() == 'false' && (
								<>
									<div>
										<strong>{pageAssets?.label_lastName}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedLastName}
										</span>
									</div>

									<div>
										<strong>{pageAssets?.label_firstName}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedFirstName}
										</span>
									</div>

									<div>
										<strong>{pageAssets?.label_email}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedEmail}
										</span>
									</div>

									<div>
										<strong>{pageAssets?.label_telephone}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedPhoneNumber}
										</span>
									</div>
								</>
							)}
						</>
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_fireplaceDeclaration_secondSubStep_title
								}
							</h4>
						</div>

						<>
							<div>
								<strong>{pageAssets[heatingSourceInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									<>
										{getKeysFromString(
											pageUtils.heatingSourceTypeListAndValues,
											answers?.heatingSource
										).map((answerLabel, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${index}-heatingSource`}
												>
													{pageAssets[answerLabel]}
												</p>
											)
										})}
									</>
								</span>
							</div>

							{answers?.heatingSource?.includes(
								REQUEST_CONFIGS_KEYS.heatingSource.others
							) && (
								<div>
									{/* <strong>
										{pageAssets[otherHeatingSourceInput.labelKey]}
									</strong>
									<br /> */}
									<span className={pageUtils.classes.answer}>
										<>{answers?.otherHeatingSource}</>
									</span>
								</div>
							)}

							{getKeysFromString(
								pageUtils.heatingSourceTypeListAndValues,
								answers?.heatingSource
							).length == 2 && (
								<>
									<div>
										<strong>
											{firstHeatingSourcePercentageInput.label +
												' «' +
												pageAssets[firstHeatingSource] +
												'»?'}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.firstHeatingSourcePercentage}
										</span>
									</div>

									<div>
										<strong>
											{secondHeatingSourcePercentageInput.label +
												' «' +
												pageAssets[secondHeatingSource] +
												'»?'}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.secondHeatingSourcePercentage}
										</span>
									</div>
								</>
							)}
						</>
					</>
				)}

				{subSteps?.details_3 && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_fireplaceDeclaration_thirdSubStep_title
								}
							</h4>
						</div>

						<>
							<div>
								<strong>{pageAssets[declarationTypeInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									<>
										<Text
											content={getLabelFromList(
												declarationTypeList,
												answers?.declarationType
											)}
										/>
									</>
								</span>
							</div>

							{/*  ---------------  replace ------------- */}
							{answers?.declarationType ==
								REQUEST_CONFIGS_KEYS.declarationType.replaced && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_fireplaceDeclaration_declarationType_option2_title
										}
									</h5>
									<div>
										<strong>
											{pageAssets[deviceReplacementTypeInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{getLabelFromList(
												deviceReplacementTypeList,
												answers?.deviceReplacementType
											)}
										</span>
									</div>

									{!!answers?.deviceWithdrawalDate && (
										<div>
											<strong>
												{pageAssets[deviceReplacementDateInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{pageUtils.getLocaleDate(
													answers?.deviceWithdrawalDate!
												)}
											</span>
										</div>
									)}
								</>
							)}

							{/*  ---------------  possess ------------- */}
							{answers?.declarationType ==
								REQUEST_CONFIGS_KEYS.declarationType.possess && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_fireplaceDeclaration_declarationType_option1_title
										}
									</h5>
								</>
							)}

							{
								//possess
								REQUEST_CONFIGS_KEYS.declarationType.possess ==
									answers?.declarationType ||
								// replace + type == wood
								(REQUEST_CONFIGS_KEYS.declarationType.replaced ==
									answers?.declarationType &&
									answers?.deviceReplacementType ==
										REQUEST_CONFIGS_KEYS.deviceReplacementType.firstOption) ? (
									<>
										<div>
											<strong>
												{pageAssets[fireplaceTypeToDeclareInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												<>
													{getLabelFromList(
														fireplaceTypeToDeclareList,
														answers?.fireplaceTypeToDeclare
													)}
												</>
											</span>
										</div>

										{answers?.fireplaceTypeToDeclare ==
											REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare
												.thirteenthOption && (
											<div>
												<strong>
													{
														pageAssets[
															otherFireplaceTypeToDeclareInput.labelKey
														]
													}
												</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													<>{answers?.otherFireplaceTypeToDeclare}</>
												</span>
											</div>
										)}

										<div>
											<strong>
												{pageAssets[frequencyOfUseInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												<>
													{getLabelFromList(
														frequencyOfUseList,
														answers?.frequencyOfUse
													)}
												</>
											</span>
										</div>
										<div>
											<strong>
												{pageAssets[installationYearInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												<>{answers?.installationYear}</>
											</span>
										</div>
										<div>
											<strong>
												{pageAssets[deviceLocationInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												<>
													{getLabelFromList(
														deviceLocationList,
														answers?.deviceLocation
													)}
												</>
											</span>
										</div>

										{answers?.deviceLocation ==
											REQUEST_CONFIGS_KEYS.deviceLocation.fifthOption && (
											<div>
												<strong>
													{pageAssets[otherDeviceLocationInput.labelKey]}
												</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													<>{answers?.otherDeviceLocation}</>
												</span>
											</div>
										)}

										{
											/* liste A */
											/* le sous titre suivant : Fabricant et modèle */
											/* le sous titre suivant : Normes environnementales  */
											fireplaceTypeListA.includes(
												answers?.fireplaceTypeToDeclare!.toString()
											) && (
												<>
													{(!!answers?.deviceManifacturer ||
														!!answers?.deviceModel) && (
														<>
															<h6 className={pageUtils.classes.h5}>
																{
																	pageAssets?.request_form_fireplaceDeclaration_manufacturerAndModel_title
																}
															</h6>

															{!!answers?.deviceManifacturer && (
																<div>
																	<strong>
																		{
																			pageAssets[
																				deviceManifacturerInput.labelKey
																			]
																		}
																	</strong>
																	<br />
																	<span className={pageUtils.classes.answer}>
																		<>{answers?.deviceManifacturer}</>
																	</span>
																</div>
															)}
															{answers?.deviceModel && (
																<div>
																	<strong>
																		{pageAssets[deviceModelInput.labelKey]}
																	</strong>
																	<br />
																	<span className={pageUtils.classes.answer}>
																		<>{answers?.deviceModel}</>
																	</span>
																</div>
															)}
														</>
													)}

													<>
														<h6 className={pageUtils.classes.h5}>
															{
																pageAssets?.request_form_fireplaceDeclaration_environmentalStandards_title
															}
														</h6>

														<div>
															<strong>
																{pageAssets[isDeviceCertifiedInput.labelKey]}
															</strong>
															<br />
															<span className={pageUtils.classes.answer}>
																{getLabelFromList(
																	isDeviceCertifiedList,
																	answers?.isDeviceCertified
																)}
															</span>
														</div>

														{answers?.isDeviceCertified ==
															REQUEST_CONFIGS_KEYS.isDeviceCertified
																.firstOption && (
															<div>
																<strong>
																	{
																		pageAssets[
																			deviceCertificationsInput.labelKey
																		]
																	}
																</strong>
																<br />
																<span className={pageUtils.classes.answer}>
																	{getLabelsFromString(
																		deviceCertificationsList,
																		answers?.deviceCertifications
																	).map((answerLabel, index) => {
																		return (
																			<p
																				className={pageUtils.classes.answer}
																				key={`${index}-deviceCertifications`}
																			>
																				{answerLabel}
																			</p>
																		)
																	})}
																</span>
															</div>
														)}
														{!!answers?.deviceParticleEmissionRate && (
															<div>
																<strong>
																	{
																		pageAssets[
																			deviceParticleEmissionRateInput.labelKey
																		]
																	}
																</strong>
																<br />
																<span className={pageUtils.classes.answer}>
																	{answers?.deviceParticleEmissionRate +
																		' ' +
																		pageAssets?.request_form_fireplaceDeclaration_deviceParticleEmissionRate_unit_label}
																</span>
															</div>
														)}
													</>
												</>
											)
										}

										{/* liste B */}
										{fireplaceTypeListB.includes(
											answers?.fireplaceTypeToDeclare!.toString()
										) &&
											!!answers?.deviceContractorName && (
												<div>
													<strong>
														{pageAssets[deviceContractorNameInput.labelKey]}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers?.deviceContractorName}
													</span>
												</div>
											)}
									</>
								) : (
									''
								)
							}

							{REQUEST_CONFIGS_KEYS.declarationType.removed ==
								answers?.declarationType && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_fireplaceDeclaration_declarationType_option3_title
										}
									</h5>

									<div>
										<strong>
											{pageAssets[fireplaceTypeToDeclareInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											<>
												{getLabelFromList(
													fireplaceTypeToDeclareList,
													answers?.fireplaceTypeToDeclare
												)}
											</>
										</span>
									</div>

									{answers?.fireplaceTypeToDeclare ==
										REQUEST_CONFIGS_KEYS.fireplaceTypeToDeclare
											.thirteenthOption && (
										<div>
											<strong>
												{pageAssets[otherFireplaceTypeToDeclareInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												<>{answers?.otherFireplaceTypeToDeclare}</>
											</span>
										</div>
									)}
									{!!answers?.deviceWithdrawalDate && (
										<div>
											<strong>
												{pageAssets[deviceWithdrawalDateInput.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{pageUtils.getLocaleDate(
													answers?.deviceWithdrawalDate!
												)}
											</span>
										</div>
									)}
								</>
							)}
						</>
						<br />
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_declaration_adresse}
						</h4>

						{/* <form style={{ paddingBottom: '20px' }}> */}
						{!isAddressOutsideLaval && (
							<div className={classes.checkbox2Container}>
								<span className={classes.checkboxWrapper}>
									<input
										onClick={(e: any) =>
											onSelectIsSameDeclarationAdresseInput(e.target.checked)
										}
										value={isSameDeclarationAdresseInput.value}
										className={classes.checkbox2}
										type="checkbox"
										name={isSameDeclarationAdresseInput.name}
										id={isSameDeclarationAdresseInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={isSameDeclarationAdresseInput.labelKey}
									>
										{pageAssets[isSameDeclarationAdresseInput.labelKey]}
									</label>
								</span>
							</div>
						)}

						<div style={{ marginBottom: '20px' }}>
							{showDeclarationAdresseBlock == true ? (
								<ConcernedInfosSection
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										`${isSameDeclarationAdresseInput.value}` == 'false'
									}
									requiredFields={['concernedAdresse', 'concernedAppartement']}
									onGetCadastralAndMatriculeNumber={
										getCadastralAndMatriculeNumber
									}
								/>
							) : (
								<div className={classes.adresseBlock}>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>
							)}
						</div>

						<RadioInput
							name={isBuildingOwnerInput.name}
							label={isBuildingOwnerInput.label}
							value={isBuildingOwnerInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isBuildingOwnerInput.name)}
							onChange={onSelectIsBuildingOwnerInput}
							ref={isBuildingOwnerInput.ref as RefObject<HTMLInputElement>}
							required={isBuildingOwnerInput.required}
							hasBackgroundWrapper={true}
						/>

						{showBuildingOwnerBlock == true && (
							<>
								<Notification
									text={
										pageAssets?.request_form_buildingOwnerInfoBlock_notification_label
									}
									type="info"
									hasHtml
								/>

								<ConcernedInfosSection
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										`${isBuildingOwnerInput.value}` == 'false'
									}
									requiredFields={[
										'concernedLastName',
										'concernedFirstName',
										'concernedEmail',
										'concernedPhoneNumber'
									]}
								/>
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
					checkTimeout={40}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_fireplaceDeclaration_secondSubStep_title
							}
						</h4>

						<CheckboxInput
							name={heatingSourceInput.name}
							label={heatingSourceInput.label}
							description={heatingSourceInput.description}
							value={`${heatingSourceInput.value}`}
							list={heatingSourceListType}
							onChange={onChangeHeatingSourceInput}
							required={heatingSourceInput.required}
							hasError={errors.includes(heatingSourceInput.name)}
							ref={heatingSourceInput.ref as RefObject<HTMLInputElement>}
							errors={errors}
							shouldNotDivide
							hasBackgroundWrapper
							onFixError={onFixError}
							newCheckValue
							others={
								pageUtils.heatingSourceTypeListAndValues[
									pageUtils.heatingSourceTypeListAndValues.length - 1
								]
							}
							otherInput={otherHeatingSourceInput}
							setOtherInput={setOtherHeatingSourceInput}
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
							isMaxOptionsSelected={
								getSplitValuesFromString(heatingSourceInput.value)?.length == 2
							}
						/>

						{firstHeatingSourcePercentageInput.required == true &&
							secondHeatingSourcePercentageInput.required == true && (
								<>
									<SelectInput
										id={firstHeatingSourcePercentageInput.name}
										label={
											firstHeatingSourcePercentageInput.label +
											' «' +
											pageAssets[firstHeatingSource] +
											'»?'
										}
										value={firstHeatingSourcePercentageInput.value}
										list={pageUtils.percentageListAndValues}
										onChange={onSelectFirstHeatingSourcePercentageInput}
										hasError={errors.includes(
											firstHeatingSourcePercentageInput.name
										)}
										required={firstHeatingSourcePercentageInput.required}
										ref={
											firstHeatingSourcePercentageInput.ref as RefObject<HTMLSelectElement>
										}
									/>
									<SelectInput
										id={secondHeatingSourcePercentageInput.name}
										label={
											secondHeatingSourcePercentageInput.label +
											' «' +
											pageAssets[secondHeatingSource] +
											'»?'
										}
										value={secondHeatingSourcePercentageInput.value}
										list={pageUtils.percentageListAndValues}
										onChange={onSelectSecondHeatingSourcePercentageInput}
										hasError={errors.includes(
											secondHeatingSourcePercentageInput.name
										)}
										required={secondHeatingSourcePercentageInput.required}
										ref={
											secondHeatingSourcePercentageInput.ref as RefObject<HTMLSelectElement>
										}
									/>
								</>
							)}
					</section>
				</Collapse>
			)}
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
					checkTimeout={40}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_fireplaceDeclaration_thirdSubStep_title}
						</h4>
						<RadioInput
							name={declarationTypeInput.name}
							label={declarationTypeInput.label}
							value={declarationTypeInput.value}
							list={declarationTypeList}
							direction={Direction.vertical}
							hasError={errors!.includes(declarationTypeInput.name)}
							onChange={onSelectDeclarationTypeInput}
							ref={declarationTypeInput.ref as RefObject<HTMLInputElement>}
							required={declarationTypeInput.required}
							hasBackgroundWrapper={true}
						/>

						{/*  ---------------  replace ------------- */}
						{declarationTypeInput.value ==
							REQUEST_CONFIGS_KEYS.declarationType.replaced && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_fireplaceDeclaration_declarationType_option2_title
									}
								</h5>
								<br />

								<SelectInput
									id={deviceReplacementTypeInput.name}
									label={deviceReplacementTypeInput.label}
									value={deviceReplacementTypeInput.value}
									list={deviceReplacementTypeList}
									onChange={onSelectDeviceReplacementTypeInput}
									hasError={errors.includes(deviceReplacementTypeInput.name)}
									required={deviceReplacementTypeInput.required}
									ref={
										deviceReplacementTypeInput.ref as RefObject<HTMLSelectElement>
									}
								/>
								<DateFilterInput
									id={deviceWithdrawalDateInput.name}
									label={deviceReplacementDateInput.label}
									value={deviceWithdrawalDateInput.value}
									onChange={onSelectDeviceWithdrawalDateInput}
									hasError={errors.includes(deviceWithdrawalDateInput.name)}
									required={deviceWithdrawalDateInput.required}
									maxDate={new Date()}
									dateFormat="dd/MM/yyyy"
								/>
							</>
						)}

						{/*  ---------------  possess ------------- */}
						{declarationTypeInput.value ==
							REQUEST_CONFIGS_KEYS.declarationType.possess && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_fireplaceDeclaration_declarationType_option1_title
									}
								</h5>
								<br />
							</>
						)}

						{
							//possess
							REQUEST_CONFIGS_KEYS.declarationType.possess ==
								declarationTypeInput.value ||
							// replace + type == wood
							(REQUEST_CONFIGS_KEYS.declarationType.replaced ==
								declarationTypeInput.value &&
								deviceReplacementTypeInput.value ==
									REQUEST_CONFIGS_KEYS.deviceReplacementType.firstOption) ? (
								<>
									<RadioInput
										name={fireplaceTypeToDeclareInput.name}
										label={fireplaceTypeToDeclareInput.label}
										value={fireplaceTypeToDeclareInput.value}
										list={fireplaceTypeToDeclareList}
										direction={Direction.vertical}
										hasError={errors!.includes(
											fireplaceTypeToDeclareInput.name
										)}
										onChange={onSelectFireplaceTypeToDeclareInput}
										ref={
											fireplaceTypeToDeclareInput.ref as RefObject<HTMLInputElement>
										}
										required={fireplaceTypeToDeclareInput.required}
										hasBackgroundWrapper={true}
										description={fireplaceTypeToDeclareInput.description}
									/>

									{!!fireplaceTypeToDeclareInput.value && (
										<>
											<Notification
												text={
													pageAssets?.request_form_fireplaceDeclaration_fireplaceTypeToDeclare_notification_label
												}
												type="info"
												hasHtml
											/>
											<SelectInput
												id={frequencyOfUseInput.name}
												label={frequencyOfUseInput.label}
												value={frequencyOfUseInput.value}
												list={frequencyOfUseList}
												onChange={onSelectFrequencyOfUseInput}
												hasError={errors.includes(frequencyOfUseInput.name)}
												required={frequencyOfUseInput.required}
												ref={
													frequencyOfUseInput.ref as RefObject<HTMLSelectElement>
												}
											/>
											<CustomYearInput
												id={installationYearInput.name}
												label={installationYearInput.label}
												value={installationYearInput.value}
												onChange={onSetInstallationYearInput}
												hasError={errors.includes(installationYearInput.name)}
												required={installationYearInput.required}
												dateFormat="yyyy"
												minDate={new Date('1801')}
												maxDate={new Date()}
											/>
											<SelectInput
												id={deviceLocationInput.name}
												label={deviceLocationInput.label}
												value={deviceLocationInput.value}
												list={deviceLocationList}
												onChange={onSelectDeviceLocationInput}
												hasError={errors.includes(deviceLocationInput.name)}
												required={deviceLocationInput.required}
												ref={
													deviceLocationInput.ref as RefObject<HTMLSelectElement>
												}
											/>

											{deviceLocationInput.value ==
												REQUEST_CONFIGS_KEYS.deviceLocation.fifthOption && (
												<TextInput
													id={otherDeviceLocationInput.name}
													label={otherDeviceLocationInput.label}
													value={otherDeviceLocationInput.value}
													onChange={onSetOtherDeviceLocationInput}
													hasError={errors.includes(
														otherDeviceLocationInput.name
													)}
													required={otherDeviceLocationInput.required}
													ref={
														otherDeviceLocationInput.ref as RefObject<HTMLInputElement>
													}
													maxCharacters={100}
												/>
											)}

											{/* liste A */}
											{/* le sous titre suivant : Fabricant et modèle */}
											{/* le sous titre suivant : Normes environnementales  */}
											{fireplaceTypeListA.includes(
												fireplaceTypeToDeclareInput.value
											) && (
												<>
													<h6 className={pageUtils.classes.h5}>
														{
															pageAssets?.request_form_fireplaceDeclaration_manufacturerAndModel_title
														}
													</h6>
													<br />
													<TextInput
														id={deviceManifacturerInput.name}
														label={deviceManifacturerInput.label}
														value={deviceManifacturerInput.value}
														onChange={onSetDeviceManifacturerInput}
														hasError={errors.includes(
															deviceManifacturerInput.name
														)}
														required={deviceManifacturerInput.required}
														ref={
															deviceManifacturerInput.ref as RefObject<HTMLInputElement>
														}
														maxCharacters={100}
													/>
													<TextInput
														id={deviceModelInput.name}
														label={deviceModelInput.label}
														value={deviceModelInput.value}
														onChange={onSetDeviceModelInput}
														hasError={errors.includes(deviceModelInput.name)}
														required={deviceModelInput.required}
														ref={
															deviceModelInput.ref as RefObject<HTMLInputElement>
														}
														maxCharacters={100}
													/>
													<h6 className={pageUtils.classes.h5}>
														{
															pageAssets?.request_form_fireplaceDeclaration_environmentalStandards_title
														}
													</h6>
													<br />

													<RadioInput
														name={isDeviceCertifiedInput.name}
														label={isDeviceCertifiedInput.label}
														value={isDeviceCertifiedInput.value}
														list={isDeviceCertifiedList}
														direction={Direction.vertical}
														hasError={errors!.includes(
															isDeviceCertifiedInput.name
														)}
														onChange={onSelectIsDeviceCertifiedInput}
														ref={
															isDeviceCertifiedInput.ref as RefObject<HTMLInputElement>
														}
														required={isDeviceCertifiedInput.required}
														hasBackgroundWrapper={true}
														description={isDeviceCertifiedInput.description}
													/>

													{isDeviceCertifiedInput.value ==
														REQUEST_CONFIGS_KEYS.isDeviceCertified
															.firstOption && (
														<CheckboxInput
															name={deviceCertificationsInput.name}
															label={deviceCertificationsInput.label}
															value={`${deviceCertificationsInput.value}`}
															list={deviceCertificationsList}
															onChange={onSelectDeviceCertificationsInput}
															required={deviceCertificationsInput.required}
															hasError={errors.includes(
																deviceCertificationsInput.name
															)}
															ref={
																deviceCertificationsInput.ref as RefObject<HTMLInputElement>
															}
															errors={errors}
															shouldNotDivide
															newCheckValue
															hasBackgroundWrapper
															onFixError={onFixError}
														/>
													)}

													<TextInput
														id={deviceParticleEmissionRateInput.name}
														label={deviceParticleEmissionRateInput.label}
														value={deviceParticleEmissionRateInput.value}
														onChange={onSetDeviceParticleEmissionRateInput}
														hasError={errors.includes(
															deviceParticleEmissionRateInput.name
														)}
														required={deviceParticleEmissionRateInput.required}
														ref={
															deviceParticleEmissionRateInput.ref as RefObject<HTMLInputElement>
														}
														description={
															deviceParticleEmissionRateInput.description
														}
														maxCharacters={100}
													/>
												</>
											)}

											{/* liste B */}
											{fireplaceTypeListB.includes(
												fireplaceTypeToDeclareInput.value
											) && (
												<>
													<TextInput
														id={deviceContractorNameInput.name}
														label={deviceContractorNameInput.label}
														value={deviceContractorNameInput.value}
														onChange={onSetDeviceContractorNameInput}
														hasError={errors.includes(
															deviceContractorNameInput.name
														)}
														required={deviceContractorNameInput.required}
														ref={
															deviceContractorNameInput.ref as RefObject<HTMLInputElement>
														}
														maxCharacters={100}
													/>
												</>
											)}
										</>
									)}
								</>
							) : (
								''
							)
						}

						{REQUEST_CONFIGS_KEYS.declarationType.removed ==
							declarationTypeInput.value && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_fireplaceDeclaration_declarationType_option3_title
									}
								</h5>
								<br />
								<RadioInput
									name={fireplaceTypeToDeclareInput.name}
									label={fireplaceTypeToDeclareInput.label}
									value={fireplaceTypeToDeclareInput.value}
									list={fireplaceTypeToDeclareList}
									direction={Direction.vertical}
									hasError={errors!.includes(fireplaceTypeToDeclareInput.name)}
									onChange={onSelectFireplaceTypeToDeclareInput}
									ref={
										fireplaceTypeToDeclareInput.ref as RefObject<HTMLInputElement>
									}
									required={fireplaceTypeToDeclareInput.required}
									hasBackgroundWrapper={true}
									description={fireplaceTypeToDeclareInput.description}
								/>
								<DateFilterInput
									id={deviceWithdrawalDateInput.name}
									label={deviceWithdrawalDateInput.label}
									value={deviceWithdrawalDateInput.value}
									onChange={onSelectDeviceWithdrawalDateInput}
									hasError={errors.includes(deviceWithdrawalDateInput.name)}
									required={deviceWithdrawalDateInput.required}
									maxDate={new Date()}
									dateFormat="dd/MM/yyyy"
								/>
							</>
						)}
					</section>
				</Collapse>
			)}
		</>
	)
}

export default FireplaceDeclarationForm
