import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import RequestFiles from '@components/ui/requestFiles'
import {
	RequestFormPDFProps,
	RequestInputs,
	RequestInputFiles,
	SelectList
} from '@utils/request'
import TextInput from './inputs/text.inputs'
import { makeClasses, joinClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import requestForm from '@components/requestForms/request.form'
import Text from '@components/ui/text'
import { chevronBlueIcon } from '@images/icons'
import {
	requestCategories,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME
} from '@services/constants'
import { Collapse } from 'react-collapse'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import RadioInput from './inputs/radio.input'
import { formatStrapiText, getSeparetedFilesFromStore } from '@utils/methods'
import RequestFilesWithMultipleButtons from '@components/ui/requestFilesWithMultipleButtons'

type Classes = {
	fileHelperSection: string
	listTitle: string
	customList: string
	list: string
	link: string
}
type RenderRainBarrel = {
	title: string
	secondQuestionObject: RequestInputs
	thirdQuestionObject: RequestInputs
	fourthQuestionObject: RequestInputs
	onSelectSecondQuestion: (value: string) => void
	onSelectThirdQuestion: (value: string) => void
	onSelectFourthQuestion: (value: string) => void
}

const classes: Classes = makeClasses({
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	listTitle: {
		lineHeight: '1.62em'
	},
	list: {
		margin: 0,
		paddingLeft: '18px',
		lineHeight: '1.62em'
	},
	customList: {
		listStyle: 'none',
		paddingLeft: '15px',
		lineHeight: '20px',
		margin: '15px 0 0 0',
		li: {
			position: 'relative',
			paddingLeft: '5px',
			marginBottom: '10px',
			'&:before': {
				content: '""',
				position: 'absolute',
				top: '2px',
				left: '-15px',
				borderRadius: 0,
				width: '10px',
				height: '15px',
				backgroundImage: `url(${chevronBlueIcon})`,
				backgroundSize: '20px',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
				verticalAlign: 'baseline',
				backgroundColor: 'transparent'
			}
		}
	},
	link: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
})

const RainwaterBarrelForm: FC<RequestFormPDFProps> = ({
	errors,
	inputs,
	inputFiles,
	subSteps,
	formPosition,
	isSummaryStep,
	steps,
	filesInputs,
	onSetInputFiles,
	onSetSubSteps,
	onSetInputs,
	onFixError,
	setCurrentStep
}) => {
	const {
		pageAssets,
		UpdateRequestInputsErrorsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const [firstQuestion, setFirstQuestion] = useState<RequestInputs>({
		name: 'numberOfBarrel',
		label: pageAssets?.request_form_rainwaterBarrel_numberOfBarrel_label,
		labelKey: 'request_form_rainwaterBarrel_numberOfBarrel_label',
		value: `${answers.numberOfBarrel || pageAssets?.number_one}`,
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [secondQuestion, setSecondQuestion] = useState<RequestInputs>({
		name: 'manufacturerName1',
		label: pageAssets?.request_form_rainwaterBarrel_manufacturerName1_label,
		labelKey: 'request_form_rainwaterBarrel_manufacturerName1_label',
		value: answers.manufacturerName1 || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [thirdQuestion, setThirdQuestion] = useState<RequestInputs>({
		name: 'nameAndModelNumber1',
		label: pageAssets?.request_form_rainwaterBarrel_nameAndModelNumber1_label,
		labelKey: 'request_form_rainwaterBarrel_nameAndModelNumber1_label',
		value: answers.nameAndModelNumber1 || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [fourthQuestion, setFourthQuestion] = useState<RequestInputs>({
		name: 'costBeforeTax1',
		label: pageAssets?.request_form_rainwaterBarrel_costBeforeTax1_label,
		labelKey: 'request_form_rainwaterBarrel_costBeforeTax1_label',
		value: `${answers.costBeforeTax1 || ''}`,
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})
	// ----------------------------------------------------------
	const [secondPath2Question, setSecondPath2Question] = useState<RequestInputs>(
		{
			name: 'manufacturerName2',
			label: pageAssets?.request_form_rainwaterBarrel_manufacturerName2_label,
			labelKey: 'request_form_rainwaterBarrel_manufacturerName2_label',
			value: answers.manufacturerName2 || '',
			required: answers.numberOfBarrel == 2,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [thirdPath2Question, setThirdPath2Question] = useState<RequestInputs>({
		name: 'nameAndModelNumber2',
		label: pageAssets?.request_form_rainwaterBarrel_nameAndModelNumber2_label,
		labelKey: 'request_form_rainwaterBarrel_nameAndModelNumber2_label',
		value: answers.nameAndModelNumber2 || '',
		required: answers.numberOfBarrel == 2,
		ref: useRef<HTMLInputElement>(null)
	})

	const [fourthPath2Question, setFourthPath2Question] = useState<RequestInputs>(
		{
			name: 'costBeforeTax2',
			label: pageAssets?.request_form_rainwaterBarrel_costBeforeTax2_label,
			labelKey: 'request_form_rainwaterBarrel_costBeforeTax2_label',
			value: `${answers.costBeforeTax2 || ''}`,
			required: answers.numberOfBarrel == 2,
			ref: useRef<HTMLInputElement>(null)
		}
	)
	// ----------------------------------------------------------
	const [secondPath3Question, setSecondPath3Question] = useState<RequestInputs>(
		{
			name: 'manufacturerName3',
			label: pageAssets?.request_form_rainwaterBarrel_manufacturerName3_label,
			labelKey: 'request_form_rainwaterBarrel_manufacturerName3_label',
			value: answers.manufacturerName3 || '',
			required: answers.numberOfBarrel == 3,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [thirdPath3Question, setThirdPath3Question] = useState<RequestInputs>({
		name: 'nameAndModelNumber3',
		label: pageAssets?.request_form_rainwaterBarrel_nameAndModelNumber3_label,
		labelKey: 'request_form_rainwaterBarrel_nameAndModelNumber3_label',
		value: answers.nameAndModelNumber3 || '',
		required: answers.numberOfBarrel == 3,
		ref: useRef<HTMLInputElement>(null)
	})

	const [fourthPath3Question, setFourthPath3Question] = useState<RequestInputs>(
		{
			name: 'costBeforeTax3',
			label: pageAssets?.request_form_rainwaterBarrel_costBeforeTax3_label,
			labelKey: 'request_form_rainwaterBarrel_costBeforeTax3_label',
			value: `${answers.costBeforeTax3 || ''}`,
			required: answers.numberOfBarrel === 3,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	// ----------------------------------------------

	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'proofOfPurchase',
		label: pageAssets?.rain_water_barrel_form_Legible_copy_of_the_invoice,
		labelKey: 'rain_water_barrel_form_Legible_copy_of_the_invoice',
		description: pageAssets?.rain_waterBarrel_doc_should_clairly_indicate,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'proofOfPurchase')?.files || []
		),
		required: true,
		category: requestCategories.proofOfPurchase,
		numberFilesRequired: 1,
		separateFiles:
			filesInputs?.find((el) => el.name == 'proofOfPurchase')?.separateFiles ||
			{}
	})

	const firstQuestionList: SelectList[] = [
		{
			label: pageAssets?.number_one,
			value: pageAssets?.number_one,
			key: 'number_one'
		},
		{
			label: pageAssets?.number_two,
			value: pageAssets?.number_two,
			key: 'number_two'
		},
		{
			label: pageAssets?.number_three,
			value: pageAssets?.number_three,
			key: 'number_'
		}
	]

	const onSelectFirstQuestion = (value) => {
		setFirstQuestion({ ...firstQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
		if (value == 3) {
			setSecondPath2Question({
				...secondPath2Question,
				required: true
			})
			setThirdPath2Question({
				...thirdPath2Question,
				required: true
			})
			setFourthPath2Question({
				...fourthPath2Question,
				required: true
			})
			setSecondPath3Question({
				...secondPath3Question,
				required: true
			})
			setThirdPath3Question({
				...thirdPath3Question,
				required: true
			})
			setFourthPath3Question({
				...fourthPath3Question,
				required: true
			})
			setSeventhQuestion({
				...seventhQuestion,
				numberFilesRequired: 1
			})
		} else if (value == 2) {
			setSecondPath2Question({
				...secondPath2Question,
				required: true
			})
			setThirdPath2Question({
				...thirdPath2Question,
				required: true
			})
			setFourthPath2Question({
				...fourthPath2Question,
				required: true
			})

			setSecondPath3Question({
				...secondPath3Question,
				required: false
			})
			setThirdPath3Question({
				...thirdPath3Question,
				required: false
			})
			setFourthPath3Question({
				...fourthPath3Question,
				required: false
			})
			setSeventhQuestion({
				...seventhQuestion,
				numberFilesRequired: 1
			})
		} else {
			setSecondPath2Question({
				...secondPath2Question,
				required: false
			})
			setThirdPath2Question({
				...thirdPath2Question,
				required: false
			})
			setFourthPath2Question({
				...fourthPath2Question,
				required: false
			})
			setSecondPath3Question({
				...secondPath3Question,
				required: false
			})
			setThirdPath3Question({
				...thirdPath3Question,
				required: false
			})
			setFourthPath3Question({
				...fourthPath3Question,
				required: false
			})
			setSeventhQuestion({
				...seventhQuestion,
				files:
					seventhQuestion.files.size == 2
						? new Set([[...seventhQuestion.files].shift()!])
						: seventhQuestion.files,
				numberFilesRequired: 1
			})
		}
	}

	const onSelectSecondQuestion = (value: string) => {
		setSecondQuestion({ ...secondQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
	}

	const onSelectThirdQuestion = (value: string) => {
		setThirdQuestion({ ...thirdQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, thirdQuestion.name))
	}

	const onSelectFourthQuestion = (value: string) => {
		setFourthQuestion({ ...fourthQuestion, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, fourthQuestion.name))
	}

	const onSelectSecondPath2Question = (value: string) => {
		setSecondPath2Question({ ...secondPath2Question, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, secondPath2Question.name))
	}
	const onSelectThirdPath2Question = (value: string) => {
		setThirdPath2Question({ ...thirdPath2Question, value })
		onFixError(UpdateRequestInputsErrorsArray(errors, thirdPath2Question.name))
	}
	const onSelectFourthPath2Question = (value: string) => {
		setFourthPath2Question({ ...fourthPath2Question, value })
		onFixError(UpdateRequestInputsErrorsArray(errors, fourthPath2Question.name))
	}

	const onSelectSecondPath3Question = (value: string) => {
		setSecondPath3Question({ ...secondPath3Question, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, secondPath3Question.name))
	}
	const onSelectThirdPath3Question = (value: string) => {
		setThirdPath3Question({ ...thirdPath3Question, value })
		onFixError(UpdateRequestInputsErrorsArray(errors, thirdPath3Question.name))
	}

	const onSelectFourthPath3Question = (value: string) => {
		setFourthPath3Question({ ...fourthPath3Question, value })
		onFixError(UpdateRequestInputsErrorsArray(errors, fourthPath3Question.name))
	}

	// ----------------------------

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		setSeventhQuestion({
			...seventhQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, seventhQuestion.name))
	}

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, fourthQuestion))
	}, [fourthQuestion])

	// ---------

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondPath2Question))
	}, [secondPath2Question])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, thirdPath2Question))
	}, [thirdPath2Question])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, fourthPath2Question))
	}, [fourthPath2Question])

	// ---------
	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondPath3Question))
	}, [secondPath3Question])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, thirdPath3Question))
	}, [thirdPath3Question])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, fourthPath3Question))
	}, [fourthPath3Question])

	useEffect(() => {
		onSetSubSteps({
			map: true,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.MAP,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.MAP,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})

		const programList: RequestInputs = {
			name: 'programlist',
			value: parseInt(REQUEST_CONFIGS_KEYS.rainwaterProgramList),
			valueKey: 'request_form_rainwater_barrel_programlist',
			label: '',
			labelKey: '',
			required: true
		}

		onSetInputs(UpdateRequestInputsArray(inputs, programList))
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files
		// ----------------------------
		// proofOfPurchase/seventhQuestion input
		if (
			!!answers.proofOfPurchase &&
			Object.keys(seventhQuestion.separateFiles!).length == 0
		) {
			getSeparetedFilesFromStore('proofOfPurchase', answers).then((result) => {
				setSeventhQuestion({
					...seventhQuestion,
					separateFiles: result
				})
			})
		}
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const renderWaterBarrelField: React.FC<RenderRainBarrel> = ({
		title,
		secondQuestionObject,
		thirdQuestionObject,
		fourthQuestionObject,
		onSelectSecondQuestion,
		onSelectThirdQuestion,
		onSelectFourthQuestion
	}) => {
		return (
			<>
				<h5 className={pageUtils.classes.h5}>{title}</h5>
				<TextInput
					id={secondQuestionObject.name}
					label={secondQuestionObject.label}
					value={secondQuestionObject.value}
					onChange={onSelectSecondQuestion}
					hasError={errors.includes(secondQuestionObject.name)}
					required={secondQuestionObject.required}
					ref={secondQuestionObject.ref as RefObject<HTMLInputElement>}
				/>
				<TextInput
					id={thirdQuestionObject.name}
					label={thirdQuestionObject.label}
					value={thirdQuestionObject.value}
					onChange={onSelectThirdQuestion}
					hasError={errors.includes(thirdQuestionObject.name)}
					required={thirdQuestionObject.required}
					ref={thirdQuestionObject.ref as RefObject<HTMLInputElement>}
				/>
				<div className={pageUtils.classes.fieldsContainer}>
					<TextInput
						id={fourthQuestionObject.name}
						type="number"
						label={fourthQuestionObject.label}
						value={fourthQuestionObject.value}
						onChange={onSelectFourthQuestion}
						hasError={errors.includes(fourthQuestionObject.name)}
						required={fourthQuestionObject.required}
						ref={fourthQuestionObject.ref as RefObject<HTMLInputElement>}
						isMoneyInput
						min="0"
					/>
				</div>
			</>
		)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
								style={{ float: 'right' }}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_grant_details}
							</h4>
						</div>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.rain_waterBarrel_info_on_first_barrel}
						</h5>

						<div>
							<strong>{pageAssets[firstQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.numberOfBarrel}
							</span>
						</div>

						<div>
							<strong>{pageAssets[secondQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.manufacturerName1}
							</span>
						</div>

						<div>
							<strong>{pageAssets[thirdQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.nameAndModelNumber1}
							</span>
						</div>

						<div>
							<strong>{pageAssets[fourthQuestion.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.costBeforeTax1}
							</span>
						</div>

						{answers.numberOfBarrel &&
							answers.numberOfBarrel >= parseInt(pageAssets.number_two) && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{pageAssets?.rain_waterBarrel_info_on_second_barrel}
									</h5>

									<div>
										<strong>{pageAssets[secondPath2Question.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.manufacturerName2}
										</span>
									</div>

									<div>
										<strong>{pageAssets[thirdPath2Question.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.nameAndModelNumber2}
										</span>
									</div>

									<div>
										<strong>{pageAssets[fourthPath2Question.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.costBeforeTax2}
										</span>
									</div>
								</>
							)}

						{answers.numberOfBarrel &&
							answers.numberOfBarrel >= parseInt(pageAssets.number_three) && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{pageAssets?.rain_waterBarrel_info_on_third_barrel}
									</h5>

									<div>
										<strong>{pageAssets[secondPath2Question.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.manufacturerName3}
										</span>
									</div>

									<div>
										<strong>{pageAssets[thirdPath2Question.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.nameAndModelNumber3}
										</span>
									</div>

									<div>
										<strong>{pageAssets[fourthPath2Question.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.costBeforeTax3}
										</span>
									</div>
								</>
							)}
					</>
				)}
				{subSteps?.requiredDocument && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
									)
								}
								style={{ float: 'right' }}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>
						</div>

						<RequestFilesWithMultipleButtons
							input={seventhQuestion}
							hasError={errors.includes(seventhQuestion.name)}
							setInput={setSeventhQuestion}
							numberOfFiles={parseInt(firstQuestion.value)}
							onSetHasError={onSetErrorFromFileComponent}
							onFixError={onFixError}
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
							errors={errors}
							steps={steps!}
							subSteps={subSteps!}
							formPosition={formPosition!}
							buttonLabels={pageAssets?.label_bill_number}
							isSummary
						>
							<></>
						</RequestFilesWithMultipleButtons>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_grant_details}
					</h4>

					<div className={pageUtils.classes.select2}>
						<RadioInput
							name={firstQuestion.name}
							label={firstQuestion.label}
							value={firstQuestion.value}
							list={firstQuestionList}
							hasError={errors.includes(firstQuestion.name)}
							onChange={onSelectFirstQuestion}
							ref={firstQuestion.ref as RefObject<HTMLInputElement>}
						/>
					</div>

					{renderWaterBarrelField({
						title: pageAssets?.rain_waterBarrel_info_on_first_barrel,
						secondQuestionObject: secondQuestion,
						thirdQuestionObject: thirdQuestion,
						fourthQuestionObject: fourthQuestion,
						onSelectSecondQuestion: onSelectSecondQuestion,
						onSelectThirdQuestion: onSelectThirdQuestion,
						onSelectFourthQuestion: onSelectFourthQuestion
					})}

					{(firstQuestion.value === pageAssets?.number_two ||
						firstQuestion.value === pageAssets?.number_three) &&
						renderWaterBarrelField({
							title: pageAssets?.rain_waterBarrel_info_on_second_barrel,
							secondQuestionObject: secondPath2Question,
							thirdQuestionObject: thirdPath2Question,
							fourthQuestionObject: fourthPath2Question,
							onSelectSecondQuestion: onSelectSecondPath2Question,
							onSelectThirdQuestion: onSelectThirdPath2Question,
							onSelectFourthQuestion: onSelectFourthPath2Question
						})}

					{firstQuestion.value === pageAssets?.number_three &&
						renderWaterBarrelField({
							title: pageAssets?.rain_waterBarrel_info_on_third_barrel,
							secondQuestionObject: secondPath3Question,
							thirdQuestionObject: thirdPath3Question,
							fourthQuestionObject: fourthPath3Question,
							onSelectSecondQuestion: onSelectSecondPath3Question,
							onSelectThirdQuestion: onSelectThirdPath3Question,
							onSelectFourthQuestion: onSelectFourthPath3Question
						})}
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_required_documents}
					</h4>

					<RequestFilesWithMultipleButtons
						input={seventhQuestion}
						hasError={errors.includes(seventhQuestion.name)}
						setInput={setSeventhQuestion}
						numberOfFiles={parseInt(firstQuestion.value)}
						onSetHasError={onSetErrorFromFileComponent}
						onFixError={onFixError}
						UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
						errors={errors}
						steps={steps!}
						subSteps={subSteps!}
						formPosition={formPosition!}
						buttonLabels={pageAssets?.label_bill_number}
						description={seventhQuestion.description}
					>
						{/* <>
							<div className={classes.fileHelperSection}>
								<strong className={classes.listTitle}>
									<Text
										content={
											pageAssets?.rain_waterBarrel_doc_should_clairly_indicate
										}
									/>
								</strong>
								<ol className={classes.list}>
									<li>
										<Text
											content={formatStrapiText(
												pageAssets?.rain_waterBarrel_name_and_coordinate
											)}
										/>
									</li>
									<li>
										<Text
											content={formatStrapiText(
												pageAssets?.rain_waterBarrel_date_of_purchase
											)}
										/>
									</li>
									<li>
										<Text
											content={formatStrapiText(
												pageAssets?.rain_waterBarrel_modele_of_barrel
											)}
										/>
									</li>
								</ol>
								<br />
								<Text
									content={formatStrapiText(
										pageAssets?.rain_waterBarrel_different_invoices
									)}
								/>
							</div>
						</> */}
					</RequestFilesWithMultipleButtons>
				</section>
			</Collapse>
		</>
	)
}

export default RainwaterBarrelForm
