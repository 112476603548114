import axios, { AxiosError } from 'axios'
import { BaseRepository } from './_miscellaneous.repository'
import { acquireAccessToken } from '@utils/authConfig'
import apiWebScrapper from '@services/api.webScrapper'

export default class ScrapperRepository extends BaseRepository {
	constructor() {
		super()
	}

	public scrap = async (url, selector) => {
		try {
			const response = await apiWebScrapper.get('', {
				params: {
					params: this.stringify({
						url,
						selector
					})
				},
				headers: {
					token: await acquireAccessToken()
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while scrapping the web page: ' + error.message)
			}

			throw error
		}
	}
}
