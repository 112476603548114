import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import RadioInput from './inputs/radio.input'
import TextInput from './inputs/text.inputs'
import Text from '@components/ui/text'
import RequestFiles from '@components/ui/requestFiles'
import { Colors } from '@utils/css-variables'
import { joinClasses, makeClasses } from '@utils/styles'
import BlueBulletsList, {
	BlueBulletsLabel
} from '@components/ui/blueBulletsList'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import {
	formatStrapiText,
	getFilesFromStore,
	getSeparetedFilesFromStore
} from '@utils/methods'
import RequiredIcon from '@components/ui/requiredIcon'
import { CustomDate } from '@services/models'
import Error from '@components/requestForms/inputs/error.input'
import RequestFilesWithMultipleButtons from '@components/ui/requestFilesWithMultipleButtons'
import DateFilterInput from './inputs/datefilter.input'
import CheckboxInput from './inputs/checkbox.input'
import Notification from '@components/ui/notification'
import MapForm from '@components/ui/map/MapForm'

type Classes = {
	button: string
	outlinedButton: string
	buttonContainer: string
	childHeader: string
	fileHelperSection: string
	fileContainer: string
	radioWrapper: string
}

const classes: Classes = makeClasses({
	button: {
		display: 'inline-block',
		padding: '10px 20px',
		fontSize: '13px',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: Colors.secondary,
		border: 'none',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2,
			color: Colors.white
		}
	},
	outlinedButton: {
		background: 'none',
		border: `1px solid ${Colors.secondary} !important`,
		color: Colors.secondary
	},
	buttonContainer: {
		display: 'flex',
		width: 'fit-content',
		columnGap: '10px',
		alignItems: 'center',
		marginBottom: '1rem!important'
	},
	childHeader: {
		fontSize: '18px',
		fontWeight: '500',
		color: Colors.secondary
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	},
	radioWrapper: {
		marginTop: '10px'
	}
})

const SustainableHygieneProductsForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	setDisableNextButton,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onCustomError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const [showAddSecondChildButton, setShowAddSecondChildButton] =
		useState<boolean>(true)
	const [showAddThirdChildButton, setShowAddThirdChildButton] =
		useState<boolean>(false)
	const [showAddFourthChildButton, setShowAddFourthChildButton] =
		useState<boolean>(false)
	const [hasSecondChild, setHasSecondChild] = useState<boolean>(false)
	const [hasThirdChild, setHasThirdChild] = useState<boolean>(false)

	const [subscriptionAmountWarning, setSubscriptionAmountWarning] =
		useState<boolean>(false)

	enum CHILD {
		FIRST,
		SECOND,
		THIRD
	}

	const [grantForWhom, setGrantForWhom] = useState<RequestInputs>({
		name: 'grantForWhom',
		label: pageAssets?.request_form_sustainableHygiene_forWhom_label,
		labelKey: 'request_form_sustainableHygiene_forWhom_label',
		value: `${answers.grantForWhom}` ?? '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [situation, setSituation] = useState<RequestInputs>({
		name: 'situation',
		label: pageAssets?.request_form_sustainableHygiene_situation_label,
		labelKey: 'request_form_sustainableHygiene_situation_label',
		value: answers.situation?.toString() ?? '',
		required: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [situationString, setSituationString] = useState<RequestInputs>({
		name: 'situationString',
		label: '',
		labelKey: '',
		value: answers.situationString ?? '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [situationList, setSituationList] = useState<CheckboxList[]>()

	const [beneficiaryFirstName, setBeneficiaryFirstName] =
		useState<RequestInputs>({
			name: 'beneficiaryFirstName',
			label: pageAssets?.request_form_sustainableHygiene_beneficiary_firstName,
			labelKey: 'request_form_sustainableHygiene_beneficiary_firstName',
			value: answers.beneficiaryFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [beneficiaryLastName, setBeneficiaryLastName] = useState<RequestInputs>(
		{
			name: 'beneficiaryLastName',
			label: pageAssets?.request_form_sustainableHygiene_beneficiary_lastName,
			labelKey: 'request_form_sustainableHygiene_beneficiary_lastName',
			value: answers.beneficiaryLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [beneficiaryAddress, setBeneficiaryAddress] = useState<RequestInputs>({
		name: 'beneficiaryAddress',
		label: pageAssets?.request_form_sustainableHygiene_beneficiary_address,
		labelKey: 'request_form_sustainableHygiene_beneficiary_address',
		value: answers.beneficiaryAddress || '',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [beneficiaryAppartmentInput, setBeneficiaryAppartmentInput] =
		useState<RequestInputs>({
			name: 'beneficiaryAppartment',
			label: pageAssets?.request_form_sustainableHygiene_beneficiary_address,
			labelKey: 'request_form_sustainableHygiene_beneficiary_address',
			value: answers.beneficiaryAppartment || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [beneficiaryOldGrant, setBeneficiaryOldGrant] = useState<RequestInputs>(
		{
			name: 'beneficiaryOldGrant',
			label:
				pageAssets?.request_form_sustainableHygiene_beneficiary_oldGrant_label,
			labelKey: 'request_form_sustainableHygiene_beneficiary_oldGrant_label',
			value: answers.beneficiaryOldGrant?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [myselfOldGrant, setMyselfOldGrant] = useState<RequestInputs>({
		name: 'myselfOldGrant',
		label: pageAssets.request_form_sustainableHygiene_myself_oldGrant_label,
		labelKey: 'request_form_sustainableHygiene_myself_oldGrant_label',
		value: answers.myselfOldGrant?.toString() || '',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [proofOfResidency, setProofOfResidency] = useState<RequestInputFiles>({
		name: 'proofOfResidencyInput',
		label: pageAssets?.request_form_sustainableHygiene_proofOfResidency_label,
		labelKey: 'request_form_sustainableHygiene_proofOfResidency_label',
		description:
			pageAssets?.request_form_sustainableHygiene_proofOfResidency_description,
		files: new Set<File>(),
		required: false,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
		numberFilesRequired: 2,
		category: requestCategories.proofOfResidency,
		separateFiles:
			filesInputs?.find((el) => el.name == 'proofOfResidencyInput')
				?.separateFiles || {}
	})

	const [proofOfResidencyChild, setProofOfResidencyChild] =
		useState<RequestInputFiles>({
			name: 'proofOfResidencyChildInput',
			label:
				pageAssets?.request_form_sustainableHygiene_proofOfResidencyChild_label,
			labelKey: 'request_form_sustainableHygiene_proofOfResidencyChild_label',
			description:
				pageAssets?.request_form_sustainableHygiene_proofOfResidency_description,
			files: new Set<File>(),
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			category: requestCategories.proofOfResidency,
			numberFilesRequired: 2,
			separateFiles:
				filesInputs?.find((el) => el.name == 'proofOfResidencyChildInput')
					?.separateFiles || {}
		})

	const [dependentFirstChildFirstName, setDependentFirstChildFirstName] =
		useState<RequestInputs>({
			name: 'dependentFirstChildFirstName',
			label:
				pageAssets?.request_form_sustainableHygiene_dependentChild_firstName,
			labelKey: 'request_form_sustainableHygiene_dependentChild_firstName',
			value: answers.dependentFirstChildFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [dependentFirstChildLastName, setDependentFirstChildLastName] =
		useState<RequestInputs>({
			name: 'dependentFirstChildLastName',
			label:
				pageAssets?.request_form_sustainableHygiene_dependentChild_lastName,
			labelKey: 'request_form_sustainableHygiene_dependentChild_lastName',
			value: answers.dependentFirstChildLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [dependentSecondChildFirstName, setDependentSecondChildFirstName] =
		useState<RequestInputs>({
			name: 'dependentSecondChildFirstName',
			label:
				pageAssets?.request_form_sustainableHygiene_dependentChild_firstName,
			labelKey: 'request_form_sustainableHygiene_dependentChild_firstName',
			value: answers.dependentSecondChildFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [dependentSecondChildLastName, setDependentSecondChildLastName] =
		useState<RequestInputs>({
			name: 'dependentSecondChildLastName',
			label:
				pageAssets?.request_form_sustainableHygiene_dependentChild_lastName,
			labelKey: 'request_form_sustainableHygiene_dependentChild_lastName',
			value: answers.dependentSecondChildLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [dependentThirdChildFirstName, setDependentThirdChildFirstName] =
		useState<RequestInputs>({
			name: 'dependentThirdChildFirstName',
			label:
				pageAssets?.request_form_sustainableHygiene_dependentChild_firstName,
			labelKey: 'request_form_sustainableHygiene_dependentChild_firstName',
			value: answers.dependentThirdChildFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [dependentThirdChildLastName, setDependentThirdChildLastName] =
		useState<RequestInputs>({
			name: 'dependentThirdChildLastName',
			label:
				pageAssets?.request_form_sustainableHygiene_dependentChild_lastName,
			labelKey: 'request_form_sustainableHygiene_dependentChild_lastName',
			value: answers.dependentThirdChildLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [childFirstOldGrant, setChildFirstOldGrant] = useState<RequestInputs>({
		name: 'childFirstOldGrant',
		label: pageAssets.request_form_sustainableHygiene_child_oldGrant_label,
		labelKey: 'request_form_sustainableHygiene_child_oldGrant_label',
		value: answers.childFirstOldGrant?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [childSecondOldGrant, setChildSecondOldGrant] = useState<RequestInputs>(
		{
			name: 'childSecondOldGrant',
			label: pageAssets.request_form_sustainableHygiene_child_oldGrant_label,
			labelKey: 'request_form_sustainableHygiene_child_oldGrant_label',
			value: answers.childSecondOldGrant?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [childThirdOldGrant, setChildThirdOldGrant] = useState<RequestInputs>({
		name: 'childThirdOldGrant',
		label: pageAssets.request_form_sustainableHygiene_child_oldGrant_label,
		labelKey: 'request_form_sustainableHygiene_child_oldGrant_label',
		value: answers.childThirdOldGrant?.toString() || '',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const [birthCertificateFirstChild, setBirthCertificateFirstChild] =
		useState<RequestInputFiles>({
			name: 'birthCertificateFirstChildInput',
			label: pageAssets?.request_form_sustainableHygiene_birthCertificate_label,
			labelKey: 'request_form_sustainableHygiene_birthCertificate_label',
			description:
				pageAssets?.request_form_sustainableHygiene_birthCertificate_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'birthCertificateFirstChildInput')
					?.files || []
			),
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			numberFilesRequired: 1,
			category: requestCategories.proofOfBirth
		})

	const [birthCertificateSecondChild, setBirthCertificateSecondChild] =
		useState<RequestInputFiles>({
			name: 'birthCertificateSecondChildInput',
			label: pageAssets?.request_form_sustainableHygiene_birthCertificate_label,
			labelKey: 'request_form_sustainableHygiene_birthCertificate_label',
			description:
				pageAssets?.request_form_sustainableHygiene_birthCertificate_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'birthCertificateSecondChildInput')
					?.files || []
			),
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			numberFilesRequired: 1,
			category: requestCategories.proofOfBirth
		})

	const [birthCertificateThirdChild, setBirthCertificateThirdChild] =
		useState<RequestInputFiles>({
			name: 'birthCertificateThirdChildInput',
			label: pageAssets?.request_form_sustainableHygiene_birthCertificate_label,
			labelKey: 'request_form_sustainableHygiene_birthCertificate_label',
			description:
				pageAssets?.request_form_sustainableHygiene_birthCertificate_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'birthCertificateThirdChildInput')
					?.files || []
			),
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			numberFilesRequired: 1,
			category: requestCategories.proofOfBirth
		})

	const [buyingProducts, setBuyingProducts] = useState<RequestInputs>({
		name: 'buyingProducts',
		label:
			pageAssets?.request_form_sustainableHygiene_buyingProducts_category_label,
		labelKey: 'request_form_sustainableHygiene_buyingProducts_category_label',
		//value: answers.buyingProducts || '',
		value: `${answers.buyingProducts}` ?? '',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		ref: useRef<HTMLInputElement>(null)
	})

	const [totalAmount, setTotalAmount] = useState<RequestInputs>({
		name: 'totalAmount',
		label:
			pageAssets?.request_form_sustainableHygiene_buyingProducts_totalAmount_label,
		labelKey:
			'request_form_sustainableHygiene_buyingProducts_totalAmount_label',
		description:
			pageAssets.request_form_sustainableHygiene_buyingProducts_totalAmount_description,
		value: answers.totalAmount || '',
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		ref: useRef<HTMLInputElement>(null)
	})

	const [proofOfPurchase, setProofOfPurchase] = useState<RequestInputFiles>({
		name: 'proofOfPurchaseInput',
		label:
			pageAssets?.request_form_sustainableHygiene_buyingProducts_proofOfPurchase_label,
		labelKey:
			'request_form_sustainableHygiene_buyingProducts_proofOfPurchase_label',
		description:
			pageAssets?.request_form_sustainableHygiene_buyingProducts_proofOfPurchase_label,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'proofOfPurchaseInput')?.files || []
		),
		required: true,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		category: requestCategories.proofOfPurchase
	})

	const grantForWhomList: RadioList[] = [
		{
			label: pageAssets?.select_sustainableHygiene_forWhom_firstOption,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption,
			key: 'select_sustainableHygiene_forWhom_firstOption'
		},
		{
			label: pageAssets?.select_sustainableHygiene_forWhom_secondOption,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption,
			key: 'select_sustainableHygiene_forWhom_secondOption',
			extraText:
				pageAssets?.select_sustainableHygiene_forWhom_secondOption_extra
		}
	]

	const productCategoryList: RadioList[] = [
		{
			label: pageAssets?.select_sustainableHygiene_buyingProducts_firstCat,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneCategory.firstCat,
			key: 'select_sustainableHygiene_buyingProducts_firstCat'
		},
		{
			label: pageAssets?.select_sustainableHygiene_buyingProducts_secondCat,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneCategory.secondCat,
			key: 'select_sustainableHygiene_buyingProducts_secondCat'
		},
		{
			label: pageAssets?.select_sustainableHygiene_buyingProducts_thirdCat,
			value: REQUEST_CONFIGS_KEYS.sustainableHygieneCategory.thirdCat,
			key: 'select_sustainableHygiene_buyingProducts_thirdCat'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const isChildIncluded = () => {
		return (
			situationString?.value?.includes(
				REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
			) &&
			grantForWhom.value ===
				REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption
		)
	}

	const isBeneficiaryOrParent = () => {
		return (
			situationString?.value?.includes(
				REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.firstOption
			) ||
			grantForWhom.value ===
				REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
		)
	}

	const isValidAmount = (value: string) => {
		let childNb = 0
		if (isChildIncluded()) {
			childNb =
				(answers.dependentFirstChildFirstName ? 1 : 0) +
				(answers.dependentSecondChildFirstName ? 1 : 0) +
				(answers.dependentThirdChildFirstName ? 1 : 0)
		}

		// Prevent execution before entering information
		if (value !== '') {
			if (
				parseInt(value) <
				100 * childNb + (isBeneficiaryOrParent() ? 100 : 0)
			) {
				return false
			} else {
				return true
			}
		}
	}

	const updateNextButton = (value: string) => {
		if (value == radioListYesNo[0].value) {
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setDisableNextButton && setDisableNextButton(true)
		}
	}

	const clearSecondChildFields = () => {
		setDependentSecondChildFirstName({
			...dependentSecondChildFirstName,
			value: '',
			required: false
		})
		setDependentSecondChildLastName({
			...dependentSecondChildLastName,
			value: '',
			required: false
		})
		setChildSecondOldGrant({
			...childSecondOldGrant,
			value: '',
			required: false
		})
		setBirthCertificateSecondChild({
			...birthCertificateSecondChild,
			required: false
		})
		setHasSecondChild(false)

		onFixError(
			errors.filter(
				(error) =>
					![
						dependentSecondChildFirstName.name,
						dependentSecondChildLastName.name,
						childSecondOldGrant.name,
						birthCertificateSecondChild.name
					].includes(error)
			)
		)
	}

	const clearThirdChildFields = () => {
		setDependentThirdChildFirstName({
			...dependentThirdChildFirstName,
			value: '',
			required: false
		})
		setDependentThirdChildLastName({
			...dependentThirdChildLastName,
			value: '',
			required: false
		})
		setChildThirdOldGrant({ ...childThirdOldGrant, value: '', required: false })
		setBirthCertificateThirdChild({
			...birthCertificateThirdChild,
			required: false
		})
		setHasThirdChild(false)

		onFixError(
			errors.filter(
				(error) =>
					![
						dependentThirdChildFirstName.name,
						dependentThirdChildLastName.name,
						childThirdOldGrant.name,
						birthCertificateThirdChild.name
					].includes(error)
			)
		)
	}

	const setSecondChildFields = () => {
		setDependentSecondChildFirstName({
			...dependentSecondChildFirstName,
			required: true
		})
		setDependentSecondChildLastName({
			...dependentSecondChildLastName,
			required: true
		})
		setChildSecondOldGrant({ ...childSecondOldGrant, required: true })
		setBirthCertificateSecondChild({
			...birthCertificateSecondChild,
			required: true
		})
		setHasSecondChild(true)
	}

	const setThirdChildFields = () => {
		setDependentThirdChildFirstName({
			...dependentThirdChildFirstName,
			required: true
		})
		setDependentThirdChildLastName({
			...dependentThirdChildLastName,
			required: true
		})
		setChildThirdOldGrant({ ...childThirdOldGrant, required: true })
		setBirthCertificateThirdChild({
			...birthCertificateThirdChild,
			required: true
		})
		setHasThirdChild(true)
	}

	const clearChild = (child: CHILD) => {
		if (child == CHILD.SECOND) {
			if (!hasThirdChild) {
				setShowAddThirdChildButton(false)
				clearSecondChildFields()
				if (
					!(
						childFirstOldGrant.value === 'true' ||
						childThirdOldGrant.value === 'true'
					)
				) {
					updateNextButton('false')
				}
			} else {
				// We want the third child to become the second, copy all third child values to second child and remove it
				onSelectDependentSecondChildFirstName(
					dependentThirdChildFirstName.value
				)
				onSelectDependentSecondChildLastName(dependentThirdChildLastName.value)
				onSelectChildSecondOldGrant(childThirdOldGrant.value)
				onSelectBirthCertificateSecondChild(
					new Set<File>([...birthCertificateThirdChild.files])
				)
				clearChild(CHILD.THIRD)
			}
		} else if (child == CHILD.THIRD) {
			setShowAddFourthChildButton(false)
			clearThirdChildFields()

			if (
				!(
					childFirstOldGrant.value === 'true' ||
					childSecondOldGrant.value === 'true'
				)
			) {
				updateNextButton('false')
			}
		}
	}

	const showChild = (child: CHILD) => {
		switch (child) {
			case CHILD.SECOND: {
				setShowAddThirdChildButton(true)
				setSecondChildFields()
				break
			}
			case CHILD.THIRD: {
				setShowAddFourthChildButton(true)
				setThirdChildFields()
			}
		}
	}

	const onSelectGrantForWhom = (value: string) => {
		setGrantForWhom({ ...grantForWhom, value })

		setTimeout(() => {
			setBeneficiaryFirstName({
				...beneficiaryFirstName,
				required:
					value === REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
			})
			setBeneficiaryLastName({
				...beneficiaryLastName,
				required:
					value === REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
			})
			setBeneficiaryAddress({
				...beneficiaryAddress,
				required:
					value === REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
			})
			setBeneficiaryOldGrant({
				...beneficiaryOldGrant,
				required:
					value === REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
			})
			setProofOfResidency({
				...proofOfResidency,
				label:
					value == REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
						? pageAssets?.request_form_sustainableHygiene_proofOfResidencyBeneficiary_label
						: pageAssets?.request_form_sustainableHygiene_proofOfResidency_label,
				labelKey:
					value == REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
						? 'request_form_sustainableHygiene_proofOfResidencyBeneficiary_label'
						: 'request_form_sustainableHygiene_proofOfResidency_label',
				required:
					value === REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption,
				separateFiles: {}
			})

			setSituation({
				...situation,
				required:
					value === REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption
			})
			setMyselfOldGrant({
				...myselfOldGrant,
				required:
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.firstOption
					)
			})

			setProofOfResidencyChild({
				...proofOfResidencyChild,
				required:
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					),
				separateFiles: {}
			})
			setDependentFirstChildFirstName({
				...dependentFirstChildFirstName,
				required:
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setDependentFirstChildLastName({
				...dependentFirstChildLastName,
				required:
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setChildFirstOldGrant({
				...childFirstOldGrant,
				required:
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setBirthCertificateFirstChild({
				...birthCertificateFirstChild,
				required:
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})

			setDependentSecondChildFirstName({
				...dependentSecondChildFirstName,
				required:
					showAddThirdChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setDependentSecondChildLastName({
				...dependentSecondChildLastName,
				required:
					showAddThirdChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setChildSecondOldGrant({
				...childSecondOldGrant,
				required:
					showAddThirdChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setBirthCertificateSecondChild({
				...birthCertificateSecondChild,
				required:
					showAddThirdChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setDependentThirdChildFirstName({
				...dependentThirdChildFirstName,
				required:
					showAddFourthChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setDependentThirdChildLastName({
				...dependentThirdChildLastName,
				required:
					showAddFourthChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setChildThirdOldGrant({
				...childThirdOldGrant,
				required:
					showAddFourthChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
			setBirthCertificateThirdChild({
				...birthCertificateThirdChild,
				required:
					showAddFourthChildButton &&
					value ===
						REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption &&
					situationString.value.includes(
						REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
					)
			})
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						beneficiaryFirstName.name,
						beneficiaryLastName.name,
						beneficiaryAddress.name,
						beneficiaryOldGrant.name,
						proofOfResidency.name,
						situation.name,
						myselfOldGrant.name,
						proofOfResidencyChild.name,
						dependentFirstChildFirstName.name,
						dependentFirstChildLastName.name,
						childFirstOldGrant.name,
						dependentSecondChildFirstName.name,
						dependentSecondChildLastName.name,
						childSecondOldGrant.name,
						dependentThirdChildFirstName.name,
						dependentThirdChildLastName.name,
						childThirdOldGrant.name,
						birthCertificateFirstChild.name,
						birthCertificateSecondChild.name,
						birthCertificateThirdChild.name
					].includes(error)
			)
		)
	}

	const onChangeSituation = (value: string, key: string) => {
		setSituation({ ...situation, value })

		pageUtils.onCheckValue(key, situationList, setSituationList, setSituation)

		if (situationList?.find((el) => el.checked) === undefined) {
			setSituation({ ...situation, value: '' })
		}

		setTimeout(() => {
			let shouldBeTruthy = generateCleanStringFromAnswer(
				situationList
			).includes(REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption)

			// On désactive enfants à charge
			setProofOfResidencyChild({
				...proofOfResidencyChild,
				required: shouldBeTruthy
			})
			setDependentFirstChildFirstName({
				...dependentFirstChildFirstName,
				value: '',
				required: shouldBeTruthy
			})
			setDependentFirstChildLastName({
				...dependentFirstChildLastName,
				value: '',
				required: shouldBeTruthy
			})
			setChildFirstOldGrant({
				...childFirstOldGrant,
				value: '',
				required: shouldBeTruthy
			})
			setBirthCertificateFirstChild({
				...birthCertificateFirstChild,
				required: shouldBeTruthy
			})
			setBirthCertificateSecondChild({
				...birthCertificateSecondChild,
				required: showAddThirdChildButton
			})
			setBirthCertificateThirdChild({
				...birthCertificateThirdChild,
				required: showAddFourthChildButton
			})

			setMyselfOldGrant({ ...myselfOldGrant, required: !shouldBeTruthy })
			setProofOfResidency({ ...proofOfResidency, required: !shouldBeTruthy })
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						situation.name,
						myselfOldGrant.name,
						proofOfResidency.name,
						proofOfResidencyChild.name,
						dependentFirstChildFirstName.name,
						dependentFirstChildLastName.name,
						birthCertificateFirstChild.name,
						birthCertificateSecondChild.name,
						birthCertificateThirdChild.name
					].includes(error)
			)
		)
	}

	const onSelectBeneficiaryFirstName = (value: string) => {
		setBeneficiaryFirstName({ ...beneficiaryFirstName, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, beneficiaryFirstName.name)
		)
	}

	const onSelectBeneficiaryLastName = (value: string) => {
		setBeneficiaryLastName({ ...beneficiaryLastName, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, beneficiaryLastName.name))
	}

	const onSelectBeneficiaryAddress = (value: string) => {
		setBeneficiaryAddress({ ...beneficiaryAddress, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, beneficiaryAddress.name))
	}

	const onSelectBeneficiaryAppartmentInput = (value: string) => {
		setBeneficiaryAppartmentInput({ ...beneficiaryAppartmentInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, beneficiaryAppartmentInput.name)
		)
	}

	const onSelectDependentFirstChildFirstName = (value: string) => {
		setDependentFirstChildFirstName({ ...dependentFirstChildFirstName, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, dependentFirstChildFirstName.name)
		)
	}

	const onSelectDependentFirstChildLastName = (value: string) => {
		setDependentFirstChildLastName({ ...dependentFirstChildLastName, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, dependentFirstChildLastName.name)
		)
	}

	const onSelectChildFirstOldGrant = (value: string) => {
		setChildFirstOldGrant({ ...childFirstOldGrant, value })

		// Update next button only if not already disable by other elements
		if (
			!(
				childSecondOldGrant.value === 'true' ||
				childThirdOldGrant.value === 'true'
			)
		) {
			updateNextButton(value)
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, childFirstOldGrant.name))
	}

	const onSelectDependentSecondChildFirstName = (value: string) => {
		setDependentSecondChildFirstName({
			...dependentSecondChildFirstName,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, dependentSecondChildFirstName.name)
		)
	}

	const onSelectDependentSecondChildLastName = (value: string) => {
		setDependentSecondChildLastName({ ...dependentSecondChildLastName, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, dependentSecondChildLastName.name)
		)
	}

	const onSelectChildSecondOldGrant = (value: string) => {
		setChildSecondOldGrant({ ...childSecondOldGrant, value })

		// Update next button only if not already disable by other elements
		if (
			!(
				childFirstOldGrant.value === 'true' ||
				childThirdOldGrant.value === 'true'
			)
		) {
			updateNextButton(value)
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, childSecondOldGrant.name))
	}

	const onSelectDependentThirdChildFirstName = (value: string) => {
		setDependentThirdChildFirstName({ ...dependentThirdChildFirstName, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, dependentThirdChildFirstName.name)
		)
	}

	const onSelectDependentThirdChildLastName = (value: string) => {
		setDependentThirdChildLastName({ ...dependentThirdChildLastName, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, dependentThirdChildLastName.name)
		)
	}

	const onSelectChildThirdOldGrant = (value: string) => {
		setChildThirdOldGrant({ ...childThirdOldGrant, value })

		// Update next button only if not already disable by other elements
		if (
			!(
				childFirstOldGrant.value === 'true' ||
				childSecondOldGrant.value === 'true'
			)
		) {
			updateNextButton(value)
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, childThirdOldGrant.name))
	}

	const onSelectBeneficiaryOldGrant = (value: string) => {
		setBeneficiaryOldGrant({ ...beneficiaryOldGrant, value })

		updateNextButton(value)

		onFixError(UpdateRequestInputsErrorsArray(errors, beneficiaryOldGrant.name))
	}

	const onSelectMyselfOldGrant = (value: string) => {
		setMyselfOldGrant({ ...myselfOldGrant, value })

		updateNextButton(value)

		onFixError(UpdateRequestInputsErrorsArray(errors, myselfOldGrant.name))
	}

	const onSelectBuyingProducts = (value: string) => {
		setBuyingProducts({ ...buyingProducts, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, buyingProducts.name))
	}

	const onSelectTotalAmount = (value: string) => {
		setDisableNextButton && setDisableNextButton(!!isValidAmount(value))

		setTotalAmount({ ...totalAmount, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, totalAmount.name))
	}

	const onSelectBirthCertificateFirstChild = (files: Set<File>) => {
		setBirthCertificateFirstChild({
			...birthCertificateFirstChild,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, birthCertificateFirstChild.name)
		)
	}

	const onSelectBirthCertificateSecondChild = (files: Set<File>) => {
		setBirthCertificateSecondChild({
			...birthCertificateSecondChild,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, birthCertificateSecondChild.name)
		)
	}

	const onSelectBirthCertificateThirdChild = (files: Set<File>) => {
		setBirthCertificateThirdChild({
			...birthCertificateThirdChild,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, birthCertificateThirdChild.name)
		)
	}

	const onSelectProofOfPurchase = (files: Set<File>) => {
		setProofOfPurchase({
			...proofOfPurchase,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, proofOfPurchase.name))
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}
			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const generateCleanStringFromAnswer = (value) => {
		let cleanString = ''
		let firstEl = true

		value?.map((el) => {
			if (el?.checked) {
				if (!firstEl) {
					cleanString += ','
				} else {
					firstEl = false
				}

				cleanString += el.value
			}
		})

		if (cleanString) {
			return cleanString
		}

		return ''
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, grantForWhom))
	}, [grantForWhom])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, situation))
	}, [situation])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, situationString))
	}, [situationString])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryFirstName))
	}, [beneficiaryFirstName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryLastName))
	}, [beneficiaryLastName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryAddress))
	}, [beneficiaryAddress])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryAppartmentInput))
	}, [beneficiaryAppartmentInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, dependentFirstChildFirstName))
	}, [dependentFirstChildFirstName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, dependentFirstChildLastName))
	}, [dependentFirstChildLastName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, dependentSecondChildFirstName))
	}, [dependentSecondChildFirstName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, dependentSecondChildLastName))
	}, [dependentSecondChildLastName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, dependentThirdChildFirstName))
	}, [dependentThirdChildFirstName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, dependentThirdChildLastName))
	}, [dependentThirdChildLastName])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, beneficiaryOldGrant))
	}, [beneficiaryOldGrant])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, myselfOldGrant))
	}, [myselfOldGrant])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, childFirstOldGrant))
	}, [childFirstOldGrant])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, childSecondOldGrant))
	}, [childSecondOldGrant])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, childThirdOldGrant))
	}, [childThirdOldGrant])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, proofOfResidency))
	}, [proofOfResidency])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, proofOfResidencyChild)
		)
	}, [proofOfResidencyChild])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, birthCertificateFirstChild)
		)
	}, [birthCertificateFirstChild])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, birthCertificateSecondChild)
		)
	}, [birthCertificateSecondChild])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, birthCertificateThirdChild)
		)
	}, [birthCertificateThirdChild])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buyingProducts))
	}, [buyingProducts])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, totalAmount))
	}, [totalAmount])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, proofOfPurchase))
	}, [proofOfPurchase])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
			]
		})
	}, [])

	useEffect(() => {
		if (!showAddThirdChildButton && !showAddFourthChildButton) {
			setShowAddSecondChildButton(true)
		}
	}, [showAddThirdChildButton, showAddFourthChildButton])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// proofOfResidencyInput input
		// separateFiles
		if (
			!!answers.proofOfResidency &&
			Object.keys(proofOfResidency.separateFiles!).length == 0
		) {
			getSeparetedFilesFromStore('proofOfResidencyInput', answers).then(
				(result) => {
					setProofOfResidency({
						...proofOfResidency,
						separateFiles: result
					})
				}
			)
		}
		// proofOfResidencyChildInput input
		if (
			!!answers.proofOfResidencyChild &&
			Object.keys(proofOfResidencyChild.separateFiles!).length == 0
		) {
			getSeparetedFilesFromStore('proofOfResidencyChildInput', answers).then(
				(result) => {
					setProofOfResidencyChild({
						...proofOfResidencyChild,
						separateFiles: result
					})
				}
			)
		}
		// birthCertificateFirstChild Input
		if (
			!!answers.birthCertificateFirstChild &&
			birthCertificateFirstChild.files.size == 0
		) {
			getFilesFromStore('birthCertificateFirstChildInput', answers).then(
				(result: Set<File>) => {
					setBirthCertificateFirstChild({
						...birthCertificateFirstChild,
						files: result
					})
				}
			)
		}
		// birthCertificateSecondChild Input
		if (
			!!answers.birthCertificateSecondChild &&
			birthCertificateSecondChild.files.size == 0
		) {
			getFilesFromStore('birthCertificateSecondChildInput', answers).then(
				(result: Set<File>) => {
					setBirthCertificateSecondChild({
						...birthCertificateSecondChild,
						files: result
					})
				}
			)
		}
		// birthCertificateThirdChild Input
		if (
			!!answers.birthCertificateThirdChild &&
			birthCertificateThirdChild.files.size == 0
		) {
			getFilesFromStore('birthCertificateThirdChildInput', answers).then(
				(result: Set<File>) => {
					setBirthCertificateThirdChild({
						...birthCertificateThirdChild,
						files: result
					})
				}
			)
		}
		// proofOfPurchase Input
		if (!!answers.proofOfPurchase && proofOfPurchase.files.size == 0) {
			getFilesFromStore('proofOfPurchaseInput', answers).then(
				(result: Set<File>) => {
					setProofOfPurchase({
						...proofOfPurchase,
						files: result
					})
				}
			)
		}
	}, [])

	useEffect(() => {
		const situationList = pageUtils.addLabelToList(
			pageUtils.situationsListAndValues,
			pageAssets
		)

		// since a string is stored as the value of tranining and experience,
		// we need to convert it to a list of objects to match the format the checkbox inputs
		const situationListAnswer = pageUtils.generateCheckboxListFromAnswer(
			pageUtils.situationsListAndValues,
			answers?.situation,
			pageAssets
		)

		const situationFormattedString =
			generateCleanStringFromAnswer(situationListAnswer)

		setSituationString({
			...situationString,
			value: situationFormattedString
		})
		setSituationList(
			situationListAnswer.length > 0 && !errors.includes(situation.name)
				? situationListAnswer
				: situationList
		)

		setShowAddThirdChildButton(hasSecondChild)

		setShowAddFourthChildButton(hasThirdChild)
	}, [answers])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_sustainableHygiene_beneficiaryInfo}
							</h4>
						</div>

						<div>
							<strong>{pageAssets[grantForWhom.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(grantForWhomList, answers.grantForWhom)}
							</span>
						</div>

						{answers.grantForWhom ==
							parseInt(
								REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption
							) && (
							<>
								<div>
									<strong>{pageAssets[situation.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{pageUtils
											.getListfromString(answers.situation)
											?.map((answer, index) => {
												return (
													<p
														className={pageUtils.classes.answer}
														key={`${answer}-${index}-situation`}
													>
														{
															pageAssets[
																pageUtils.situationsListAndValues.find(
																	(list) => list.value === answer
																)?.key!
															]
														}
													</p>
												)
											})}
									</span>
									<span className={pageUtils.classes.answer}>
										{getLabelFromList(situationList, answers?.situation)}
									</span>
								</div>

								{!generateCleanStringFromAnswer(situationList).includes(
									REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
								) && (
									<>
										<div>
											<strong>{pageAssets[myselfOldGrant.labelKey]}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{getLabelFromList(
													radioListYesNo,
													answers?.myselfOldGrant
												)}
											</span>
										</div>

										<RequestFilesWithMultipleButtons
											input={proofOfResidency}
											hasError={errors.includes(proofOfResidency.name)}
											setInput={setProofOfResidency}
											numberOfFiles={2}
											onSetHasError={onSetErrorFromFileComponent}
											onFixError={onFixError}
											UpdateRequestInputsErrorsArray={
												UpdateRequestInputsErrorsArray
											}
											errors={errors}
											steps={steps!}
											subSteps={subSteps!}
											formPosition={formPosition!}
											buttonLabels={
												pageAssets?.request_from_residence_proof_number
											}
											isSummary
										>
											<></>
										</RequestFilesWithMultipleButtons>
									</>
								)}
								{generateCleanStringFromAnswer(situationList).includes(
									REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
								) && (
									<>
										<RequestFilesWithMultipleButtons
											input={proofOfResidencyChild}
											hasError={errors.includes(proofOfResidencyChild.name)}
											setInput={setProofOfResidencyChild}
											numberOfFiles={2}
											onSetHasError={onSetErrorFromFileComponent}
											onFixError={onFixError}
											UpdateRequestInputsErrorsArray={
												UpdateRequestInputsErrorsArray
											}
											errors={errors}
											steps={steps!}
											subSteps={subSteps!}
											formPosition={formPosition!}
											buttonLabels={
												pageAssets?.request_from_residence_proof_number
											}
											isSummary
										>
											<></>
										</RequestFilesWithMultipleButtons>

										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_sustainableHygiene_dependentChild_label
											}
										</h5>

										<span className={classes.childHeader}>
											{
												<Text
													content={
														pageAssets?.request_form_sustainableHygiene_firstChild_label
													}
												/>
											}
										</span>

										<div>
											<strong>
												{pageAssets[dependentFirstChildFirstName.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers.dependentFirstChildFirstName}
											</span>
										</div>

										<div>
											<strong>
												{pageAssets[dependentFirstChildLastName.labelKey]}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers.dependentFirstChildLastName}
											</span>
										</div>

										<div>
											<strong>{pageAssets[childFirstOldGrant.labelKey]}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{getLabelFromList(
													radioListYesNo,
													answers?.childFirstOldGrant
												)}
											</span>
										</div>

										<RequestFiles
											subtitle={birthCertificateFirstChild.label}
											name={birthCertificateFirstChild.name}
											required={birthCertificateFirstChild.required}
											files={birthCertificateFirstChild.files}
											hasError={errors.includes(
												birthCertificateFirstChild.name
											)}
											removeSectionMarginTop
											removeSectionPaddingTop
											subSteps={subSteps}
											onSetHasError={onSetErrorFromFileComponent}
											onSetFiles={onSelectBirthCertificateFirstChild}
											maxFiles={1}
											isSummary
										>
											<></>
										</RequestFiles>

										{answers.dependentSecondChildFirstName && (
											<>
												<span className={classes.childHeader}>
													{
														<Text
															content={
																pageAssets?.request_form_sustainableHygiene_secondChild_label
															}
														/>
													}
												</span>

												<div>
													<strong>
														{pageAssets[dependentSecondChildFirstName.labelKey]}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers.dependentSecondChildFirstName}
													</span>
												</div>

												<div>
													<strong>
														{pageAssets[dependentSecondChildLastName.labelKey]}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers.dependentSecondChildLastName}
													</span>
												</div>

												<div>
													<strong>
														{pageAssets[childSecondOldGrant.labelKey]}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{getLabelFromList(
															radioListYesNo,
															answers?.childSecondOldGrant
														)}
													</span>
												</div>

												<RequestFiles
													subtitle={birthCertificateSecondChild.label}
													name={birthCertificateSecondChild.name}
													required={birthCertificateSecondChild.required}
													files={birthCertificateSecondChild.files}
													hasError={errors.includes(
														birthCertificateSecondChild.name
													)}
													removeSectionMarginTop
													removeSectionPaddingTop
													subSteps={subSteps}
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectBirthCertificateSecondChild}
													maxFiles={1}
													isSummary
												>
													<></>
												</RequestFiles>
											</>
										)}
										{answers.dependentThirdChildFirstName && (
											<>
												<span className={classes.childHeader}>
													{
														<Text
															content={
																pageAssets?.request_form_sustainableHygiene_thirdChild_label
															}
														/>
													}
												</span>

												<div>
													<strong>
														{pageAssets[dependentThirdChildFirstName.labelKey]}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers.dependentThirdChildFirstName}
													</span>
												</div>

												<div>
													<strong>
														{pageAssets[dependentThirdChildLastName.labelKey]}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{answers.dependentThirdChildLastName}
													</span>
												</div>

												<div>
													<strong>
														{pageAssets[childThirdOldGrant.labelKey]}
													</strong>
													<br />
													<span className={pageUtils.classes.answer}>
														{getLabelFromList(
															radioListYesNo,
															answers?.childThirdOldGrant
														)}
													</span>
												</div>

												<RequestFiles
													subtitle={birthCertificateThirdChild.label}
													name={birthCertificateThirdChild.name}
													required={birthCertificateThirdChild.required}
													files={birthCertificateThirdChild.files}
													hasError={errors.includes(
														birthCertificateThirdChild.name
													)}
													removeSectionMarginTop
													removeSectionPaddingTop
													subSteps={subSteps}
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={onSelectBirthCertificateThirdChild}
													maxFiles={1}
													isSummary
												>
													<></>
												</RequestFiles>
											</>
										)}
									</>
								)}
							</>
						)}

						{answers.grantForWhom ==
							parseInt(
								REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption
							) && (
							<>
								<div>
									<strong>{pageAssets[beneficiaryFirstName.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.beneficiaryFirstName}
									</span>
								</div>

								<div>
									<strong>{pageAssets[beneficiaryLastName.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.beneficiaryLastName}
									</span>
								</div>

								<div>
									<strong>{pageAssets[beneficiaryAddress.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.beneficiaryAddress}
									</span>
								</div>
								{answers.beneficiaryAppartment && (
									<div>
										<strong>{pageAssets?.apartment_label}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers.beneficiaryAppartment}
										</span>
									</div>
								)}

								<div>
									<strong>{pageAssets[beneficiaryOldGrant.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{getLabelFromList(
											radioListYesNo,
											answers?.beneficiaryOldGrant
										)}
									</span>
								</div>

								<RequestFilesWithMultipleButtons
									input={proofOfResidency}
									hasError={errors.includes(proofOfResidency.name)}
									setInput={setProofOfResidency}
									numberOfFiles={2}
									onSetHasError={onSetErrorFromFileComponent}
									onFixError={onFixError}
									UpdateRequestInputsErrorsArray={
										UpdateRequestInputsErrorsArray
									}
									errors={errors}
									steps={steps!}
									subSteps={subSteps!}
									formPosition={formPosition!}
									buttonLabels={pageAssets?.request_from_residence_proof_number}
									isSummary
								>
									<></>
								</RequestFilesWithMultipleButtons>
							</>
						)}
					</>
				)}
				{subSteps?.details_2 && (
					<div>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_sustainableHygiene_buyingProducts}
						</h4>

						<div>
							<strong>{pageAssets[buyingProducts.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(productCategoryList, answers.buyingProducts)}
							</span>
						</div>

						<div>
							<strong>{pageAssets[totalAmount.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.totalAmount}
							</span>
						</div>

						<RequestFiles
							subtitle={proofOfPurchase.label}
							name={proofOfPurchase.name}
							required={proofOfPurchase.required}
							files={proofOfPurchase.files}
							hasError={errors.includes(proofOfPurchase.name)}
							removeSectionMarginTop
							removeSectionPaddingTop
							subSteps={subSteps}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectProofOfPurchase}
							maxFiles={1}
							isSummary
						>
							<></>
						</RequestFiles>
					</div>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_sustainableHygiene_beneficiaryInfo}
						</h4>

						<div className={pageUtils.classes.radioLabel}>
							<RadioInput
								name={grantForWhom.name}
								label={grantForWhom.label}
								value={grantForWhom.value}
								list={grantForWhomList}
								onChange={onSelectGrantForWhom}
								hasError={errors.includes(grantForWhom.name)}
								required
								ref={grantForWhom.ref as RefObject<HTMLInputElement>}
								hasBackgroundWrapper={true}
							/>
						</div>

						{/* Pour moi-même et/ou un enfant à charge */}
						{grantForWhom.value ===
							REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.firstOption && (
							<>
								<div className={classes.radioWrapper}>
									<CheckboxInput
										name={situation.name}
										label={situation.label}
										value={`${situation.value}`}
										list={situationList}
										onChange={onChangeSituation}
										required={situation.required}
										hasError={errors.includes(situation.name)}
										ref={situation.ref as RefObject<HTMLInputElement>}
										setList={setSituationList}
										setInput={setSituation}
										errors={errors}
										onFixError={onFixError}
										hasBackgroundWrapper={true}
										shouldNotDivide
									/>
								</div>
								{/* Pour moi-même */}
								{generateCleanStringFromAnswer(situationList).includes(
									REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.firstOption
								) && (
									<>
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.select_form_sustainableHygiene_situation_firstOption
											}
										</h5>
										<div className={classes.radioWrapper}>
											<RadioInput
												name={myselfOldGrant.name}
												label={myselfOldGrant.label}
												value={myselfOldGrant.value}
												list={radioListYesNo}
												onChange={onSelectMyselfOldGrant}
												hasError={errors.includes(myselfOldGrant.name)}
												required={true}
												ref={myselfOldGrant.ref as RefObject<HTMLInputElement>}
												hasBackgroundWrapper
											/>
										</div>
										{myselfOldGrant.value === radioListYesNo[0].value && (
											<Notification
												text={
													pageAssets?.request_form_sustainableHygiene_oldGrant_notification
												}
												type="warning"
												hasHtml
											/>
										)}
										{myselfOldGrant.value === radioListYesNo[1].value &&
											generateCleanStringFromAnswer(situationList).includes(
												REQUEST_CONFIGS_KEYS.sustainableHygieneSituation
													.secondOption
											) === false && (
												<RequestFilesWithMultipleButtons
													input={proofOfResidency}
													hasError={errors.includes(proofOfResidency.name)}
													setInput={setProofOfResidency}
													numberOfFiles={2}
													onSetHasError={onSetErrorFromFileComponent}
													onFixError={onFixError}
													UpdateRequestInputsErrorsArray={
														UpdateRequestInputsErrorsArray
													}
													errors={errors}
													steps={steps!}
													subSteps={subSteps!}
													formPosition={formPosition!}
													buttonLabels={
														pageAssets?.request_from_residence_proof_number
													}
													description={proofOfResidency.description}
												></RequestFilesWithMultipleButtons>
											)}
									</>
								)}
								{/* Pour un ou des enfant(s) à charge */}
								{generateCleanStringFromAnswer(situationList).includes(
									REQUEST_CONFIGS_KEYS.sustainableHygieneSituation.secondOption
								) && (
									<>
										<RequestFilesWithMultipleButtons
											input={proofOfResidencyChild}
											hasError={errors.includes(proofOfResidencyChild.name)}
											setInput={setProofOfResidencyChild}
											numberOfFiles={2}
											onSetHasError={onSetErrorFromFileComponent}
											onFixError={onFixError}
											UpdateRequestInputsErrorsArray={
												UpdateRequestInputsErrorsArray
											}
											errors={errors}
											steps={steps!}
											subSteps={subSteps!}
											formPosition={formPosition!}
											buttonLabels={
												pageAssets?.request_from_residence_proof_number
											}
											description={proofOfResidencyChild.description}
										></RequestFilesWithMultipleButtons>

										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_sustainableHygiene_dependentChild_label
											}
										</h5>

										<div className={classes.buttonContainer}>
											<span className={classes.childHeader}>
												{
													<Text
														content={
															pageAssets?.request_form_sustainableHygiene_firstChild_label
														}
													/>
												}
											</span>
										</div>

										<div className={pageUtils.classes.fieldsContainer}>
											<TextInput
												id={dependentFirstChildFirstName.name}
												label={dependentFirstChildFirstName.label}
												value={dependentFirstChildFirstName.value}
												onChange={onSelectDependentFirstChildFirstName}
												hasError={errors.includes(
													dependentFirstChildFirstName.name
												)}
												required
												ref={
													dependentFirstChildFirstName.ref as RefObject<HTMLInputElement>
												}
												classWrapper={pageUtils?.classes.textFieldContainer}
											/>
											<TextInput
												id={dependentFirstChildLastName.name}
												label={dependentFirstChildLastName.label}
												value={dependentFirstChildLastName.value}
												onChange={onSelectDependentFirstChildLastName}
												hasError={errors.includes(
													dependentFirstChildLastName.name
												)}
												required
												ref={
													dependentFirstChildLastName.ref as RefObject<HTMLInputElement>
												}
												classWrapper={pageUtils?.classes.textFieldContainer}
											/>
										</div>
										<div className={classes.radioWrapper}>
											<RadioInput
												name={childFirstOldGrant.name}
												label={childFirstOldGrant.label}
												value={childFirstOldGrant.value}
												list={radioListYesNo}
												onChange={onSelectChildFirstOldGrant}
												hasError={errors.includes(childFirstOldGrant.name)}
												required
												ref={
													childFirstOldGrant.ref as RefObject<HTMLInputElement>
												}
												hasBackgroundWrapper={true}
											/>
										</div>
										{childFirstOldGrant.value === radioListYesNo[0].value && (
											<>
												<Notification
													text={
														pageAssets?.request_form_sustainableHygiene_oldGrant_notification
													}
													type="warning"
													hasHtml
												/>
											</>
										)}
										<RequestFiles
											subtitle={birthCertificateFirstChild.label}
											name={birthCertificateFirstChild.name}
											required={birthCertificateFirstChild.required}
											files={birthCertificateFirstChild.files}
											hasError={errors.includes(
												birthCertificateFirstChild.name
											)}
											removeSectionMarginTop
											subSteps={subSteps}
											onSetHasError={onSetErrorFromFileComponent}
											onSetFiles={onSelectBirthCertificateFirstChild}
											maxFiles={1}
										></RequestFiles>

										{/* Bouton ajout du 2e enfant */}
										{!showAddThirdChildButton && (
											<a
												style={{
													marginBottom: showAddSecondChildButton ? '12px' : ''
												}}
												onClick={() => showChild(CHILD.SECOND)}
												className={classes.button}
											>
												{
													pageAssets?.request_form_sustainableHygiene_addChild_button_label
												}
											</a>
										)}

										<Collapse isOpened={showAddThirdChildButton}>
											<div
												style={{
													marginBottom: showAddFourthChildButton ? '12px' : ''
												}}
												className={classes.buttonContainer}
											>
												<span className={classes.childHeader}>
													{
														<Text
															content={
																pageAssets?.request_form_sustainableHygiene_secondChild_label
															}
														/>
													}
												</span>
												<a
													onClick={() => clearChild(CHILD.SECOND)}
													className={joinClasses([
														classes.button,
														classes.outlinedButton
													])}
												>
													{
														pageAssets?.request_form_sustainableHygiene_removeChild_button_label
													}
												</a>
											</div>
											<div className={pageUtils.classes.fieldsContainer}>
												<TextInput
													id={dependentSecondChildFirstName.name}
													label={dependentSecondChildFirstName.label}
													value={dependentSecondChildFirstName.value}
													onChange={onSelectDependentSecondChildFirstName}
													hasError={errors.includes(
														dependentSecondChildFirstName.name
													)}
													required
													ref={
														dependentSecondChildFirstName.ref as RefObject<HTMLInputElement>
													}
													classWrapper={pageUtils?.classes.textFieldContainer}
												/>
												<TextInput
													id={dependentSecondChildLastName.name}
													label={dependentSecondChildLastName.label}
													value={dependentSecondChildLastName.value}
													onChange={onSelectDependentSecondChildLastName}
													hasError={errors.includes(
														dependentSecondChildLastName.name
													)}
													required
													ref={
														dependentSecondChildLastName.ref as RefObject<HTMLInputElement>
													}
													classWrapper={pageUtils?.classes.textFieldContainer}
												/>
											</div>
											<div className={classes.radioWrapper}>
												<RadioInput
													name={childSecondOldGrant.name}
													label={childSecondOldGrant.label}
													value={childSecondOldGrant.value}
													list={radioListYesNo}
													onChange={onSelectChildSecondOldGrant}
													hasError={errors.includes(childSecondOldGrant.name)}
													required
													ref={
														childSecondOldGrant.ref as RefObject<HTMLInputElement>
													}
													hasBackgroundWrapper={true}
												/>
											</div>
											{childSecondOldGrant.value ===
												radioListYesNo[0].value && (
												<>
													<Notification
														text={
															pageAssets?.request_form_sustainableHygiene_oldGrant_notification
														}
														type="warning"
														hasHtml
													/>
												</>
											)}
											<RequestFiles
												subtitle={birthCertificateSecondChild.label}
												name={birthCertificateSecondChild.name}
												required={birthCertificateSecondChild.required}
												files={birthCertificateSecondChild.files}
												hasError={errors.includes(
													birthCertificateSecondChild.name
												)}
												removeSectionMarginTop
												subSteps={subSteps}
												onSetHasError={onSetErrorFromFileComponent}
												onSetFiles={onSelectBirthCertificateSecondChild}
												maxFiles={1}
											></RequestFiles>
										</Collapse>

										{/* Bouton ajout du 3e enfant */}
										{showAddThirdChildButton && !showAddFourthChildButton && (
											<a
												style={{
													marginBottom: showAddThirdChildButton ? '12px' : ''
												}}
												onClick={() => showChild(CHILD.THIRD)}
												className={classes.button}
											>
												{
													pageAssets?.request_form_sustainableHygiene_addChild_button_label
												}
											</a>
										)}

										<Collapse isOpened={showAddFourthChildButton}>
											<div
												style={{
													marginBottom: showAddFourthChildButton ? '12px' : ''
												}}
												className={classes.buttonContainer}
											>
												<span className={classes.childHeader}>
													{
														<Text
															content={
																pageAssets?.request_form_sustainableHygiene_thirdChild_label
															}
														/>
													}
												</span>
												<a
													onClick={() => clearChild(CHILD.THIRD)}
													className={joinClasses([
														classes.button,
														classes.outlinedButton
													])}
												>
													{
														pageAssets?.request_form_sustainableHygiene_removeChild_button_label
													}
												</a>
											</div>
											<div className={pageUtils.classes.fieldsContainer}>
												<TextInput
													id={dependentThirdChildFirstName.name}
													label={dependentThirdChildFirstName.label}
													value={dependentThirdChildFirstName.value}
													onChange={onSelectDependentThirdChildFirstName}
													hasError={errors.includes(
														dependentThirdChildFirstName.name
													)}
													required
													ref={
														dependentThirdChildFirstName.ref as RefObject<HTMLInputElement>
													}
													classWrapper={pageUtils?.classes.textFieldContainer}
												/>
												<TextInput
													id={dependentThirdChildLastName.name}
													label={dependentThirdChildLastName.label}
													value={dependentThirdChildLastName.value}
													onChange={onSelectDependentThirdChildLastName}
													hasError={errors.includes(
														dependentThirdChildLastName.name
													)}
													required
													ref={
														dependentThirdChildLastName.ref as RefObject<HTMLInputElement>
													}
													classWrapper={pageUtils?.classes.textFieldContainer}
												/>
											</div>
											<div className={classes.radioWrapper}>
												<RadioInput
													name={childThirdOldGrant.name}
													label={childThirdOldGrant.label}
													value={childThirdOldGrant.value}
													list={radioListYesNo}
													onChange={onSelectChildThirdOldGrant}
													hasError={errors.includes(childThirdOldGrant.name)}
													required
													ref={
														childThirdOldGrant.ref as RefObject<HTMLInputElement>
													}
													hasBackgroundWrapper={true}
												/>
											</div>
											{childThirdOldGrant.value === radioListYesNo[0].value && (
												<>
													<Notification
														text={
															pageAssets?.request_form_sustainableHygiene_oldGrant_notification
														}
														type="warning"
														hasHtml
													/>
												</>
											)}
											<RequestFiles
												subtitle={birthCertificateThirdChild.label}
												name={birthCertificateThirdChild.name}
												required={birthCertificateThirdChild.required}
												files={birthCertificateThirdChild.files}
												hasError={errors.includes(
													birthCertificateThirdChild.name
												)}
												removeSectionMarginTop
												subSteps={subSteps}
												onSetHasError={onSetErrorFromFileComponent}
												onSetFiles={onSelectBirthCertificateThirdChild}
												maxFiles={1}
											></RequestFiles>
										</Collapse>
									</>
								)}
							</>
						)}

						{/* Pour un(e) bénéficiaire */}
						{grantForWhom.value ===
							REQUEST_CONFIGS_KEYS.sustainableHygieneForWhom.secondOption && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_sustainableHygiene_beneficiary_label
									}
								</h5>

								<div className={pageUtils.classes.fieldsContainer}>
									<TextInput
										id={beneficiaryFirstName.name}
										label={beneficiaryFirstName.label}
										value={beneficiaryFirstName.value}
										onChange={onSelectBeneficiaryFirstName}
										hasError={errors.includes(beneficiaryFirstName.name)}
										required={beneficiaryFirstName.required}
										ref={
											beneficiaryFirstName.ref as RefObject<HTMLInputElement>
										}
										classWrapper={pageUtils?.classes.textFieldContainer}
									/>
									<TextInput
										id={beneficiaryLastName.name}
										label={beneficiaryLastName.label}
										value={beneficiaryLastName.value}
										onChange={onSelectBeneficiaryLastName}
										hasError={errors.includes(beneficiaryLastName.name)}
										required={beneficiaryLastName.required}
										ref={beneficiaryLastName.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
									/>
								</div>

								<MapForm
									addressLabel={beneficiaryAddress.label}
									addressRequired={beneficiaryAddress.required}
									displayAptInput
									onApartmentInputChange={onSelectBeneficiaryAppartmentInput}
									value={answers?.beneficiaryAddress}
									hasError={errors?.includes(beneficiaryAddress.name)}
									onAddressInputChange={onSelectBeneficiaryAddress}
									addressLabelNoWrapper
								/>
								<div style={{ marginTop: '20px' }}>
									<RadioInput
										name={beneficiaryOldGrant.name}
										label={beneficiaryOldGrant.label}
										value={beneficiaryOldGrant.value}
										list={radioListYesNo}
										onChange={onSelectBeneficiaryOldGrant}
										hasError={errors.includes(beneficiaryOldGrant.name)}
										required={beneficiaryOldGrant.required}
										ref={beneficiaryOldGrant.ref as RefObject<HTMLInputElement>}
										hasBackgroundWrapper={true}
									/>
								</div>
								{beneficiaryOldGrant.value === radioListYesNo[0].value && (
									<Notification
										text={
											pageAssets?.request_form_sustainableHygiene_oldGrant_notification
										}
										type="warning"
										hasHtml
									/>
								)}
								{beneficiaryOldGrant.value === radioListYesNo[1].value && (
									<RequestFilesWithMultipleButtons
										input={proofOfResidency}
										hasError={errors.includes(proofOfResidency.name)}
										setInput={setProofOfResidency}
										numberOfFiles={2}
										onSetHasError={onSetErrorFromFileComponent}
										onFixError={onFixError}
										UpdateRequestInputsErrorsArray={
											UpdateRequestInputsErrorsArray
										}
										errors={errors}
										steps={steps!}
										subSteps={subSteps!}
										formPosition={formPosition!}
										buttonLabels={
											pageAssets?.request_from_residence_proof_number
										}
										description={proofOfResidency.description}
									></RequestFilesWithMultipleButtons>
								)}
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
						//subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_sustainableHygiene_buyingProducts}
						</h4>

						<div className={classes.radioWrapper}>
							<RadioInput
								name={buyingProducts.name}
								label={buyingProducts.label}
								value={buyingProducts.value}
								list={productCategoryList}
								onChange={onSelectBuyingProducts}
								hasError={errors.includes(buyingProducts.name)}
								required
								ref={buyingProducts.ref as RefObject<HTMLInputElement>}
								hasBackgroundWrapper={true}
							/>
						</div>
						<div style={{ marginBottom: '35px' }}>
							<TextInput
								type="number"
								id={totalAmount.name}
								label={totalAmount.label}
								value={totalAmount.value}
								description={totalAmount.description}
								onChange={onSelectTotalAmount}
								hasError={errors.includes(totalAmount.name)}
								required
								ref={totalAmount.ref as RefObject<HTMLInputElement>}
								classWrapper={pageUtils?.classes.textFieldContainer}
								onKeyPress={(event) => {
									const charCode = event.which ? event.which : event.keyCode
									const char = String.fromCharCode(charCode)

									if (!/^\d$/.test(char)) {
										event.preventDefault()
									}
								}}
								isMoneyInput
								onBlur={() => {
									setSubscriptionAmountWarning(
										totalAmount.value !== '' && Number(totalAmount.value) < 100
									)
								}}
							/>
						</div>
						{/* {totalAmount.value !== "" && ( */}
						{subscriptionAmountWarning && (
							<Notification
								text={
									pageAssets?.request_form_sustainableHygiene_buyingProducts_totalAmount_notification
								}
								type="warning"
								hasHtml
							/>
						)}
						<RequestFiles
							subtitle={proofOfPurchase.label}
							name={proofOfPurchase.name}
							required
							files={proofOfPurchase.files}
							hasError={errors.includes(proofOfPurchase.name)}
							removeSectionMarginTop
							subSteps={subSteps}
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectProofOfPurchase}
							maxFiles={proofOfPurchase.numberMaxFiles}
						></RequestFiles>
					</section>
				</Collapse>
			)}
		</>
	)
}

export default SustainableHygieneProductsForm
