export const myCustomLocale = {
    // Months list by order
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    
    // Week days by order
    weekDays: [
      {
        name: 'Dimanche', // Used for accessibility 
        short: 'D', // Displayed at the top of days' rows
        isWeekend: true, // Is it a formal weekend or not?
      },
      {
        name: 'Lundi',
        short: 'L',
      },
      {
        name: 'Mardi',
        short: 'M',
      },
      {
        name: 'Mercredi',
        short: 'M',
      },
      {
        name: 'Jeudi',
        short: 'J',
      },
      {
        name: 'Vendredi',
        short: 'V',
      },
      {
        name: 'Samedi',
        short: 'S',
        isWeekend: true,
      },
    ],
    
    // Just play around with this number between 0 and 6
    weekStartingIndex: 0,
    
    // Return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },
    
    // Return a native JavaScript date here
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },
    
    // Return a number for date's month length
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },
    
    // Return a transformed digit according to your locale
    transformDigit(digit) {
      return digit;
    },
    
    // Texts in the date picker
    nextMonth: 'Mois suivant',
    previousMonth: 'Mois précédent',
    openMonthSelector: 'Ouvrir le sélecteur de mois',
    openYearSelector: 'Ouvrir le sélecteur d\'année',
    closeMonthSelector: 'Fermer le sélecteur de mois',
    closeYearSelector: 'Fermer le sélecteur d\'année',
    defaultPlaceholder: 'Sélectionner...',
    
    // For input range value
    from: 'De',
    to: 'À',
    
    
    // Used for input value when multiple dates are selected
    digitSeparator: ',',
    
    // If you provide -2, for example, the year will be 2-digit
    yearLetterSkip: 0,
    
    // Is your language RTL or LTR?
    isRtl: false,
  }