import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates } from '@services/models'

import CustomYearInput from './inputs/customYear.input'
import DateFilterInput from './inputs/datefilter.input'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import RequestFiles from '@components/ui/requestFiles'

const FireplaceGrantForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		language,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		ownerSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		rowSection: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},
		rowSection: {
			display: 'flex',
			alignItems: 'end',
			columnGap: '1%',
			'& > div': {
				marginBottom: '20px',
				width: '50%'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	//----------------------------------------------------------------
	//----------------------- DETAILS INPUTS -------------------------
	//----------------------------------------------------------------

	const [installationYearOldDeviceInput, setInstallationYearOldDeviceInput] =
		useState<RequestInputs>({
			name: 'installationYear',
			label: pageAssets?.request_form_fireplaceGrant_installationYear_label,
			labelKey: 'request_form_fireplaceGrant_installationYear_label',
			value: answers.installationYear || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const [oldDeviceMakeInput, setOldDeviceMakeInput] = useState<RequestInputs>({
		name: 'oldDeviceMake',
		label: pageAssets?.label_brand,
		labelKey: 'label_brand',
		value: answers.oldDeviceMake || '',
		required: true,
		description: formatStrapiText(
			pageAssets?.request_form_fireplaceGrant_oldDeviceMake_description_label
		),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [oldDeviceModelInput, setOldDeviceModelInput] = useState<RequestInputs>(
		{
			name: 'oldDeviceModel',
			label: pageAssets?.label_model,
			labelKey: 'label_model',
			value: answers.oldDeviceModel || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		}
	)

	const [installationDateNewDeviceInput, setInstallationDateNewDeviceInput] =
		useState<RequestInputs>({
			name: 'installationDate',
			label:
				pageAssets?.request_form_fireplaceGrant_installationDateNewDevice_label,
			labelKey: 'request_form_fireplaceGrant_installationDateNewDevice_label',
			value: answers.installationDate || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const [newDeviceMakeInput, setNewDeviceMakeInput] = useState<RequestInputs>({
		name: 'newDeviceMake',
		label: pageAssets?.label_brand,
		labelKey: 'label_brand',
		value: answers.newDeviceMake || '',
		required: false,
		description:
			pageAssets?.request_form_fireplaceGrant_newDeviceMake_description_label,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const [newDeviceModelInput, setNewDeviceModelInput] = useState<RequestInputs>(
		{
			name: 'newDeviceModel',
			label: pageAssets?.label_model,
			labelKey: 'label_model',
			value: answers.newDeviceModel || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		}
	)

	const [installationCostsInput, setInstallationCostsInput] =
		useState<RequestInputs>({
			name: 'installationCosts',
			label: pageAssets?.request_form_fireplaceGrant_installationCosts_label,
			labelKey: 'request_form_fireplaceGrant_installationCosts_label',
			value: `${answers.installationCosts}` || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const [
		buildingAddressSameHasApplicantInput,
		setBuildingAddressSameHasApplicantInput
	] = useState<RequestInputs>({
		name: 'buildingAddressSameHasApplicant',
		value: 'true',
		label: '',
		labelKey: '',
		required: false
	})

	const [buildingAddressInput, setBuildingAddressInput] =
		useState<RequestInputs>({
			name: 'buildingAddress',
			value: location.address,
			label: '',
			labelKey: '',
			required: false
		})

	const [buildingPostalCodeInput, setBuildingPostalCodeInput] =
		useState<RequestInputs>({
			name: 'buildingPostalCode',
			value: location.postalCode,
			label: '',
			labelKey: '',
			required: false
		})

	const [buildingCityInput, setBuildingCityInput] = useState<RequestInputs>({
		name: 'buildingCity',
		value: location.city,
		label: '',
		labelKey: '',
		required: false
	})

	const [buildingApartmentInput, setBuildingApartmentInput] =
		useState<RequestInputs>({
			name: 'buildingApartment',
			value: location.apartment,
			label: '',
			labelKey: '',
			required: false
		})

	// Même adresse que celle inscrite dans mes informations personnelles

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------
	const [recyclingProofInput, setRecyclingProofInput] =
		useState<RequestInputFiles>({
			name: 'recyclingProofInput',
			label: pageAssets?.request_form_fireplaceGrant_recyclingProof_label,
			labelKey: 'request_form_fireplaceGrant_recyclingProof_label',
			description:
				pageAssets?.request_form_fireplaceGrant_recyclingProof_description_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'recyclingProofInput')?.files || []
			),
			required: true,
			numberFilesRequired: 1,
			numberMaxFiles: 1,
			category: requestCategories.proofOfPurchase
		})

	const [purchaseProofInput, setPurchaseProofInput] =
		useState<RequestInputFiles>({
			name: 'purchaseProofInput',
			label: pageAssets?.request_form_fireplaceGrant_purchaseProof_label,
			labelKey: 'request_form_fireplaceGrant_purchaseProof_label',
			description:
				pageAssets?.request_form_fireplaceGrant_purchaseProof_description_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'purchaseProofInput')?.files || []
			),
			required: true,
			numberFilesRequired: 1,
			numberMaxFiles: 10,
			category: requestCategories.proofOfPurchase
		})

	const [oldDevicePictureInput, setOldDevicePictureInput] =
		useState<RequestInputFiles>({
			name: 'oldDevicePictureInput',
			label: pageAssets?.request_form_fireplaceGrant_oldDevicePicture_label,
			labelKey: 'request_form_fireplaceGrant_newDevicePicture_label',
			description:
				pageAssets?.request_form_fireplaceGrant_oldDevicePicture_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'oldDevicePictureInput')?.files ||
					[]
			),
			required: true,
			numberFilesRequired: 1,
			numberMaxFiles: 1,
			category: requestCategories.photographicEvidence
		})

	const [newDevicePictureInput, setNewDevicePictureInput] =
		useState<RequestInputFiles>({
			name: 'newDevicePictureInput',
			label: pageAssets?.request_form_fireplaceGrant_newDevicePicture_label,
			labelKey: 'request_form_fireplaceGrant_newDevicePicture_label',
			description:
				pageAssets?.request_form_fireplaceGrant_newDevicePicture_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'newDevicePictureInput')?.files ||
					[]
			),
			required: true,
			numberFilesRequired: 1,
			numberMaxFiles: 1,
			category: requestCategories.photographicEvidence
		})

	const [oldPlatePictureInput, setOldPlatePictureInput] =
		useState<RequestInputFiles>({
			name: 'oldPlatePictureInput',
			label: pageAssets?.request_form_fireplaceGrant_oldPlatePicture_label,
			labelKey: 'request_form_fireplaceGrant_oldPlatePicture_label',
			description:
				pageAssets?.request_form_fireplaceGrant_oldPlatePicture_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'oldPlatePictureInput')?.files ||
					[]
			),
			required: false,
			numberFilesRequired: 1,
			numberMaxFiles: 1,
			category: requestCategories.photographicEvidence
		})

	const [newPlatePictureInput, setNewPlatePictureInput] =
		useState<RequestInputFiles>({
			name: 'newPlatePictureInput',
			label: pageAssets?.request_form_fireplaceGrant_newPlatePicture_label,
			labelKey: 'request_form_fireplaceGrant_newPlatePicture_label',
			description:
				pageAssets?.request_form_fireplaceGrant_newPlatePicture_label,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'newPlatePictureInput')?.files ||
					[]
			),
			required: true,
			numberFilesRequired: 1,
			numberMaxFiles: 1,
			category: requestCategories.photographicEvidence
		})

	const [otherDocumentInput, setOtherDocumentInput] =
		useState<RequestInputFiles>({
			name: 'otherDocumentsInput',
			label: pageAssets?.request_form_temporaryUseOfPesticide_other_documents,
			labelKey: 'request_form_temporaryUseOfPesticide_other_documents',
			description:
				pageAssets?.request_form_hydraulicAndPressureTest_otherDocuments_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'otherDocumentsInput')?.files || []
			),
			required: false,
			numberFilesRequired: 1,
			numberMaxFiles: 10,
			category: requestCategories.other
		})
	// ------------------- DETAILS INPUTS SETTERS --------------------
	const onSelectInstallationYearOldDeviceInput = (value: string) => {
		setInstallationYearOldDeviceInput({
			...installationYearOldDeviceInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				installationYearOldDeviceInput.name
			)
		)
	}

	const onSelectOldDeviceMakeInput = (value: string) => {
		setOldDeviceMakeInput({
			...oldDeviceMakeInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, oldDeviceMakeInput.name))
	}

	const onSelectDeviceOldModelInput = (value: string) => {
		setOldDeviceModelInput({
			...oldDeviceModelInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, oldDeviceModelInput.name))
	}

	const onSelectInstallationDateNewDeviceInput = (value: string) => {
		setInstallationDateNewDeviceInput({
			...installationDateNewDeviceInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				installationDateNewDeviceInput.name
			)
		)
	}

	const onSelectNewDeviceMakeInput = (value: string) => {
		setNewDeviceMakeInput({
			...newDeviceMakeInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, newDeviceMakeInput.name))
	}

	const onSelectNewDeviceModelInput = (value: string) => {
		setNewDeviceModelInput({
			...newDeviceModelInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, newDeviceModelInput.name))
	}

	const onSelectInstallationCostsInput = (value: string) => {
		setInstallationCostsInput({
			...installationCostsInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, installationCostsInput.name)
		)
	}

	const onSelectRecyclingProofInput = (files: Set<File>) => {
		setRecyclingProofInput({
			...recyclingProofInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, recyclingProofInput.name))
	}

	const onSelectPurchaseProofInput = (files: Set<File>) => {
		setPurchaseProofInput({
			...purchaseProofInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, purchaseProofInput.name))
	}

	const onSelectOldDevicePictureInput = (files: Set<File>) => {
		setOldDevicePictureInput({
			...oldDevicePictureInput,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, oldDevicePictureInput.name)
		)
	}

	const onSelectNewDevicePictureInput = (files: Set<File>) => {
		setNewDevicePictureInput({
			...newDevicePictureInput,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, newDevicePictureInput.name)
		)
	}

	const onSelectOldPlatePictureInput = (files: Set<File>) => {
		setOldPlatePictureInput({
			...oldPlatePictureInput,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, oldPlatePictureInput.name)
		)
	}

	const onSelectNewPlatePictureInput = (files: Set<File>) => {
		setNewPlatePictureInput({
			...newPlatePictureInput,
			files
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, newPlatePictureInput.name)
		)
	}

	const onSelectOtherDocumentInput = (files: Set<File>) => {
		setOtherDocumentInput({
			...otherDocumentInput,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, otherDocumentInput.name))
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, installationYearOldDeviceInput)
		)
	}, [installationYearOldDeviceInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, oldDeviceMakeInput))
	}, [oldDeviceMakeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, oldDeviceModelInput))
	}, [oldDeviceModelInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, installationDateNewDeviceInput)
		)
	}, [installationDateNewDeviceInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, newDeviceMakeInput))
	}, [newDeviceMakeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, newDeviceModelInput))
	}, [newDeviceModelInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, installationCostsInput))
	}, [installationCostsInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, otherDocumentInput)
		)
	}, [otherDocumentInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, recyclingProofInput)
		)
	}, [recyclingProofInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, purchaseProofInput)
		)
	}, [purchaseProofInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, oldDevicePictureInput)
		)
	}, [oldDevicePictureInput])
	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, newDevicePictureInput)
		)
	}, [newDevicePictureInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, oldPlatePictureInput)
		)
	}, [oldPlatePictureInput])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, newPlatePictureInput)
		)
	}, [newPlatePictureInput])

	useEffect(() => {
		if (
			location.coordinates.latitude ==
				authUser?.profile?.address.coordinates?.latitude &&
			location.coordinates.longitude ==
				authUser?.profile?.address.coordinates?.longitude
		) {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'true'
			})
		} else {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'false'
			})
		}
	}, [location.coordinates])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingAddressSameHasApplicantInput)
		)
	}, [buildingAddressSameHasApplicantInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingAddressInput))
	}, [buildingAddressInput])

	useEffect(() => {
		setBuildingAddressInput((prevState) => ({
			...prevState,
			value: location.address
		}))
	}, [location.address])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingPostalCodeInput))
	}, [buildingPostalCodeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingCityInput))
	}, [buildingCityInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingApartmentInput))
	}, [buildingApartmentInput])

	useEffect(() => {
		if (!!answers.recyclingProof && recyclingProofInput.files.size == 0) {
			getFilesFromStore('recyclingProofInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...recyclingProofInput,
						files: result
					})
				}
			)
		}
		if (!!answers.purchaseProof && purchaseProofInput.files.size == 0) {
			getFilesFromStore('purchaseProofInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...purchaseProofInput,
						files: result
					})
				}
			)
		}
		if (!!answers.oldDevicePicture && oldDevicePictureInput.files.size == 0) {
			getFilesFromStore('oldDevicePictureInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...oldDevicePictureInput,
						files: result
					})
				}
			)
		}
		if (!!answers.newDevicePicture && newDevicePictureInput.files.size == 0) {
			getFilesFromStore('newDevicePictureInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...newDevicePictureInput,
						files: result
					})
				}
			)
		}
		if (!!answers.oldPlatePicture && oldPlatePictureInput.files.size == 0) {
			getFilesFromStore('oldPlatePictureInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...oldPlatePictureInput,
						files: result
					})
				}
			)
		}
		if (!!answers.newPlatePicture && newPlatePictureInput.files.size == 0) {
			getFilesFromStore('newPlatePictureInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...newPlatePictureInput,
						files: result
					})
				}
			)
		}

		// otherDocuments input
		if (!!answers.otherDocumentsInput && otherDocumentInput.files.size == 0) {
			getFilesFromStore('otherDocumentsInput', answers).then(
				(result: Set<File>) => {
					setOtherDocumentInput({
						...otherDocumentInput,
						files: result
					})
				}
			)
		}
	}, [])

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (!coordinates.validate()) {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	useEffect(() => {
		onSetSubSteps({
			map: true,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.MAP,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.MAP,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}
			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_fireplaceGrant_firstSubStep_title}
						</h4>
						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_fireplaceGrant_replaced_device_title}
						</h5>
						<div>
							<strong>
								{pageAssets[installationYearOldDeviceInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.installationYear}
							</span>
						</div>

						<div>
							<strong>{pageAssets[oldDeviceMakeInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.oldDeviceMake}
							</span>
						</div>

						{answers?.oldDeviceModel && (
							<div>
								<strong>{pageAssets[oldDeviceModelInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers.oldDeviceModel}
								</span>
							</div>
						)}

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_fireplaceGrant_new_device_title}
						</h5>
						<div>
							<strong>
								{pageAssets[installationDateNewDeviceInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.installationDate}
							</span>
						</div>
						{answers.newDeviceMake && (
							<div>
								<strong>{pageAssets[newDeviceMakeInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers.newDeviceMake}
								</span>
							</div>
						)}

						{answers.newDeviceModel && (
							<div>
								<strong>{pageAssets[newDeviceModelInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers.newDeviceModel}
								</span>
							</div>
						)}

						<div>
							<strong>{pageAssets[installationCostsInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers.installationCosts}
							</span>
						</div>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<RequestFiles
							subtitle={recyclingProofInput.label}
							name={recyclingProofInput.name}
							required={recyclingProofInput.required}
							files={recyclingProofInput.files}
							hasError={errors.includes(recyclingProofInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectRecyclingProofInput}
							subSteps={subSteps}
							maxFiles={recyclingProofInput.numberMaxFiles}
							description={recyclingProofInput.description}
							isSummary
						></RequestFiles>

						<RequestFiles
							subtitle={purchaseProofInput.label}
							name={purchaseProofInput.name}
							required={purchaseProofInput.required}
							files={purchaseProofInput.files}
							hasError={errors.includes(purchaseProofInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectPurchaseProofInput}
							subSteps={subSteps}
							maxFiles={purchaseProofInput.numberMaxFiles}
							description={purchaseProofInput.description}
							isSummary
						></RequestFiles>

						<RequestFiles
							subtitle={oldDevicePictureInput.label}
							name={oldDevicePictureInput.name}
							required={oldDevicePictureInput.required}
							files={oldDevicePictureInput.files}
							hasError={errors.includes(oldDevicePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOldDevicePictureInput}
							subSteps={subSteps}
							maxFiles={oldDevicePictureInput.numberMaxFiles}
							isSummary
						></RequestFiles>

						<RequestFiles
							subtitle={newDevicePictureInput.label}
							name={newDevicePictureInput.name}
							required={newDevicePictureInput.required}
							files={newDevicePictureInput.files}
							hasError={errors.includes(newDevicePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectNewDevicePictureInput}
							subSteps={subSteps}
							maxFiles={newDevicePictureInput.numberMaxFiles}
							isSummary
						></RequestFiles>

						<RequestFiles
							subtitle={oldPlatePictureInput.label}
							name={oldPlatePictureInput.name}
							required={oldPlatePictureInput.required}
							files={oldPlatePictureInput.files}
							hasError={errors.includes(oldPlatePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOldPlatePictureInput}
							subSteps={subSteps}
							maxFiles={oldPlatePictureInput.numberMaxFiles}
							isSummary
						></RequestFiles>

						<RequestFiles
							subtitle={newPlatePictureInput.label}
							name={newPlatePictureInput.name}
							required={newPlatePictureInput.required}
							files={newPlatePictureInput.files}
							hasError={errors.includes(newPlatePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectNewPlatePictureInput}
							subSteps={subSteps}
							maxFiles={newPlatePictureInput.numberMaxFiles}
							isSummary
						></RequestFiles>

						<RequestFiles
							subtitle={otherDocumentInput.label}
							name={otherDocumentInput.name}
							required={otherDocumentInput.required}
							files={otherDocumentInput.files}
							hasError={errors.includes(otherDocumentInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOtherDocumentInput}
							subSteps={subSteps}
							maxFiles={otherDocumentInput.numberMaxFiles}
							description={otherDocumentInput.description}
							isSummary
						></RequestFiles>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_fireplaceGrant_firstSubStep_title}
						</h4>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_fireplaceGrant_replaced_device_title}
						</h5>

						<CustomYearInput
							id={installationYearOldDeviceInput.name}
							label={installationYearOldDeviceInput.label}
							value={installationYearOldDeviceInput.value}
							onChange={onSelectInstallationYearOldDeviceInput}
							hasError={errors.includes(installationYearOldDeviceInput.name)}
							required={installationYearOldDeviceInput.required}
							dateFormat="yyyy"
							minDate={new Date('1801')}
							maxDate={new Date()}
						/>

						<div className={classes.rowSection}>
							<TextInput
								id={oldDeviceMakeInput.name}
								label={oldDeviceMakeInput.label}
								value={oldDeviceMakeInput.value}
								hasError={errors?.includes(oldDeviceMakeInput.name)}
								required={oldDeviceMakeInput.required}
								description={oldDeviceMakeInput.description}
								onChange={onSelectOldDeviceMakeInput}
							/>
							<TextInput
								id={oldDeviceModelInput.name}
								label={oldDeviceModelInput.label}
								value={oldDeviceModelInput.value}
								hasError={errors?.includes(oldDeviceModelInput.name)}
								required={oldDeviceModelInput.required}
								onChange={onSelectDeviceOldModelInput}
							/>
						</div>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_fireplaceGrant_new_device_title}
						</h5>
						<DateFilterInput
							id={installationDateNewDeviceInput.name}
							label={installationDateNewDeviceInput.label}
							value={installationDateNewDeviceInput.value}
							onChange={onSelectInstallationDateNewDeviceInput}
							hasError={errors.includes(installationDateNewDeviceInput.name)}
							required={installationDateNewDeviceInput.required}
							dateFormat="dd/MM/yyyy"
							minDate={new Date('2024')}
							shouldDisplayCustomHeader
						/>

						<div className={classes.rowSection}>
							<TextInput
								id={newDeviceMakeInput.name}
								label={newDeviceMakeInput.label}
								value={newDeviceMakeInput.value}
								hasError={errors?.includes(newDeviceMakeInput.name)}
								required={newDeviceMakeInput.required}
								onChange={onSelectNewDeviceMakeInput}
							/>
							<TextInput
								id={newDeviceModelInput.name}
								label={newDeviceModelInput.label}
								value={newDeviceModelInput.value}
								hasError={errors?.includes(newDeviceModelInput.name)}
								required={newDeviceModelInput.required}
								onChange={onSelectNewDeviceModelInput}
							/>
						</div>

						<TextInput
							id={installationCostsInput.name}
							type="number"
							label={installationCostsInput.label}
							value={installationCostsInput.value}
							hasError={errors?.includes(installationCostsInput.name)}
							required={installationCostsInput.required}
							onChange={onSelectInstallationCostsInput}
							isMoneyInput
						/>
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
					checkTimeout={40}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>
						<RequestFiles
							subtitle={recyclingProofInput.label}
							name={recyclingProofInput.name}
							required={recyclingProofInput.required}
							files={recyclingProofInput.files}
							hasError={errors.includes(recyclingProofInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectRecyclingProofInput}
							subSteps={subSteps}
							maxFiles={recyclingProofInput.numberMaxFiles}
							description={recyclingProofInput.description}
						></RequestFiles>

						<RequestFiles
							subtitle={purchaseProofInput.label}
							name={purchaseProofInput.name}
							required={purchaseProofInput.required}
							files={purchaseProofInput.files}
							hasError={errors.includes(purchaseProofInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectPurchaseProofInput}
							subSteps={subSteps}
							maxFiles={purchaseProofInput.numberMaxFiles}
							description={purchaseProofInput.description}
						></RequestFiles>

						<RequestFiles
							subtitle={oldDevicePictureInput.label}
							name={oldDevicePictureInput.name}
							required={oldDevicePictureInput.required}
							files={oldDevicePictureInput.files}
							hasError={errors.includes(oldDevicePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOldDevicePictureInput}
							subSteps={subSteps}
							maxFiles={oldDevicePictureInput.numberMaxFiles}
						></RequestFiles>

						<RequestFiles
							subtitle={newDevicePictureInput.label}
							name={newDevicePictureInput.name}
							required={newDevicePictureInput.required}
							files={newDevicePictureInput.files}
							hasError={errors.includes(newDevicePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectNewDevicePictureInput}
							subSteps={subSteps}
							maxFiles={newDevicePictureInput.numberMaxFiles}
						></RequestFiles>

						<RequestFiles
							subtitle={newPlatePictureInput.label}
							name={newPlatePictureInput.name}
							required={newPlatePictureInput.required}
							files={newPlatePictureInput.files}
							hasError={errors.includes(newPlatePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectNewPlatePictureInput}
							subSteps={subSteps}
							maxFiles={newPlatePictureInput.numberMaxFiles}
						></RequestFiles>

						<RequestFiles
							subtitle={oldPlatePictureInput.label}
							name={oldPlatePictureInput.name}
							required={oldPlatePictureInput.required}
							files={oldPlatePictureInput.files}
							hasError={errors.includes(oldPlatePictureInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOldPlatePictureInput}
							subSteps={subSteps}
							maxFiles={oldPlatePictureInput.numberMaxFiles}
						></RequestFiles>

						<RequestFiles
							subtitle={otherDocumentInput.label}
							name={otherDocumentInput.name}
							required={otherDocumentInput.required}
							files={otherDocumentInput.files}
							hasError={errors.includes(otherDocumentInput.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectOtherDocumentInput}
							subSteps={subSteps}
							maxFiles={otherDocumentInput.numberMaxFiles}
							description={otherDocumentInput.description}
						></RequestFiles>
					</section>
				</Collapse>
			)}
		</>
	)
}

export default FireplaceGrantForm
