import axios, { AxiosError } from 'axios'
import { PUBLIC_HOLIDAYS_URL } from '@services/constants/holidays.constant'

export interface Holiday {
	id: number
	date: string
	nameEn: string
	nameFr: string
	federal: number
	observedDate: string
}

interface ProvinceData {
	id: string
	nameEn: string
	nameFr: string
	sourceLink: string
	sourceEn: string
	holidays: Holiday[]
	nextHoliday: Holiday
}

export interface ProvinceResponse {
	province: ProvinceData
}
export const fetchPublicHolidays = async (): Promise<ProvinceResponse> => {
	try {
		const rawData = await axios.get(PUBLIC_HOLIDAYS_URL)
		return rawData.data as ProvinceResponse
	} catch (error: unknown | Error | AxiosError) {
		if (axios.isAxiosError(error)) {
			throw new Error('Error while getting public holidays: ' + error.message)
		}
		throw error
	}
}
