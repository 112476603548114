import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Error from '@components/requestForms/inputs/error.input'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import fr from 'date-fns/locale/fr'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { calendar } from '@images/icons'
import Breakpoints from '@utils/breakpoints'

type Props = {
	id: string
	label?: string
	value: string
	required?: boolean
	hasError?: boolean
	onChange: (value: string) => void
	classWrapper?: string
	filterDate?: (value: Date) => boolean
	placeholderText?: string
	minDate?: Date
	maxDate?: Date
	dateFormat?: string
	description?: string
	disabled?: boolean
	customErrorText?: string
	onFocus?: React.FocusEventHandler<HTMLInputElement>
	disableErrorText?: boolean
	titleAndDescError?: boolean
	excludeDates?: Date[]
}

type Classes = {
	root: string
	select: string
	selectDisable: string
	error: string
	required: string
	contentRoot: string
	noWrap: string
	description: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '20px',
		'.react-datepicker-wrapper': {
			position: 'relative',
			width: '100%',

			'& input': {
				background: 'transparent !important',
				cursor: 'pointer',
				'&:disabled': {
					background: `${Colors.lightGrey4} !important`
				}
			},
			'& div': {
				zIndex: 2
			},
			'&::after': {
				background: `url(${calendar}) no-repeat center center`,
				backgroundSize: 'contain',
				width: '20px',
				height: '20px',
				content: '""',
				fontFamily: 'Arial',
				position: 'absolute',
				top: '50%',
				right: '12px',
				transform: 'translateY(-50%)',
				cursor: 'pointer',
				zIndex: 1
			}
		},
		'.react-datepicker-popper': {
			zIndex: 3,
			'.react-datepicker__year-wrapper': {
				maxWidth: '204px',
				[Breakpoints.maxWidth('md')]: {
					maxWidth: '150px'
				}
			},
			'.react-datepicker__triangle': {
				transform: 'translate3d(113px, 0px, 0px) !important',
				[Breakpoints.maxWidth('md')]: {
					transform: 'translate3d(85px, 0px, 0px) !important'
				}
			}
		}
	},
	select: {
		width: '100%',
		height: '43.6px',
		maxWidth: '100%',
		padding: '8px 12px',
		fontSize: '16px',
		fontWeight: '500',
		color: Colors.lightBlack,
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		'&:focus': {
			outline: 'none'
		}
	},
	selectDisable: {
		background: Colors.lightGrey4
	},
	error: {
		label: {
			color: Colors.errorRed
		},
		span: {
			color: Colors.errorRed
		},
		select: {
			borderColor: Colors.errorRed,
			'&:focus': {
				outline: 'none',
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		},
		input: {
			borderColor: Colors.errorRed,
			'&:focus': {
				borderColor: Colors.errorRed,
				boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
			}
		}
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0px 3px',
		padding: '0',
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		fontWeight: '400'
	}
})

const CustomYearInputForwardRef: ForwardRefRenderFunction<
	HTMLSelectElement,
	Props
> = (
	{
		id,
		label,
		hasError,
		required,
		value,
		onChange,
		classWrapper,
		filterDate,
		placeholderText,
		minDate,
		maxDate,
		dateFormat,
		description,
		disabled,
		customErrorText,
		onFocus,
		disableErrorText = false,
		titleAndDescError,
		excludeDates
	},
	ref
) => {
	const getLabel = () => {
		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={required ? classes.noWrap : ''}>?</span>
				</div>
			)
		} else {
			return <span className={required ? classes.required : ''}>{label}</span>
		}
	}

	const handleDateChanges = (date: Date | null) => {
		const value = date?.toISOString().match(/\d{4}/)! ?? ['']
		onChange(value[0])
	}

	const getDate = () => {
		if (value) {
			// I added ( + 'T12:00:00' ) to set the time portion of the date to noon,
			// To ensure the date is interpreted correctly in our local timezone
			// Our local timezone is behind UTC, so the date is interpreted as the previous day.
			return new Date(value + 'T12:00:00')
		}
		return null
	}

	const getPlaceholder = (): string => {
		if (placeholderText) return placeholderText

		const locale = moment.locale()
		if (locale.toLowerCase().includes('en')) return 'yyyy'
		return 'aaaa'
	}

	const locale: Locale = {
		...fr,
		options: {
			...fr.options,
			weekStartsOn: 0
		}
	}

	return (
		<div
			className={joinClasses([
				classes.root,
				hasError ? classes.error : '',
				classWrapper || ''
			])}
		>
			<label htmlFor={id}>
				<span className={titleAndDescError ? classes.error : ''}>
					{getLabel()}
					{description && (
						<span className={classes.description}>{description}</span>
					)}
				</span>
				{!disableErrorText && (
					<Error
						hasError={hasError}
						errorText={!!customErrorText ? customErrorText : undefined}
					/>
				)}
			</label>

			<DatePicker
				selected={getDate()}
				onChange={(date) => handleDateChanges(date)}
				filterDate={filterDate}
				placeholderText={getPlaceholder()}
				showYearPicker
				dateFormat={dateFormat}
				minDate={minDate}
				maxDate={maxDate}
			/>
		</div>
	)
}

const CustomYearInput = forwardRef(CustomYearInputForwardRef)

export default CustomYearInput
