import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	ParkingPermitFile,
	ParkingPermitInformation,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	Direction,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { AddressCoordinates } from '@services/models'
import 'moment-business-days'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import {
	getAddressByCoordinates,
	getVignetteByCoordinates
} from '@components/ui/addressForm/__index.utils'
import { capitalize, formatStrapiText, getFilesFromStore } from '@utils/methods'
import { ConcernFieldNames } from '@components/ui/concernedInfosSection/__index.utils'
import { licenceNumberImage, stationnementIcon } from '@images/icons'
import RequestFiles from '@components/ui/requestFiles'
import Text from '@components/ui/text'
import Notification from '@components/ui/notification'
import SelectInput from './inputs/select.inputs'
import Modal from '@components/ui/modal'
import ParkingPermitModal from '@components/ui/modals/parkingPermitModal'
import { ILocationDTO } from '@services/dtos/_miscellaneous.dto'

type Classes = {
	rowSection: string
	adresseBlock: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	checkboxWrapper: string
	fieldsContainer: string
	button: string
	description: string
	fileHelperSection: string
	fileContainer: string
	imgBlock: string
	ligneContainer: string
	closeIcon: string
	withoutLabel: string
	pesticideRowSection: string
	pesticideRowHolderName: string
	vehiculeRowSection: string
	customDateInput: string

	headerContainer: string
	contentWrapper: string
	removePadding: string
	card: string
	permitWrapper: string
	buttonWrapper: string
	permitHover: string
	btnPadding: string
	imageIcon: string
	modalWrapper: string
	centerText: string
	linkButton: string
	underlineOnHover: string
	gridDisplay: string
	outlinedButton: string
	noMarginBottom: string
}

const classes: Classes = makeClasses({
	adresseBlock: {
		border: `1px solid ${Colors.greyAlto}`,
		padding: '20px 25px',
		marginBottom: '10px'
	},
	checkbox2: {
		width: 'fit-content',
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		},

		'&.error-check': {
			color: `${Colors.red} !important`,
			':before': {
				border: `1px solid ${Colors.red}`
			}
		}
	},
	checkbox2Container: {
		'& input[value=true] ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '14px 12px'
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	ligneContainer: {
		display: 'none',
		[Breakpoints.maxWidth('sm')]: {
			display: 'flex'
		}
	},

	withoutLabel: {
		'& > label': {
			display: 'none',
			[Breakpoints.maxWidth('sm')]: {
				display: 'block'
			}
		}
	},
	customDateInput: {
		'& input': {
			padding: '7px 12px'
		}
	},

	button: {
		display: 'inline-block',
		padding: '10px 20px',
		fontSize: '13px',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: Colors.secondary,
		border: 'none',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2,
			color: Colors.white
		}
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2,
		marginBottom: '10px!important'
	},
	fileHelperSection: {
		background: Colors.darkWhite,
		padding: '30px',
		marginBottom: '15px'
	},
	fileContainer: {
		'.blue-bullets-list': {
			ul: {
				padding: '0px 15px 0px 20px'
			}
		}
	},
	imgBlock: {
		border: '1px solid #ddd',
		padding: '20px',
		textAlign: 'center',
		img: {
			maxWidth: '500px',
			width: '100%'
		}
	},

	// //////////////////
	headerContainer: {
		marginBottom: '10px',
		display: 'flex',
		gap: '20px',
		'& h5': {
			margin: `30px 0 15px 0`
		}
	},
	contentWrapper: {
		border: `1px solid ${Colors.grey}`,
		padding: '40px'
	},
	removePadding: {
		'& section': {
			padding: '0px !important',
			marginTop: '20px !important'
		}
	},
	card: {
		background: Colors.darkWhite,
		marginBottom: '20px',
		display: 'flex',
		justifyContent: 'space-between',
		// cursor: 'pointer',
		alignItems: 'center',
		border: `3px solid ${Colors.darkWhite}`,
		transition: '0.2s',
		padding: '36px 30px',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			padding: '20px',
			'& .css-r1u628 ': {
				marginLeft: '0px ',
				marginRight: '30px ',
				marginTop: '10px'
			}
		}
	},
	permitWrapper: {
		display: 'flex',
		alignItems: 'center',
		'& img': {
			marginRight: '10px'
		}
	},
	buttonWrapper: {
		display: 'flex',
		alignItems: 'center',
		[Breakpoints.maxWidth('lg')]: {
			'& button:nth-child(1)': {
				marginRight: '15px !important'
			},
			'& button:nth-child(2)': {
				marginRight: '0px !important'
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			flexDirection: 'column',
			alignItems: 'start'
		}
	},
	permitHover: {
		'&:hover': {
			border: `3px solid ${Colors.white}`,
			boxShadow: `0 0 15px ${Colors.blackTransparent15}`,
			transition: '0.2s'
		}
	},
	btnPadding: {
		padding: '5px 20px !important',
		height: '38px',
		fontSize: '14px'
	},
	imageIcon: {
		width: '45px',
		verticalAlign: 'middle'
	},
	modalWrapper: {
		'.css-q29xgf': {
			background: `${Colors.blackTransparent20} !important`
		}
	},
	centerText: {
		fontSize: '14px'
	},
	linkButton: {
		backgroundColor: 'transparent',
		border: `none`,
		color: `${Colors.secondary}`,
		'&:hover': {
			backgroundColor: 'transparent !important',
			border: `none !important`,
			color: `${Colors.secondary} !important`
		}
	},
	underlineOnHover: {
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	gridDisplay: {
		display: 'grid',
		gridTemplateColumns: '60%38%',
		alignItems: 'end',
		gap: '2%',
		[Breakpoints.maxWidth('lg')]: {
			gridTemplateColumns: '40%58%'
		},
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '1fr',
			'&:nth-child(2)': {
				gridRow: 2
			}
		},
		'& span': {
			marginBottom: '20px !important'
		}
	},
	outlinedButton: {
		background: 'none',
		border: `1px solid ${Colors.secondary}`,
		color: Colors.secondary,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	noMarginBottom: {
		'& .no-margin': {
			margin: '0px'
		}
	}
})

const YearRange = 75

function generateYearRange(): string[] {
	const currentYear = new Date().getFullYear()
	const endYear = currentYear - YearRange
	const yearRange: string[] = ['']

	for (let year = currentYear + 1; year >= endYear; year--) {
		yearRange.push(`${year}`)
	}
	return yearRange
}

const ParkingPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	isSummaryStep,
	steps,
	toPrint,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	filesInputs,
	onSetInputFiles,
	inputFiles,
	setDisableNextButton
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		UpdateRequestInputFilesArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const initialParkingInfo: ParkingPermitInformation = {
		display_information: true,
		vehicle_plate_number: '',
		vehicle_brand: '',
		vehicle_model: '',
		vehicle_year: '',
		// is_vehicle_owner: '',
		is_owner_same_as_vignette: '',
		renewal_sticker_number: '',
		renewal_sticker_owner_name: '',
		reason_for_replacement: '',
		reason_for_replacement_other: '',
		vehicle_owner: '',
		know_vignette_number: ''
	}
	const initialParkingInfoFile: ParkingPermitFile = {
		applicant_proof_of_recidence: [],
		vehicle_registration: [],
		proof_of_insurance: []
	}

	const [openDetailModal, setOpenDetailModal] = useState<boolean>(false)
	const [modalParkingInformation, setModalParkingInformation] =
		useState<ParkingPermitInformation>()
	const [currentAnimalIndex, setCurrentAnimalIndex] = useState<number>(0)
	const [zone, setZone] = useState<ILocationDTO[]>()
	const [duplicateFile, setDuplicateFiles] = useState<boolean>()

	// var moment = require('moment-business-days')
	//----------------------------------------------------------------
	//----------------------- INPUTS ---------------------------------
	//----------------------------------------------------------------

	const [isSameDeclarationAdresseInput, setIsSameDeclarationAdresseInput] =
		useState<RequestInputs>({
			name: 'sameAddressAsApplicant',
			label: pageAssets?.request_form_isSameAdresse_label,
			labelKey: 'request_form_isSameAdresse_label',
			value: answers.sameAddressAsApplicant?.toString() || 'false',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingRegistrationNumberInput, setBuildingRegistrationNumberInput] =
		useState<RequestInputs>({
			name: 'buildingRegistrationNumber',
			label: '',
			labelKey: '',
			value: answers.buildingRegistrationNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [demandAddressInput, setDemandAddressInput] = useState<RequestInputs>({
		name: 'demandAddress',
		label: '',
		labelKey: '',
		value: answers.demandAddress || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [demandAppartmentInput, setDemandAppartmentInput] =
		useState<RequestInputs>({
			name: 'demandAppartment',
			label: '',
			labelKey: '',
			value: answers.demandAppartment || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [showDeclarationAdresseBlock, setShowDeclarationAdresseBlock] =
		useState<boolean>(answers.sameAddressAsApplicant == true ? false : true)

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	// ---------------------------------- step 3.1 ------------------------ //

	const [specialPermitInput, setSpecialPermitInput] = useState<RequestInputs>({
		name: 'transactionReason',
		label: pageAssets?.label_what_do_you_want_to_do,
		labelKey: 'label_what_do_you_want_to_do',
		value: answers.transactionReason?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [replacingReason, setReplacingReason] = useState<RequestInputs>({
		name: 'replacingReason',
		label: pageAssets?.label_what_do_you_want_to_do,
		labelKey: 'label_what_do_you_want_to_do',
		value: answers.replacingReason || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [replacingReasonOther, setReplacingReasonOther] =
		useState<RequestInputs>({
			name: 'replacingReasonOther',
			label: pageAssets?.label_what_do_you_want_to_do,
			labelKey: 'label_what_do_you_want_to_do',
			value: answers.replacingReasonOther || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [parkingPermitList, setParkingPermitList] = useState<
		ParkingPermitInformation[]
	>(
		answers?.parkingPermitList
			? answers.parkingPermitList
			: [initialParkingInfo]
	)

	const [parkingPermitFileList, setParkingPermitFileList] = useState<
		ParkingPermitFile[]
	>(
		!!answers?.parkingPermitFileList
			? answers.parkingPermitFileList
			: [initialParkingInfoFile]
	)

	const [parkingPermitListInput, setParkingPermitListInput] =
		useState<RequestInputs>({
			name: 'parkingPermitList',
			label: pageAssets?.label_is_owner_of_vignette_owner_of_property,
			labelKey: 'label_is_owner_of_vignette_owner_of_property',
			value: JSON.stringify(answers.parkingPermitList || parkingPermitList),
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [parkingRequiredAttributesInput, setParkingRequiredAttributesInput] =
		useState<RequestInputs>({
			name: 'parkingRequiredAttributes',
			label: pageAssets?.label_is_owner_of_vignette_owner_of_property,
			labelKey: 'label_is_owner_of_vignette_owner_of_property',
			value: answers?.parkingRequiredAttributes || '[]',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [parkingPermitFileListInput, setParkingPermitFileListInput] = useState<
		RequestInputFiles<ParkingPermitFile[]>
	>({
		name: 'parkingPermitFileList',
		label: pageAssets?.request_form_parkingPermit_proof_of_insurance,
		labelKey: 'request_form_parkingPermit_proof_of_insurance',
		description: '',
		files: (filesInputs?.find((el) => el.name == 'parkingPermitFileList') || [
			initialParkingInfoFile
		]) as ParkingPermitFile[],
		required: false,
		// category: requestCategories.other
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	const [openRemoveParkingModal, setOpenRemoveParkingModal] =
		useState<boolean>(false)

	const radioListSpecialPermit: RadioList[] = [
		{
			label: pageAssets?.label_i_want_to_buy,
			value: REQUEST_CONFIGS_KEYS.transactionReason.firstParking,
			key: 'label_i_want_to_buy'
		},
		{
			label: pageAssets?.label_i_want_to_renew_a_parking_permit,
			value: REQUEST_CONFIGS_KEYS.transactionReason.renewParking,
			key: 'label_i_want_to_renew_a_parking_permit'
		},
		{
			label: pageAssets?.label_I_want_to_replace_a_parking_permit,
			value: REQUEST_CONFIGS_KEYS.transactionReason.replaceMySticker,
			key: 'label_I_want_to_replace_a_parking_permit'
		}
	]

	const reasonForReplacementList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_parkingPermit_broken_or_destroyed,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.broken,
			key: 'request_form_parkingPermit_broken_or_destroyed'
		},
		{
			label: pageAssets?.request_form_parkingPermit_change_of_verhicle,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.changeOfVehicle,
			key: 'request_form_parkingPermit_change_of_verhicle'
		},
		{
			label: pageAssets?.request_form_parkingPermit_data_correction,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.dataCorrection,
			key: 'request_form_parkingPermit_data_correction'
		},
		{
			label: pageAssets?.request_form_parkingPermit_moving,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.moving,
			key: 'request_form_parkingPermit_moving'
		},
		{
			label:
				pageAssets?.request_form_parkingPermit_moving_and_changing_vehicles,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.movingVehicles,
			key: 'request_form_parkingPermit_moving_and_changing_vehicles'
		},
		{
			label: pageAssets?.request_form_parkingPermit_lost_vignette,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.lostthumbnail,
			key: 'request_form_parkingPermit_lost_vignette'
		},
		{
			label: pageAssets?.request_form_parkingPermit_stolen_vignette,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.stolenThumbnail,
			key: 'request_form_parkingPermit_stolen_vignette'
		},
		{
			label: pageAssets?.request_form_parkingPermit_other_reason,
			value: REQUEST_CONFIGS_KEYS.reasonForReplacement.otherReason,
			key: 'request_form_parkingPermit_other_reason'
		}
	]

	const onSelectIsSameDeclarationAdresseInput = (value) => {
		setIsSameDeclarationAdresseInput({
			...isSameDeclarationAdresseInput,
			value: `${value}`
		})

		if (value == true) {
			const coordinate = authUser?.profile?.address.coordinates
			setDisableNextButton && setDisableNextButton(false)

			getCadastralAndMatriculeNumber(coordinate!)
			if (zone) {
				setZone(undefined)
			}

			getVignetteZone(coordinate)
		} else {
			setDisableNextButton && setDisableNextButton(true)
			setZone(undefined)
		}

		setTimeout(() => {
			setShowDeclarationAdresseBlock(!value)
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(errors, isSameDeclarationAdresseInput.name)
		)
	}

	const onSetSpecialPermitInput = (value: string) => {
		setSpecialPermitInput({ ...specialPermitInput, value })

		// reset parking permit list
		setParkingPermitList([initialParkingInfo])

		setParkingPermitListInput({
			...parkingPermitListInput,
			value: JSON.stringify([initialParkingInfo])
		})

		// reset files
		setParkingPermitFileList([initialParkingInfoFile])

		setParkingPermitFileListInput({
			...parkingPermitFileListInput,
			files: [initialParkingInfoFile]
		})

		// Update required attributes
		updateRequiredAttributes(value, 0)

		// Clears all error messages
		onFixError([])
	}

	//----------------------------------------------------------------
	//------------------- USE EFFECTS --------------------------------
	//----------------------------------------------------------------

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isSameDeclarationAdresseInput))
	}, [isSameDeclarationAdresseInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, specialPermitInput))
	}, [specialPermitInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, replacingReason))
	}, [replacingReason])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, replacingReasonOther))
	}, [replacingReasonOther])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, demandAddressInput))
	}, [demandAddressInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, demandAppartmentInput))
	}, [demandAppartmentInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, parkingPermitListInput))
	}, [parkingPermitListInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs!, parkingRequiredAttributesInput)
		)
	}, [parkingRequiredAttributesInput])

	useEffect(() => {
		onSetInputFiles<ParkingPermitFile[]>(
			UpdateRequestInputFilesArray<ParkingPermitFile[]>(
				inputFiles,
				parkingPermitFileListInput
			)
		)
	}, [parkingPermitFileListInput])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
			]
		})
	}, [])

	useEffect(() => {
		async function processFiles() {
			let parkingFiles: ParkingPermitFile[] = []

			for (let i = 0; i < 4; i++) {
				let parkingFile = JSON.parse(JSON.stringify(initialParkingInfoFile))

				if (!!answers[`parkingPermitInfo_vehicle_registration${i}`]) {
					const result = await getFilesFromStore(
						`parkingPermitInfo_vehicle_registration${i}`,
						answers
					)
					parkingFile.vehicle_registration = [...result]
				}

				if (!!answers[`parkingPermitInfo_applicant_proof_of_recidence${i}`]) {
					const result = await getFilesFromStore(
						`parkingPermitInfo_applicant_proof_of_recidence${i}`,
						answers
					)

					parkingFile.applicant_proof_of_recidence = [...result]
				}

				if (!!answers[`parkingPermitInfo_proof_of_insurance${i}`]) {
					const result = await getFilesFromStore(
						`parkingPermitInfo_proof_of_insurance${i}`,
						answers
					)

					parkingFile.proof_of_insurance = [...result]
				}

				// Ajoutez animalFile à animalFiles uniquement si au moins une condition est vraie
				if (
					parkingFile.vehicle_registration.length > 0 ||
					parkingFile.vehicle_registration.length > 0 ||
					parkingFile.proof_of_insurance.length > 0
				) {
					parkingFiles.push(parkingFile)
				}
			}

			if (parkingFiles.length == 0) {
				parkingFiles = [initialParkingInfoFile]
			}

			setParkingPermitFileList(parkingFiles)
			setParkingPermitFileListInput({
				...parkingPermitFileListInput,
				files: parkingFiles
			})
		}

		processFiles()
	}, [answers])

	useEffect(() => {
		if (zone?.length == 0) {
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setDisableNextButton && setDisableNextButton(true)
		}
	}, [zone])
	//----------------------------------------------------------------
	//---------------- CONSTANTS AND FUNCTIONS -----------------------
	//----------------------------------------------------------------

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getVignetteZone = (coordinates) => {
		getVignetteByCoordinates(coordinates as any).then((res: ILocationDTO[]) => {
			const vigette = res
			if (vigette) {
				setZone(vigette)
			} else {
				setZone(undefined)
			}
		})
	}

	const getCadastralAndMatriculeNumber = (coordinates: AddressCoordinates) => {
		setDisableNextButton && setDisableNextButton(false)
		getVignetteZone(coordinates)

		getAddressByCoordinates(coordinates).then((res) => {
			const features = res?.data?.features?.[0]

			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: features.properties.cadastre
			})

			setBuildingRegistrationNumberInput({
				...buildingRegistrationNumberInput,
				value: features.properties.matricule
			})
		})
	}

	const duplicateFileInArray = (
		fileList: ParkingPermitFile[],
		currentInput: { row: number; attribute: string }
	) => {
		const { row, attribute } = currentInput
		const currentFile: File[] = fileList[row][`${attribute}`]
		let currentFileName
		currentFile.forEach((el) => (currentFileName = el.name))

		for (let index = 0; index < fileList.length; index++) {
			if (index !== row && attribute === 'vehicle_registration') {
				const element = fileList[index].vehicle_registration!
				let elementName
				element.forEach((el) => (elementName = el.name))
				if (elementName == currentFileName) {
					return true
				}
			}
		}

		return false
	}

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	// ----------------------------------------------------------------
	// ------------------- VehicleInformation -------------------------
	// ----------------------------------------------------------------
	////////////////////////////////////////////////////////////////
	const onSelectFileInput = (
		files: Set<File>,
		currentInput: { row: number; attribute: string }
	) => {
		const { row, attribute } = currentInput
		let fileList: ParkingPermitFile[] = parkingPermitFileList
		fileList[row][`${attribute}`] = files

		setDuplicateFiles(false)

		if (duplicateFileInArray(fileList, currentInput)) {
			errors.push(`parkingPermitInfo_vehicle_registration${row}`)
			fileList[row][`${attribute}`] = []
			setDuplicateFiles(true)
		}

		setParkingPermitFileList(fileList)

		setParkingPermitFileListInput({
			...parkingPermitFileListInput,
			files: fileList
		})

		onFixError(updateParkingPermitErrorsArray(fileList))
	}

	const onSetParkingPermitInput = (
		value: string | boolean,
		currentInput: { row: number; attribute: string }
	) => {
		const { row, attribute } = currentInput

		let itemList = JSON.parse(JSON.stringify(parkingPermitList))
		itemList[row][`${attribute}`] = value

		if (
			attribute == 'reason_for_replacement' &&
			value != reasonForReplacementList[8].value
		) {
			itemList[row][`reason_for_replacement_other`] = ''
		}

		setParkingPermitList(itemList)

		setParkingPermitListInput({
			...parkingPermitListInput,
			value: JSON.stringify(itemList)
		})

		// add or remove required attributes
		if (attribute == 'vehicle_owner' || attribute == 'is_vehicle_owner') {
			changeRequiredAttributes(
				value == radioListYesNo[1].value,
				`parkingPermitInfo_proof_of_insurance${row}`
			)
		}

		if (attribute == 'know_vignette_number') {
			changeRequiredAttributes(
				value != true,
				`parkingPermitInfo_renewal_sticker_number${row}`
			)
		}

		onFixError(updateParkingPermitErrorsArray(itemList))

		if (attribute == 'reason_for_replacement_other') {
			setReplacingReasonOther({
				...replacingReasonOther,
				value: value as string
			})
		}

		if (attribute == 'reason_for_replacement') {
			setReplacingReason({ ...replacingReason, value: value as string })
			const requiredFields = JSON.parse(parkingRequiredAttributesInput.value)
			if (value == reasonForReplacementList[8].value) {
				setParkingRequiredAttributesInput({
					...parkingRequiredAttributesInput,
					value: JSON.stringify([
						...requiredFields,
						'parkingPermitInfo_reason_for_replacement_other0'
					])
				})
			} else {
				setParkingRequiredAttributesInput({
					...parkingRequiredAttributesInput,
					value: JSON.stringify(
						requiredFields?.filter(
							(field) =>
								field != 'parkingPermitInfo_reason_for_replacement_other0'
						)
					)
				})
				onFixError(
					errors.filter(
						(err) => err != 'parkingPermitInfo_reason_for_replacement_other0'
					)
				)
			}
		}
	}

	const changeRequiredAttributes = (shouldAdd: boolean, value: string) => {
		// Adds or removes  a required attribute from "parkingRequiredAttributesInput"
		const requiredAttributes = JSON.parse(parkingRequiredAttributesInput.value)
		if (shouldAdd) {
			setParkingRequiredAttributesInput({
				...parkingRequiredAttributesInput,
				value: JSON.stringify([...requiredAttributes, value])
			})
		} else {
			const filteredAttributes = requiredAttributes?.filter(
				(attribute) => attribute !== value
			)
			setParkingRequiredAttributesInput({
				...parkingRequiredAttributesInput,
				value: JSON.stringify([...filteredAttributes])
			})
		}
	}

	const doesParkingInformationHasErrors = (row: number): boolean => {
		const listErrors: string[] = []
		const parkingInforList =
			row != undefined ? [parkingPermitList[row]] : parkingPermitList

		const parkingInforFileList =
			row != undefined ? [parkingPermitFileList[row]] : parkingPermitFileList

		let requiredAttr: string[] = JSON.parse(
			parkingRequiredAttributesInput.value
		)

		parkingInforList?.forEach((element) => {
			Object.keys(element)?.forEach((key) => {
				if (
					element[`${key}`] == '' &&
					requiredAttr.includes(`parkingPermitInfo_${key}${row}`)
				) {
					listErrors.push(`parkingPermitInfo_${key}${row}`)
				}
			})
		})

		parkingInforFileList?.forEach((element) => {
			Object.keys(element)?.forEach((key) => {
				if (
					(isSet(element[`${key}`]) && element[`${key}`]?.size == 0) ||
					(element[`${key}`]?.length == 0 &&
						requiredAttr.includes(`parkingPermitInfo_${key}${row}`))
				) {
					listErrors.push(`parkingPermitInfo_${key}${row}`)
				}
			})
		})

		// Verify if it has errors and dispatch an action to set the various error messages
		if (listErrors.length > 0) {
			onFixError(listErrors)
		}

		return listErrors.length > 0
	}

	const addParkingInformationRow = () => {
		if (doesParkingInformationHasErrors(parkingPermitList.length - 1)) return

		updateRequiredAttributes(
			radioListSpecialPermit[1].value,
			parkingPermitList.length
		)
		let itemList = JSON.parse(JSON.stringify(parkingPermitList))

		let newItem: ParkingPermitInformation = initialParkingInfo

		itemList = itemList?.map((item: ParkingPermitInformation) => {
			return {
				...item,
				display_information: false
			}
		})

		itemList.push(newItem)

		setParkingPermitList(itemList)

		setParkingPermitListInput({
			...parkingPermitListInput,
			value: JSON.stringify(itemList)
		})

		// add files
		// =================================================================
		let fileList: ParkingPermitFile[] = parkingPermitFileList
		let newFileItem: ParkingPermitFile = initialParkingInfoFile
		fileList.push(newFileItem)
		setParkingPermitFileList(fileList)

		setParkingPermitFileListInput({
			...parkingPermitFileListInput,
			files: fileList
		})

		// =================================================================

		onFixError(updateParkingPermitErrorsArray(itemList))
	}

	const updateRequiredAttributes = (key: string, row: number) => {
		switch (key) {
			case radioListSpecialPermit[0].value:
				setParkingRequiredAttributesInput({
					...parkingRequiredAttributesInput,
					value: JSON.stringify([
						'parkingPermitInfo_vehicle_plate_number0',
						'parkingPermitInfo_vehicle_model0',
						'parkingPermitInfo_vehicle_brand0',
						'parkingPermitInfo_vehicle_year0',
						'parkingPermitInfo_vehicle_owner0',
						'parkingPermitInfo_applicant_proof_of_recidence0',
						'parkingPermitInfo_vehicle_registration0'
					])
				})
				// updateAllErrors()
				break
			case radioListSpecialPermit[1].value:
				const requiredValues: string[] =
					row == 0 ? [] : JSON.parse(parkingRequiredAttributesInput.value)
				const uniqueValues = new Set([
					...requiredValues,
					`parkingPermitInfo_renewal_sticker_owner_name${row}`,
					`parkingPermitInfo_vehicle_plate_number${row}`,
					`parkingPermitInfo_vehicle_model${row}`,
					`parkingPermitInfo_vehicle_brand${row}`,
					`parkingPermitInfo_vehicle_year${row}`,
					`parkingPermitInfo_vehicle_owner${row}`,

					`parkingPermitInfo_applicant_proof_of_recidence${row}`,
					`parkingPermitInfo_vehicle_registration${row}`
				])

				setParkingRequiredAttributesInput({
					...parkingRequiredAttributesInput,
					value: JSON.stringify(Array.from(uniqueValues))
				})
				// updateAllErrors()
				break
			case radioListSpecialPermit[2].value:
				setParkingRequiredAttributesInput({
					...parkingRequiredAttributesInput,
					value: JSON.stringify([
						'parkingPermitInfo_reason_for_replacement0',
						'parkingPermitInfo_renewal_sticker_owner_name0',
						'parkingPermitInfo_vehicle_plate_number0',
						'parkingPermitInfo_vehicle_model0',
						'parkingPermitInfo_vehicle_brand0',
						'parkingPermitInfo_vehicle_year0',
						'parkingPermitInfo_vehicle_owner0'
					])
				})
				// updateAllErrors(0)
				break
			default:
				break
		}
	}

	const isSet = (value: any): boolean => {
		return value instanceof Set
	}

	const updateParkingPermitErrorsArray = (
		itemList: ParkingPermitInformation[] | ParkingPermitFile[]
	): string[] => {
		let errorArray: string[] = errors

		errors?.forEach((error) => {
			if (error.includes('parkingPermitInfo')) {
				itemList.forEach((parkingPermitInfo, row) => {
					if (
						parkingPermitInfo.reason_for_replacement &&
						parkingPermitInfo.reason_for_replacement != '' &&
						error == `parkingPermitInfo_reason_for_replacement${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_reason_for_replacement${row}`
						)
					}
					if (
						parkingPermitInfo.reason_for_replacement_other &&
						parkingPermitInfo.reason_for_replacement_other != '' &&
						error == `parkingPermitInfo_reason_for_replacement_other${row}`
					) {
						errorArray = errorArray.filter(
							(err) =>
								err != `parkingPermitInfo_reason_for_replacement_other${row}`
						)
					}
					if (
						parkingPermitInfo.renewal_sticker_number &&
						parkingPermitInfo.renewal_sticker_number != '' &&
						error == `parkingPermitInfo_renewal_sticker_number${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_renewal_sticker_number${row}`
						)
					}
					if (
						parkingPermitInfo.renewal_sticker_owner_name &&
						parkingPermitInfo.renewal_sticker_owner_name != '' &&
						error == `parkingPermitInfo_renewal_sticker_owner_name${row}`
					) {
						errorArray = errorArray.filter(
							(err) =>
								err != `parkingPermitInfo_renewal_sticker_owner_name${row}`
						)
					}
					if (
						parkingPermitInfo.vehicle_plate_number &&
						parkingPermitInfo.vehicle_plate_number != '' &&
						error == `parkingPermitInfo_vehicle_plate_number${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_vehicle_plate_number${row}`
						)
					}
					if (
						parkingPermitInfo.vehicle_model &&
						parkingPermitInfo.vehicle_model != '' &&
						error == `parkingPermitInfo_vehicle_model${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_vehicle_model${row}`
						)
					}
					if (
						parkingPermitInfo.vehicle_brand &&
						parkingPermitInfo.vehicle_brand != '' &&
						error == `parkingPermitInfo_vehicle_brand${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_vehicle_brand${row}`
						)
					}
					if (
						parkingPermitInfo.vehicle_year &&
						parkingPermitInfo.vehicle_year != '' &&
						error == `parkingPermitInfo_vehicle_year${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_vehicle_year${row}`
						)
					}
					// if (
					// 	parkingPermitInfo.is_vehicle_owner &&
					// 	parkingPermitInfo.is_vehicle_owner != '' &&
					// 	error == `parkingPermitInfo_is_vehicle_owner${row}`
					// ) {
					// 	errorArray = errorArray.filter(
					// 		(err) => err != `parkingPermitInfo_is_vehicle_owner${row}`
					// 	)
					// }
					if (
						parkingPermitInfo.vehicle_owner &&
						parkingPermitInfo.vehicle_owner != '' &&
						error == `parkingPermitInfo_vehicle_owner${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_vehicle_owner${row}`
						)
					}

					// Files
					if (
						isSet(parkingPermitInfo.applicant_proof_of_recidence) &&
						error == `parkingPermitInfo_applicant_proof_of_recidence${row}`
					) {
						errorArray = errorArray.filter(
							(err) =>
								err != `parkingPermitInfo_applicant_proof_of_recidence${row}`
						)
					}
					if (
						isSet(parkingPermitInfo.vehicle_registration) &&
						error == `parkingPermitInfo_vehicle_registration${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_vehicle_registration${row}`
						)
					}
					if (
						isSet(parkingPermitInfo.proof_of_insurance) &&
						error == `parkingPermitInfo_proof_of_insurance${row}`
					) {
						errorArray = errorArray.filter(
							(err) => err != `parkingPermitInfo_proof_of_insurance${row}`
						)
					}
				})
			} else {
				errorArray.push(error)
			}
		})

		return errorArray
	}

	const editParkingPermitInfo = (row: number, value: boolean) => {
		// changes the display_information value to `true or false` to collapse section or not
		let itemList = [...parkingPermitList]

		itemList = itemList?.map((item, index) => {
			if (index == row) {
				return {
					...item,
					display_information: value
				}
			}
			return item
		})

		setParkingPermitList(itemList)

		setParkingPermitListInput({
			...parkingPermitListInput,
			value: JSON.stringify(itemList)
		})
	}
	const closeEditedSection = (row: number, value: boolean) => {
		// validates current section before closing
		if (doesParkingInformationHasErrors(row)) return
		editParkingPermitInfo(row, value)
	}

	const removeParkingInformationRow = (row: number) => {
		let itemList = [...parkingPermitList]

		if (itemList.length <= 1) return
		itemList.splice(row, 1)

		setParkingPermitList(itemList)

		setParkingPermitListInput({
			...parkingPermitListInput,
			value: JSON.stringify(itemList)
		})

		// Remove file
		// =================================================================
		let fileList = [...parkingPermitFileList]
		fileList.splice(row, 1)
		setParkingPermitFileList(fileList)

		setParkingPermitFileListInput({
			...parkingPermitFileListInput,
			files: fileList
		})

		updateAllErrors(row)
	}

	const updateAllErrors = (row) => {
		onFixError(
			updateParkingPermitRowErrorsArray([
				`parkingPermitInfo_reason_for_replacement${row}`,
				`parkingPermitInfo_reason_for_replacement_other${row}`,
				`parkingPermitInfo_renewal_sticker_number${row}`,
				`parkingPermitInfo_renewal_sticker_owner_name${row}`,
				`parkingPermitInfo_vehicle_plate_number${row}`,
				`parkingPermitInfo_vehicle_model${row}`,
				`parkingPermitInfo_vehicle_brand${row}`,
				`parkingPermitInfo_vehicle_year${row}`,
				// `parkingPermitInfo_is_vehicle_owner${row}`,
				`parkingPermitInfo_vehicle_owner${row}`,

				// files
				`parkingPermitInfo_applicant_proof_of_recidence${row}`,
				`parkingPermitInfo_vehicle_registration${row}`,
				`parkingPermitInfo_proof_of_insurance${row}`
			])
		)
	}

	const updateParkingPermitRowErrorsArray = (
		inputNameList: string[]
	): string[] => {
		return errors.filter((err) => !inputNameList.includes(err))
	}

	const vignetteNumber = {
		0: pageAssets?.request_form_parkingPermit_second,
		1: pageAssets?.request_form_parkingPermit_third,
		2: pageAssets?.request_form_parkingPermit_fouth
	}

	const getSubtitle = (vignetteNumber: string): string => {
		return `${pageAssets?.label_information_about} ${vignetteNumber || ''} ${
			pageAssets?.label_vignette
		}`
	}

	const yearList = pageUtils.generateRadioList(generateYearRange())

	///////////////////// RENDERING ROW FUNCTION ///////////////////////

	const renderParkingPermitInfo = (
		parkingPermitInfo: ParkingPermitInformation,
		row: number
	) => {
		const shouldReplaceVignette =
			specialPermitInput.value == radioListSpecialPermit[2].value
		const requiredAttributes: string[] = JSON.parse(
			parkingRequiredAttributesInput.value
		)

		return (
			<div key={`permit-${row}`}>
				<div className={classes.headerContainer}>
					<h5 className={pageUtils.classes.h5}>
						{
							<>
								<Text
									content={
										specialPermitInput.value == radioListSpecialPermit[0].value
											? pageAssets?.label_Information_about_the_vehicle
											: specialPermitInput.value ==
											  radioListSpecialPermit[2].value
											? pageAssets?.label_Information_about_the_parking_permit
											: specialPermitInput.value ==
													radioListSpecialPermit[2].value &&
											  parkingPermitList.length <= 1
											? pageAssets?.label_Information_about_the_parking_permit
											: getSubtitle(vignetteNumber[row - 1])
									}
								/>
							</>
						}
					</h5>
					{row > 0 && parkingPermitInfo.display_information && (
						<a
							style={{ margin: '20px 0px 12px' }}
							onClick={() => removeParkingInformationRow(row)}
							className={joinClasses([
								classes.button,
								classes.outlinedButton,
								classes.btnPadding
							])}
						>
							{pageAssets?.label_remove_this_vignette}
						</a>
					)}
				</div>

				{/* Vignette grey Card */}
				{!parkingPermitInfo.display_information && (
					<div className={joinClasses([classes.card, classes.permitHover])}>
						<div className={classes.permitWrapper}>
							<img
								src={stationnementIcon}
								alt="parking-permit"
								className={classes.imageIcon}
							/>
							<div>
								<strong>
									{capitalize(pageAssets?.label_vignette)}{' '}
									{parkingPermitInfo?.renewal_sticker_number}
								</strong>
								<p style={{ marginTop: '5px' }}>
									{parkingPermitInfo?.vehicle_brand}{' '}
									{parkingPermitInfo?.vehicle_model}{' '}
									{parkingPermitInfo?.vehicle_year}
								</p>
							</div>
						</div>

						<div className={classes.buttonWrapper}>
							<>
								<button
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline,
										classes.btnPadding
									])}
									onClick={() => {
										editParkingPermitInfo(row, true)
									}}
									type="button"
								>
									{formatStrapiText(pageAssets?.label_edit_informations)}
								</button>

								{parkingPermitList.length > 1 && (
									<button
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											classes.btnPadding
										])}
										onClick={() => setOpenRemoveParkingModal(true)}
										type="button"
									>
										{formatStrapiText(pageAssets?.label_remove)}
									</button>
								)}
							</>
						</div>
					</div>
				)}

				{/* Permit form fields */}
				{parkingPermitInfo.display_information && (
					<div className={classes.contentWrapper}>
						{specialPermitInput.value == radioListSpecialPermit[2].value && (
							<>
								<SelectInput
									id={`parkingPermitInfo_reason_for_replacement${row}`}
									label={pageAssets?.label_what_is_the_reason_for_replacement}
									value={parkingPermitInfo.reason_for_replacement || ''}
									list={reasonForReplacementList}
									required={requiredAttributes?.includes(
										`parkingPermitInfo_reason_for_replacement${row}`
									)}
									hasError={errors.includes(
										`parkingPermitInfo_reason_for_replacement${row}`
									)}
									onChange={(e) =>
										onSetParkingPermitInput(e, {
											row,
											attribute: 'reason_for_replacement'
										})
									}
								/>

								<Collapse
									isOpened={
										parkingPermitList?.at(0)?.reason_for_replacement ==
										reasonForReplacementList[8].value
									}
								>
									<TextInput
										id={`parkingPermitInfo_reason_for_replacement_other${row}`}
										label={
											pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label
										}
										value={parkingPermitInfo.reason_for_replacement_other || ''}
										hasError={errors?.includes(
											`parkingPermitInfo_reason_for_replacement_other${row}`
										)}
										required={requiredAttributes?.includes(
											`parkingPermitInfo_reason_for_replacement_other${row}`
										)}
										onChange={(e) =>
											onSetParkingPermitInput(e, {
												row,
												attribute: 'reason_for_replacement_other'
											})
										}
									/>
								</Collapse>
							</>
						)}

						<div className={pageUtils.classes.fieldsContainer}>
							{specialPermitInput.value != radioListSpecialPermit[0].value && (
								<>
									<TextInput
										id={`parkingPermitInfo_renewal_sticker_number${row}`}
										label={pageAssets?.label_vignette_number}
										value={parkingPermitInfo.renewal_sticker_number || ''}
										// description={pageAssets?.label_vignette_number_description}
										maxCharacters={50}
										hasError={errors?.includes(
											`parkingPermitInfo_renewal_sticker_number${row}`
										)}
										required={requiredAttributes?.includes(
											`parkingPermitInfo_renewal_sticker_number${row}`
										)}
										onChange={(e) =>
											onSetParkingPermitInput(e, {
												row,
												attribute: 'renewal_sticker_number'
											})
										}
										showCheckbox={shouldReplaceVignette}
										checkboxId={`parkingPermitInfo_know_vignette_number${row}`}
										checkboxLable={
											pageAssets?.request_form_claimNotice_isDateAndTimeNotKnown_label
										}
										checkboxValue={`${parkingPermitInfo.know_vignette_number}`}
										onClickCheckbox={(e) =>
											onSetParkingPermitInput(e, {
												row,
												attribute: 'know_vignette_number'
											})
										}
									/>
									<TextInput
										id={`parkingPermitInfo_renewal_sticker_owner_name${row}`}
										label={pageAssets?.label_name_of_vignette_owner}
										value={parkingPermitInfo.renewal_sticker_owner_name || ''}
										maxCharacters={100}
										hasError={errors?.includes(
											`parkingPermitInfo_renewal_sticker_owner_name${row}`
										)}
										required={requiredAttributes?.includes(
											`parkingPermitInfo_renewal_sticker_owner_name${row}`
										)}
										onChange={(e) =>
											onSetParkingPermitInput(e, {
												row,
												attribute: 'renewal_sticker_owner_name'
											})
										}
									/>
								</>
							)}

							<TextInput
								id={`parkingPermitInfo_vehicle_plate_number${row}`}
								label={pageAssets?.label_license_plate_number}
								value={parkingPermitInfo.vehicle_plate_number || ''}
								maxCharacters={10}
								hasError={errors?.includes(
									`parkingPermitInfo_vehicle_plate_number${row}`
								)}
								required={requiredAttributes?.includes(
									`parkingPermitInfo_vehicle_plate_number${row}`
								)}
								onChange={(e) =>
									onSetParkingPermitInput(e, {
										row,
										attribute: 'vehicle_plate_number'
									})
								}
								toolTip
								tooltipMedia={licenceNumberImage}
								toolTipLabel={''}
							/>
							<TextInput
								id={`parkingPermitInfo_vehicle_brand${row}`}
								label={pageAssets?.label_brand}
								value={parkingPermitInfo.vehicle_brand || ''}
								maxCharacters={50}
								hasError={errors?.includes(
									`parkingPermitInfo_vehicle_brand${row}`
								)}
								required={requiredAttributes?.includes(
									`parkingPermitInfo_vehicle_brand${row}`
								)}
								onChange={(e) =>
									onSetParkingPermitInput(e, {
										row,
										attribute: 'vehicle_brand'
									})
								}
							/>
							<TextInput
								id={`parkingPermitInfo_vehicle_model${row}`}
								label={pageAssets?.label_model}
								value={parkingPermitInfo.vehicle_model || ''}
								maxCharacters={50}
								hasError={errors?.includes(
									`parkingPermitInfo_vehicle_model${row}`
								)}
								required={requiredAttributes?.includes(
									`parkingPermitInfo_vehicle_model${row}`
								)}
								onChange={(e) =>
									onSetParkingPermitInput(e, {
										row,
										attribute: 'vehicle_model'
									})
								}
							/>
							<SelectInput
								id={`parkingPermitInfo_vehicle_year${row}`}
								label={pageAssets?.label_year}
								value={parkingPermitInfo.vehicle_year || ''}
								list={yearList}
								hasError={errors?.includes(
									`parkingPermitInfo_vehicle_year${row}`
								)}
								required={requiredAttributes?.includes(
									`parkingPermitInfo_vehicle_year${row}`
								)}
								onChange={(e) =>
									onSetParkingPermitInput(e, {
										row,
										attribute: 'vehicle_year'
									})
								}
							/>
						</div>
						{/* ======================= acheter une première vignette de stationnement ======== */}
						{/* {specialPermitInput.value == radioListSpecialPermit[0].value && (
							<RadioInput
								name={`parkingPermitInfo_is_vehicle_owner${row}`}
								label={pageAssets?.request_form_is_car_owner}
								value={parkingPermitInfo.is_vehicle_owner || ''}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors!.includes(
									`parkingPermitInfo_is_vehicle_owner${row}`
								)}
								required={requiredAttributes?.includes(
									`parkingPermitInfo_is_vehicle_owner${row}`
								)}
								onChange={(e) =>
									onSetParkingPermitInput(e, {
										row,
										attribute: 'is_vehicle_owner'
									})
								}
								hasBackgroundWrapper={true}
							/>
						)} */}

						{/* {specialPermitInput.value != radioListSpecialPermit[0].value && ( */}
						<div className={classes.noMarginBottom}>
							<RadioInput
								name={`parkingPermitInfo_vehicle_owner${row}`}
								label={
									specialPermitInput.value == radioListSpecialPermit[0].value
										? pageAssets?.request_form_is_car_owner
										: pageAssets?.label_is_owner_of_vignette_owner_of_property
								}
								value={parkingPermitInfo.vehicle_owner?.toString() || ''}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors!.includes(
									`parkingPermitInfo_vehicle_owner${row}`
								)}
								required={requiredAttributes?.includes(
									`parkingPermitInfo_vehicle_owner${row}`
								)}
								onChange={(e) =>
									onSetParkingPermitInput(e, {
										row,
										attribute: 'vehicle_owner'
									})
								}
								hasBackgroundWrapper={true}
							/>
						</div>
						{/* )} */}

						{
							<div className={classes.removePadding}>
								{(specialPermitInput.value != radioListSpecialPermit[2].value ||
									(specialPermitInput.value ==
										radioListSpecialPermit[2].value &&
										parkingPermitInfo.vehicle_owner?.toString() ==
											radioListYesNo[1].value)) && (
									<h6 className={pageUtils.classes.h6}>
										{pageAssets?.label_justification_document}
									</h6>
								)}
								{specialPermitInput.value !=
									radioListSpecialPermit[2].value && (
									<RequestFiles
										subtitle={
											pageAssets?.request_form_parkingPermit_proof_of_recidence
										}
										name={`parkingPermitInfo_applicant_proof_of_recidence${row}`}
										files={
											new Set<File>(
												parkingPermitFileList[row]?.applicant_proof_of_recidence
											)
										}
										hasError={errors!.includes(
											`parkingPermitInfo_applicant_proof_of_recidence${row}`
										)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={(e) =>
											onSelectFileInput(e, {
												row,
												attribute: 'applicant_proof_of_recidence'
											})
										}
										required
										subSteps={subSteps}
										maxFiles={2}
										description={
											pageAssets?.request_form_parkingPermit_proof_of_recidence_description
										}
									></RequestFiles>
								)}

								{specialPermitInput.value !=
									radioListSpecialPermit[2].value && (
									<RequestFiles
										subtitle={pageAssets?.request_form_vehicle_registration}
										name={`parkingPermitInfo_vehicle_registration${row}`}
										files={
											new Set<File>(
												parkingPermitFileList[row]?.vehicle_registration
											)
										}
										hasError={errors.includes(
											`parkingPermitInfo_vehicle_registration${row}`
										)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={(e) =>
											onSelectFileInput(e, {
												row,
												attribute: 'vehicle_registration'
											})
										}
										required
										subSteps={subSteps}
										maxFiles={2}
										description={''}
										isDuplicate={duplicateFile}
									></RequestFiles>
								)}
								{
									// parkingPermitInfo.is_vehicle_owner ==
									// radioListYesNo[1].value ||
									parkingPermitInfo.vehicle_owner?.toString() ==
										radioListYesNo[1].value && (
										<RequestFiles
											subtitle={
												pageAssets?.request_form_parkingPermit_proof_of_insurance
											}
											name={`parkingPermitInfo_proof_of_insurance${row}`}
											files={
												new Set<File>(
													parkingPermitFileList[row]?.proof_of_insurance
												)
											}
											hasError={errors.includes(
												`parkingPermitInfo_proof_of_insurance${row}`
											)}
											removeSectionMarginTop
											onSetHasError={onSetErrorFromFileComponent}
											onSetFiles={(e) =>
												onSelectFileInput(e, {
													row,
													attribute: 'proof_of_insurance'
												})
											}
											required
											subSteps={subSteps}
											maxFiles={2}
											description={''}
										></RequestFiles>
									)
								}
							</div>
						}

						{specialPermitInput.value == radioListSpecialPermit[1].value && (
							<a
								style={{ margin: '20px 0px 0px' }}
								onClick={() => closeEditedSection(row, false)}
								className={joinClasses([
									classes.button,
									// classes.btnPadding,
									classes.centerText
								])}
							>
								{pageAssets?.button_complete}
							</a>
						)}
					</div>
				)}

				{/* Remove Modal */}
				<div className={classes.modalWrapper}>
					<Modal
						cancelButtonText={pageAssets?.button_cancel}
						primaryButtonText={pageAssets?.label_remove}
						isOpen={openRemoveParkingModal}
						onCancelBtn={() => {
							setOpenRemoveParkingModal(false)
						}}
						onPrimaryButton={() => {
							removeParkingInformationRow(row)
							setOpenRemoveParkingModal(false)
						}}
						onSetIsOpen={() => {}}
						onCloseModal={() => setOpenRemoveParkingModal(false)}
						alignBtn={'left'}
						title={pageAssets?.label_remove_vignette}
						showExitBtn
					>
						<div>{pageAssets?.label_remove_vignette_message}</div>
					</Modal>
				</div>
			</div>
		)
	}

	const getLabel = (list: RadioList[], value?: string) => {
		return list.find((element) => element.value == value)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_parkingPermit_address_of_request}
							</h4>
						</div>

						<div>
							<strong>{pageAssets?.request_form_isSameAdresse_label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.sameAddressAsApplicant
								)}
							</span>
						</div>

						<div>
							<strong>{pageAssets?.label_address}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.concernedAdresse ||
									authUser?.profile?.address.address}
							</span>
						</div>
						{(answers?.concernedAppartement ||
							(authUser?.profile?.address.apartment &&
								answers?.sameAddressAsApplicant?.toString() ==
									radioListYesNo[0].value)) && (
							<div>
								<strong>{`${formatStrapiText(
									pageAssets?.label_apartment_office
								)}`}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{!answers?.sameAddressAsApplicant
										? answers?.concernedAppartement
										: authUser?.profile?.address.apartment}
								</span>
							</div>
						)}
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.label_vehicle_information}
							</h4>
						</div>
						{/* ====================== ==================== */}
						<div>
							<strong>{pageAssets?.label_what_do_you_want_to_do}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									getLabel(radioListSpecialPermit, answers?.transactionReason)
										?.label
								}
							</span>
						</div>

						{answers?.transactionReason != radioListSpecialPermit[1].value && (
							<>
								{answers?.parkingPermitList?.at(0)?.reason_for_replacement && (
									<div>
										<strong>
											{pageAssets?.label_what_is_the_reason_for_replacement}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												getLabel(
													reasonForReplacementList,
													answers?.parkingPermitList?.at(0)
														?.reason_for_replacement
												)?.label
											}
										</span>
										{answers?.parkingPermitList?.at(0)
											?.reason_for_replacement_other && (
											<div>
												<strong>
													{
														pageAssets?.request_form_fireplaceDeclaration_otherFireplaceTypeToDeclare_label
													}
												</strong>
												<span className={pageUtils.classes.answer}>
													{
														answers?.parkingPermitList?.at(0)
															?.reason_for_replacement_other
													}
												</span>
											</div>
										)}
									</div>
								)}
								{answers?.parkingPermitList?.at(0)?.renewal_sticker_number && (
									<div>
										<strong>{pageAssets?.label_vignette_number}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												answers?.parkingPermitList?.at(0)
													?.renewal_sticker_number
											}
										</span>
									</div>
								)}

								{answers?.parkingPermitList?.at(0)
									?.renewal_sticker_owner_name && (
									<div>
										<strong>{pageAssets?.label_name_of_vignette_owner}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												answers?.parkingPermitList?.at(0)
													?.renewal_sticker_owner_name
											}
										</span>
									</div>
								)}

								<div>
									<strong>{pageAssets?.label_license_plate_number}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.parkingPermitList?.at(0)?.vehicle_plate_number}
									</span>
								</div>
								<div>
									<strong>{pageAssets?.label_brand}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.parkingPermitList?.at(0)?.vehicle_brand}
									</span>
								</div>
								<div>
									<strong>{pageAssets?.label_model}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.parkingPermitList?.at(0)?.vehicle_model}
									</span>
								</div>
								<div>
									<strong>{pageAssets?.label_year}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.parkingPermitList?.at(0)?.vehicle_year}
									</span>
								</div>

								{/* {answers?.parkingPermitList?.at(0)?.is_vehicle_owner && (
									<div>
										<strong>{pageAssets?.request_form_is_car_owner}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												getLabel(
													radioListYesNo,
													answers?.parkingPermitList?.at(0)?.is_vehicle_owner
												)?.label
											}
										</span>
									</div>
								)} */}

								{
									<div>
										<strong>
											{answers?.transactionReason ==
											radioListSpecialPermit[0].value
												? pageAssets?.request_form_is_car_owner
												: pageAssets?.label_is_owner_of_vignette_owner_of_property}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{
												getLabel(
													radioListYesNo,
													answers?.parkingPermitList
														?.at(0)
														?.vehicle_owner?.toString()
												)?.label
											}
										</span>
									</div>
								}

								{/* Documents */}
								{
									<>
										{(answers?.transactionReason !=
											radioListSpecialPermit[2].value ||
											(answers?.transactionReason ==
												radioListSpecialPermit[2].value &&
												answers?.parkingPermitList
													?.at(0)
													?.vehicle_owner?.toString() ==
													radioListYesNo[1].value)) && (
											<h6 className={pageUtils.classes.h6}>
												{pageAssets?.label_justification_document}
											</h6>
										)}

										{answers?.transactionReason !=
											radioListSpecialPermit[2].value && (
											<>
												<RequestFiles
													subtitle={
														pageAssets?.request_form_parkingPermit_proof_of_recidence
													}
													name={`parkingPermitInfo_applicant_proof_of_recidence${0}`}
													files={
														new Set<File>(
															parkingPermitFileList?.at(
																0
															)?.applicant_proof_of_recidence
														)
													}
													hasError={errors!.includes(
														`parkingPermitInfo_applicant_proof_of_recidence${0}`
													)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={() => {}}
													subSteps={subSteps}
													maxFiles={10}
													description={''}
													isSummary
												></RequestFiles>
												<RequestFiles
													subtitle={
														pageAssets?.request_form_vehicle_registration
													}
													name={`parkingPermitInfo_vehicle_registration${0}`}
													files={
														new Set<File>(
															parkingPermitFileList?.at(0)?.vehicle_registration
														)
													}
													hasError={errors.includes(
														`parkingPermitInfo_vehicle_registration${0}`
													)}
													removeSectionMarginTop
													onSetHasError={onSetErrorFromFileComponent}
													onSetFiles={(e) => {}}
													subSteps={subSteps}
													maxFiles={10}
													description={''}
													isSummary
												></RequestFiles>
											</>
										)}
									</>
								}

								{answers?.parkingPermitList?.at(0)?.vehicle_owner?.toString() ==
									radioListYesNo[1].value && (
									<RequestFiles
										subtitle={
											pageAssets?.request_form_parkingPermit_proof_of_insurance
										}
										name={`parkingPermitInfo_proof_of_insurance${0}`}
										files={
											new Set<File>(
												parkingPermitFileList?.at(0)?.proof_of_insurance
											)
										}
										hasError={errors.includes(
											`parkingPermitInfo_proof_of_insurance${0}`
										)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={(e) => {}}
										subSteps={subSteps}
										maxFiles={10}
										description={''}
										isSummary
									></RequestFiles>
								)}
							</>
						)}
						{answers?.transactionReason == radioListSpecialPermit[1].value && (
							<>
								{answers?.parkingPermitList?.map((parkingInformation, row) => (
									<>
										<div className={classes.headerContainer}>
											<h5 className={pageUtils.classes.h5}>
												{
													<Text
														content={
															specialPermitInput.value ==
																radioListSpecialPermit[1].value &&
															parkingPermitList.length > 1
																? getSubtitle(vignetteNumber[row - 1])
																: pageAssets?.label_Information_about_the_vehicle
														}
													/>
												}
											</h5>
										</div>
										<div className={classes.card}>
											<div className={classes.permitWrapper}>
												<img
													src={stationnementIcon}
													alt="animal"
													className={classes.imageIcon}
												/>
												<div>
													<strong>
														{capitalize(pageAssets?.label_vignette)}{' '}
														{parkingInformation?.renewal_sticker_number}
													</strong>
													<p style={{ marginTop: '5px' }}>
														{parkingInformation?.vehicle_brand}{' '}
														{parkingInformation?.vehicle_model}{' '}
														{parkingInformation?.vehicle_year}
													</p>
												</div>
											</div>

											<div className={classes.buttonWrapper}>
												<>
													<button
														className={joinClasses([
															pageUtils.classes.btn,
															pageUtils.classes.button,
															classes.linkButton,
															classes.underlineOnHover
														])}
														onClick={() => {
															setModalParkingInformation(parkingInformation)
															setOpenDetailModal(true)
															setCurrentAnimalIndex(row)
														}}
														type="button"
													>
														{formatStrapiText(pageAssets?.label_see_details)}
													</button>
												</>
											</div>
										</div>
									</>
								))}

								<ParkingPermitModal
									isModalOpen={openDetailModal}
									parkingInformation={modalParkingInformation}
									primaryButtonText={pageAssets?.button_complete}
									close={() => {
										setOpenDetailModal(false)
									}}
									onPrimaryButton={() => {
										setOpenDetailModal(false)
									}}
									files={parkingPermitFileList?.at(currentAnimalIndex)}
								/>
							</>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_parkingPermit_address_of_request}
						</h4>
						{authUser?.profile?.address.inMunicipality && (
							<div className={classes.checkbox2Container}>
								<span className={classes.checkboxWrapper}>
									<input
										onClick={(e: any) =>
											onSelectIsSameDeclarationAdresseInput(e.target.checked)
										}
										value={isSameDeclarationAdresseInput.value}
										className={classes.checkbox2}
										type="checkbox"
										name={isSameDeclarationAdresseInput.name}
										id={isSameDeclarationAdresseInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={isSameDeclarationAdresseInput.labelKey}
									>
										{pageAssets?.request_form_isSameAdresse_label}
									</label>
								</span>
							</div>
						)}

						<div style={{ marginBottom: '20px' }}>
							{showDeclarationAdresseBlock == true ? (
								<ConcernedInfosSection
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										`${isSameDeclarationAdresseInput.value}` == 'false'
									}
									requiredFields={[
										ConcernFieldNames.concernedAdresse,
										ConcernFieldNames.concernedAppartement
									]}
									onGetCadastralAndMatriculeNumber={
										getCadastralAndMatriculeNumber
									}
								/>
							) : (
								<div className={classes.adresseBlock}>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>
							)}
						</div>

						{zone && (
							<Notification
								type={zone?.length != 0 ? 'info' : 'warning'}
								text={
									zone?.length != 0
										? pageAssets?.request_form_parkingPermit_the_address_entered_is_located +
										  ` ${zone[0]?.place_name}`
										: pageAssets?.request_form_parkingPermit_the_address_entered_is_not_located
								}
							/>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
					checkTimeout={40}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.label_vehicle_information}
						</h4>

						<RadioInput
							name={specialPermitInput.name}
							label={specialPermitInput.label}
							value={specialPermitInput.value}
							list={radioListSpecialPermit}
							direction={Direction.vertical}
							hasError={errors!.includes(specialPermitInput.name)}
							onChange={onSetSpecialPermitInput}
							ref={specialPermitInput.ref as RefObject<HTMLInputElement>}
							required={specialPermitInput.required}
							hasBackgroundWrapper={true}
						/>

						{specialPermitInput.value && (
							<>
								{/* /////////////////  Animal Information List  //////////////// */}
								{parkingPermitList?.map((parkingPermitInfo, index) => {
									return renderParkingPermitInfo(parkingPermitInfo, index)
								})}
								{/* /////////////////  Add Button  //////////////// */}
								{specialPermitInput.value == radioListSpecialPermit[1].value &&
									parkingPermitList?.length < 4 && (
										<a
											style={{ margin: '20px 0px 12px' }}
											onClick={() => addParkingInformationRow()}
											className={joinClasses([
												classes.button
												// classes.btnPadding
											])}
										>
											{pageAssets?.label_add_vignette}
										</a>
									)}
							</>
						)}
					</section>
				</Collapse>
			)}
		</>
	)
}

export default ParkingPermitForm
