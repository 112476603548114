import React, {
	FC,
	RefObject,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories,
	Direction,
	REQUEST_CONFIGS_KEYS
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { formatStrapiText } from '@utils/methods'
import RadioInput from '@components/requestForms/inputs/radio.input'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import CheckboxInput from './inputs/checkbox.input'
import { CustomDate } from '@services/models'
import TextAreaInput from './inputs/textarea.inputs'
import RequiredIcon from '@components/ui/requiredIcon'
import Notification from '@components/ui/notification'
import DateFilterInput from './inputs/datefilter.input'
import SelectInput from './inputs/select.inputs'
import moment from 'moment'
import { usePublicHolidaysData } from '@services/hooks'
import axios from 'axios'

const HistoricalArchiveForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	isSummaryStep,
	steps,
	toPrint,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		pageAssets,
		language,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		coveredSearchPeriodContainer: string | undefined
		errorLabel: string
		rowSection: string | undefined
		button: string
		buttonContainer: string
		inputContainer: string
		dateAndTimeSection: string
		timeInputPadding: string
		timeInputMargin: string
		noWrapper: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		inputContainer: {
			marginBottom: '20px',
			display: 'block',
			width: '-webkit-fill-available'
		},
		dateAndTimeSection: {
			display: 'grid',
			alignItems: 'center',
			gridTemplateColumns: '27%27%',
			columnGap: '1%',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '49.5%49.5%'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '49.5%49.5%',
				fontSize: '15px'
			}
		},
		coveredSearchPeriodContainer: {
			display: 'block',
			width: '40%'
		},
		rowSection: {
			display: 'flex',
			alignItems: 'end',
			columnGap: '1%',
			'& > div': {
				marginBottom: '20px'
			}
		},
		errorLabel: {
			color: Colors.errorRed,
			'& span': {
				color: Colors.errorRed + ' !important'
			}
		},
		timeInputPadding: {
			'& label': {
				paddingBottom: '1.8rem'
			}
		},
		timeInputMargin: {
			[Breakpoints.widthBetween('md', 'lg')]: {
				marginTop: '19px'
			},
			[Breakpoints.maxWidth('sm')]: {
				marginTop: '19px'
			}
		},
		noWrapper: {
			'white-space': 'nowrap'
		}
	})

	const answers = useAppSelector((state) => state.request.configs)

	const [
		coveredSearchPeriodInputHasError,
		setCoveredSearchPeriodInputHasError
	] = useState<boolean>(false)

	//----------------------------------------------------------------
	//----------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	//****** isOrganizationRequest inputs *******

	const [isOrganizationRequestInput, setIsOrganizationRequestInput] =
		useState<RequestInputs>({
			name: 'isOrganizationRequest',
			label:
				pageAssets?.request_form_historicalArchive_isOrganizationRequest_label,
			labelKey: 'request_form_historicalArchive_isOrganizationRequest_label',
			value: answers.isOrganizationRequest?.toString() ?? '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [showTheOrganizationBlock, setShowTheOrganizationBlock] =
		useState<boolean>(
			answers?.isOrganizationRequest ??
				isOrganizationRequestInput.value == 'true'
		)

	const radioIsOrganizationRequest: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	//****** organization info inputs ******

	const [organizationNameInput, setOrganizationNameInput] =
		useState<RequestInputs>({
			name: 'organizationName',
			label: pageAssets?.request_form_historicalArchive_organizationName_label,
			labelKey: 'request_form_historicalArchive_organizationName_label',
			value: answers.organizationName ?? '',
			required: answers?.isOrganizationRequest ?? false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [applicantPurposeInput, setApplicantPurposeInput] =
		useState<RequestInputs>({
			name: 'applicantPurpose',
			label: pageAssets?.request_form_historicalArchive_applicantPurpose_label,
			labelKey: 'request_form_historicalArchive_applicantPurpose_label',
			value: answers.applicantPurpose ?? '',
			required: answers?.isOrganizationRequest ?? false,
			ref: useRef<HTMLSelectElement>(null)
		})

	//----------------------------------------------------------------
	//--------------- DETAILS 2 INPUTS ------------
	//----------------------------------------------------------------

	const [specifiedDocumentsInput, setSpecifiedDocumentsInput] =
		useState<RequestInputs>({
			name: 'specifiedDocuments',
			label:
				pageAssets?.request_form_historicalArchive_specifiedDocuments_label,
			labelKey: 'request_form_historicalArchive_specifiedDocuments_label',
			value: answers.specifiedDocuments ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [researchSubjectInput, setResearchSubjectInput] =
		useState<RequestInputs>({
			name: 'researchSubject',
			label: pageAssets?.request_form_historicalArchive_researchSubject_label,
			labelKey: 'request_form_historicalArchive_researchSubject_label',
			value: answers.researchSubject ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [concernedSectorInput, setConcernedSectorInput] =
		useState<RequestInputs>({
			name: 'concernedSector',
			label: pageAssets?.request_form_historicalArchive_concernedSector_label,
			labelKey: 'request_form_historicalArchive_concernedSector_label',
			value: answers.concernedSector ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [coveredSearchPeriodStartInput, setCoveredSearchPeriodStartInput] =
		useState<RequestInputs>({
			name: 'coveredSearchPeriodStart',
			label:
				pageAssets?.request_form_historicalArchive_coveredSearchPeriod_label,
			labelKey: 'request_form_historicalArchive_coveredSearchPeriod_label',
			value: answers?.coveredSearchPeriodStart ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			extraParams: {
				type: 'date',
				min: moment().subtract(2000, 'years').format('YYYY-MM-DD'),
				max: answers.coveredSearchPeriodEnd ?? '',
				customErrorText:
					pageAssets?.request_form_historicalArchive_coveredSearchPeriod_error_label
			}
		})

	const [coveredSearchPeriodEndInput, setCoveredSearchPeriodEndInput] =
		useState<RequestInputs>({
			name: 'coveredSearchPeriodEnd',
			label: '',
			labelKey: '',
			value: answers?.coveredSearchPeriodEnd ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			extraParams: {
				type: 'date',
				min: answers.coveredSearchPeriodStart ?? '',
				max: moment().add(2000, 'years').format('YYYY-MM-DD'),
				customErrorText:
					pageAssets?.request_form_historicalArchive_coveredSearchPeriod_error_label
			}
		})

	const [requiresTechnicalSpecsInput, setRequiresTechnicalSpecsInput] =
		useState<RequestInputs>({
			name: 'requiresTechnicalSpecs',
			label:
				pageAssets?.request_form_historicalArchive_requiresTechnicalSpecs_label,
			labelKey: 'request_form_historicalArchive_requiresTechnicalSpecs_label',
			value: answers.requiresTechnicalSpecs?.toString() ?? '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	const [showSpecifiedTechBlock, setShowSpecifiedTechBlock] = useState<boolean>(
		requiresTechnicalSpecsInput.value == 'true'
	)

	const radioRequiresTechnicalSpecs: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const [technicalSpecsInput, setTechnicalSpecsInput] = useState<RequestInputs>(
		{
			name: 'technicalSpecs',
			label: pageAssets?.request_form_historicalArchive_technicalSpecs_label,
			labelKey: 'request_form_historicalArchive_technicalSpecs_label',
			value: answers.technicalSpecs ?? '',
			required: answers.requiresTechnicalSpecs ?? false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	//----------------------------------------------------------------
	//--------------- DETAILS 3 INPUTS ------------
	//----------------------------------------------------------------

	const [isInPersonAppointmentInput, setIsInPersonAppointmentInput] =
		useState<RequestInputs>({
			name: 'isInPersonAppointment',
			label:
				pageAssets?.request_form_historicalArchive_isInPersonAppointment_label,
			labelKey: 'request_form_historicalArchive_isInPersonAppointment_label',
			value: answers.isInPersonAppointment?.toString() ?? '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const [showTheAppointmentBlock, setShowTheAppointmentBlock] =
		useState<boolean>(isInPersonAppointmentInput.value == 'true')

	const radioIsInPersonAppointment: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const [appointmentDateInput, setAppointmentDateInput] =
		useState<RequestInputs>({
			name: 'appointmentDate',
			label: pageAssets?.request_form_historicalArchive_appointmentDate_label,
			labelKey: 'request_form_historicalArchive_appointmentDate_label',
			value: answers.appointmentDate ?? '',
			required: answers.isInPersonAppointment ?? false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
			extraParams: {
				customErrorText:
					pageAssets?.request_form_oilHeatingDeclaration_installationDateInput_customTextError
			}
		})

	const [appointmentTimeInput, setAppointmentTimeInput] =
		useState<RequestInputs>({
			name: 'appointmentTime',
			label: pageAssets?.request_form_historicalArchive_appointmentTime_label,
			labelKey: 'request_form_historicalArchive_appointmentTime_label',
			value: answers.appointmentTime ?? '',
			required: answers.isInPersonAppointment ?? false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const appointmentTimeList: SelectList[] = pageUtils.generateTimeList(
		'08:30',
		'16:30',
		30
	)

	const [appointmentCommentInput, setAppointmentCommentInput] =
		useState<RequestInputs>({
			name: 'appointmentComment',
			label:
				pageAssets?.request_form_historicalArchive_appointmentComment_label,
			labelKey: 'request_form_historicalArchive_appointmentComment_label',
			value: answers.appointmentComment ?? '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	//----------------------------------------------------------------
	//--------------- DETAILS 4 INPUTS ------------
	//----------------------------------------------------------------

	const [areDocumentsDiffusedInput, setAreDocumentsDiffusedInput] =
		useState<RequestInputs>({
			name: 'areDocumentsDiffused',
			label:
				pageAssets?.request_form_historicalArchive_areDocumentsDiffused_label,
			labelKey: 'request_form_historicalArchive_areDocumentsDiffused_label',
			value: answers.areDocumentsDiffused?.toString() ?? '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			description:
				pageAssets?.request_form_historicalArchive_areDocumentsDiffused_description_label,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [showTheDiffusedBlock, setShowTheDiffusedBlock] = useState<boolean>(
		areDocumentsDiffusedInput.value == 'true'
	)

	const radioAreDocumentsDiffused: RadioList[] = [
		{
			label: pageAssets?.label_yes,
			value: 'true',
			key: 'label_yes'
		},
		{
			label: pageAssets?.label_no,
			value: 'false',
			key: 'label_no'
		}
	]

	const [
		isLicenceRecipientApplicantInput,
		setisLicenceRecipientApplicantInput
	] = useState<RequestInputs>({
		name: 'isLicenceRecipientApplicant',
		label:
			pageAssets?.request_form_historicalArchive_isLicenceRecipientApplicant_label,
		labelKey:
			'request_form_historicalArchive_isLicenceRecipientApplicant_label',
		value: answers.isLicenceRecipientApplicant?.toString() ?? '',
		required: answers.areDocumentsDiffused ?? false,
		ref: useRef<HTMLSelectElement>(null),
		description:
			pageAssets?.request_form_historicalArchive_isLicenceRecipientApplicant_description_label,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [showTheCoApplicantBlock, setShowTheCoApplicantBlock] =
		useState<boolean>(
			isLicenceRecipientApplicantInput.value ==
				REQUEST_CONFIGS_KEYS.licenceAttribution.otherPerson
		)

	const radioIsLicenceRecipientApplicant: RadioList[] = [
		{
			label:
				pageAssets?.request_form_historicalArchive_isLicenceRecipientApplicant_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.licenceAttribution.myName,
			key: 'request_form_historicalArchive_isLicenceRecipientApplicant_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_historicalArchive_isLicenceRecipientApplicant_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.licenceAttribution.otherPerson,
			key: 'request_form_historicalArchive_isLicenceRecipientApplicant_secondOption_label'
		}
	]

	// CO APPLICANT SECTION
	//

	const [useTypeInput, setUseTypeInput] = useState<RequestInputs>({
		name: 'useType',
		label: pageAssets?.request_form_historicalArchive_useType_label,
		labelKey: 'request_form_historicalArchive_useType_label',
		value: answers.useType ?? '',
		required: answers.areDocumentsDiffused ?? false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [useTypeList, setUseTypeList] = useState<CheckboxList[]>()

	const [typeOfUse, setTypeOfUse] = useState<RequestInputs>({
		name: 'typeOfUse',
		label: '',
		labelKey: '',
		value: answers.typeOfUse ?? '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [projectTitleInput, setProjectTitleInput] = useState<RequestInputs>({
		name: 'projectTitle',
		label: pageAssets?.request_form_historicalArchive_projectTitle_label,
		labelKey: 'request_form_historicalArchive_projectTitle_label',
		value: answers.projectTitle ?? '',
		required: answers.areDocumentsDiffused ?? false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [projectDescriptionInput, setProjectDescriptionInput] =
		useState<RequestInputs>({
			name: 'projectDescription',
			label:
				pageAssets?.request_form_historicalArchive_projectDescription_label,
			labelKey: 'request_form_historicalArchive_projectDescription_label',
			value: answers.projectDescription ?? '',
			required: answers.areDocumentsDiffused ?? false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [publishingHouseInput, setPublishingHouseInput] =
		useState<RequestInputs>({
			name: 'publishingHouse',
			label: pageAssets?.request_form_historicalArchive_publishingHouse_label,
			labelKey: 'request_form_historicalArchive_publishingHouse_label',
			value: answers.publishingHouse ?? '',
			required: answers.areDocumentsDiffused ?? false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [releaseDateInput, setReleaseDateInput] = useState<RequestInputs>({
		name: 'releaseDate',
		label: pageAssets?.request_form_historicalArchive_releaseDate_label,
		labelKey: 'request_form_historicalArchive_releaseDate_label',
		value: answers.releaseDate ?? '',
		required: answers.areDocumentsDiffused ?? false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
		extraParams: {
			type: 'date',
			min: CustomDate.currentDate(),
			max: moment().add(10, 'years').format('YYYY-MM-DD'),
			customErrorText:
				pageAssets?.request_form_oilHeatingDeclaration_installationDateInput_customTextError
		}
	})

	// Get public holidays that will be used to disable date in the calendar
	const { isWeekday } = usePublicHolidaysData()

	//----------------------------------------------------------------
	//--------------- DETAILS 1 INPUTS ------------
	//----------------------------------------------------------------
	// selector for radio
	const onSelectIsOrganizationRequestInput = (value: string) => {
		setIsOrganizationRequestInput({
			...isOrganizationRequestInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == 'true') {
				setShowTheOrganizationBlock(true)
			} else {
				setShowTheOrganizationBlock(false)
			}

			setOrganizationNameInput({
				...organizationNameInput,
				required: value == 'true'
			})
			setApplicantPurposeInput({
				...applicantPurposeInput,
				required: value == 'true'
			})
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						isOrganizationRequestInput.name,
						organizationNameInput.name,
						applicantPurposeInput.name
					].includes(error)
			)
		)
	}

	const onSelectOrganizationNameInput = (value: string) => {
		setOrganizationNameInput({ ...organizationNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, organizationNameInput.name)
		)
	}

	const onSelectApplicantPurposeInput = (value: string) => {
		setApplicantPurposeInput({ ...applicantPurposeInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, applicantPurposeInput.name)
		)
	}

	//----------------------------------------------------------------
	//--------------- DETAILS 2 INPUTS ------------
	//----------------------------------------------------------------

	const onSelectSpecifiedDocumentsInput = (value: string) => {
		setSpecifiedDocumentsInput({ ...specifiedDocumentsInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, specifiedDocumentsInput.name)
		)
	}

	const onSelectResearchSubjectInput = (value: string) => {
		setResearchSubjectInput({ ...researchSubjectInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, researchSubjectInput.name)
		)
	}

	const onSelectConcernedSectorInput = (value: string) => {
		setConcernedSectorInput({ ...concernedSectorInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, concernedSectorInput.name)
		)
	}

	const onSelectCoveredSearchPeriodStartInput = (value: string) => {
		let dateRestriction =
			new Date(value) > new Date(coveredSearchPeriodEndInput.value)

		if (Number(value.split('-', 2)[0]).toString().length >= 5) {
			return
		}

		setCoveredSearchPeriodStartInput({
			...coveredSearchPeriodStartInput,
			value
		})

		setCoveredSearchPeriodEndInput({
			...coveredSearchPeriodEndInput,
			extraParams: {
				...coveredSearchPeriodEndInput.extraParams,
				min: `${value}`
			}
		})

		if (dateRestriction) {
			if (!errors.includes(coveredSearchPeriodStartInput.name)) {
				errors.push(coveredSearchPeriodStartInput.name)
			}
			return
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						coveredSearchPeriodStartInput.name,
						coveredSearchPeriodEndInput.name
					].includes(error)
			)
		)
	}

	const onSelectCoveredSearchPeriodEndInput = (value: string) => {
		let dateRestriction =
			new Date(value) < new Date(coveredSearchPeriodStartInput.value)
		if (Number(value.split('-', 2)[0]).toString().length >= 5) {
			return
		}

		setCoveredSearchPeriodEndInput({ ...coveredSearchPeriodEndInput, value })
		setCoveredSearchPeriodStartInput({
			...coveredSearchPeriodStartInput,
			extraParams: {
				...coveredSearchPeriodStartInput.extraParams,
				max: `${value}`
			}
		})

		if (dateRestriction) {
			if (!errors.includes(coveredSearchPeriodEndInput.name)) {
				errors.push(coveredSearchPeriodEndInput.name)
			}
			return
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						coveredSearchPeriodStartInput.name,
						coveredSearchPeriodEndInput.name
					].includes(error)
			)
		)
	}

	const onSelectRequiresTechnicalSpecsInput = (value: string) => {
		setRequiresTechnicalSpecsInput({
			...requiresTechnicalSpecsInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == 'true') {
				setShowSpecifiedTechBlock(true)
			} else {
				setShowSpecifiedTechBlock(false)
			}

			setTechnicalSpecsInput({
				...technicalSpecsInput,
				required: value == 'true'
			})
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						requiresTechnicalSpecsInput.name,
						technicalSpecsInput.name
					].includes(error)
			)
		)
	}

	const onSelectTechnicalSpecsInput = (value: string) => {
		setTechnicalSpecsInput({ ...technicalSpecsInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, technicalSpecsInput.name))
	}

	//----------------------------------------------------------------
	//--------------- DETAILS 3 INPUTS ------------
	//----------------------------------------------------------------
	const onSelectIsInPersonAppointmentInput = (value: string) => {
		setIsInPersonAppointmentInput({
			...isInPersonAppointmentInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == 'true') {
				setShowTheAppointmentBlock(true)
			} else {
				setShowTheAppointmentBlock(false)
			}
			setAppointmentDateInput({
				...appointmentDateInput,
				required: value == 'true'
			})
			setAppointmentTimeInput({
				...appointmentTimeInput,
				required: value == 'true'
			})
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						isInPersonAppointmentInput.name,
						appointmentDateInput.name,
						appointmentTimeInput.name,
						appointmentCommentInput.name
					].includes(error)
			)
		)
	}

	const onSelectAppointmentDateInput = (value: string) => {
		if (Number(value.split('-', 2)[0]).toString().length > 4) {
			return
		}

		let date =
			appointmentTimeInput.value !== ''
				? value + 'T' + appointmentTimeInput.value
				: value

		setAppointmentDateInput({ ...appointmentDateInput, value: date })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, appointmentDateInput.name)
		)
	}

	const onSelectAppointmentTimeInput = (value: string) => {
		setAppointmentTimeInput({ ...appointmentTimeInput, value })

		let date =
			value !== ''
				? getAppointmentDate(appointmentDateInput.value) + 'T' + value
				: getAppointmentDate(appointmentDateInput.value)

		appointmentDateInput.value !== '' &&
			setAppointmentDateInput({ ...appointmentDateInput, value: date })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, appointmentTimeInput.name)
		)
	}

	const onSelectAppointmentCommentInput = (value: string) => {
		setAppointmentCommentInput({ ...appointmentCommentInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, appointmentCommentInput.name)
		)
	}

	//----------------------------------------------------------------
	//--------------- DETAILS 4 INPUTS ------------
	//----------------------------------------------------------------

	const onSelectAreDocumentsDiffusedInput = (value: string) => {
		setAreDocumentsDiffusedInput({
			...areDocumentsDiffusedInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == 'true') {
				setShowTheDiffusedBlock(true)
			} else {
				setShowTheDiffusedBlock(false)
			}
			setisLicenceRecipientApplicantInput({
				...isLicenceRecipientApplicantInput,
				required: value == 'true'
			})
			setUseTypeInput({ ...useTypeInput, required: value == 'true' })
			setProjectTitleInput({ ...projectTitleInput, required: value == 'true' })
			setProjectDescriptionInput({
				...projectDescriptionInput,
				required: value == 'true'
			})
			setPublishingHouseInput({
				...publishingHouseInput,
				required: value == 'true'
			})
			setReleaseDateInput({ ...releaseDateInput, required: value == 'true' })
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						areDocumentsDiffusedInput.name,
						isLicenceRecipientApplicantInput.name,
						useTypeInput.name,
						typeOfUse.name,
						projectTitleInput.name,
						publishingHouseInput.name,
						areDocumentsDiffusedInput.name,
						releaseDateInput.name
					].includes(error)
			)
		)
	}

	const onSelectIsLicenceRecipientApplicantInput = (value: string) => {
		setisLicenceRecipientApplicantInput({
			...isLicenceRecipientApplicantInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == REQUEST_CONFIGS_KEYS.licenceAttribution.otherPerson) {
				setShowTheCoApplicantBlock(true)
			} else {
				setShowTheCoApplicantBlock(false)
			}
		}, 100)

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				isLicenceRecipientApplicantInput.name
			)
		)
	}

	const onSelectUseTypeInput = (value: string, key: string) => {
		pageUtils.onCheckValue(key, useTypeList, setUseTypeList, setUseTypeInput)
		if (useTypeList?.find((el) => el.checked) === undefined) {
			setUseTypeInput({ ...useTypeInput, value: '' })
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, useTypeInput.name))
	}

	const onSelectProjectTitleInput = (value: string) => {
		setProjectTitleInput({ ...projectTitleInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, projectTitleInput.name))
	}

	const onSelectprojectDescriptionInput = (value: string) => {
		setProjectDescriptionInput({ ...projectDescriptionInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, projectDescriptionInput.name)
		)
	}

	const onSelectPublishingHouseInput = (value: string) => {
		setPublishingHouseInput({ ...publishingHouseInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, publishingHouseInput.name)
		)
	}

	const onSelectReleaseDateInput = (value: string) => {
		if (Number(value.split('-', 2)[0]).toString().length > 4) {
			return
		}

		setReleaseDateInput({ ...releaseDateInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, releaseDateInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isOrganizationRequestInput))
	}, [isOrganizationRequestInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, organizationNameInput))
	}, [organizationNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, applicantPurposeInput))
	}, [applicantPurposeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, specifiedDocumentsInput))
	}, [specifiedDocumentsInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, researchSubjectInput))
	}, [researchSubjectInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, concernedSectorInput))
	}, [concernedSectorInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, coveredSearchPeriodStartInput))
	}, [coveredSearchPeriodStartInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, coveredSearchPeriodEndInput))
	}, [coveredSearchPeriodEndInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, requiresTechnicalSpecsInput))
	}, [requiresTechnicalSpecsInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, technicalSpecsInput))
	}, [technicalSpecsInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, isInPersonAppointmentInput))
	}, [isInPersonAppointmentInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, appointmentDateInput))
	}, [appointmentDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, appointmentTimeInput))
	}, [appointmentTimeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, technicalSpecsInput))
	}, [technicalSpecsInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, appointmentCommentInput))
	}, [appointmentCommentInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, areDocumentsDiffusedInput))
	}, [areDocumentsDiffusedInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, isLicenceRecipientApplicantInput)
		)
	}, [isLicenceRecipientApplicantInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, useTypeInput))
	}, [useTypeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, typeOfUse))
	}, [typeOfUse])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, projectTitleInput))
	}, [projectTitleInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, projectDescriptionInput))
	}, [projectDescriptionInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, publishingHouseInput))
	}, [publishingHouseInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, releaseDateInput))
	}, [releaseDateInput])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
			]
		})
	}, [])

	useEffect(() => {
		const useTypeList = pageUtils.addLabelToList(
			pageUtils.useTypeListAndValues,
			pageAssets
		)

		// since a string is stored as the value of tranining and experience,
		// we need to convert it to a list of objects to match the format the checkbox inputs
		const useTypeListAnswer = pageUtils.generateCheckboxListFromAnswer(
			pageUtils.useTypeListAndValues,
			answers?.useType,
			pageAssets
		)

		const useTypeFormattedString =
			generateCleanStringFromAnswer(useTypeListAnswer)

		setTypeOfUse({ ...typeOfUse, value: useTypeFormattedString })

		setUseTypeList(
			useTypeListAnswer.length > 0 && !errors.includes(useTypeInput.name)
				? useTypeListAnswer
				: useTypeList
		)
	}, [answers])

	useEffect(() => {
		errors.includes('coveredSearchPeriodStart') ??
		errors.includes('coveredSearchPeriodEnd')
			? setCoveredSearchPeriodInputHasError(true)
			: setCoveredSearchPeriodInputHasError(false)
	})

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getAppointmentDate = (value, setInput?: boolean) => {
		let date = value?.split('T', 1)

		if (date) {
			return date[0]
		}

		return ''
	}

	const appointmentDateLimit = () => {
		let date = new Date(CustomDate.currentDate())

		let index = 0
		date.setDate(date.getDate() + 1)
		while (index < 5) {
			date.setDate(date.getDate() + 1)
			isWeekday(date) && index++
		}

		return date
		// let newCurrentDate = date.toISOString().match(/\d{4}-\d{2}-\d{2}/)!

		// return newCurrentDate[0]
	}

	const releaseDateLimit = () => {
		let date = new Date(CustomDate.currentDate())
		date.setDate(date.getDate() + 1)

		return date
	}

	const yearLimit = () => {
		let date = new Date(CustomDate.currentDate())
		date.setFullYear(date.getFullYear() + 7)

		return date.getFullYear()
	}

	const validateDateRestriction = () => {
		return (
			new Date(coveredSearchPeriodEndInput.value) <
			new Date(coveredSearchPeriodStartInput.value)
		)
	}

	const generateCleanStringFromAnswer = (value) => {
		let cleanString = ''
		let firstEl = true

		value?.map((el) => {
			if (el?.checked) {
				if (!firstEl) {
					cleanString += ','
				} else {
					firstEl = false
				}

				cleanString += el.value
			}
		})

		if (cleanString) {
			return cleanString
		}

		return ''
	}

	const [holidayDates, setHolidayDates] = useState<Date[]>([])

	const serviceName = 'test'

	const getHolidayDates = () => {
		axios
			.get(
				`${process.env.STRAPI_API}/api/ptc-holidays-lavals?populate=*&filters[service_name][$eq]=${serviceName}`
			)
			.then(async (res) => {
				const data = res.data

				const holidays =
					data?.data[0]?.attributes?.ptc_holiday_dates?.data?.map(
						(holidayDates) => {
							return new Date(holidayDates.attributes.date + 'T12:00:00')
						}
					)

				setHolidayDates(holidays)
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {})
	}

	useEffect(() => {
		getHolidayDates()
	}, [isInPersonAppointmentInput])

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_historicalArchive_firstSubStep_title}
							</h4>
						</div>
						<div>
							<strong>{pageAssets[isOrganizationRequestInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										radioIsOrganizationRequest.find(
											(isOrganizationRequest) =>
												isOrganizationRequest.value ==
												answers.isOrganizationRequest?.toString()
										)?.key!
									]
								}
							</span>
						</div>
						{isOrganizationRequestInput.value == 'true' && (
							<>
								<>
									<div>
										<strong>
											{pageAssets[organizationNameInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.organizationName}
										</span>
										<strong>
											{pageAssets[applicantPurposeInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.applicantPurpose}
										</span>
									</div>
								</>
							</>
						)}
					</>
				)}

				{subSteps?.details_2 && (
					<div style={{ marginTop: '40px' }}>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_historicalArchive_secondSubStep_title}
						</h4>

						<div>
							<strong>{pageAssets[specifiedDocumentsInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.specifiedDocuments}
							</span>

							<strong>{pageAssets[researchSubjectInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.researchSubject}
							</span>

							<strong>{pageAssets[concernedSectorInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.concernedSector}
							</span>

							<strong>
								{pageAssets[coveredSearchPeriodStartInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{pageUtils.getLocaleDate(answers?.coveredSearchPeriodStart!) +
									' - ' +
									pageUtils.getLocaleDate(answers?.coveredSearchPeriodEnd!)}
							</span>

							<strong>
								{pageAssets[requiresTechnicalSpecsInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										radioRequiresTechnicalSpecs.find(
											(requiresTechnicalSpecs) =>
												requiresTechnicalSpecs.value ==
												answers.requiresTechnicalSpecs?.toString()
										)?.key!
									]
								}
							</span>

							{requiresTechnicalSpecsInput.value == 'true' && (
								<>
									<strong>{pageAssets[technicalSpecsInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.technicalSpecs}
									</span>
								</>
							)}
						</div>
					</div>
				)}

				{subSteps?.details_3 && (
					<div style={{ marginTop: '40px' }}>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_historicalArchive_thirdSubStep_title}
						</h4>

						<strong>{pageAssets[isInPersonAppointmentInput.labelKey]}</strong>
						<br />
						<span className={pageUtils.classes.answer}>
							{
								pageAssets[
									radioIsInPersonAppointment.find(
										(isInPersonAppointment) =>
											isInPersonAppointment.value ==
											answers.isInPersonAppointment?.toString()
									)?.key!
								]
							}
						</span>
						{isInPersonAppointmentInput.value == 'true' && (
							<>
								<strong>{pageAssets[appointmentDateInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{pageUtils.getLocaleDate(answers?.appointmentDate!)}{' '}
									{answers?.appointmentTime}
								</span>
								{appointmentCommentInput.value != '' && (
									<>
										<strong>
											{pageAssets[appointmentCommentInput.labelKey]}
										</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.appointmentComment}
										</span>
									</>
								)}
							</>
						)}
					</div>
				)}

				{subSteps?.details_4 && (
					<div style={{ marginTop: '40px' }}>
						<a
							style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline
							])}
							onClick={() =>
								backToForm(
									steps?.form!,
									REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
								)
							}
						>
							{pageAssets?.label_modify}
						</a>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_historicalArchive_fourthSubStep_title}
						</h4>
						<strong>{pageAssets[areDocumentsDiffusedInput.labelKey]}</strong>
						<br />
						<span className={pageUtils.classes.answer}>
							{
								pageAssets[
									radioAreDocumentsDiffused.find(
										(areDocumentsDiffused) =>
											areDocumentsDiffused.value ==
											answers.areDocumentsDiffused?.toString()
									)?.key!
								]
							}
						</span>
						{areDocumentsDiffusedInput.value == 'true' && (
							<>
								<strong>
									{pageAssets[isLicenceRecipientApplicantInput.labelKey]}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{
										pageAssets[
											radioIsLicenceRecipientApplicant.find(
												(isLicenceRecipientApplicant) =>
													isLicenceRecipientApplicant.value ==
													answers.isLicenceRecipientApplicant?.toString()
											)?.key!
										]
									}
								</span>
								{isLicenceRecipientApplicantInput.value ==
									REQUEST_CONFIGS_KEYS.licenceAttribution.otherPerson && (
									<>
										<div>
											<strong>{pageAssets?.label_lastName}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.concernedLastName}
											</span>
										</div>
										<>
											<div>
												<strong>{pageAssets?.label_firstName}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedFirstName}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_address}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedAdresse}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{`${formatStrapiText(
													pageAssets?.label_apartment_office
												)}`}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedAppartement}
												</span>
											</div>
										</>

										{/* ---------------------------------------------------------------- */}
										{answers?.concernedHasLavalAdresse?.toString() ==
											'false' && (
											<>
												<>
													<div>
														<strong>{pageAssets?.label_city}</strong>
														<br />
														<span className={pageUtils.classes.answer}>
															{answers?.concernedCity}
														</span>
													</div>
												</>
												<>
													<div>
														<strong>{pageAssets?.label_postalCode}</strong>
														<br />
														<span className={pageUtils.classes.answer}>
															{answers?.concernedPostalCode}
														</span>
													</div>
												</>
											</>
										)}
										{/* ---------------------------------------------------------------- */}

										<>
											<div>
												<strong>{pageAssets?.label_email}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedEmail}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_telephone}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedPhoneNumber}
												</span>
											</div>
										</>
									</>
								)}

								<strong>{pageAssets[useTypeInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{pageUtils
										.getListfromString(answers.useType)
										?.map((answer, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${answer}-${index}-useType`}
												>
													{
														pageAssets[
															pageUtils.useTypeListAndValues.find(
																(list) => list.value === answer
															)?.key!
														]
													}
												</p>
											)
										})}
								</span>
								<strong>{pageAssets[projectTitleInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.projectTitle}
								</span>
								<strong>{pageAssets[projectDescriptionInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.projectDescription}
								</span>
								<strong>{pageAssets[publishingHouseInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.publishingHouse}
								</span>
								<strong>{pageAssets[releaseDateInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{pageUtils.getLocaleDate(answers?.releaseDate!)}
								</span>
							</>
						)}
					</div>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_historicalArchive_firstSubStep_title}
						</h4>

						<RadioInput
							name={isOrganizationRequestInput.name}
							label={isOrganizationRequestInput.label}
							value={isOrganizationRequestInput.value}
							list={radioIsOrganizationRequest}
							direction={Direction.vertical}
							hasError={errors!.includes(isOrganizationRequestInput.name)}
							onChange={onSelectIsOrganizationRequestInput}
							ref={
								isOrganizationRequestInput.ref as RefObject<HTMLInputElement>
							}
							required={isOrganizationRequestInput.required}
							hasBackgroundWrapper={true}
						/>
						{showTheOrganizationBlock && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_historicalArchive_organization_subTitle_label
									}
								</h5>
								<TextInput
									id={organizationNameInput.name}
									label={organizationNameInput.label}
									value={organizationNameInput.value}
									onChange={onSelectOrganizationNameInput}
									hasError={errors.includes(organizationNameInput.name)}
									required={isOrganizationRequestInput.value == 'true'}
									ref={organizationNameInput.ref as RefObject<HTMLInputElement>}
									classWrapper={classes.inputContainer}
									description={organizationNameInput.description}
								/>

								<TextInput
									id={applicantPurposeInput.name}
									label={applicantPurposeInput.label}
									value={applicantPurposeInput.value}
									onChange={onSelectApplicantPurposeInput}
									hasError={errors.includes(applicantPurposeInput.name)}
									required={isOrganizationRequestInput.value == 'true'}
									ref={applicantPurposeInput.ref as RefObject<HTMLInputElement>}
									classWrapper={classes.inputContainer}
									description={applicantPurposeInput.description}
								/>
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_historicalArchive_secondSubStep_title}
						</h4>

						<TextInput
							id={specifiedDocumentsInput.name}
							label={specifiedDocumentsInput.label}
							value={specifiedDocumentsInput.value}
							onChange={onSelectSpecifiedDocumentsInput}
							hasError={errors.includes(specifiedDocumentsInput.name)}
							required={specifiedDocumentsInput.required}
							ref={specifiedDocumentsInput.ref as RefObject<HTMLInputElement>}
							classWrapper={classes.inputContainer}
							description={specifiedDocumentsInput.description}
						/>

						<TextInput
							id={researchSubjectInput.name}
							label={researchSubjectInput.label}
							value={researchSubjectInput.value}
							onChange={onSelectResearchSubjectInput}
							hasError={errors.includes(researchSubjectInput.name)}
							required={researchSubjectInput.required}
							ref={researchSubjectInput.ref as RefObject<HTMLInputElement>}
							classWrapper={classes.inputContainer}
							description={researchSubjectInput.description}
						/>

						<TextInput
							id={concernedSectorInput.name}
							label={concernedSectorInput.label}
							value={concernedSectorInput.value}
							onChange={onSelectConcernedSectorInput}
							hasError={errors.includes(concernedSectorInput.name)}
							required={concernedSectorInput.required}
							ref={concernedSectorInput.ref as RefObject<HTMLInputElement>}
							classWrapper={classes.inputContainer}
							description={concernedSectorInput.description}
						/>

						<label
							className={joinClasses([
								coveredSearchPeriodInputHasError ? classes.errorLabel : ''
							])}
						>
							{coveredSearchPeriodStartInput.label} <RequiredIcon />
						</label>
						<div className={classes.rowSection}>
							<DateFilterInput
								id={coveredSearchPeriodStartInput.name}
								label={''}
								value={coveredSearchPeriodStartInput.value}
								onChange={onSelectCoveredSearchPeriodStartInput}
								hasError={errors.includes(coveredSearchPeriodStartInput.name)}
								maxDate={
									new Date(coveredSearchPeriodStartInput.extraParams?.max!)
								}
								dateFormat="dd/MM/yyyy"
								shouldDisplayCustomHeader
							/>
							<DateFilterInput
								id={coveredSearchPeriodEndInput.name}
								label={''}
								value={coveredSearchPeriodEndInput.value}
								onChange={onSelectCoveredSearchPeriodEndInput}
								hasError={errors.includes(coveredSearchPeriodEndInput.name)}
								minDate={
									new Date(
										coveredSearchPeriodEndInput.extraParams?.min! + 'T12:00:00'
									)
								}
								dateFormat="dd/MM/yyyy"
								shouldDisplayCustomHeader
							/>
						</div>

						<RadioInput
							name={requiresTechnicalSpecsInput.name}
							label={requiresTechnicalSpecsInput.label}
							value={requiresTechnicalSpecsInput.value}
							list={radioRequiresTechnicalSpecs}
							direction={Direction.vertical}
							hasError={errors!.includes(requiresTechnicalSpecsInput.name)}
							onChange={onSelectRequiresTechnicalSpecsInput}
							ref={
								requiresTechnicalSpecsInput.ref as RefObject<HTMLInputElement>
							}
							required={requiresTechnicalSpecsInput.required}
							hasBackgroundWrapper={true}
						/>
						{showSpecifiedTechBlock && (
							<>
								<TextInput
									id={technicalSpecsInput.name}
									label={technicalSpecsInput.label}
									value={technicalSpecsInput.value}
									onChange={onSelectTechnicalSpecsInput}
									hasError={errors.includes(technicalSpecsInput.name)}
									required={requiresTechnicalSpecsInput.value == 'true'}
									ref={technicalSpecsInput.ref as RefObject<HTMLInputElement>}
									classWrapper={classes.inputContainer}
									description={technicalSpecsInput.description}
								/>
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_historicalArchive_thirdSubStep_title}
						</h4>

						<RadioInput
							name={isInPersonAppointmentInput.name}
							label={isInPersonAppointmentInput.label}
							value={isInPersonAppointmentInput.value}
							list={radioIsInPersonAppointment}
							direction={Direction.vertical}
							hasError={errors!.includes(isInPersonAppointmentInput.name)}
							onChange={onSelectIsInPersonAppointmentInput}
							ref={
								isInPersonAppointmentInput.ref as RefObject<HTMLInputElement>
							}
							required={isInPersonAppointmentInput.required}
							hasBackgroundWrapper={true}
						/>
						{showTheAppointmentBlock && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_historicalArchive_taking_appointment
									}
								</h5>

								<Notification
									text={
										pageAssets?.request_form_historicalArchive_taking_appointment_description
									}
									type="info"
									hasHtml
								/>

								<div className={classes.dateAndTimeSection}>
									<DateFilterInput
										id={appointmentDateInput.name}
										label={appointmentDateInput.label}
										value={getAppointmentDate(appointmentDateInput.value, true)}
										onChange={onSelectAppointmentDateInput}
										hasError={errors.includes(appointmentDateInput.name)}
										required={isInPersonAppointmentInput.value == 'true'}
										filterDate={isWeekday}
										minDate={appointmentDateLimit()}
										dateFormat="dd/MM/yyyy"
										excludeDates={holidayDates}
										shouldDisplayCustomHeader
									/>

									<SelectInput
										id={appointmentTimeInput.name}
										label={appointmentTimeInput.label}
										value={appointmentTimeInput.value}
										list={appointmentTimeList}
										onChange={onSelectAppointmentTimeInput}
										hasError={errors.includes(appointmentTimeInput.name)}
										required={isInPersonAppointmentInput.value == 'true'}
										ref={
											appointmentTimeInput.ref as RefObject<HTMLSelectElement>
										}
										classWrapper={joinClasses([
											errors.includes(appointmentDateInput.name) &&
											!errors.includes(appointmentTimeInput.name)
												? classes.timeInputPadding
												: classes.timeInputMargin,
											classes.noWrapper
										])}
									/>
								</div>

								<TextAreaInput
									id={appointmentCommentInput.name}
									label={appointmentCommentInput.label}
									value={appointmentCommentInput.value}
									onChange={onSelectAppointmentCommentInput}
									hasError={errors.includes(appointmentCommentInput.name)}
									required={appointmentCommentInput.required}
									ref={
										appointmentCommentInput.ref as RefObject<HTMLTextAreaElement>
									}
									maxCharacters={500}
								/>
							</>
						)}
					</section>
				</Collapse>
			)}
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_historicalArchive_fourthSubStep_title}
						</h4>
						<RadioInput
							name={areDocumentsDiffusedInput.name}
							label={areDocumentsDiffusedInput.label}
							value={areDocumentsDiffusedInput.value}
							list={radioAreDocumentsDiffused}
							direction={Direction.vertical}
							hasError={errors!.includes(areDocumentsDiffusedInput.name)}
							onChange={onSelectAreDocumentsDiffusedInput}
							ref={areDocumentsDiffusedInput.ref as RefObject<HTMLInputElement>}
							description={areDocumentsDiffusedInput.description}
							required={areDocumentsDiffusedInput.required}
							hasBackgroundWrapper={true}
						/>

						{showTheDiffusedBlock && (
							<>
								<RadioInput
									name={isLicenceRecipientApplicantInput.name}
									label={isLicenceRecipientApplicantInput.label}
									value={isLicenceRecipientApplicantInput.value}
									list={radioIsLicenceRecipientApplicant}
									direction={Direction.vertical}
									hasError={errors!.includes(
										isLicenceRecipientApplicantInput.name
									)}
									onChange={onSelectIsLicenceRecipientApplicantInput}
									ref={
										isLicenceRecipientApplicantInput.ref as RefObject<HTMLInputElement>
									}
									description={isLicenceRecipientApplicantInput.description}
									required={isLicenceRecipientApplicantInput.required}
									hasBackgroundWrapper={true}
								/>
								{showTheCoApplicantBlock && (
									<>
										<Notification
											text={formatStrapiText(
												pageAssets?.request_form_historicalArchive_licence_description
											)}
											type="info"
											hasHtml
										/>
										<ConcernedInfosSection
											errors={errors}
											inputs={inputs}
											onFixError={onFixError}
											onSetInputs={onSetInputs}
											isConcernedInfosRequired={
												isLicenceRecipientApplicantInput.value ==
												REQUEST_CONFIGS_KEYS.licenceAttribution.otherPerson
											}
											requiredFields={[
												'concernedLastName',
												'concernedFirstName',
												'concernedHasLavalAdresse',
												'concernedAdresse',
												'concernedAppartement',
												'concernedCity',
												'concernedPostalCode',
												'concernedEmail',
												'concernedPhoneNumber'
											]}
											subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4}
										/>
									</>
								)}
								<CheckboxInput
									name={useTypeInput.name}
									label={useTypeInput.label}
									value={`${useTypeInput.value}`}
									list={useTypeList}
									onChange={onSelectUseTypeInput}
									required={useTypeInput.required}
									hasError={errors.includes(useTypeInput.name)}
									ref={useTypeInput.ref as RefObject<HTMLInputElement>}
									setList={setUseTypeList}
									setInput={setUseTypeInput}
									errors={errors}
									onFixError={onFixError}
									hasBackgroundWrapper={true}
									shouldNotDivide
								/>

								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_historicalArchive_projectInfo_description_label
									}
								</h5>
								<TextInput
									id={projectTitleInput.name}
									label={projectTitleInput.label}
									value={projectTitleInput.value}
									onChange={onSelectProjectTitleInput}
									hasError={errors.includes(projectTitleInput.name)}
									required={projectTitleInput.required}
									ref={projectTitleInput.ref as RefObject<HTMLInputElement>}
									classWrapper={classes.inputContainer}
									description={projectTitleInput.description}
								/>

								<TextInput
									id={projectDescriptionInput.name}
									label={projectDescriptionInput.label}
									value={projectDescriptionInput.value}
									onChange={onSelectprojectDescriptionInput}
									hasError={errors.includes(projectDescriptionInput.name)}
									required={projectDescriptionInput.required}
									ref={
										projectDescriptionInput.ref as RefObject<HTMLInputElement>
									}
									classWrapper={classes.inputContainer}
									description={projectDescriptionInput.description}
								/>

								<TextInput
									id={publishingHouseInput.name}
									label={publishingHouseInput.label}
									value={publishingHouseInput.value}
									onChange={onSelectPublishingHouseInput}
									hasError={errors.includes(publishingHouseInput.name)}
									required={publishingHouseInput.required}
									ref={publishingHouseInput.ref as RefObject<HTMLInputElement>}
									classWrapper={classes.inputContainer}
									description={publishingHouseInput.description}
								/>

								<div className={classes.dateAndTimeSection}>
									<DateFilterInput
										id={releaseDateInput.name}
										label={releaseDateInput.label}
										value={releaseDateInput.value}
										onChange={onSelectReleaseDateInput}
										hasError={errors.includes(releaseDateInput.name)}
										minDate={releaseDateLimit()}
										required={areDocumentsDiffusedInput.value == 'true'}
										dateFormat="dd/MM/yyyy"
										shouldDisplayCustomHeader
									/>
								</div>
							</>
						)}
					</section>
				</Collapse>
			)}
		</>
	)
}

export default HistoricalArchiveForm
