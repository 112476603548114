import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import Text from '@components/ui/text'
import SelectInput from './inputs/select.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import { CustomDate } from '@services/models'
import { getFilesFromStore } from '@utils/methods'
import Notification from '@components/ui/notification'
import MaskedTextInput from './inputs/textWithValidation.inputs'
import * as formUtils from '@pages/auth/__index.utils'
import { PHONE_NUMBER_REGEX } from '@pages/auth/profile/__index.utils'
import DateFilterInput from './inputs/datefilter.input'

const CrackedHousesForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		ownerSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [showFirstAddButton, setShowFirstAddButton] = useState<boolean>(true)
	const [showSecondAddButton, setShowSecondAddButton] = useState<boolean>(false)
	const [showThirdAddButton, setShowThirdAddButton] = useState<boolean>(false)

	//----------------------------------------------------------------
	//----------------------- DETAILS INPUTS -------------------------
	//----------------------------------------------------------------

	//****** Owners inputs ******

	const [firstOwnersLastNameInput, setFirstOwnersLastNameInput] =
		useState<RequestInputs>({
			name: 'firstOwnersLastName',
			label: pageAssets?.label_lastName,
			labelKey: 'label_lastName',
			value: answers.firstOwnersLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [firstOwnersFirstNameInput, setFirstOwnersFirstNameInput] =
		useState<RequestInputs>({
			name: 'firstOwnersFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.firstOwnersFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [firstOwnersEmailInput, setFirstOwnersEmailInput] =
		useState<RequestInputs>({
			name: 'firstOwnersEmail',
			label: pageAssets?.label_email,
			labelKey: 'label_email',
			value: answers.firstOwnersEmail || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [firstOwnersPhoneNumberInput, setFirstOwnersPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'firstOwnersPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.firstOwnersPhoneNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersLastNameInput, setSecondOwnersLastNameInput] =
		useState<RequestInputs>({
			name: 'secondOwnersLastName',
			label: pageAssets?.label_lastName,
			labelKey: 'label_lastName',
			value: answers.secondOwnersLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersFirstNameInput, setSecondOwnersFirstNameInput] =
		useState<RequestInputs>({
			name: 'secondOwnersFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.secondOwnersFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersEmailInput, setSecondOwnersEmailInput] =
		useState<RequestInputs>({
			name: 'secondOwnersEmail',
			label: pageAssets?.label_email,
			labelKey: 'label_email',
			value: answers.secondOwnersEmail || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersPhoneNumberInput, setSecondOwnersPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'secondOwnersPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.secondOwnersPhoneNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	enum OWNER {
		FIRST,
		SECOND
	}

	//****** Building inputs ******

	const [buildingNumberInput, setBuildingNumberInput] = useState<RequestInputs>(
		{
			name: 'buildingNumber',
			label: pageAssets?.request_form_crackedHouses_buildingNumber_label,
			labelKey: 'request_form_crackedHouses_buildingNumber_label',
			value: answers.buildingNumber ? `${answers.buildingNumber}` : '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		}
	)

	const [workProgressInput, setWorkProgressInput] = useState<RequestInputs>({
		name: 'workProgress',
		label: pageAssets?.request_form_crackedHouses_workProgress_label,
		labelKey: 'request_form_crackedHouses_workProgress_label',
		value: answers.workProgress?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const workProgressList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_workProgress_not_done,
			value: REQUEST_CONFIGS_KEYS.workProgress.notDone,
			key: 'select_workProgress_not_done'
		},
		{
			label: pageAssets?.select_workProgress_permit_submitted,
			value: REQUEST_CONFIGS_KEYS.workProgress.permitSubmitted,
			key: 'select_workProgress_permit_submitted'
		},
		{
			label: pageAssets?.select_workProgress_already_done,
			value: REQUEST_CONFIGS_KEYS.workProgress.alreadyDone,
			key: 'select_workProgress_already_done'
		}
	]

	const [workDateInput, setWorkDateInput] = useState<RequestInputs>({
		name: 'workDate',
		label: pageAssets?.request_form_crackedHouses_workDate_label,
		labelKey: 'request_form_crackedHouses_workDate_label',
		value: answers.workDate || '',
		required:
			REQUEST_CONFIGS_KEYS.workProgress.alreadyDone ==
			String(answers.workProgress),
		ref: useRef<HTMLInputElement>(null)
	})

	const [permitNumberInput, setPermitNumberInput] = useState<RequestInputs>({
		name: 'permitNumber',
		label: pageAssets?.request_form_crackedHouses_permitNumber_label,
		labelKey: 'request_form_crackedHouses_permitNumber_label',
		value: answers.permitNumber || '',
		required:
			REQUEST_CONFIGS_KEYS.workProgress.alreadyDone ==
			String(answers.workProgress),
		ref: useRef<HTMLSelectElement>(null)
	})

	// ----------------------------------------------------------------
	// -------------------- MASQUED INPUTS ----------------------------

	const [
		buildingAddressSameHasApplicantInput,
		setBuildingAddressSameHasApplicantInput
	] = useState<RequestInputs>({
		name: 'buildingAddressSameHasApplicant',
		value: 'true',
		label: '',
		labelKey: '',
		required: false
	})

	const [buildingAddressInput, setBuildingAddressInput] =
		useState<RequestInputs>({
			name: 'buildingAddress',
			value: location.address,
			label: '',
			labelKey: '',
			required: false
		})

	const [buildingPostalCodeInput, setBuildingPostalCodeInput] =
		useState<RequestInputs>({
			name: 'buildingPostalCode',
			value: location.postalCode,
			label: '',
			labelKey: '',
			required: false
		})

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingAddressInput))
	}, [buildingAddressInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingPostalCodeInput))
	}, [buildingPostalCodeInput])

	useEffect(() => {
		if (
			location.coordinates.latitude ==
				authUser?.profile?.address.coordinates?.latitude &&
			location.coordinates.longitude ==
				authUser?.profile?.address.coordinates?.longitude
		) {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'true'
			})
		} else {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'false'
			})
		}
	}, [location.coordinates])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingAddressSameHasApplicantInput)
		)
	}, [buildingAddressSameHasApplicantInput])

	useEffect(() => {
		setBuildingAddressInput((prevState) => ({
			...prevState,
			value: location.address
		}))
	}, [location.address])

	useEffect(() => {
		setBuildingPostalCodeInput((prevState) => ({
			...prevState,
			value: location.postalCode
		}))
	}, [location.postalCode])

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// Preuve de propriété
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_crackedHouses_firstInput_label,
		labelKey: 'request_form_crackedHouses_firstInput_label',
		description: pageAssets?.request_form_crackedHouses_firstInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		category: requestCategories.proofOfResidency
	})

	// Photos
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label: pageAssets?.request_form_crackedHouses_secondInput_label,
		labelKey: 'request_form_crackedHouses_secondInput_label',
		description: pageAssets?.request_form_crackedHouses_secondInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		category: requestCategories.photographicEvidence
	})

	// Rapport d’expertise
	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label: pageAssets?.request_form_crackedHouses_thirdInput_label,
		labelKey: 'request_form_crackedHouses_thirdInput_label',
		description: pageAssets?.request_form_crackedHouses_thirdInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		category: requestCategories.expertReport
	})

	// Devis détaillé
	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label: pageAssets?.request_form_crackedHouses_fourthInput_label,
		labelKey: 'request_form_crackedHouses_fourthInput_label',
		description: pageAssets?.request_form_crackedHouses_fourthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		category: requestCategories.technicalSpecification
	})

	// Formulaire de soumission ventilée
	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label: pageAssets?.request_form_crackedHouses_fifthInput_label,
		labelKey: 'request_form_crackedHouses_fifthInput_label',
		description: pageAssets?.request_form_crackedHouses_fifthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		category: requestCategories.submission
	})

	// Procuration ou la Déclaration des autres propriétaires
	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label: pageAssets?.request_form_crackedHouses_sixthInput_label,
		labelKey: 'request_form_crackedHouses_sixthInput_label',
		description: pageAssets?.request_form_crackedHouses_sixthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.proxy
	})

	// Autre document pertinent
	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label: pageAssets?.request_form_crackedHouses_seventhInput_label,
		labelKey: 'request_form_crackedHouses_seventhInput_label',
		description:
			pageAssets?.request_form_crackedHouses_seventhInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'seventhInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.other
	})

	const [duplicateOwner, setDuplicateOwner] = useState<boolean>(false)

	const clearFirstOwnerFields = () => {
		setFirstOwnersEmailInput({
			...firstOwnersEmailInput,
			required: false,
			value: ''
		})

		setFirstOwnersFirstNameInput({
			...firstOwnersFirstNameInput,
			required: false,
			value: ''
		})

		setFirstOwnersLastNameInput({
			...firstOwnersLastNameInput,
			required: false,
			value: ''
		})

		setFirstOwnersPhoneNumberInput({
			...firstOwnersPhoneNumberInput,
			required: false,
			value: ''
		})

		// Vider les errors concenant ces inputs
		onFixError(
			errors.filter(
				(error) =>
					![
						firstOwnersEmailInput.name,
						firstOwnersFirstNameInput.name,
						firstOwnersLastNameInput.name,
						firstOwnersPhoneNumberInput.name
					].includes(error)
			)
		)
	}

	const clearSecondUserFields = () => {
		setSecondOwnersLastNameInput({
			...secondOwnersLastNameInput,
			required: false,
			value: ''
		})
		setSecondOwnersEmailInput({
			...secondOwnersEmailInput,
			required: false,
			value: ''
		})
		setSecondOwnersFirstNameInput({
			...secondOwnersFirstNameInput,
			required: false,
			value: ''
		})
		setSecondOwnersPhoneNumberInput({
			...secondOwnersPhoneNumberInput,
			required: false,
			value: ''
		})

		// Vider les errors concenant ces inputs
		onFixError(
			errors.filter(
				(error) =>
					![
						secondOwnersLastNameInput.name,
						secondOwnersEmailInput.name,
						secondOwnersFirstNameInput.name,
						secondOwnersPhoneNumberInput.name
					].includes(error)
			)
		)
	}

	const setFirstOwnerFields = () => {
		setFirstOwnersEmailInput({ ...firstOwnersEmailInput, required: true })
		setFirstOwnersFirstNameInput({
			...firstOwnersFirstNameInput,
			required: true
		})
		setFirstOwnersLastNameInput({ ...firstOwnersLastNameInput, required: true })
		setFirstOwnersPhoneNumberInput({
			...firstOwnersPhoneNumberInput,
			required: true
		})
	}

	const setSecondOwnerFields = () => {
		setSecondOwnersLastNameInput({
			...secondOwnersLastNameInput,
			required: true
		})
		setSecondOwnersEmailInput({ ...secondOwnersEmailInput, required: true })
		setSecondOwnersFirstNameInput({
			...secondOwnersFirstNameInput,
			required: true
		})
		setSecondOwnersPhoneNumberInput({
			...secondOwnersPhoneNumberInput,
			required: true
		})
	}

	const clearOwner = (owner: OWNER) => {
		if (owner == OWNER.FIRST) {
			setShowSecondAddButton(false)
			clearFirstOwnerFields()
		} else if (owner == OWNER.SECOND) {
			setShowThirdAddButton(false)
			clearSecondUserFields()
		}
	}

	const showOwner = (owner: OWNER) => {
		switch (owner) {
			case OWNER.FIRST: {
				setShowFirstAddButton(false)
				setShowSecondAddButton(true)
				setFirstOwnerFields()
				break
			}
			case OWNER.SECOND: {
				if (showSecondAddButton) {
					setShowThirdAddButton(true)
					setSecondOwnerFields()
				} else {
					setShowSecondAddButton(true)
					setFirstOwnerFields()
				}
			}
		}
	}

	const onSetFirstOwnersLastNameInput = (value: string) => {
		setFirstOwnersLastNameInput({ ...firstOwnersLastNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersLastNameInput.name)
		)
	}

	const onSetFirstOwnersFirstNameInput = (value: string) => {
		setFirstOwnersFirstNameInput({ ...firstOwnersFirstNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersFirstNameInput.name)
		)
	}

	const onSetFirstOwnersEmailInput = (value: string) => {
		setFirstOwnersEmailInput({ ...firstOwnersEmailInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersEmailInput.name)
		)
	}

	const onSetFirstOwnersPhoneNumberInput = (value: string) => {
		setFirstOwnersPhoneNumberInput({ ...firstOwnersPhoneNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersPhoneNumberInput.name)
		)
	}

	const onSetSecondOwnersLastNameInput = (value: string) => {
		setSecondOwnersLastNameInput({ ...secondOwnersLastNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersLastNameInput.name)
		)
	}

	const onSetSecondOwnersFirstNameInput = (value: string) => {
		setSecondOwnersFirstNameInput({ ...secondOwnersFirstNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersFirstNameInput.name)
		)
	}

	const onSetSecondOwnersEmailInput = (value: string) => {
		setSecondOwnersEmailInput({ ...secondOwnersEmailInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersEmailInput.name)
		)
	}

	const onSetSecondOwnersPhoneNumberInput = (value: string) => {
		setSecondOwnersPhoneNumberInput({ ...secondOwnersPhoneNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersPhoneNumberInput.name)
		)
	}

	// ------------- DETAILS INPUTS SETTERS ----------------

	const onSetBuildingNumberInput = (value: string) => {
		// Number of building doit etre entre 1 et 8
		if (value !== '' && (Number(value) < 1 || Number(value) > 8)) {
			return
		}
		setBuildingNumberInput({ ...buildingNumberInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, buildingNumberInput.name))
	}

	const onSelectWorkProgressInput = (value: string) => {
		setWorkProgressInput({ ...workProgressInput, value })

		if (value === REQUEST_CONFIGS_KEYS.workProgress.alreadyDone) {
			setWorkDateInput({ ...workDateInput, required: true })
			setPermitNumberInput({ ...permitNumberInput, required: true })
		} else {
			setWorkDateInput({ ...workDateInput, required: false })
			setPermitNumberInput({ ...permitNumberInput, required: false })
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, workProgressInput.name))
	}

	const onSelectWorkDateInput = (value: string) => {
		setWorkDateInput({ ...workDateInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, workDateInput.name))
	}

	const onSetPermitNumberInput = (value: string) => {
		setPermitNumberInput({ ...permitNumberInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, permitNumberInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersLastNameInput))
	}, [firstOwnersLastNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersFirstNameInput))
	}, [firstOwnersFirstNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersEmailInput))
	}, [firstOwnersEmailInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersPhoneNumberInput))
	}, [firstOwnersPhoneNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersLastNameInput))
	}, [secondOwnersLastNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersFirstNameInput))
	}, [secondOwnersFirstNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersEmailInput))
	}, [secondOwnersEmailInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersPhoneNumberInput))
	}, [secondOwnersPhoneNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingNumberInput))
	}, [buildingNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, workProgressInput))
	}, [workProgressInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, workDateInput))
	}, [workDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, permitNumberInput))
	}, [permitNumberInput])

	useEffect(() => {
		if (!showSecondAddButton && !showThirdAddButton) {
			setShowFirstAddButton(true)
		}
	}, [showSecondAddButton, showThirdAddButton])

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		setFirstQuestion({
			...firstQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		setSecondQuestion({
			...secondQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		setThirdQuestion({
			...thirdQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, thirdQuestion.name))
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		setFourthQuestion({
			...fourthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, fourthQuestion.name))
	}

	const onSelectFileFifthQuestion = (files: Set<File>) => {
		setFifthQuestion({
			...fifthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, fifthQuestion.name))
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		setSixthQuestion({
			...sixthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, sixthQuestion.name))
	}

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		setSeventhQuestion({
			...seventhQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, seventhQuestion.name))
	}

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		if (!!answers.firstInput && firstQuestion.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setFirstQuestion({
					...firstQuestion,
					files: result
				})
			})
		}
		// secondInput input
		if (!!answers.secondInput && secondQuestion.files.size == 0) {
			getFilesFromStore('secondInput', answers).then((result: Set<File>) => {
				setSecondQuestion({
					...secondQuestion,
					files: result
				})
			})
		}
		// thirdInput input
		if (!!answers.thirdInput && thirdQuestion.files.size == 0) {
			getFilesFromStore('thirdInput', answers).then((result: Set<File>) => {
				setThirdQuestion({
					...thirdQuestion,
					files: result
				})
			})
		}
		// fouthInput input
		if (!!answers.fourthInput && fourthQuestion.files.size == 0) {
			getFilesFromStore('fourthInput', answers).then((result: Set<File>) => {
				setFourthQuestion({
					...fourthQuestion,
					files: result
				})
			})
		}
		// fifthInput input
		if (!!answers.fifthInput && fifthQuestion.files.size == 0) {
			getFilesFromStore('fifthInput', answers).then((result: Set<File>) => {
				setFifthQuestion({
					...fifthQuestion,
					files: result
				})
			})
		}
		// sixthInput input
		if (!!answers.sixthInput && sixthQuestion.files.size == 0) {
			getFilesFromStore('sixthInput', answers).then((result: Set<File>) => {
				setSixthQuestion({
					...sixthQuestion,
					files: result
				})
			})
		}

		// seventhInput input
		if (!!answers.seventhInput && seventhQuestion.files.size == 0) {
			getFilesFromStore('seventhInput', answers).then((result: Set<File>) => {
				setSeventhQuestion({
					...seventhQuestion,
					files: result
				})
			})
		}

		// if first owner exist, show the collapse
		if (
			!!answers.firstOwnersEmail ||
			!!answers.firstOwnersLastName ||
			!!answers.firstOwnersFirstName ||
			!!answers.firstOwnersPhoneNumber
		) {
			setShowFirstAddButton(false)
			setShowSecondAddButton(true)
		}

		// if second owner exist, show the collapse
		if (
			!!answers.secondOwnersEmail ||
			!!answers.secondOwnersLastName ||
			!!answers.secondOwnersFirstName ||
			!!answers.secondOwnersPhoneNumber
		) {
			setShowFirstAddButton(false)
			setShowSecondAddButton(true)
			setShowThirdAddButton(true)
		}
	}, [])

	// if other owners exist, make the input file required
	useEffect(() => {
		if (!!showSecondAddButton || !!showThirdAddButton) {
			setSixthQuestion({
				...sixthQuestion,
				required: true
			})
		} else {
			setSixthQuestion({
				...sixthQuestion,
				required: false
			})
		}
	}, [showSecondAddButton, showThirdAddButton])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	useEffect(() => {
		onSetSubSteps({
			map: true,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.MAP,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.MAP,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_grant_details}
							</h4>
						</div>

						{answers.firstOwnersFirstName && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{pageAssets?.request_form_otherOwnersIfAny_section_title}
								</h5>

								<div>
									<strong>
										{pageAssets[firstOwnersLastNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersLastName}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[firstOwnersFirstNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersFirstName}
									</span>
								</div>

								<div>
									<strong>{pageAssets[firstOwnersEmailInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersEmail}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[firstOwnersPhoneNumberInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersPhoneNumber}
									</span>
								</div>
							</>
						)}

						{answers.secondOwnersFirstName && (
							<>
								<div>
									<strong>
										{pageAssets[secondOwnersLastNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersLastName}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[secondOwnersFirstNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersFirstName}
									</span>
								</div>

								<div>
									<strong>{pageAssets[secondOwnersEmailInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersEmail}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[secondOwnersPhoneNumberInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersPhoneNumber}
									</span>
								</div>
							</>
						)}

						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_concernedBuildingInformations_section_title
							}
						</h5>

						<div>
							<strong>{pageAssets[buildingNumberInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{answers?.buildingNumber}
							</span>
						</div>

						<div>
							<strong>{pageAssets[workProgressInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										workProgressList.find(
											(wp) => wp.value == answers.workProgress?.toString()
										)?.key!
									]
								}
							</span>
						</div>

						{REQUEST_CONFIGS_KEYS.workProgress.alreadyDone ==
							String(answers.workProgress) && (
							<>
								<div>
									<strong>{pageAssets[workDateInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{pageUtils.getLocaleDate(answers?.workDate!)}
									</span>
								</div>

								<div>
									<strong>{pageAssets[permitNumberInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.permitNumber}
									</span>
								</div>
							</>
						)}
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>
						</div>

						<RequestFiles
							subtitle={firstQuestion.label}
							name={firstQuestion.name}
							required={firstQuestion.required}
							files={firstQuestion.files}
							hasError={errors.includes(firstQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFirstQuestion}
							maxFiles={firstQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={secondQuestion.label}
							name={secondQuestion.name}
							required={secondQuestion.required}
							files={secondQuestion.files}
							hasError={errors.includes(secondQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileSecondQuestion}
							maxFiles={secondQuestion.numberMaxFiles}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={thirdQuestion.label}
							name={thirdQuestion.name}
							required={thirdQuestion.required}
							files={thirdQuestion.files}
							hasError={errors.includes(thirdQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileThirdQuestion}
							maxFiles={thirdQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={fourthQuestion.label}
							name={fourthQuestion.name}
							required={fourthQuestion.required}
							files={fourthQuestion.files}
							hasError={errors.includes(fourthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFourthQuestion}
							maxFiles={fourthQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={fifthQuestion.label}
							name={fifthQuestion.name}
							required={fifthQuestion.required}
							files={fifthQuestion.files}
							hasError={errors.includes(fifthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFifthQuestion}
							maxFiles={fifthQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						{sixthQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={sixthQuestion.label}
								name={sixthQuestion.name}
								required={sixthQuestion.required}
								files={sixthQuestion.files}
								hasError={errors.includes(sixthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSixthQuestion}
								maxFiles={sixthQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>
						)}

						{seventhQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={seventhQuestion.label}
								name={seventhQuestion.name}
								required={seventhQuestion.required}
								files={seventhQuestion.files}
								hasError={errors.includes(seventhQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSeventhQuestion}
								maxFiles={seventhQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_grant_details}
					</h4>

					<h5
						className={joinClasses([
							pageUtils.classes.h5,
							duplicateOwner ? pageUtils.classes.error : ''
						])}
					>
						{pageAssets?.request_form_otherOwnersIfAny_section_title}
					</h5>

					<p>{pageAssets?.request_form_otherOwnersIfAny_section_remark_1}</p>
					<br />

					<Collapse isOpened={duplicateOwner}>
						<Notification
							type="error"
							text={
								pageAssets?.request_form_heritageBuildingsRevitalizationProgram_owners_error
							}
						/>
					</Collapse>
					{showFirstAddButton && (
						<a
							style={{ marginBottom: showFirstAddButton ? '12px' : '' }}
							onClick={() => showOwner(OWNER.FIRST)}
							className={classes.button}
						>
							{pageAssets?.add}
						</a>
					)}

					<Collapse isOpened={showSecondAddButton}>
						<div className={classes.ownerSection}>
							<TextInput
								id={firstOwnersLastNameInput.name}
								label={firstOwnersLastNameInput.label}
								value={firstOwnersLastNameInput.value}
								onChange={onSetFirstOwnersLastNameInput}
								hasError={errors.includes(firstOwnersLastNameInput.name)}
								required
								autoComplete="family-name"
								ref={
									firstOwnersLastNameInput.ref as RefObject<HTMLInputElement>
								}
							/>
							<TextInput
								id={firstOwnersFirstNameInput.name}
								label={firstOwnersFirstNameInput.label}
								value={firstOwnersFirstNameInput.value}
								onChange={onSetFirstOwnersFirstNameInput}
								hasError={errors.includes(firstOwnersFirstNameInput.name)}
								required
								autoComplete="given-name"
								ref={
									firstOwnersFirstNameInput.ref as RefObject<HTMLInputElement>
								}
							/>
							<TextInput
								id={firstOwnersEmailInput.name}
								label={firstOwnersEmailInput.label}
								value={firstOwnersEmailInput.value}
								onChange={onSetFirstOwnersEmailInput}
								type="email"
								hasError={errors.includes(firstOwnersEmailInput.name)}
								onSetError={onFixError}
								schema={formUtils.MASKS.email}
								schemaErrorText={pageAssets.label_emailErrorText}
								required
								autoComplete="email"
								ref={firstOwnersEmailInput.ref as RefObject<HTMLInputElement>}
							/>

							<MaskedTextInput
								id={firstOwnersPhoneNumberInput.name}
								label={firstOwnersPhoneNumberInput.label}
								value={firstOwnersPhoneNumberInput.value}
								onChange={onSetFirstOwnersPhoneNumberInput}
								type="tel"
								hasError={errors.includes(firstOwnersPhoneNumberInput.name)}
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors}
								setError={onFixError}
								required
								schema={PHONE_NUMBER_REGEX}
								ref={
									firstOwnersPhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>
						</div>
					</Collapse>

					<div
						style={{ marginBottom: showThirdAddButton ? '12px' : '' }}
						className={classes.buttonContainer}
					>
						{showSecondAddButton && (
							<a
								onClick={() => clearOwner(OWNER.FIRST)}
								className={joinClasses([
									classes.button,
									classes.outlinedButton
								])}
							>
								{pageAssets?.request_form_removeOwner_button_title}
							</a>
						)}
						{!showThirdAddButton && !showFirstAddButton && (
							<a
								onClick={() => showOwner(OWNER.SECOND)}
								className={classes.button}
							>
								{pageAssets?.add}
							</a>
						)}
					</div>

					<Collapse isOpened={showThirdAddButton}>
						<>
							<div className={classes.ownerSection}>
								<TextInput
									id={secondOwnersLastNameInput.name}
									label={secondOwnersLastNameInput.label}
									value={secondOwnersLastNameInput.value}
									onChange={onSetSecondOwnersLastNameInput}
									hasError={errors.includes(secondOwnersLastNameInput.name)}
									required
									autoComplete="family-name"
									ref={
										secondOwnersLastNameInput.ref as RefObject<HTMLInputElement>
									}
								/>
								<TextInput
									id={secondOwnersFirstNameInput.name}
									label={secondOwnersFirstNameInput.label}
									value={secondOwnersFirstNameInput.value}
									onChange={onSetSecondOwnersFirstNameInput}
									hasError={errors.includes(secondOwnersFirstNameInput.name)}
									required
									autoComplete="given-name"
									ref={
										secondOwnersFirstNameInput.ref as RefObject<HTMLInputElement>
									}
								/>
								<TextInput
									id={secondOwnersEmailInput.name}
									label={secondOwnersEmailInput.label}
									value={secondOwnersEmailInput.value}
									onChange={onSetSecondOwnersEmailInput}
									type="email"
									onSetError={onFixError}
									schema={formUtils.MASKS.email}
									schemaErrorText={pageAssets.label_emailErrorText}
									hasError={errors.includes(secondOwnersEmailInput.name)}
									required
									autoComplete="email"
									ref={
										secondOwnersEmailInput.ref as RefObject<HTMLInputElement>
									}
								/>

								<MaskedTextInput
									id={secondOwnersPhoneNumberInput.name}
									label={secondOwnersPhoneNumberInput.label}
									value={secondOwnersPhoneNumberInput.value}
									onChange={onSetSecondOwnersPhoneNumberInput}
									hasError={errors.includes(secondOwnersPhoneNumberInput.name)}
									type="tel"
									min={10}
									max={14}
									mask={formUtils.MASKS.phoneNumber}
									placeholder={formUtils.MASKS.phoneNumber}
									errors={errors}
									setError={onFixError}
									schema={PHONE_NUMBER_REGEX}
									required
									ref={
										secondOwnersPhoneNumberInput.ref as RefObject<HTMLInputElement>
									}
								/>
							</div>

							<div className={classes.buttonContainer}>
								<a
									onClick={() => clearOwner(OWNER.SECOND)}
									className={joinClasses([
										classes.button,
										classes.outlinedButton
									])}
								>
									{pageAssets?.request_form_removeOwner_button_title}
								</a>
							</div>
						</>
						<br />
						<p>
							{
								<Text
									content={
										pageAssets?.request_form_otherOwnersIfAny_section_remark_2
									}
								/>
							}
						</p>
						<br />
					</Collapse>

					<h5 className={pageUtils.classes.h5}>
						{
							pageAssets?.request_form_concernedBuildingInformations_section_title
						}
					</h5>
					<div className={pageUtils.classes.fieldsContainer}>
						<TextInput
							type="number"
							id={buildingNumberInput.name}
							label={buildingNumberInput.label}
							value={buildingNumberInput.value}
							onChange={onSetBuildingNumberInput}
							required={buildingNumberInput.required}
							min="1"
							max="8"
							hasError={errors.includes(buildingNumberInput.name)}
							ref={buildingNumberInput.ref as RefObject<HTMLInputElement>}
							onKeyPress={(event) => {
								const charCode = event.which ? event.which : event.keyCode
								const char = String.fromCharCode(charCode)

								if (!/^\d$/.test(char)) {
									event.preventDefault()
								}
							}}
						/>
					</div>

					<SelectInput
						id={workProgressInput.name}
						label={workProgressInput.label}
						value={workProgressInput.value}
						list={workProgressList}
						onChange={onSelectWorkProgressInput}
						hasError={errors.includes(workProgressInput.name)}
						required={workProgressInput.required}
						ref={workProgressInput.ref as RefObject<HTMLSelectElement>}
					/>

					{workProgressInput.value ===
						REQUEST_CONFIGS_KEYS.workProgress.alreadyDone && (
						<>
							<div className={pageUtils.classes.fieldsContainer}>
								<DateFilterInput
									id={workDateInput.name}
									label={workDateInput.label}
									value={workDateInput.value}
									onChange={onSelectWorkDateInput}
									hasError={errors.includes(workDateInput.name)}
									required={workDateInput.required}
									maxDate={new Date(CustomDate.currentDate())}
									dateFormat="dd/MM/yyyy"
								/>
								{/*
								<TextInput
									type="date"
									max={CustomDate.currentDate()}
									id={workDateInput.name}
									label={workDateInput.label}
									value={workDateInput.value}
									onChange={onSelectWorkDateInput}
									hasError={errors.includes(workDateInput.name)}
									required={workDateInput.required}
									ref={workDateInput.ref as RefObject<HTMLInputElement>}
								/>
							*/}
							</div>
							<TextInput
								id={permitNumberInput.name}
								label={permitNumberInput.label}
								value={permitNumberInput.value}
								onChange={onSetPermitNumberInput}
								hasError={errors.includes(permitNumberInput.name)}
								required={permitNumberInput.required}
								ref={permitNumberInput.ref as RefObject<HTMLInputElement>}
							/>
						</>
					)}
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_required_documents}
					</h4>

					<RequestFiles
						subtitle={firstQuestion.label}
						name={firstQuestion.name}
						required
						files={firstQuestion.files}
						hasError={errors.includes(firstQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFirstQuestion}
						subSteps={subSteps}
						maxFiles={firstQuestion.numberFilesRequired}
						description={firstQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={secondQuestion.label}
						name={secondQuestion.name}
						required
						files={secondQuestion.files}
						hasError={errors.includes(secondQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileSecondQuestion}
						subSteps={subSteps}
						maxFiles={secondQuestion.numberMaxFiles}
						description={secondQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={thirdQuestion.label}
						name={thirdQuestion.name}
						required
						files={thirdQuestion.files}
						hasError={errors.includes(thirdQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileThirdQuestion}
						subSteps={subSteps}
						maxFiles={thirdQuestion.numberFilesRequired}
						description={thirdQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={fourthQuestion.label}
						name={fourthQuestion.name}
						required
						files={fourthQuestion.files}
						hasError={errors.includes(fourthQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFourthQuestion}
						subSteps={subSteps}
						maxFiles={fourthQuestion.numberFilesRequired}
						description={fourthQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={fifthQuestion.label}
						name={fifthQuestion.name}
						required={fifthQuestion.required}
						files={fifthQuestion.files}
						hasError={errors.includes(fifthQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFifthQuestion}
						subSteps={subSteps}
						maxFiles={fifthQuestion.numberFilesRequired}
						description={fifthQuestion.description}
					></RequestFiles>

					{(showSecondAddButton || showThirdAddButton) && (
						<RequestFiles
							subtitle={sixthQuestion.label}
							name={sixthQuestion.name}
							required={sixthQuestion.required}
							files={sixthQuestion.files}
							hasError={errors.includes(sixthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileSixthQuestion}
							subSteps={subSteps}
							maxFiles={sixthQuestion.numberMaxFiles}
							description={sixthQuestion.description}
						></RequestFiles>
					)}
					<RequestFiles
						subtitle={seventhQuestion.label}
						name={seventhQuestion.name}
						required={seventhQuestion.required}
						files={seventhQuestion.files}
						hasError={errors.includes(seventhQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileSeventhQuestion}
						subSteps={subSteps}
						maxFiles={seventhQuestion.numberMaxFiles}
						description={seventhQuestion.description}
					></RequestFiles>
				</section>
			</Collapse>
		</>
	)
}

export default CrackedHousesForm
