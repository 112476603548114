import { Colors, Constants } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { RadioList, RequestInputs } from '@utils/request'
import Breakpoints from '@utils/breakpoints'
import { AddressInfo } from '../map/mapForm.interfaces'
import { AddressCoordinates } from '@services/models'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'

export type ConcernedInfosSectionProps = {
	form?: string
	errors?: string[]
	inputs?: RequestInputs[]
	isConcernedInfosRequired: boolean
	subStep?: string
	requiredFields?: string[]
	fieldsAlternativeLabel?: any
	onFixError?: React.Dispatch<React.SetStateAction<string[]>>
	onSetInputs?: (inputs: RequestInputs[]) => void
	onAddressInputChange?: (value: string) => void
	onGetCadastralAndMatriculeNumber?: (coordinates: AddressCoordinates) => void
	isSameAddressAsPersonalInfor?: boolean
	renderAddressInfo?: () => EmotionJSX.Element | undefined
	appartmentList?: RadioList[]
}

type Classes = {
	container: string
	h3: string
	label: string
	formInput: string
	searchAddressDisabled: string
	map: string
	formLabel: string
	labelError: string
	required: string
	field: string
	fieldsContainer: string
	helpText: string
}

export const classes: Classes = makeClasses({
	container: {
		background: '#eee',
		padding: '20px',
		marginBottom: '10px'
	},
	h3: {
		color: Colors.primary,
		marginTop: 0,
		marginBottom: '25px'
	},
	label: {
		margin: '5px 0px',
		':first-child': {
			marginTop: '0px'
		},
		'& span': {
			display: 'block',
			margin: '10px 0px 20px',
			lineHeight: '24px'
		}
	},
	labelError: {
		color: Colors.errorRed,
		marginBottom: '12px'
	},
	formInput: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',

		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400 as any,
		padding: '12px',
		borderRadius: '0px',
		lineHeight: '26px',
		marginBottom: '10px',
		'&:focus': {
			outline: 'none'
		},
		height: '46px'
	},
	searchAddressDisabled: {
		background: Colors.lightGrey4
	},
	map: {
		':focus': {
			border: `1px solid ${Colors.primary}`
		}
	},
	formLabel: {
		margin: '0px',
		paddingBottom: 0,
		fontSize: '16px',
		fontWeight: 'bold' as any,
		display: 'block'
	},
	required: {
		':after': {
			content: '"*"',
			marginLeft: '5px',
			color: Colors.ligthPurple
		}
	},
	field: {
		'& > div': {
			padding: '0px'
		},
		'& .notification': {
			marginBottom: '20px !important',
			marginTop: '-24px'
		},
		'& .soleField input': {
			width: '100% !important',
			padding: 'none'
		}
	},
	fieldsContainer: {
		[Breakpoints.minWidth('md')]: {
			display: 'grid',
			columnGap: '20px',
			gridTemplateColumns: '1fr 1fr',
			alignItems: 'end'
		}
	},
	helpText: {
		lineHeight: '1.62em',
		color: '#999',
		fontWeight: '400',
		fontSize: '14px',
		margin: 'unset',
		position: 'relative',
		top: '-16px'
	}
})

export enum ConcernFieldNames {
	concernedLastName = 'concernedLastName',
	concernedFirstName = 'concernedFirstName',
	concernedHasLavalAdresse = 'concernedHasLavalAdresse',
	concernedAdresse = 'concernedAdresse',
	concernedAppartement = 'concernedAppartement',
	concernedEmail = 'concernedEmail',
	concernedPhoneNumber = 'concernedPhoneNumber',
	concernedCity = 'concernedCity',
	concernedProvince = 'concernedProvince',
	concernedPostalCode = 'concernedPostalCode',
	concernedCountry = 'concernedCountry'
}
