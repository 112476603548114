import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	RequestCategoryEnum,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import Text from '@components/ui/text'
import SelectInput from './inputs/select.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import RequestFiles from '@components/ui/requestFiles'
import RequestFilesWithMultipleButtons from '@components/ui/requestFilesWithMultipleButtons'
import {
	formatStrapiText,
	getFilesFromStore,
	getSeparetedFilesFromStore
} from '@utils/methods'
import Notification from '@components/ui/notification'
import MaskedTextInput from './inputs/textWithValidation.inputs'
import * as formUtils from '@pages/auth/__index.utils'
import { PHONE_NUMBER_REGEX } from '@pages/auth/profile/__index.utils'
import RequiredIcon from '@components/ui/requiredIcon'

const HeritageBuildingsRevitalizationProgram: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		ownerSection: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		addMarginTop: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},
		ownerSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '47.5%47.5%',
			columnGap: '5%',
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '100%'
			},
			'& > div': {
				marginBottom: '8px'
			}
		},
		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		addMarginTop: {
			marginTop: '10px'
		}
	})

	const answers = useAppSelector((state) => state.request.configs)

	const [showFirstAddButton, setShowFirstAddButton] = useState<boolean>(true)
	const [showSecondAddButton, setShowSecondAddButton] = useState<boolean>(false)
	const [showThirdAddButton, setShowThirdAddButton] = useState<boolean>(false)
	const location = useAppSelector((state) => state.request.location)

	const [firstOwnersLastNameInput, setFirstOwnersLastNameInput] =
		useState<RequestInputs>({
			name: 'firstOwnersLastName',
			label: pageAssets?.label_lastName,
			labelKey: 'label_lastName',
			value: answers.firstOwnersLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [firstOwnersFirstNameInput, setFirstOwnersFirstNameInput] =
		useState<RequestInputs>({
			name: 'firstOwnersFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.firstOwnersFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [firstOwnersEmailInput, setFirstOwnersEmailInput] =
		useState<RequestInputs>({
			name: 'firstOwnersEmail',
			label: pageAssets?.label_email,
			labelKey: 'label_email',
			value: answers.firstOwnersEmail || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [firstOwnersPhoneNumberInput, setFirstOwnersPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'firstOwnersPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.firstOwnersPhoneNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersLastNameInput, setSecondOwnersLastNameInput] =
		useState<RequestInputs>({
			name: 'secondOwnersLastName',
			label: pageAssets?.label_lastName,
			labelKey: 'label_lastName',
			value: answers.secondOwnersLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersFirstNameInput, setSecondOwnersFirstNameInput] =
		useState<RequestInputs>({
			name: 'secondOwnersFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.secondOwnersFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersEmailInput, setSecondOwnersEmailInput] =
		useState<RequestInputs>({
			name: 'secondOwnersEmail',
			label: pageAssets?.label_email,
			labelKey: 'label_email',
			value: answers.secondOwnersEmail || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [secondOwnersPhoneNumberInput, setSecondOwnersPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'secondOwnersPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.secondOwnersPhoneNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [
		buildingAddressSameHasApplicantInput,
		setBuildingAddressSameHasApplicantInput
	] = useState<RequestInputs>({
		name: 'buildingAddressSameHasApplicant',
		value: 'true',
		label: '',
		labelKey: '',
		required: false
	})

	const [buildingAddressInput, setBuildingAddressInput] =
		useState<RequestInputs>({
			name: 'buildingAddress',
			value: location.cadastralAddress || location.address,
			label: '',
			labelKey: '',
			required: false
		})

	enum OWNER {
		FIRST,
		SECOND
	}

	const [buildingTypeInput, setBuildingTypeInput] = useState<RequestInputs>({
		name: 'buildingType',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_buildingType_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_buildingType_label',
		value: answers.buildingType?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [buildingCategoryInput, setBuildingCategoryInput] =
		useState<RequestInputs>({
			name: 'buildingCategory',
			label:
				pageAssets?.request_form_heritageBuildingsRevitalizationProgram_buildingCategoryInput_label,
			labelKey:
				'request_form_heritageBuildingsRevitalizationProgram_buildingCategoryInput_label',
			value: answers.buildingCategory?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const buildingTypeInputList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_buildingTypeInput_heritageBuilding,
			value: REQUEST_CONFIGS_KEYS.buildingType.heritageBuilding,
			key: 'select_buildingTypeInput_heritageBuilding'
		},
		{
			label: pageAssets?.select_buildingTypeInput_calvary,
			value: REQUEST_CONFIGS_KEYS.buildingType.calvary,
			key: 'select_buildingTypeInput_calvary'
		},
		{
			label: pageAssets?.select_buildingTypeInput_crossOfTheRoad,
			value: REQUEST_CONFIGS_KEYS.buildingType.crossOfTheRoad,
			key: 'select_buildingTypeInput_crossOfTheRoad'
		}
	]

	const buildingCategoryInputList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_buildingCategory_residential,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.residential,
			key: 'select_buildingCategory_residential'
		},
		{
			label: pageAssets?.select_buildingCategory_commercial,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.commercial,
			key: 'select_buildingCategory_commercial'
		},
		{
			label: pageAssets?.select_buildingCategory_industrial,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.industrial,
			key: 'select_buildingCategory_industrial'
		},
		{
			label: pageAssets?.select_buildingCategory_institutional,
			value: REQUEST_CONFIGS_KEYS.buildingCategory.institutional,
			key: 'select_buildingCategory_institutional'
		}
	]

	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_firstInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_firstInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_firstInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		category: requestCategories.identityDocument
	})

	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_secondInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_secondInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_secondInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		category: requestCategories.photographicEvidence
	})

	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_thirdInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_thirdInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_thirdInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		category: requestCategories.technicalSpecification
	})

	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_fourthInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_fourthInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_fourthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required: true,
		numberFilesRequired: 1,
		category: requestCategories.submission
	})

	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_fifthInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_fifthInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_fifthInput_description,
		files: new Set<File>(),
		required: true,
		numberFilesRequired: 1,
		separateFiles:
			filesInputs?.find((el) => el.name == 'fifthInput')?.separateFiles || {},
		category: requestCategories.technicalSpecification
	})

	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_sixthInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_sixthInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_sixthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required: false,
		numberFilesRequired: 1,
		numberMaxFiles: 10,
		category: requestCategories.photographicEvidence
	})

	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_seventhInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_seventhInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_seventhInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'seventhInput')?.files || []
		),
		required: false,
		numberFilesRequired: 1,
		category: requestCategories.technicalPlan
	})

	const [eighthQuestion, setEighthQuestion] = useState<RequestInputFiles>({
		name: 'eighthInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_eighthInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_eighthInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_eighthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthInput')?.files || []
		),
		required: false,
		numberFilesRequired: 1,
		category: requestCategories.other
	})

	const [ninthQuestion, setNinthQuestion] = useState<RequestInputFiles>({
		name: 'ninthInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_ninthInput_label,
		labelKey:
			'request_form_heritageBuildingsRevitalizationProgram_ninthInput_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_ninthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthInput')?.files || []
		),
		required: false,
		numberFilesRequired: 1,
		category: requestCategories.proofOfResidency
	})

	const [twelveQuestion, setTwelveQuestion] = useState<RequestInputFiles>({
		name: 'twelveInput',
		label:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_proxy_label,
		labelKey: 'request_form_heritageBuildingsRevitalizationProgram_proxy_label',
		description:
			pageAssets?.request_form_heritageBuildingsRevitalizationProgram_proxy_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'twelveInput')?.files || []
		),
		required: false,
		numberMaxFiles: 2,
		category: requestCategories.proxy
	})

	const [duplicateOwner, setDuplicateOwner] = useState<boolean>(false)

	const clearFirstOwnerFields = () => {
		setFirstOwnersEmailInput({
			...firstOwnersEmailInput,
			required: false,
			value: ''
		})
		setFirstOwnersFirstNameInput({
			...firstOwnersFirstNameInput,
			required: false,
			value: ''
		})
		setFirstOwnersLastNameInput({
			...firstOwnersLastNameInput,
			required: false,
			value: ''
		})
		setFirstOwnersPhoneNumberInput({
			...firstOwnersPhoneNumberInput,
			required: false,
			value: ''
		})

		// Vider les errors concenant ces inputs
		onFixError(
			errors.filter(
				(error) =>
					![
						firstOwnersEmailInput.name,
						firstOwnersFirstNameInput.name,
						firstOwnersLastNameInput.name,
						firstOwnersPhoneNumberInput.name
					].includes(error)
			)
		)
	}

	const clearSecondUserFields = () => {
		setSecondOwnersLastNameInput({
			...secondOwnersLastNameInput,
			required: false,
			value: ''
		})
		setSecondOwnersEmailInput({
			...secondOwnersEmailInput,
			required: false,
			value: ''
		})
		setSecondOwnersFirstNameInput({
			...secondOwnersFirstNameInput,
			required: false,
			value: ''
		})
		setSecondOwnersPhoneNumberInput({
			...secondOwnersPhoneNumberInput,
			required: false,
			value: ''
		})

		// Vider les errors concenant ces inputs
		onFixError(
			errors.filter(
				(error) =>
					![
						secondOwnersLastNameInput.name,
						secondOwnersEmailInput.name,
						secondOwnersFirstNameInput.name,
						secondOwnersPhoneNumberInput.name
					].includes(error)
			)
		)
	}

	const setFirstOwnerFields = () => {
		setFirstOwnersEmailInput({ ...firstOwnersEmailInput, required: true })
		setFirstOwnersFirstNameInput({
			...firstOwnersFirstNameInput,
			required: true
		})
		setFirstOwnersLastNameInput({ ...firstOwnersLastNameInput, required: true })
		setFirstOwnersPhoneNumberInput({
			...firstOwnersPhoneNumberInput,
			required: true
		})
	}

	const setSecondOwnerFIelds = () => {
		setSecondOwnersLastNameInput({
			...secondOwnersLastNameInput,
			required: true
		})
		setSecondOwnersEmailInput({ ...secondOwnersEmailInput, required: true })
		setSecondOwnersFirstNameInput({
			...secondOwnersFirstNameInput,
			required: true
		})
		setSecondOwnersPhoneNumberInput({
			...secondOwnersPhoneNumberInput,
			required: true
		})
	}

	const clearOwner = (owner: OWNER) => {
		if (owner == OWNER.FIRST) {
			setShowSecondAddButton(false)
			clearFirstOwnerFields()
		} else if (owner == OWNER.SECOND) {
			setShowThirdAddButton(false)
			clearSecondUserFields()
		}
	}

	const showOwner = (owner: OWNER) => {
		switch (owner) {
			case OWNER.FIRST: {
				setShowFirstAddButton(false)
				setShowSecondAddButton(true)
				setFirstOwnerFields()
				break
			}
			case OWNER.SECOND: {
				if (showSecondAddButton) {
					setShowThirdAddButton(true)
					setSecondOwnerFIelds()
				} else {
					setShowSecondAddButton(true)
					setFirstOwnerFields()
				}
			}
		}
	}

	const onSetFirstOwnersLastNameInput = (value: string) => {
		setFirstOwnersLastNameInput({ ...firstOwnersLastNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersLastNameInput.name)
		)
	}

	const onSetFirstOwnersFirstNameInput = (value: string) => {
		setFirstOwnersFirstNameInput({ ...firstOwnersFirstNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersFirstNameInput.name)
		)
	}

	const onSetFirstOwnersEmailInput = (value: string) => {
		setFirstOwnersEmailInput({ ...firstOwnersEmailInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersEmailInput.name)
		)
	}

	const onSetFirstOwnersPhoneNumberInput = (value: string) => {
		setFirstOwnersPhoneNumberInput({ ...firstOwnersPhoneNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, firstOwnersPhoneNumberInput.name)
		)
	}

	const onSetSecondOwnersLastNameInput = (value: string) => {
		setSecondOwnersLastNameInput({ ...secondOwnersLastNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersLastNameInput.name)
		)
	}

	const onSetSecondOwnersFirstNameInput = (value: string) => {
		setSecondOwnersFirstNameInput({ ...secondOwnersFirstNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersFirstNameInput.name)
		)
	}

	const onSetSecondOwnersEmailInput = (value: string) => {
		setSecondOwnersEmailInput({ ...secondOwnersEmailInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersEmailInput.name)
		)
	}

	const onSetSecondOwnersPhoneNumberInput = (value: string) => {
		setSecondOwnersPhoneNumberInput({ ...secondOwnersPhoneNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, secondOwnersPhoneNumberInput.name)
		)
	}

	const onSetBuildingTypeInput = (value: string) => {
		setBuildingTypeInput({ ...buildingTypeInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, buildingTypeInput.name))
	}

	const onSetBuildingCategoryInput = (value: string) => {
		setBuildingCategoryInput({ ...buildingCategoryInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, buildingCategoryInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersLastNameInput))
	}, [firstOwnersLastNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersFirstNameInput))
	}, [firstOwnersFirstNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersEmailInput))
	}, [firstOwnersEmailInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, firstOwnersPhoneNumberInput))
	}, [firstOwnersPhoneNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersLastNameInput))
	}, [secondOwnersLastNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersFirstNameInput))
	}, [secondOwnersFirstNameInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersEmailInput))
	}, [secondOwnersEmailInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, secondOwnersPhoneNumberInput))
	}, [secondOwnersPhoneNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingTypeInput))
	}, [buildingTypeInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingCategoryInput))
	}, [buildingCategoryInput])

	useEffect(() => {
		if (!showSecondAddButton && !showThirdAddButton) {
			setShowFirstAddButton(true)
		}
	}, [showSecondAddButton, showThirdAddButton])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingAddressInput))
	}, [buildingAddressInput])

	useEffect(() => {
		if (
			location.coordinates.latitude ==
				authUser?.profile?.address.coordinates?.latitude &&
			location.coordinates.longitude ==
				authUser?.profile?.address.coordinates?.longitude
		) {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'true'
			})
		} else {
			setBuildingAddressSameHasApplicantInput({
				...buildingAddressSameHasApplicantInput,
				value: 'false'
			})
		}
	}, [location.coordinates])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingAddressSameHasApplicantInput)
		)
	}, [buildingAddressSameHasApplicantInput])

	useEffect(() => {
		if (answers.firstOwnersEmail) {
			setTwelveQuestion((currentValue) => ({
				...currentValue,
				required: true
			}))
		} else {
			setTwelveQuestion((currentValue) => ({
				...currentValue,
				required: false
			}))
		}
	}, [answers.firstOwnersEmail])

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		setFirstQuestion({
			...firstQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		setSecondQuestion({
			...secondQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		setThirdQuestion({
			...thirdQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, thirdQuestion.name))
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		setFourthQuestion({
			...fourthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, fourthQuestion.name))
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		setSixthQuestion({
			...sixthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, sixthQuestion.name))
	}

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		setSeventhQuestion({
			...seventhQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, seventhQuestion.name))
	}

	const onSelectFileEighthQuestion = (files: Set<File>) => {
		setEighthQuestion({
			...eighthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, eighthQuestion.name))
	}

	const onSelectFileNinthQuestion = (files: Set<File>) => {
		setNinthQuestion({
			...ninthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, ninthQuestion.name))
	}
	const onSelectFileTwelveQuestion = (files: Set<File>) => {
		setTwelveQuestion({
			...twelveQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, twelveQuestion.name))
	}

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthQuestion))
	}, [eighthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthQuestion))
	}, [ninthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, twelveQuestion))
	}, [twelveQuestion])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// fifthQuestion input
		// separateFiles
		if (
			!!answers.fifthInput &&
			Object.keys(fifthQuestion.separateFiles!).length == 0
		) {
			getSeparetedFilesFromStore('fifthInput', answers).then((result) => {
				setFifthQuestion({
					...fifthQuestion,
					separateFiles: result
				})
			})
		}
		// firstInput input
		if (!!answers.firstInput && firstQuestion.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setFirstQuestion({
					...firstQuestion,
					files: result
				})
			})
		}
		// secondInput input
		if (!!answers.secondInput && secondQuestion.files.size == 0) {
			getFilesFromStore('secondInput', answers).then((result: Set<File>) => {
				setSecondQuestion({
					...secondQuestion,
					files: result
				})
			})
		}
		// thirdInput input
		if (!!answers.thirdInput && thirdQuestion.files.size == 0) {
			getFilesFromStore('thirdInput', answers).then((result: Set<File>) => {
				setThirdQuestion({
					...thirdQuestion,
					files: result
				})
			})
		}
		// fouthInput input
		if (!!answers.fourthInput && fourthQuestion.files.size == 0) {
			getFilesFromStore('fourthInput', answers).then((result: Set<File>) => {
				setFourthQuestion({
					...fourthQuestion,
					files: result
				})
			})
		}
		// seventhInput input
		if (!!answers.seventhInput && seventhQuestion.files.size == 0) {
			getFilesFromStore('seventhInput', answers).then((result: Set<File>) => {
				setSeventhQuestion({
					...seventhQuestion,
					files: result
				})
			})
		}
		// sixthInput input
		if (!!answers.sixthInput && sixthQuestion.files.size == 0) {
			getFilesFromStore('sixthInput', answers).then((result: Set<File>) => {
				setSixthQuestion({
					...sixthQuestion,
					files: result
				})
			})
		}
		// eighthInput input
		if (!!answers.eighthInput && eighthQuestion.files.size == 0) {
			getFilesFromStore('eighthInput', answers).then((result: Set<File>) => {
				setEighthQuestion({
					...eighthQuestion,
					files: result
				})
			})
		}
		// ninthInput input
		if (!!answers.ninthInput && ninthQuestion.files.size == 0) {
			getFilesFromStore('ninthInput', answers).then((result: Set<File>) => {
				setNinthQuestion({
					...ninthQuestion,
					files: result
				})
			})
		}
		// twelveInput input
		if (!!answers.twelveInput && twelveQuestion.files.size == 0) {
			getFilesFromStore('twelveInput', answers).then((result: Set<File>) => {
				setTwelveQuestion({
					...twelveQuestion,
					files: result
				})
			})
		}

		// if first owner exist, show the collapse
		if (
			!!answers.firstOwnersEmail ||
			!!answers.firstOwnersLastName ||
			!!answers.firstOwnersFirstName ||
			!!answers.firstOwnersPhoneNumber
		) {
			setShowFirstAddButton(false)
			setShowSecondAddButton(true)
		}

		// if second owner exist, show the collapse
		if (
			!!answers.secondOwnersEmail ||
			!!answers.secondOwnersLastName ||
			!!answers.secondOwnersFirstName ||
			!!answers.secondOwnersPhoneNumber
		) {
			setShowFirstAddButton(false)
			setShowSecondAddButton(true)
			setShowThirdAddButton(true)
		}
	}, [])
	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	useEffect(() => {
		onSetSubSteps({
			map: true,
			details_1: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.MAP,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.MAP,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})
	}, [])

	useEffect(() => {
		const linksContainer = document.getElementsByClassName('link-redirect')
		Array.prototype.forEach.call(linksContainer, (container: HTMLElement) => {
			const links = container.getElementsByTagName('a')
			Array.prototype.forEach.call(links, (link) => {
				link.setAttribute('target', '_blank')
			})
		})
	}, [showSecondAddButton])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_grant_details}
							</h4>
						</div>

						<h5 className={pageUtils.classes.h5}>
							{pageAssets?.request_form_otherOwnersIfAny_section_title}
						</h5>

						{answers.firstOwnersFirstName && (
							<>
								<div>
									<strong>
										{pageAssets[firstOwnersLastNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersLastName}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[firstOwnersFirstNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersFirstName}
									</span>
								</div>

								<div>
									<strong>{pageAssets[firstOwnersEmailInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersEmail}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[firstOwnersPhoneNumberInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.firstOwnersPhoneNumber}
									</span>
								</div>
							</>
						)}

						{answers.secondOwnersFirstName && (
							<>
								<div>
									<strong>
										{pageAssets[secondOwnersLastNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersLastName}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[secondOwnersFirstNameInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersFirstName}
									</span>
								</div>

								<div>
									<strong>{pageAssets[secondOwnersEmailInput.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersEmail}
									</span>
								</div>

								<div>
									<strong>
										{pageAssets[secondOwnersPhoneNumberInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.secondOwnersPhoneNumber}
									</span>
								</div>
							</>
						)}

						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_concernedBuildingInformations_section_title
							}
						</h5>

						<div>
							<strong>{pageAssets[buildingTypeInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										buildingTypeInputList.find(
											(el) => el.value == answers.buildingType?.toString()
										)?.key!
									]
								}
							</span>
						</div>

						<div>
							<strong>{pageAssets[buildingCategoryInput.labelKey]}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										buildingCategoryInputList.find(
											(el) => el.value == answers.buildingCategory?.toString()
										)?.key!
									]
								}
							</span>
						</div>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>
						</div>

						<RequestFiles
							subtitle={firstQuestion.label}
							name={firstQuestion.name}
							required={firstQuestion.required}
							files={firstQuestion.files}
							hasError={errors.includes(firstQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFirstQuestion}
							maxFiles={firstQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={secondQuestion.label}
							name={secondQuestion.name}
							required={secondQuestion.required}
							files={secondQuestion.files}
							hasError={errors.includes(secondQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileSecondQuestion}
							maxFiles={secondQuestion.numberMaxFiles}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={thirdQuestion.label}
							name={thirdQuestion.name}
							required={thirdQuestion.required}
							files={thirdQuestion.files}
							hasError={errors.includes(thirdQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileThirdQuestion}
							maxFiles={thirdQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={fourthQuestion.label}
							name={fourthQuestion.name}
							required={fourthQuestion.required}
							files={fourthQuestion.files}
							hasError={errors.includes(fourthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFourthQuestion}
							maxFiles={fourthQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFilesWithMultipleButtons
							input={fifthQuestion}
							hasError={errors.includes(fifthQuestion.name)}
							setInput={setFifthQuestion}
							numberOfFiles={2}
							onSetHasError={onSetErrorFromFileComponent}
							onFixError={onFixError}
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
							errors={errors}
							steps={steps!}
							subSteps={subSteps!}
							formPosition={formPosition!}
							buttonLabels={
								pageAssets?.request_from_residence_submission_number
							}
							isSummary
						>
							<></>
						</RequestFilesWithMultipleButtons>
						{answers.firstOwnersEmail && twelveQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={
									pageAssets?.request_form_heritageBuildingsRevitalizationProgram_declaration_of_other_owners_label
								}
								name={twelveQuestion.name}
								required
								files={twelveQuestion.files}
								hasError={errors.includes(twelveQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileTwelveQuestion}
								maxFiles={twelveQuestion.numberMaxFiles}
								isSummary
							>
								<></>
							</RequestFiles>
						)}

						{sixthQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={sixthQuestion.label}
								name={sixthQuestion.name}
								required={sixthQuestion.required}
								files={sixthQuestion.files}
								hasError={errors.includes(sixthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSixthQuestion}
								maxFiles={sixthQuestion.numberMaxFiles}
								isSummary
							>
								<></>
							</RequestFiles>
						)}

						{seventhQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={seventhQuestion.label}
								name={seventhQuestion.name}
								required={seventhQuestion.required}
								files={seventhQuestion.files}
								hasError={errors.includes(seventhQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSeventhQuestion}
								maxFiles={seventhQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>
						)}

						{eighthQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={eighthQuestion.label}
								name={eighthQuestion.name}
								required={eighthQuestion.required}
								files={eighthQuestion.files}
								hasError={errors.includes(eighthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileEighthQuestion}
								maxFiles={eighthQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>
						)}

						{ninthQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={ninthQuestion.label}
								name={ninthQuestion.name}
								required={ninthQuestion.required}
								files={ninthQuestion.files}
								hasError={errors.includes(ninthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileNinthQuestion}
								maxFiles={ninthQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
				}
			>
				<section>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_grant_details}
					</h4>

					<h5
						className={joinClasses([
							pageUtils.classes.h5,
							pageUtils.classes.marginBottom,
							duplicateOwner ? pageUtils.classes.error : ''
						])}
					>
						{pageAssets?.request_form_otherOwnersIfAny_section_title}
					</h5>

					<Collapse isOpened={duplicateOwner}>
						<Notification
							type="error"
							text={
								pageAssets?.request_form_heritageBuildingsRevitalizationProgram_owners_error
							}
						/>
					</Collapse>

					{showFirstAddButton && (
						<a
							style={{ marginBottom: showFirstAddButton ? '12px' : '' }}
							onClick={() => showOwner(OWNER.FIRST)}
							className={classes.button}
						>
							{pageAssets?.add}
						</a>
					)}

					{showSecondAddButton && (
						<Collapse isOpened={showSecondAddButton}>
							<div
								className={joinClasses([
									pageUtils.classes.applyMargin,
									pageUtils.classes.bottomMargin,
									'link-redirect'
								])}
							>
								{showSecondAddButton && (
									<Text
										content={
											pageAssets?.request_form_otherOwnersIfAny_will_also_need
										}
									/>
								)}
							</div>
							<div className={classes.ownerSection}>
								<TextInput
									id={firstOwnersLastNameInput.name}
									label={firstOwnersLastNameInput.label}
									value={firstOwnersLastNameInput.value}
									onChange={onSetFirstOwnersLastNameInput}
									hasError={errors.includes(firstOwnersLastNameInput.name)}
									required
									autoComplete="family-name"
									ref={
										firstOwnersLastNameInput.ref as RefObject<HTMLInputElement>
									}
								/>

								<TextInput
									id={firstOwnersFirstNameInput.name}
									label={firstOwnersFirstNameInput.label}
									value={firstOwnersFirstNameInput.value}
									onChange={onSetFirstOwnersFirstNameInput}
									hasError={errors.includes(firstOwnersFirstNameInput.name)}
									required
									autoComplete="given-name"
									ref={
										firstOwnersFirstNameInput.ref as RefObject<HTMLInputElement>
									}
								/>

								<TextInput
									id={firstOwnersEmailInput.name}
									label={firstOwnersEmailInput.label}
									value={firstOwnersEmailInput.value}
									onChange={onSetFirstOwnersEmailInput}
									type="email"
									hasError={errors.includes(firstOwnersEmailInput.name)}
									onSetError={onFixError}
									schema={formUtils.MASKS.email}
									schemaErrorText={pageAssets.label_emailErrorText}
									required
									autoComplete="email"
									ref={firstOwnersEmailInput.ref as RefObject<HTMLInputElement>}
								/>

								<MaskedTextInput
									id={firstOwnersPhoneNumberInput.name}
									label={firstOwnersPhoneNumberInput.label}
									value={firstOwnersPhoneNumberInput.value}
									onChange={onSetFirstOwnersPhoneNumberInput}
									type="tel"
									hasError={errors.includes(firstOwnersPhoneNumberInput.name)}
									min={10}
									max={14}
									mask={formUtils.MASKS.phoneNumber}
									placeholder={formUtils.MASKS.phoneNumber}
									errors={errors}
									setError={onFixError}
									required
									schema={PHONE_NUMBER_REGEX}
									ref={
										firstOwnersPhoneNumberInput.ref as RefObject<HTMLInputElement>
									}
								/>
							</div>
						</Collapse>
					)}

					<div
						style={{ marginBottom: showThirdAddButton ? '12px' : '' }}
						className={classes.buttonContainer}
					>
						{showSecondAddButton && (
							<a
								onClick={() => clearOwner(OWNER.FIRST)}
								className={joinClasses([
									classes.button,
									classes.outlinedButton
								])}
							>
								{pageAssets?.request_form_removeOwner_button_title}
							</a>
						)}
						{!showThirdAddButton && !showFirstAddButton && (
							<a
								onClick={() => showOwner(OWNER.SECOND)}
								className={classes.button}
							>
								{pageAssets?.add}
							</a>
						)}
					</div>

					{showThirdAddButton && (
						<Collapse isOpened={showThirdAddButton}>
							<>
								<div className={classes.ownerSection}>
									<TextInput
										id={secondOwnersLastNameInput.name}
										label={secondOwnersLastNameInput.label}
										value={secondOwnersLastNameInput.value}
										type="text"
										onChange={onSetSecondOwnersLastNameInput}
										hasError={errors.includes(secondOwnersLastNameInput.name)}
										required
										autoComplete="family-name"
										ref={
											secondOwnersLastNameInput.ref as RefObject<HTMLInputElement>
										}
									/>

									<TextInput
										id={secondOwnersFirstNameInput.name}
										label={secondOwnersFirstNameInput.label}
										value={secondOwnersFirstNameInput.value}
										onChange={onSetSecondOwnersFirstNameInput}
										hasError={errors.includes(secondOwnersFirstNameInput.name)}
										required
										autoComplete="given-name"
										ref={
											secondOwnersFirstNameInput.ref as RefObject<HTMLInputElement>
										}
									/>

									<TextInput
										id={secondOwnersEmailInput.name}
										label={secondOwnersEmailInput.label}
										value={secondOwnersEmailInput.value}
										onChange={onSetSecondOwnersEmailInput}
										type="email"
										onSetError={onFixError}
										schema={formUtils.MASKS.email}
										schemaErrorText={pageAssets.label_emailErrorText}
										hasError={errors.includes(secondOwnersEmailInput.name)}
										required
										autoComplete="email"
										ref={
											secondOwnersEmailInput.ref as RefObject<HTMLInputElement>
										}
									/>

									<MaskedTextInput
										id={secondOwnersPhoneNumberInput.name}
										label={secondOwnersPhoneNumberInput.label}
										value={secondOwnersPhoneNumberInput.value}
										onChange={onSetSecondOwnersPhoneNumberInput}
										hasError={errors.includes(
											secondOwnersPhoneNumberInput.name
										)}
										type="tel"
										min={10}
										max={14}
										mask={formUtils.MASKS.phoneNumber}
										placeholder={formUtils.MASKS.phoneNumber}
										errors={errors}
										setError={onFixError}
										schema={PHONE_NUMBER_REGEX}
										required
										ref={
											secondOwnersPhoneNumberInput.ref as RefObject<HTMLInputElement>
										}
									/>
								</div>
								<div className={pageUtils.classes.applyMargin}>
									{showSecondAddButton && (
										<div
											className={joinClasses([
												pageUtils.classes.notification,
												'link-redirect'
											])}
										>
											<Text
												content={
													pageAssets?.request_form_otherOwnersIfAny_send_proxies
												}
											/>
										</div>
									)}
								</div>
								<div className={classes.buttonContainer}>
									<a
										onClick={() => clearOwner(OWNER.SECOND)}
										className={joinClasses([
											classes.button,
											classes.outlinedButton
										])}
									>
										{pageAssets?.request_form_removeOwner_button_title}
									</a>
									{!showSecondAddButton && !showFirstAddButton && (
										<a
											onClick={() => showOwner(OWNER.SECOND)}
											className={classes.button}
										>
											{pageAssets?.add}
										</a>
									)}
								</div>
							</>
						</Collapse>
					)}

					<h5 className={pageUtils.classes.h5}>
						{
							pageAssets?.request_form_concernedBuildingInformations_section_title
						}
					</h5>

					<SelectInput
						id={buildingTypeInput.name}
						label={buildingTypeInput.label}
						value={buildingTypeInput.value}
						list={buildingTypeInputList}
						onChange={onSetBuildingTypeInput}
						hasError={errors.includes(buildingTypeInput.name)}
						required
						ref={buildingTypeInput.ref as RefObject<HTMLSelectElement>}
					/>

					<SelectInput
						id={buildingCategoryInput.name}
						label={buildingCategoryInput.label}
						value={buildingCategoryInput.value}
						list={buildingCategoryInputList}
						onChange={onSetBuildingCategoryInput}
						hasError={errors.includes(buildingCategoryInput.name)}
						required
						ref={buildingCategoryInput.ref as RefObject<HTMLSelectElement>}
					/>
				</section>
			</Collapse>
			<Collapse
				isOpened={
					subSteps !== undefined &&
					subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				}
			>
				<section className={pageUtils.classes.sectionNoPadding}>
					<h4 className={pageUtils.classes.h4}>
						{pageAssets?.request_form_required_documents}
					</h4>

					<RequestFiles
						subtitle={firstQuestion.label}
						name={firstQuestion.name}
						required
						files={firstQuestion.files}
						hasError={errors.includes(firstQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFirstQuestion}
						subSteps={subSteps}
						maxFiles={firstQuestion.numberFilesRequired}
						description={firstQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={secondQuestion.label}
						name={secondQuestion.name}
						required
						files={secondQuestion.files}
						hasError={errors.includes(secondQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileSecondQuestion}
						subSteps={subSteps}
						maxFiles={secondQuestion.numberMaxFiles}
						description={secondQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={thirdQuestion.label}
						name={thirdQuestion.name}
						required
						files={thirdQuestion.files}
						hasError={errors.includes(thirdQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileThirdQuestion}
						subSteps={subSteps}
						maxFiles={thirdQuestion.numberFilesRequired}
						description={thirdQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={fourthQuestion.label}
						name={fourthQuestion.name}
						required
						files={fourthQuestion.files}
						hasError={errors.includes(fourthQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFourthQuestion}
						subSteps={subSteps}
						maxFiles={fourthQuestion.numberFilesRequired}
						description={fourthQuestion.description}
					></RequestFiles>

					<RequestFilesWithMultipleButtons
						input={fifthQuestion}
						hasError={errors.includes(fifthQuestion.name)}
						setInput={setFifthQuestion}
						numberOfFiles={2}
						onSetHasError={onSetErrorFromFileComponent}
						onFixError={onFixError}
						UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
						errors={errors}
						steps={steps!}
						subSteps={subSteps!}
						formPosition={formPosition!}
						buttonLabels={pageAssets?.request_from_residence_submission_number}
						description={fifthQuestion.description}
					></RequestFilesWithMultipleButtons>

					{answers.firstOwnersEmail && (
						<RequestFiles
							subtitle={twelveQuestion.label}
							name={twelveQuestion.name}
							required
							files={twelveQuestion.files}
							hasError={errors.includes(twelveQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileTwelveQuestion}
							subSteps={subSteps}
							maxFiles={twelveQuestion.numberMaxFiles}
							description={twelveQuestion.description}
						></RequestFiles>
					)}

					<RequestFiles
						subtitle={sixthQuestion.label}
						name={sixthQuestion.name}
						required={sixthQuestion.required}
						files={sixthQuestion.files}
						hasError={errors.includes(sixthQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileSixthQuestion}
						subSteps={subSteps}
						maxFiles={sixthQuestion.numberMaxFiles}
						description={sixthQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={seventhQuestion.label}
						name={seventhQuestion.name}
						required={seventhQuestion.required}
						files={seventhQuestion.files}
						hasError={errors.includes(seventhQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileSeventhQuestion}
						subSteps={subSteps}
						maxFiles={seventhQuestion.numberFilesRequired}
						description={seventhQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={eighthQuestion.label}
						name={eighthQuestion.name}
						required={eighthQuestion.required}
						files={eighthQuestion.files}
						hasError={errors.includes(eighthQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileEighthQuestion}
						subSteps={subSteps}
						maxFiles={eighthQuestion.numberFilesRequired}
						description={eighthQuestion.description}
					></RequestFiles>

					<RequestFiles
						subtitle={ninthQuestion.label}
						name={ninthQuestion.name}
						required={ninthQuestion.required}
						files={ninthQuestion.files}
						hasError={errors.includes(ninthQuestion.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileNinthQuestion}
						subSteps={subSteps}
						maxFiles={ninthQuestion.numberFilesRequired}
						description={ninthQuestion.description}
					></RequestFiles>
				</section>
			</Collapse>
		</>
	)
}

export default HeritageBuildingsRevitalizationProgram
