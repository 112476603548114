import { useState, useEffect, useMemo } from 'react'
import { fetchPublicHolidays } from './__hooks.utils'

interface PublicHoliday {
	date: string
}

interface Province {
	holidays: PublicHoliday[]
}

interface ProvinceResponse {
	province: Province
}

type PublicHolidayHook = {
	publicHolidays: string[]
	isWeekday: (date: Date) => boolean
	isNotWorkday: (day: number | number[]) => (date: Date) => boolean
}

export const usePublicHolidaysData = (): PublicHolidayHook => {
	const [publicHolidays, setPublicHolidays] = useState<string[]>([])

	const getPublicHolidaysData: Promise<ProvinceResponse> = useMemo(async () => {
		return fetchPublicHolidays()
	}, [])

	useEffect(() => {
		getPublicHolidaysData
			.then((data) => {
				const publicHolidaysDates = data?.province?.holidays?.map(
					(holiday) => holiday.date
				)
				setPublicHolidays(publicHolidaysDates)
			})
			.catch((err) => console.error(err))
	}, [getPublicHolidaysData])

	const isNotWorkday = (
		dayNumber: number | number[]
	): ((date: Date) => boolean) => {
		return (date: Date): boolean => {
			const day = date.getDay()
			// Disable any days you pass
			if (Array.isArray(dayNumber) && dayNumber.includes(day)) {
				return false
			} else if (day === dayNumber) {
				return false
			}
			// Convert date to a string in ISO format (YYYY-MM-DD)
			const dateStr = date.toISOString().substr(0, 10)
			// Return true if the date is not a public holiday, false otherwise
			return !publicHolidays.includes(dateStr)
		}
	}

	const isWeekday = isNotWorkday([0, 6])

	return { publicHolidays, isWeekday, isNotWorkday }
}
